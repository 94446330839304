/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate } from "@hookstate/core";
import { Folder, FolderOpen, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import React from "react";
import { Catalog } from "../../../hooks/useCatalogState";
import { ProductCategory, ProductDepartment } from "../../../hooks/useInventoryState";
import { CategoryControl } from "./CategoryControl";


//-------------------------------------------------------------------------------------------------------
export type DepartmentControlProps = {
    catalog: Catalog;
    department: ProductDepartment;
    departments: ProductDepartment[];
}
export const DepartmentControl: React.FC<DepartmentControlProps> = props => {
    let isFilterActiveState = useHookstate<boolean>(props.departments.length === 1);
    let isFilterActive = isFilterActiveState.get();
    //let url = `/inventory/${props.catalog.Id}/${props.department.Id}`;
    //if (isFilterActive) {
    //    url = `/inventory/${props.catalog.Id}`;
    //}
    //const checkoutFormState = useCheckoutFormState();

    return (
        <React.Fragment key={props.department.Id}>
            <div
                css={css`
                    display:flex;
                    align-items:center;
                    padding:.1rem;
                    cursor: pointer;
                `}
                onClick={async () => {
                    return Promise.resolve().then(() => {
                        isFilterActive = (!isFilterActive);
                        isFilterActiveState.set(isFilterActive);
                    });
                }}>
                <div
                    css={css`
                        flex:1 1 auto;
                        padding-left:.5em;
                        text-indent: -.5em;
                        display: flex;
                        align-items: center;
                    `}>
                    <div css={css`flex: 0 0 auto;`}>
                        {!isFilterActive && (<Folder sx={{ fontSize: 12 }} />)}
                        {isFilterActive && (<FolderOpen sx={{ fontSize: 12 }} />)}
                    </div>
                    <div css={css`flex: 1 1 0;padding:0 10px;`}>{props.department.Name}</div>
                    {(props.department.hasExpander || (props.department.hasExpander === undefined)) && (
                        <div
                            css={css`
                                display: inline;
                                flex:0 0 auto;
                            `}>
                            {!isFilterActive &&
                                <KeyboardArrowRight css={css`font-size:.8rem;`} />
                            }
                            {isFilterActive &&
                                <KeyboardArrowDown css={css`font-size:.8rem;`} />
                            }
                        </div>
                    )}
                </div>
            </div>
            {isFilterActive && (
                <div css={css`margin:0 0 0 8px;border-left: 1px dotted #aaaaaa;`}>
                    {props.department.Categories && props.department.Categories.map((productCategory: ProductCategory) => (
                        <CategoryControl
                            key={productCategory.Id}
                            catalog={props.catalog}
                            department={props.department}
                            category={productCategory}
                            isParentFilterActive={isFilterActive} />
                    ))}
                </div>
            )}
        </React.Fragment>
    );
}