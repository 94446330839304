import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import { devtools } from '@hookstate/devtools';
import axios, { AxiosRequestConfig } from 'axios';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { Product } from './useInventoryState';
import { CheckoutFormState, useCheckoutFormState } from './useCheckoutFormState';
import { Catalog, CatalogState, useCatalogState } from './useCatalogState';
import { SiteLayoutState, useSiteLayoutState } from './useSiteLayoutState';
import { SessionState, useSessionState } from './useSessionState';

// state data
export class ProductDetailsModel {
    product?: Product;
    catalog?: Catalog;

    //isLoadingAvailability: boolean = false;
    //availability?: ProductAvailability;

    isLoadingProductDetails: boolean = false;
    productDetails?: ProductDetails;
    displayingImageNo?: number = 0;

    showPopup: boolean = false;
}

// make this a global state
const productDetailsStateStore = hookstate<ProductDetailsModel>(new ProductDetailsModel(), devtools({ key: 'ProductDetailsState' }));

// expose methods to get and modify the state
export class ProductDetailsState {
    private appConfigState: AppConfigState;
    private productDetailsState: State<ImmutableObject<ProductDetailsModel>>;
    private checkoutFormState: CheckoutFormState;
    private sessionState: SessionState;
    private catalogState: CatalogState;
    private siteLayoutState: SiteLayoutState;

    constructor(appConfigState: AppConfigState, productDetailsState: State<ImmutableObject<ProductDetailsModel>>, checkoutFormState: CheckoutFormState, sessionState: SessionState, catalogState: CatalogState, siteLayoutState: SiteLayoutState) {
        this.appConfigState = appConfigState;
        this.productDetailsState = productDetailsState;
        this.checkoutFormState = checkoutFormState;
        this.sessionState = sessionState;
        this.catalogState = catalogState;
        this.siteLayoutState = siteLayoutState;
    }

    get(): ImmutableObject<ProductDetailsModel> {
        return this.productDetailsState.get();
    }

    async setProductIdAsync(productid: string): Promise<void> {
        this.productDetailsState.isLoadingProductDetails.set(true);
        try {
            const warehouseid = this.checkoutFormState.get().warehouseIdValue;
            const locationid = this.checkoutFormState.get().locationIdValue;
            const fromDate = this.checkoutFormState.get().fromDateValue;
            const toDate = this.checkoutFormState.get().toDateValue;
            if (warehouseid && locationid) {
                const axiosRequestConfig: AxiosRequestConfig = {};
                if (this.sessionState.get().loggedIn) {
                    axiosRequestConfig.headers = {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                }
                const product = (await axios.get<Product>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/product/${productid}/warehouseid/${warehouseid}/locationid/${locationid}/fromdate/${fromDate}/todate/${toDate}`, axiosRequestConfig)).data;
                this.setProductAsync(product);
            }
        }
        catch (error) {
            this.siteLayoutState.showError(error);
        }
        finally {
            this.productDetailsState.isLoadingProductDetails.set(false);
        }
    }

    async setProductAsync(product?: Product): Promise<void> {
        if (product) {
            let catalogs = this.catalogState.get().catalogs ?? [];
            let catalog;
            if (catalogs.length > 0) {
                let catalogIndex = catalogs.findIndex(c => c.Id === product.WebCatalogId);
                if (catalogIndex === -1) {
                    await this.catalogState.loadAsync();
                    catalogIndex = catalogs.findIndex(c => c.Id === product.WebCatalogId);
                }
                if (catalogIndex !== -1) {
                    catalog = catalogs[catalogIndex];
                }
            }
            if (!catalog) {
                await this.catalogState.loadAsync();
                catalogs = this.catalogState.get().catalogs ?? [];
                const catalogIndex = catalogs.findIndex(c => c.Id === product.WebCatalogId);
                if (catalogIndex !== -1) {
                    catalog = catalogs[catalogIndex];
                }
            }
            if (catalog) {
                this.productDetailsState.catalog.set(catalog);
            }
        }

        this.productDetailsState.product.set(product);
        //this.productDetailsState.availability.set(undefined);
        //this.loadAvailabilityAsync();
        this.productDetailsState.productDetails.set(undefined);
        this.productDetailsState.displayingImageNo.set(0);
        setTimeout(() => {
            this.loadProductDetailsAsync();
        }, 0);
    }

    setShowPopup(isVisible: boolean): void {
        this.productDetailsState.showPopup.set(isVisible);
        if (!isVisible) {
            if (window.location.hash.startsWith('#/product/')) {
                window.history.back();
            }
        }
    }

    showNextImage(): void {
        const currentImageNo = this.productDetailsState.get().displayingImageNo ?? 0;
        const totalImages = this.productDetailsState.get().product?.Images?.length ?? 0;
        if (currentImageNo < totalImages) {
            this.productDetailsState.displayingImageNo.set(currentImageNo + 1);
        }
    }

    showPrevImage(): void {
        const currentImageNo = this.productDetailsState.get().displayingImageNo ?? 0;
        if (currentImageNo > 0) {
            this.productDetailsState.displayingImageNo.set(currentImageNo - 1);
        }
    }

    //hasAvailabilityPrerequisites(): boolean {
    //    const quoteRequestForm = this.quoteRequestFormState.get();
    //    return (quoteRequestForm.warehouseIdValue && quoteRequestForm.startDateValue && quoteRequestForm.stopDateValue) ? true : false;
    //}

    hasProductDetailsPrerequisites(): boolean {
        const quoteRequestForm = this.checkoutFormState.get();
        return (quoteRequestForm.warehouseIdValue && quoteRequestForm.fromDateValue && quoteRequestForm.toDateValue) ? true : false;
    }

    //async loadAvailabilityAsync(): Promise<void> {
    //    if (!this.productDetailsState.get().isLoadingAvailability && this.hasAvailabilityPrerequisites()) {
    //        const inventoryid = this.productDetailsState.get().product?.InventoryId;
    //        const warehouseid = this.quoteRequestFormState.get().warehouseIdValue
    //        const fromdate = this.quoteRequestFormState.get().startDateValue;
    //        const todate = this.quoteRequestFormState.get().stopDateValue;
    //        const availResponse = await axios.get<ProductAvailability>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/productavailability/inventoryid/${inventoryid}/warehouseid/${warehouseid}/fromdate/${fromdate}/todate/${todate}`, {
    //            headers: {
    //                Authorization: 'Bearer ' + this.sessionState.get().authToken
    //            }
    //        });
    //        if (availResponse.status === 200) {
    //            this.productDetailsState.availability.set(availResponse.data);
    //        }
    //    }
    //}

    async loadProductDetailsAsync(): Promise<void> {
        //if (!this.productDetailsState.get().isLoadingProductDetails && this.hasProductDetailsPrerequisites()) {
        //    const productid = this.productDetailsState.get().product?.Id;
        //    const locationid = this.checkoutFormState.get().locationIdValue;
        //    const warehouseid = this.checkoutFormState.get().warehouseIdValue
        //    const fromdate = this.checkoutFormState.get().fromDateValue;
        //    const todate = this.checkoutFormState.get().toDateValue;
        //    const productDetailsResponse = await axios.get<ProductDetails>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/productdetails/productid/${productid}/locationid/${locationid}/warehouseid/${warehouseid}/fromdate/${fromdate}/todate/${todate}`, {
        //        headers: {
        //            Authorization: 'Bearer ' + this.sessionState.get().authToken
        //        }
        //    });
        //    if (productDetailsResponse.status === 200) {
        //        this.productDetailsState.productDetails.set(productDetailsResponse.data);
        //    }
        //}
    }
}

//type ProductAvailability = {
//    TotalIn: number;
//    Total: number;
//}

type ProductDetails = {
    //Availability: ProductAvailability;
    //DailyRate: number;
    //WeeklyRate: number;
    //MonthlyRate: number;
    //CurrencySymbol: string;
    //CurrencyCode: string;
}


// useState function for consumers to use
export function useProductDetailsState(): ProductDetailsState {
    const appConfigState = useAppConfigState();
    const productDetailsState = useHookstate(productDetailsStateStore);
    const quoteRequestFormState = useCheckoutFormState();
    const sessionState = useSessionState();
    const catalogState = useCatalogState();
    const siteLayoutState = useSiteLayoutState();
    return new ProductDetailsState(appConfigState, productDetailsState, quoteRequestFormState, sessionState, catalogState, siteLayoutState);
}

