import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import axios from "axios";
import { devtools } from '@hookstate/devtools';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { SiteLayoutState, useSiteLayoutState } from './useSiteLayoutState';

export type StorefrontSettings = {
    IsStorefrontEnabled: boolean;
    IsUniversity: boolean;
    IsAnonymousAccessEnabled: boolean;
    SeparateQuoteRequestsByCatalog: boolean;
    MakeQuoteRequestIntoOrderOnSubmission: boolean;
    AutoplaySpeed: number;
    AutoplayImagesOnLandingPage: boolean;
    AutoplayImagesOnProductBrowser: boolean;
    AutoplayImagesOnProductDetails: boolean;
}

export class StorefrontSettingsModel {
    settings?: StorefrontSettings;
    preventLoading: boolean = false;
    isLoading: boolean = false;
}

const settingsStateStore = hookstate<StorefrontSettingsModel>(new StorefrontSettingsModel(), devtools({ key: 'StorefrontSettingsState' }));

async function waitUntilAsync(condition: () => boolean, time: number = 100) {
    while (!condition()) {
        await new Promise((resolve) => setTimeout(resolve, time));
    }
}

export class StorefrontSettingsState {
    private settingsState: State<StorefrontSettingsModel>;
    private appConfigState: AppConfigState;
    private siteLayoutState: SiteLayoutState;

    constructor(settingsState: State<StorefrontSettingsModel>, appConfigState: AppConfigState, siteLayoutState: SiteLayoutState) {
        this.settingsState = settingsState;
        this.appConfigState = appConfigState;
        this.siteLayoutState = siteLayoutState;
    }

    get(): ImmutableObject<StorefrontSettingsModel> {
        return this.settingsState.get();
    }

    async loadAsync() {
        try {
            if (this.appConfigState.get().appConfig && /*!this.settingsState.get().settings &&*/ !this.settingsState.get().isLoading && !this.settingsState.get().preventLoading) {
                this.settingsState.isLoading.set(true);
                const getSettingsResponse = await axios.get<StorefrontSettings>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/settings`);
                const settings = getSettingsResponse.data;
                this.settingsState.settings.set(settings);
            }
            else if (this.settingsState.get().isLoading) {
                await waitUntilAsync(() => !this.settingsState.get().isLoading);
            }
        }
        catch (error) {
            this.settingsState.preventLoading.set(true);
            this.siteLayoutState.showError(error);
        }
        finally {
            if (this.settingsState.get().isLoading) {
                this.settingsState.isLoading.set(false);
            }
        }
    }

}

export function useStorefrontSettingsState(): StorefrontSettingsState {
    const settingsState = useHookstate(settingsStateStore);
    const appConfigState = useAppConfigState();
    const siteLayoutState = useSiteLayoutState();
    return new StorefrontSettingsState(settingsState, appConfigState, siteLayoutState);
}
