/** @jsxImportSource @emotion/react */
import { css, ThemeProvider } from "@emotion/react"
import { useHookstate, useHookstateEffect } from "@hookstate/core";
import { useParams } from "react-router-dom";
import { ProductDetails } from "../components/ProductDetails/ProductDetails";
import { useAppConfigState } from "../hooks/useAppConfigState";
import { useCheckoutFormState } from "../hooks/useCheckoutFormState";
import { useProductDetailsState } from "../hooks/useProductDetailsState";
import { lightTheme } from "../hooks/useThemeState";
import '../styles/print.css'

//------------------------------------------------------------------------------------ 
type PrintProductPageProps = {

}
const PrintProductPage: React.FC<PrintProductPageProps> = props => {
    const appConfigState = useAppConfigState();
    const { productid, warehouseid } = useParams();
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    let productDetailsProductId: string | undefined;
    if (product !== undefined) {
        productDetailsProductId = product.Id;
    }
    const appConfig = appConfigState.get().appConfig;
    const pageLoadSentState = useHookstate<boolean>(false);
    const pageLoadSent = pageLoadSentState.get();
    const checkoutFormState = useCheckoutFormState();

    useHookstateEffect(() => {
        (async () => {
            //console.log(`productid: ${productid}, productDetailsProductId: ${productDetailsProductId}`)
            if ((warehouseid && !productDetailsState.get().isLoadingProductDetails) && productid && ((!productDetailsProductId) || (productDetailsProductId && (productid !== productDetailsProductId)))) {
                checkoutFormState.setWarehouseId(warehouseid);
                await productDetailsState.setProductIdAsync(productid);
            }
        })();
    }, [])

    useHookstateEffect(() => {
        if (product && !pageLoadSent) {
            const onPageLoad = () => {
                if (product && !pageLoadSent) {
                    pageLoadSentState.set(true);
                    window.parent.postMessage({ action: 'page-loaded' });
                    //console.log('page loaded');
                }
            };

            // Check if the page has already loaded
            if (document.readyState === 'complete') {
                onPageLoad();
            } else {
                window.addEventListener('load', onPageLoad, false);
                // Remove the event listener when component unmounts
                return () => window.removeEventListener('load', onPageLoad);
            }
        }
    }, [product]);
    
    return (<>
        <ThemeProvider theme={lightTheme}>
            <div
                css={css`
                    /*background-color:#ffffff;*/
                    * {
                        color:#000000 !important;
                    }
                    `}>
                {/* Company Logo */}
                {appConfig && (
                    <div
                        css={css`
                            flex: 0 0 auto;
                            display: flex;
                            align-items:center;`}>
                        <div css={css`
                            margin:4px 8px;
                            display:flex;
                            img {
                                max-height:64px;
                            }
                            @media (min-width: 768px) {
                                margin: 4px 8px;
                                img {
                                    max-height: 64px;
                                }
                            }`}>
                            <img
                                src={appConfig.CompanyLogoUrl}
                                alt={appConfig?.CompanyName}
                            />
                        </div>
                    </div>
                )}
                <ProductDetails printMode={true} />
            </div>
        </ThemeProvider>
    </>);
}

export default PrintProductPage