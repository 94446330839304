import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import { devtools } from '@hookstate/devtools';

export class SessionLocalStorageModel {
    loggedIn: boolean = false;
    authToken: string = '';
    fullName: string = '';
    email: string = '';
}

const storefront_sessionstate_key = 'storefront-sessionstate';
let initialState = new SessionLocalStorageModel();
const localstorageJson = window.localStorage.getItem(storefront_sessionstate_key);
if (localstorageJson) {
    try {
        initialState = JSON.parse(localstorageJson);
    }
    catch (ex) {
        console.error(ex);
    }
}
const sessionStateStore = hookstate<SessionLocalStorageModel>(initialState, devtools({ key: 'SessionState' }));

export class SessionState {
    private sessionState: State<SessionLocalStorageModel>;

    constructor(sessionState: State<SessionLocalStorageModel>) {
        this.sessionState = sessionState;
    }

    get(): ImmutableObject<SessionLocalStorageModel> {
        return this.sessionState.get();
    }

    reset() {
        this.sessionState.set(new SessionLocalStorageModel());
        this.saveToLocalStorage();
    }

    private saveToLocalStorage() {
        const obj = this.sessionState.get();
        let json = '';
        if (obj) {
            json = JSON.stringify(obj);
        }
        window.localStorage.setItem(storefront_sessionstate_key, json);
    }

    set(sessionState: SessionLocalStorageModel) {
        this.sessionState.set(sessionState);
        this.saveToLocalStorage();
    }
}

export function useSessionState(): SessionState {
    const sessionState = useHookstate<SessionLocalStorageModel>(sessionStateStore);
    return new SessionState(sessionState);
}

