/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
/* ManufacturerUrl */
export type ManufacturerUrlFieldProps = {

}
export const ManufacturerUrlField: React.FC<ManufacturerUrlFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const manufacturerUrl = productDetailsState.get().product?.ManufacturerUrl;
    const themeState = useThemeState();
    const isDark = themeState.isDark();
    const translate = useTranslate();
    return (<>
        <div className="field">
            <div className="caption">{translate("Manufacturer URL")}:</div>
            <div className="value">
                <a style={{ color: isDark ? '#03a9f4' : 'blue', fontSize: '.8rem', transition: 'color 1s' }} href={manufacturerUrl} target="_blank" rel="noreferrer">{manufacturerUrl}</a>
            </div>
        </div>
    </>);
}