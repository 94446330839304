/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Inventory2Outlined } from "@mui/icons-material";
//import { Badge, Chip } from "@mui/material";
import { useCatalogState } from "../../../hooks/useCatalogState";
import { Product } from "../../../hooks/useInventoryState";
import { useProductBrowserState } from "../../../hooks/useProductBrowserState";
import { useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import { ProductImageControl } from "../ProductImageControl";
import { ProductBrowserGridItemBarcodes } from "./ProductBrowserGridItemBarcodes";
import { ProductBrowserGridItemDepartmentCategorySubcategoryLinks } from "./ProductBrowserGridItemDepartmentCategorySubcategoryLinks";
import { ProductBrowserGridItemDescription } from "./ProductBrowserGridItemDescription";
import { ProductBrowserGridItemItemNo } from "./ProductBrowserGridItemItemNo";
import { ProductBrowserGridItemQty } from "./ProductBrowserGridItemQty";
import { ProductBrowserGridItemRates } from "./ProductBrowserGridItemRates";
import { ProductBrowserGridItemTags } from "./ProductBrowserGridItemTags";

export type ProductBrowserGridItemProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserGridItem: React.FC<ProductBrowserGridItemProps> = props => {
    const product = props.product;
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    const shoppingCartItemsState = useShoppingCartItemsState();
    const productBrowserState = useProductBrowserState();
    const qty = shoppingCartItemsState.getQty(product!);
    const translate = useTranslate();
    //const isDesktop = useIsDesktopMediaQuery();
    //let itemWidth = productBrowserState.get().itemWidth;
    //if (!isDesktop && !productBrowserState.get().expandedView) {
    //    itemWidth = Math.round(itemWidth / 2);
    //}
    let fontSize = '1rem';
    const expandedView = productBrowserState.get().expandedView;

    const catalogState = useCatalogState();
    let hasImage = true;
    let hasDepartmentCategorySubcategoryLinks = false;
    let hasICode = false;
    let hasDescription = true;
    let hasBarcodes = false;
    let hasTags = false;
    let hasDailyRate = false;
    let hasWeeklyRate = false;
    let hasMonthlyRate = false;
    let hasSalesPrice = false;
    let hasQty = false;
    let hasRates = false;
    let hasAvailability = false;
    const catalog = catalogState.getProductCatalog(product);
    if(catalog) {
        const gridFieldsByName = catalog.gridFieldsByName;
        if (gridFieldsByName) {
            //hasImageField = (shoppingCartFieldsByName['IMAGE'] !== undefined) && (shoppingCartFieldsByName['IMAGE'].Selected);
            hasDepartmentCategorySubcategoryLinks = (gridFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'] !== undefined) && (gridFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'].Selected);
            hasICode = (gridFieldsByName['ICODE'] !== undefined) && (gridFieldsByName['ICODE'].Selected);
            //hasDescription = (shoppingCartFieldsByName['DESCRIPTION'] !== undefined) && (shoppingCartFieldsByName['DESCRIPTION'].Selected);
            hasBarcodes = (gridFieldsByName['BARCODENUMBER'] !== undefined) && (gridFieldsByName['BARCODENUMBER'].Selected);
            hasTags = (gridFieldsByName['TAGS'] !== undefined) && (gridFieldsByName['TAGS'].Selected);
            hasQty = (gridFieldsByName['ITEMQUANTITYINPUT'] !== undefined) && (gridFieldsByName['ITEMQUANTITYINPUT'].Selected);
            hasDailyRate = (gridFieldsByName['DAILYRATE'] !== undefined) && (gridFieldsByName['DAILYRATE'].Selected);
            hasWeeklyRate = (gridFieldsByName['WEEKLYRATE'] !== undefined) && (gridFieldsByName['WEEKLYRATE'].Selected);
            hasMonthlyRate = (gridFieldsByName['MONTHLYRATE'] !== undefined) && (gridFieldsByName['MONTHLYRATE'].Selected);
            hasSalesPrice = (gridFieldsByName['SALESPRICE'] !== undefined) && (gridFieldsByName['SALESPRICE'].Selected);
            hasRates = hasDailyRate || hasWeeklyRate || hasMonthlyRate || hasSalesPrice;
            hasAvailability = (gridFieldsByName['AVAILABILITY'] !== undefined) && (gridFieldsByName['AVAILABILITY'].Selected) && (product.Availability !== undefined);
            //console.log({
            //    hasQty: hasQty,
            //    gridFieldsByName: gridFieldsByName
            //})
        }
    }
    //const isDesktop = useIsDesktopMediaQuery();

    const totalIn = product.Availability?.TotalIn ?? 0;
    //${productBrowserState.get().expandedView ? '1em' : '0'};
    //text-align: ${expandedView ? 'left' : 'center'};
    return (
        <div
            css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                flex: 1;
                border: 1px solid ${themeName === 'dark' ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.1)'};
                transition: background-color 1s;
                background-color: ${themeName === 'dark' ? 'rgb(33,33,33)' : 'rgb(255,255,255)'};
                border-radius: 5px;
                padding: .25em;
                overflow: hidden;
                font-size: ${fontSize};
                .field {
                    text-align: center; 
                    flex: 0 0 auto;
                }
                .field.icode {height:1rem; line-height:.9rem;padding:.25em .5em;font-size:.75em;}
                .field.categorylinks {
                    flex: 1 0 auto;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    font-size: .7rem;
                    padding: .5em 0;
                }
                .field.itemdescription {
                    flex: 1 0 auto;
                    height: ${productBrowserState.get().expandedView ? 'auto' : 'auto'};
                    line-height: 1rem;
                    padding:.5em .5em;
                    font-size: .9rem;
                    font-weight: 500;
                    cursor: pointer;
                    text-align: center;
                        &:hover {
                            text-decoration: underline;
                        }
                }
                .field.availability {
                    height:1.5rem;
                    line-height:.9rem;
                    padding:.25em .5em;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    font-size:.8em;
                    text-align:center;
                }
                .field .caption {
                    font-size: .8em;
                    font-weight:500;
                    color: ${themeName === 'dark' ? '#00bcd4' : '#006064'};
                    padding:1.5em 0 .25em 0;
                }
                .field .value {
                    max-height: 128px;
                }
            `}
        >
            {(product.PackageItems && (product.PackageItems.length > 0)) && (
                <div css={css`position:absolute;top:0;right:0;left:0;background-color:#0d47a1;color:#ffffff;padding:2px 0;font-size:.7rem;z-index:2;display:flex;align-items:center;justify-content:center;`}>
                    <span>-</span><Inventory2Outlined css={css`font-size:.7rem;margin:0 4px;`} />{translate('package')}<span>&nbsp;-</span>
                </div>
            )}
            {hasImage && (
                <div
                    css={css`display:flex; justify-content: center;` }>
                    <ProductImageControl product={product} />
                </div>
            )}
            {expandedView && hasDepartmentCategorySubcategoryLinks && (<ProductBrowserGridItemDepartmentCategorySubcategoryLinks product={product} />)}
            {hasDescription && (<ProductBrowserGridItemDescription product={product} />)}
            {(expandedView && hasICode) && (<ProductBrowserGridItemItemNo product={product} />)}
            {(expandedView && hasRates) && (
                <ProductBrowserGridItemRates product={product} hasDailyRate={hasDailyRate} hasWeeklyRate={hasWeeklyRate} hasMonthlyRate={hasMonthlyRate} hasSalesPrice={hasSalesPrice} />
            )}
            {(expandedView && hasAvailability) && (
                <div className="field availability">
                    {totalIn > 0 && (
                        <span css={css`${themeName === 'dark' ? 'color:hsl(180 100% 40% / 1);' : 'color:#777777;'}`}>({(product.Availability?.TotalIn ?? 0).toString() + ' of ' + (product.Availability?.Total ?? 0).toString() + ' ' + translate('available')})</span>
                    )}
                    {totalIn === 0 && (
                        <span css={css`color:hsl(0 0% 40% / 1);`}>({translate('Unavailable')})</span>
                    )}
                </div>
            )}
            {(expandedView && hasQty) && (
                <ProductBrowserGridItemQty product={product} />
            )}
            {(expandedView && hasTags) && (<ProductBrowserGridItemTags product={product} />)}
            {(expandedView && hasBarcodes) && (<ProductBrowserGridItemBarcodes product={product} />)}
            {(qty > 0) && (
                <div
                    css={css`
                        position:absolute;
                        top:.7rem;
                        right:.7rem;
                        background-color:#f44336;
                        color:#ffffff;
                        z-index:2;
                        padding:.2rem .5rem;
                        border-radius: .8rem;
                        font-size:.8rem;
                    `}
                    title={`${qty} ${translate('in cart')}`}
                >
                    {qty}
                </div>
            )}
        </div>
    );
}