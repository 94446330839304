import { State, useHookstate, useHookstateEffect } from "@hookstate/core";
import { useRef } from "react";
import { UseIntervalCallback } from "./useInterval";

//const getSecondsRemaining = (expiresMs: number) => {
//    const secondsRemaining = Math.ceil(expiresMs - Date.now()) / 1000;
//    return secondsRemaining;
//}

export class CountdownTimerState {
    private expiresState: State<number>;
    private secondsRemainingState: State<number>

    constructor(expiresState: State<number>, secondsRemainingState: State<number>) {
        this.expiresState = expiresState;
        this.secondsRemainingState = secondsRemainingState;
    }

    getSecondsRemaning() {
        return this.secondsRemainingState.get();
    }
}

export const useCountdownTimerState = (callback: UseIntervalCallback, expiresInSec: number) => {
    const savedCallback = useRef<UseIntervalCallback>();
    const expiresState = useHookstate<number>(Date.now() + (expiresInSec * 1000));
    const secondsRemainingState = useHookstate<number>(expiresInSec);

    // Remember the latest callback.
    useHookstateEffect(() => {
        savedCallback.current = callback;
    }, [callback]);


    // Set up the interval.
    useHookstateEffect(() => {
        const tick = () => {
            const secondsRemaining = Math.ceil((expiresState.get() - Date.now()) / 1000);
            if (secondsRemaining !== secondsRemainingState.get()) {
                secondsRemainingState.set(secondsRemaining);
            }
            if ((secondsRemaining <= 0) && savedCallback && savedCallback.current && (!document.hidden)) {
                savedCallback.current();
            }
        }
        let id = setInterval(tick, 250);
        return () => clearInterval(id);
    }, [expiresInSec]);

    return new CountdownTimerState(expiresState, secondsRemainingState);
}
