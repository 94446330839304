import { hookstate, ImmutableObject, State, useHookstate } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";
import { createTheme, Palette, Theme, ThemeOptions } from "@mui/material";
import clone from "clone";
import { AppConfigState, useAppConfigState } from "./useAppConfigState";

interface IPalette extends Palette {
    siteBackground: string;
    siteHeaderBackground: string;
    siteFooterBackground: string;
    siteTextPrimary: string;
    siteTextSecondary: string;
    siteAccent: string;
    siteAccentContrastText: string;
    siteCardBackground: string;
    siteCardBorder: string;
    siteCardBackgroundLighter1: string;
    siteSideBar: string;
    siteSideBarText: string;
    siteSideBarText2: string;
    siteSideBarContrastText: string;
}
export interface ITheme extends Theme {
    palette: IPalette;
}
interface IThemeOptions extends ThemeOptions {
    palette: IPalette;
}

export const darkTheme: ITheme = createTheme({
    palette: {
        // custom ITheme properties
        siteBackground: '#212121',
        //siteHeaderBackground: '#303030',
        siteHeaderBackground: '#212121',
        siteTextPrimary: '#ffffff',
        siteTextSecondary: '#eeeeee',
        siteAccent: '#f44336',
        siteAccentContrastText: '#ffffff',
        siteCardBackground: '#333333',
        siteCardBorder: '1px solid rgba(256,256,256,.1)',
        siteCardBackgroundLighter1: '#444444',
        siteSideBar: 'transparent',
        siteSideBarText: '#ffffff',
        siteSideBarText2: '#cccccc',
        siteSideBarContrastText: '#ff0000',

        // inherited MUI Theme properties
        mode: 'dark',
        primary: {
            //light: '#ffffff',
            main: '#ffffff',
            //dark: '#002884',
            //contrastText: '#fff',
        },
        secondary: {
            //light: '#ff7961',
            main: '#f44336',
            //dark: '#ba000d',
            //contrastText: '#000',
        },
    }
} as IThemeOptions) as ITheme;

export const lightTheme: ITheme = createTheme({
    palette: {
        // custom ITheme properties
        siteBackground: '#ffffff',
        //siteHeaderBackground: '#eeeeee',
        siteHeaderBackground: '#ffffff',
        siteTextPrimary: '#000000',
        siteTextSecondary: '#000000',
        siteAccent: '#f44336',
        siteAccentContrastText: '#ffffff',
        siteCardBackground: '#eeeeee',
        siteCardBorder: '1px solid rgba(0,0,0,.2)',
        siteCardBackgroundLighter1: '#ffffff',
        siteSideBar: 'transparent',
        siteSideBarText: '#555555',
        siteSideBarText2: '#777777',
        siteSideBarContrastText: '#ff0000',

        // inherited MUI Theme properties
        mode: 'light',
        primary: {
            //light: '#757ce8',
            main: '#333333',
            //dark: '#002884',
            //contrastText: '#000000',
        },
        secondary: {
            //light: '#ff7961',
            main: '#f44336',
            //dark: '#ba000d',
            //contrastText: '#000',
        },
    }
} as IThemeOptions) as ITheme;

export class ThemeModel {
    themeName: string;
    theme: ITheme;

    constructor(themeName: string, theme: ITheme) {
        this.themeName = themeName;
        this.theme = theme;
    }
}


function addClientCodeStyles(appConfigState: AppConfigState, themeName: string, theme: ITheme) {
    //if (appConfigState.get().appConfig?.ClientCode === 'TELEMUNDO') {
    //    theme.palette.siteSideBar = 'hsl(266, 100%, 29%)';
    //    if (themeName === 'dark') {
    //        theme.palette.siteSideBar = 'hsl(266, 100%, 22%)';
    //    }
    //    theme.palette.siteSideBarText = '#ffffff';
    //    theme.palette.siteSideBarText2 = '#cccccc';
    //    theme.palette.siteSideBarContrastText = '#ff0000';
    //}
}

// global for theme state
const storefront_theme_key = 'storefront-theme';
const themeName = window.localStorage.getItem(storefront_theme_key);
let initialTheme: ThemeModel | undefined;
if (themeName) {
    try {
        if (themeName === 'dark' || themeName === 'light') {
            initialTheme = {
                themeName: themeName,
                theme: (themeName === 'light') ? clone(lightTheme) : clone(darkTheme)
            };
        }
    }
    catch (error) {
        console.log(error);
    }
}
if (!initialTheme) {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const themeName = defaultDark ? 'dark' : 'light';
    window.localStorage.setItem(storefront_theme_key, themeName);
    const theme = defaultDark ? clone(darkTheme) : clone(lightTheme);
    initialTheme = {
        themeName: themeName,
        theme: theme
    };
}
const themeStateStore = hookstate<ThemeModel>(initialTheme, devtools({ key: 'ThemeState' }));

export class ThemeState {
    private themeState: State<ThemeModel>;
    private appConfigState: AppConfigState;

    constructor(themeState: State<ThemeModel>, siteConfigState: AppConfigState) {
        this.themeState = themeState;
        this.appConfigState = siteConfigState;
    }

    get(): ImmutableObject<ThemeModel> {
        return this.themeState.get();
    }

    getTheme(): ITheme {
        //let theme = darkTheme;
        //const themeName = this.themeState.get().themeName;
        //if (this.themeState.get().themeName === 'light') {
        //    theme = lightTheme;
        //}
        //addClientCodeStyles(this.siteConfigState, themeName, theme);
        //return theme;
        return clone(this.themeState.get({ noproxy: true }).theme) as ITheme;
    }

    set(themeName: string) {
        let theme = clone(darkTheme);
        if (themeName === 'light') {
            theme = lightTheme;
        }
        addClientCodeStyles(this.appConfigState, themeName, theme);
        this.themeState.merge({
            themeName: clone(themeName),
            theme: theme
        });
        this.saveToLocalStorage();
    }

    isDark() {
        return this.themeState.get().themeName === 'dark';
    }

    private saveToLocalStorage() {
        window.localStorage.setItem(storefront_theme_key, this.themeState.get().themeName);
    }
}

export function useThemeState(): ThemeState {
    const appConfigState = useAppConfigState();
    const themeState = useHookstate<ThemeModel>(themeStateStore);
    return new ThemeState(themeState, appConfigState);
}
