/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { Badge, ClickAwayListener, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { DarkMode, LightMode, Person, PersonOff, ShoppingCart, Translate, Close, Check } from "@mui/icons-material";
import { useShoppingCartItemsState } from "../hooks/useShoppingCartItemsState";
import { useSessionState } from "../hooks/useSessionState";
import { useThemeState } from "../hooks/useThemeState";
import { useCatalogState } from "../hooks/useCatalogState";
import { AnimatePresence, motion } from "framer-motion";
import { useLocaleState } from "../hooks/useLocaleState";
import { useHookstate, useHookstateCallback, useHookstateEffect } from "@hookstate/core";
import { useLoginFormState } from "../hooks/useLoginFormState";
import { useRegisterFormState } from "../hooks/useRegisterFormState";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { useSiteLayoutState } from "../hooks/useSiteLayoutState";
import { useAppConfigState } from "../hooks/useAppConfigState";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { useInventoryState } from "../hooks/useInventoryState";
import React from "react";
import { useCheckoutFormState } from "../hooks/useCheckoutFormState";
import dayjs from "dayjs";
import { useIsDesktopMediaQuery } from "../hooks/useIsDesktopMediaQuery";
import { useStorefrontSettingsState } from "../hooks/useStorefrontSettingsState";
import { AppConstants } from "../data/AppConstants";

type SiteLayoutProps = {
    title?: string
    companyName?: string
    fullLeftGutter?: React.ReactNode
    mainLeftGutter?: React.ReactNode
    mainHeader?: React.ReactNode
    mainChildren?: React.ReactNode
    mainFooter?: React.ReactNode
    mainRightGutter?: React.ReactNode
    rightGutter?: React.ReactNode
    afterLogo?: React.ReactNode
    topRightMenu?: React.ReactNode
}
export const SiteLayout: React.FC<SiteLayoutProps> = props => {
    const themeState = useThemeState();
    //const themeName = themeState.get().themeName;
    const sessionState = useSessionState();
    const siteLayoutState = useSiteLayoutState();
    const loginFormState = useLoginFormState();
    const contactRegistrationFormState = useRegisterFormState();
    useDocumentTitle(props.title ?? '');
    const shoppingCartItemsState = useShoppingCartItemsState();
    const appConfigState = useAppConfigState();
    const appConfig = appConfigState.get().appConfig;
    //let shoppingCartCount = shoppingCartItemsState.get().length;
    let shoppingCartCount = 0;
    let items = shoppingCartItemsState.get();
    for (let i = 0; i < items.length; i++) {
        shoppingCartCount += items[i].qty;
    }
    const catalogState = useCatalogState();
    //const isMobile = useMediaQuery('(min-width:0px) and (max-width:719px)');
    const isDesktop = useIsDesktopMediaQuery();
    const inventoryState = useInventoryState();
    //const showTooltip = (siteLayoutState.get().tooltipMessage !== undefined) && ((siteLayoutState.get().tooltipMessage?.length ?? 0) > 0);
    const checkoutFormState = useCheckoutFormState();
    const fromDateValue = checkoutFormState.get().fromDateValue;
    const toDateValue = checkoutFormState.get().toDateValue;
    const today = dayjs().startOf('day');

    const storefrontSettingsState = useStorefrontSettingsState();
    const isAnonymousAccessEnabled = storefrontSettingsState.get().settings?.IsAnonymousAccessEnabled ?? false;
    const logggedIn = sessionState.get().loggedIn;
    //const hasLoginPopupCloseButton = !siteLayoutState.get().removeloginFormPopupCloseButton;
    const hasLoginPopupCloseButton = storefrontSettingsState.get().settings?.IsAnonymousAccessEnabled ?? false;
    const shoppingCartEnabled = ((isAnonymousAccessEnabled || logggedIn) && (shoppingCartCount > 0));

    //const versionParts = (process.env.REACT_APP_VERSION ?? '0.0.0').split('.');
    //let version = '0.0.0';
    //if ((versionParts.length === 3) && (versionParts[0].length) >= 5) {
    //    version = versionParts[0].substring(0, 4) + '.' + versionParts[0].substring(4) + '.' + versionParts[1] + '.' + versionParts[2];
    //}
    //const year = dayjs().year();

    // create a callback function to create memoized function that won't change every render and trigger useEffect
    const onInit = useHookstateCallback(() => {
        loginFormState.setIsPopup(true);
    }, [loginFormState])

    useHookstateEffect(() => {
        loginFormState.setIsPopup(true);

        // update dates on checkoutFormState
        let fromDate = fromDateValue;
        let toDate = toDateValue;
        
        if (dayjs(fromDate).isBefore(today)) {
            fromDate = today.format('YYYY-MM-DD');
            toDate = dayjs(fromDate).add(6, 'day').format('YYYY-MM-DD');
            checkoutFormState.setDates(fromDate, toDate);
        }
        if (dayjs(toDate).isBefore(fromDate)) {
            toDate = dayjs(fromDate).add(6, 'day').format('YYYY-MM-DD');
            checkoutFormState.setToDate(toDate);
        }
    }, [onInit, today, fromDateValue, toDateValue]);

    const catalogs = catalogState.get().catalogs?.map((catalog) => {
        const isSelected = (inventoryState.get().catalog) && (catalog.Id === (inventoryState.get().catalog?.Id ?? ''));

        return (
             //onClick={e => {
             //       return Promise.resolve().then(() => {
             //           //inventoryState.filterByCatalogIdAsync(catalog.Id, true);
             //           inventoryState.setShowLandingPage(true);
             //       });
             //   }}
            <Link
                key={catalog.Id}
                to={`/inventory/${catalog.Id}/index`}
                css={css`
                        text-decoration:none;
                        &:hover {
                            text-decoration:underline;
                        }`}>
                <div
                    css={css`
                        flex:1 1 0;
                        font-family: 'Ropa Sans';
                        font-size:1em;
                        white-space:no-wrap;
                        padding:0 .5em;
                        color: ${(isSelected) ? themeState.get().theme.palette.siteAccent : themeState.get().theme.palette.siteTextPrimary};
                        transition: color .1s;
                        text-decoration: ${(isSelected) ? 'underline' : 'initial'};
                        text-underline-offset: 3px;
                        `}>
                    {catalog.Name}
                </div>
            </Link>
        );
    });

    return <>
        <div css={css`
                position:fixed;
                top:0;
                right:0;
                bottom:0;
                left:0;
                display:flex;
                overflow:hidden;
                background-color: ${themeState.get().theme.palette.siteBackground};
                color:${themeState.get().theme.palette.siteTextPrimary};
                transition: all 1s;`}>
            <motion.div css={css`flex:0 0 auto;display:flex;flex-direction:column;overflow:hidden;`} layout>
                {props.fullLeftGutter}
            </motion.div>
            <motion.div css={css`flex:1 1 0;display:flex;flex-direction:column;` } layout>
                <header
                    css={css`
                        flex: 0 1 auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        background-color: ${themeState.get().theme.palette.siteHeaderBackground};
                        transition: background-color 1s;`}>
                    <div
                        css={css`
                            flex:0 0 auto;
                            display:flex;
                            align-items: center;`}>
                        <div
                            css={css`
                                flex: 1 1 0;
                                display:flex;
                                margin:0 auto;
                                max-width:${AppConstants.siteWidthCss};` }>

                            {/* Company Logo */}
                            {appConfig && (
                                <div
                                    css={css`
                                        flex: 0 0 auto;
                                        display: flex;
                                        align-items:center;`}>
                                    <div css={css`
                                        margin:4px 8px;
                                        display:flex;
                                        align-items:center;
                                        img {
                                            max-height:64px;
                                            max-width:128px;
                                        }
                                        `}>
                                        <Link to="/">
                                                <img
                                                    src={appConfig.CompanyLogoUrl}
                                                    alt={appConfig.CompanyName}
                                                    onClick={async e => {
                                                        return Promise.resolve().then(() => {
                                                            inventoryState.clearCatalogFilterAsync();
                                                        });
                                                    }}
                                                />
                                        </Link> 
                                        {props.afterLogo}
                                    </div>
                                    {/*<div css={css`*/}
                                    {/*    font-family: 'Ropa Sans';*/}
                                    {/*    font-size:2em;*/}
                                    {/*    white-space:nowrap;*/}
                                    {/*    @media screen and (width < 600px) {*/}
                                    {/*    & {*/}
                                    {/*        display: none;*/}
                                    {/*    }*/}
                                    {/*}`}>{siteConfigState.get()?.siteConfig?.CompanyName ?? ""}</div>*/}
                                </div>
                            )}

                            <div css={css`
                                flex: 1 1 0;
                                @media print {
                                    & {
                                        display: none;
                                    }
                            `}>

                                <div css={css`
                                    flex: 1 1 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    margin: 16px 0 0 0;`}>
                                    {isDesktop && (<div
                                        css={css`
                                            flex:1 1 0;
                                            /*min-width:400px;
                                            max-width:500px;*/
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: center;
                                            justify-content: flex-end;`}>
                                        {catalogs}
                                    </div>)}

                                    {/*{isMobile && (<>*/}
                                    {/*    <div*/}
                                    {/*        css={css`*/}
                                    {/*            flex:1 1 0;*/}
                                    {/*            display: none;*/}
                                    {/*            @media (min-width: 720px) {*/}
                                    {/*                display:flex;*/}
                                    {/*            }`}>*/}
                                    {/*        <CatalogMenu />*/}
                                    {/*    </div>*/}
                                    {/*</>)}*/}

                                    <div css={css`flex: 0 0 auto;display:flex;align-items:center;`}>
                                        {props.topRightMenu}

                                        <Tooltip title={siteLayoutState.translate('Theme') + ': ' + ((themeState.get().themeName === 'dark') ? siteLayoutState.translate('Dark') : siteLayoutState.translate('Light'))} >
                                            <IconButton aria-label="toggle light and dark mode"
                                                onClick={async e => {
                                                    return Promise.resolve().then(() => {
                                                        let newThemeName = (themeState.get().themeName === 'dark') ? 'light' : 'dark'
                                                        themeState.set(newThemeName);;
                                                    });
                                                }} >
                                                {themeState.get().themeName === 'dark' &&
                                                    <DarkMode />
                                                }
                                                {themeState.get().themeName === 'light' &&
                                                    <LightMode />
                                                }
                                            </IconButton>
                                        </Tooltip>

                                        <TranslateMenu />
                                        {shoppingCartEnabled && (<>
                                            <Tooltip title={siteLayoutState.translate('Shopping Cart')} >
                                                <Link to="/shoppingcart">
                                                    <Badge badgeContent={shoppingCartCount} max={99999} css={css`.MuiBadge-badge {background-color: #f44336; !important;color: #ffffff !important}`}>
                                                        <IconButton aria-label="shopping cart">
                                                            <ShoppingCart />
                                                        </IconButton>
                                                    </Badge>
                                                </Link>
                                            </Tooltip>

                                        </>)}
                                        {!shoppingCartEnabled && (<>
                                            <Tooltip title={siteLayoutState.translate('Shopping Cart')} >
                                                <span>
                                                    <IconButton aria-label="shopping cart" disabled>
                                                        <ShoppingCart />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>

                                        </>)}
                                        {(isAnonymousAccessEnabled || logggedIn) && (<>
                                            <AccountMenu />
                                        </>)}
                                    </div>
                                </div>
                                <div css={css`
                                    flex: 1 1 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    padding: 0 1em;
                                    font-size:.8em;`}>
                                    <div>{sessionState.get().email}</div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {/*<div*/}
                    {/*    css={css`*/}
                    {/*            flex:0 0 auto;*/}
                    {/*            display: flex;*/}
                    {/*            flex-wrap:wrap;*/}
                    {/*            justify-content: center;*/}
                    {/*            @media (min-width: 720px) {*/}
                    {/*                display:none;*/}
                    {/*                justify-content: flex-end;*/}
                    {/*            }*/}
                    {/*        `}>*/}
                    {/*    <CatalogMenu />*/}
                    {/*</div>*/}
                
                </header>

                <main
                    css={css`
                        flex: 1 1 0;
                        display: flex;
                        overflow: hidden;
                        max-width:100vw;`}>
                    <div
                        css={css`
                            flex: 0 0 auto;
                            display: flex;
                            flex-direction: column;
                            overflow: auto;`}>
                        {props.mainLeftGutter}
                    </div>
                    <div css={css`flex:1 1 0;display:flex;flex-direction:column;overflow:auto;`}>
                        <div css={css`flex:0 0 auto;display:flex;justify-content:flex-start;`}>
                            {props.mainHeader}
                        </div>
                        <div css={css`
                            flex:1 1 0;
                            display:flex;
                            flex-direction:column;
                            justify-content:flex-start;
                            @media screen {
                                overflow:hidden;
                            }
                            `}>
                            {props.mainChildren}
                        </div>
                        <div css={css`flex:0 0 auto;display:flex;flex-direction:column;` }>
                            {props.mainFooter}
                        </div>
                        <div css={css`flex:0 0 auto;display:flex;` }>
                            <div css={css`display:flex;font-weight:bold` }>
                                {/* <FooterLink name="Privacy" href="/privacy" />
                                <FooterLink name="About" href="/about" /> */}
                            </div>
                        </div>
                    </div>
                    <div css={css`flex:0 0 auto;display:flex;flex-direction:column;overflow:auto;`}>{props.mainRightGutter}</div>
                </main>
                {/*background-color: ${themeState.get().theme.palette.siteFooterBackground};*/}
                <footer
                    css={css`
                        display:flex:0 0 auto;
                        display:flex;
                        justify-content:center;
                        background-color: #333333;
                        padding-left: 44px;
                        @media (min-width: 768px) {
                            padding-left: 0;
                        }`}>
                    {/*<div css={css`*/}
                    {/*    font-size:.6rem;*/}
                    {/*    color:#777777;*/}
                    {/*    a {*/}
                    {/*        color:#777777;*/}
                    {/*        text-decoration: none;*/}
                    {/*    }*/}
                    {/*    a:hover {*/}
                    {/*        text-decoration: underline;*/}
                    {/*    }*/}
                    {/*    `}>*/}
                    {/*    <a href="https://dbworks.com/products/rentalworks/">RentalWorks&reg; Storefront</a> v{version} &copy;{year}  <a href="https://dbworks.com/">Database Works, Inc.</a> All rights reserved.*/}
                    {/*</div>*/}
                </footer>
            </motion.div>
            <motion.div css={css`flex:0 0 auto;display:flex;flex-direction:column;`} layout>{props.rightGutter}</motion.div>

        </div>
        {/*<ReactTooltip
            id={siteLayoutState.get().tooltipId ?? 'numberbox'}
            isOpen={showTooltip}
            content={siteLayoutState.get().tooltipMessage}
            variant={siteLayoutState.get().tooltipVariant}
            place={siteLayoutState.get().tooltipPlace}
            positionStrategy="fixed"
        />*/}
        <AnimatePresence>
            {siteLayoutState.get().loginFormPopupVisible && (
                <div
                    css={css`
                    background-color:rgba(0,0,0,.5);
                    position:fixed;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    z-index:1;
                    display:flex;
                    align-items:center;
                    justify-content:center;`}
                    onClick={async e => {
                        //return Promise.resolve().then(() => {
                        //    siteLayoutState.setLoginFormPopupVisible(false)
                        //});
                    }}>
                    <motion.div
                        css={css`
                        margin: 1em;
                        padding: 1em;
                        z-index: 2;
                        background-color: ${themeState.get().theme.palette.siteBackground};
                        overflow:hidden;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                        position: relative;`}
                        layout
                        layoutId="loginform"
                        transition={{ type: "spring", stiffness: 300, damping: 22 }}
                        onClick={async e => { e.stopPropagation(); }}>
                        {hasLoginPopupCloseButton && (
                            <IconButton css={css`position:absolute;top:8px;right:8px;`} onClick={e => {
                                return Promise.resolve().then(() => {
                                    siteLayoutState.setLoginFormPopupVisible(false)
                                });
                            }} ><Close /></IconButton>
                        )}
                        <LoginForm />
                    </motion.div>
                </div>
            )}

            {siteLayoutState.get().resetPasswordFormPopupVisible && (
                <div
                    css={css`
                    background-color:rgba(0,0,0,.5);
                    position:fixed;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    z-index:1;
                    display:flex;
                    align-items:center;
                    justify-content:center;`}
                    onClick={async e => {
                        //return Promise.resolve().then(() => {
                        //    siteLayoutState.setResetPasswordFormPopupVisible(false)
                        //});
                    }}>
                    <motion.div
                        css={css`
                        margin: 1em;
                        padding: 1em;
                        z-index: 2;
                        background-color: ${themeState.get().theme.palette.siteBackground};
                        overflow:hidden;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                        position: relative;`}
                        layout
                        layoutId="loginform"
                        transition={{ type: "spring", stiffness: 300, damping: 22 }}
                        onClick={async e => { e.stopPropagation(); }}>
                        <IconButton css={css`position:absolute;top:8px;right:8px;`} onClick={e => {
                            return Promise.resolve().then(() => {
                                siteLayoutState.setResetPasswordFormPopupVisible(false)
                            });
                        }} ><Close /></IconButton>
                        <ResetPasswordForm type="resetpassword" />
                    </motion.div>
                </div>
            )}

            {siteLayoutState.get().registerFormPopupVisible && (
                <div
                    css={css`
                    background-color:rgba(0,0,0,.5);
                    position:fixed;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    z-index:1;
                    display:flex;
                    align-items:center;
                    justify-content:center;`}
                    onClick={async e => {
                        //return Promise.resolve().then(() => {
                        //    siteLayoutState.setRegisterFormPopupVisible(false)
                        //});
                    }}>
                    <motion.div
                        css={css`
                        margin: 1em;
                        padding: 1em;
                        z-index: 2;
                        background-color: ${themeState.get().theme.palette.siteBackground};
                        overflow:hidden;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                        position: relative;`}
                        layout
                        layoutId="registerform"
                        transition={{ type: "spring", stiffness: 300, damping: 22 }}
                        onClick={async e => { e.stopPropagation(); }}>
                        <IconButton css={css`position:absolute;top:8px;right:8px;`} onClick={e => {
                            return Promise.resolve().then(() => {
                                siteLayoutState.setRegisterFormPopupVisible(false)
                            });
                        }} ><Close /></IconButton>
                        <RegisterForm registerFormState={contactRegistrationFormState}></RegisterForm>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    </>
}



type TranslateMenuProps = {

}
const TranslateMenu: React.FC<TranslateMenuProps> = props => {
    //const globalStateController = useGlobalState();
    const localeState = useLocaleState();
    const currentLanguage = localeState.get().language;
    const anchorElState = useHookstate<null | HTMLElement>(null);
    const open = Boolean(anchorElState.get());
    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        anchorElState.set(event.currentTarget);
    };
    const closeTranslateMenu = (language?: string) => {
        anchorElState.set(null);
        if (language) {
            localeState.setLanguage(language);
            //window.location.reload();
        }
    };

    return (
        <ClickAwayListener onClickAway={async e => {
            return Promise.resolve().then(() => {
                closeTranslateMenu();
            });
        }}>
            <React.Fragment>
                <Tooltip title={localeState.translate({ en: 'Language', es: 'Idioma', fr: 'Langue'}) + ': ' + localeState.getLanguageFriendlyName()}>
                    <IconButton aria-label="translate"
                        id="translate-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <Translate />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElState.get() as HTMLButtonElement}
                    open={open}
                    onClose={async e => {
                        return Promise.resolve().then(() => {
                            closeTranslateMenu();
                        });
                    }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                closeTranslateMenu('en');
                            });
                        }}
                    >
                         <ListItemIcon>
                            {currentLanguage === 'en' && (
                                <Check />
                            )}
                        </ListItemIcon>
                        English
                    </MenuItem>
                    <MenuItem
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                closeTranslateMenu('es');
                            });
                        }}
                    >
                        <ListItemIcon>
                            {currentLanguage === 'es' && (
                                <Check />
                            )}
                        </ListItemIcon>
                        Español
                    </MenuItem>
                    <MenuItem onClick={async e => {
                        return Promise.resolve().then(() => {
                            closeTranslateMenu('fr');
                        });
                    }}>
                        <ListItemIcon>
                            {currentLanguage === 'fr' && (
                                    <Check />
                            )}
                        </ListItemIcon>
                        Français
                    </MenuItem>
                </Menu>
            </React.Fragment>
        </ClickAwayListener>
    );
}

type AccountMenuProps = {

}
const AccountMenu: React.FC<AccountMenuProps> = props => {
    const sessionState = useSessionState();
    const siteLayoutState = useSiteLayoutState();

    // menu
    const anchorElState = useHookstate<null | HTMLElement>(null);
    const openAccountMenu = Boolean(anchorElState.get());
    const showAccountMenu = async (event: React.MouseEvent<HTMLButtonElement>) => {
        anchorElState.set(event.currentTarget);
    };
    const closeAccountMenu = (language?: string) => {
        anchorElState.set(null);
    };

    let resetPasswordType: 'change' | 'reset' = (sessionState.get().loggedIn) ? 'change' : 'reset';
    let resetPasswordText = (sessionState.get().loggedIn) ? siteLayoutState.translate('Change Password') : siteLayoutState.translate('Reset Password');

    const versionParts = (process.env.REACT_APP_VERSION ?? '0.0.0').split('.');
    let version = '0.0.0';
    if ((versionParts.length === 3) && (versionParts[0].length) >= 5) {
        version = versionParts[0].substring(0, 4) + '.' + versionParts[0].substring(4) + '.' + versionParts[1] + '.' + versionParts[2];
    }
    const year = dayjs().year();

    return (<>
        <ClickAwayListener onClickAway={async e => {
            return Promise.resolve().then(() => {
                closeAccountMenu();
            });
        }}>
            <div>
                <Tooltip title={siteLayoutState.translate('User Account Menu')}>
                    <IconButton aria-label="account"
                        id="account-button"
                        aria-controls={openAccountMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openAccountMenu ? 'true' : undefined}
                        css={css`/*color:${sessionState.get().loggedIn ? '#ffffff' : '#ff0000'};*/`}
                        onClick={showAccountMenu}>
                        {sessionState.get().loggedIn && (
                            <Person />
                        )}
                        {!sessionState.get().loggedIn && (
                            <PersonOff />
                        )}
                    </IconButton>
                </Tooltip>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElState.get() as HTMLButtonElement}
                    open={openAccountMenu}
                    onClose={e => { closeAccountMenu(); }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        disabled={sessionState.get().loggedIn}
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                closeAccountMenu();
                                siteLayoutState.setLoginFormPopupVisible(true);
                            });
                        }}>{siteLayoutState.translate('Login')}</MenuItem>
                    <MenuItem
                        disabled={sessionState.get().loggedIn}
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                closeAccountMenu();
                                siteLayoutState.setRegisterFormPopupVisible(true);
                            });
                        }}>{siteLayoutState.translate('Register')}</MenuItem>
                    <MenuItem
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                closeAccountMenu();
                                if (sessionState.get().loggedIn) {
                                    siteLayoutState.sendResetPasswordEmailAsync(resetPasswordType);
                                } else {
                                    siteLayoutState.setResetPasswordFormPopupVisible(true);
                                }
                            });
                        }}>{resetPasswordText}</MenuItem>

                    {/*<MenuItem*/}
                    {/*    disabled>Quotes</MenuItem>*/}
                    {/*<MenuItem*/}
                    {/*    disabled*/}
                    {/*    onClick={e => {*/}
                    {/*        closeAccountMenu();*/}
                    {/*        navigate('/settings');*/}
                    {/*    }}>Settings</MenuItem>*/}

                    
                    <MenuItem
                        disabled={!sessionState.get().loggedIn}
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                closeAccountMenu();
                                siteLayoutState.logoutAsync();
                            });
                        }}>Logout</MenuItem>
                    <MenuItem disabled>
                        <div css={css`font-size:.7rem;text-align:center;`}>
                            <div>RentalWorks&reg; v{version}</div>
                            <div>&copy;{year} Database Works, Inc.</div>
                        </div>
                    </MenuItem>
                </Menu>
            </div>
        </ClickAwayListener>
    </>);
}
