/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { KeyboardArrowRight } from "@mui/icons-material";
//import { Link } from "react-router-dom";
//import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
//import { useInventoryState } from "../../hooks/useInventoryState";
import { useProductDetailsState } from "../../hooks/useProductDetailsState";
//import { useThemeState } from "../../hooks/useThemeState";

//------------------------------------------------------------------------------------
export type DepartmentCategorySubcategoryLinksProps = {

}
export const DepartmentCategorySubcategoryLinks: React.FC<DepartmentCategorySubcategoryLinksProps> = props => {
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    //const themeState = useThemeState();
    //const theme = themeState.get().theme;
    //const inventoryState = useInventoryState();
    //const checkoutFormState = useCheckoutFormState();
    //const warehouse = checkoutFormState.getWarehouse();

    if (product !== undefined) {
        return (
            <div css={css`display:flex;font-size:.7rem;padding:.5em 1em;`}>
                <div css={css`height:4em;`}>
                    <span css={css`flex: 0 0 auto;`}>{product.WebCatalog ?? ''}</span>
                    {/*<Link*/}
                    {/*    to={`/inventory/${product.WebCatalogId}/index`}*/}
                    {/*    css={css`*/}
                    {/*            flex:0 0 auto;*/}
                    {/*            cursor:pointer;*/}
                    {/*            color: ${theme.palette.siteTextPrimary};*/}
                    {/*            text-decoration: none;*/}
                    {/*            &:hover {*/}
                    {/*                text-decoration: underline;*/}
                    {/*            }`}*/}
                    {/*>{product.WebCatalog ?? ''}</Link>*/}
                    <KeyboardArrowRight css={css`font-size:.9em;`} />
                    <span css={css`flex: 0 0 auto;`}>{product.InventoryDepartment ?? ''}</span>
                    {/*<Link*/}
                    {/*    to={`/inventory/${product.WebCatalogId}/${product.InventoryDepartmentId}`}*/}
                    {/*    css={css`*/}
                    {/*            flex:0 0 auto;*/}
                    {/*            cursor:pointer;*/}
                    {/*            color: ${theme.palette.siteTextPrimary};*/}
                    {/*            text-decoration: none;*/}
                    {/*            &:hover {*/}
                    {/*                text-decoration: underline;*/}
                    {/*            }`}*/}
                    {/*>{product.InventoryDepartment ?? ''}</Link>*/}
                    <KeyboardArrowRight css={css`font-size:.9em;`} />
                    <span css={css`flex: 0 0 auto;`}>{product.Category ?? ''}</span>
                    {/*<Link*/}
                    {/*    to={`/inventory/${product.WebCatalogId}/${product.InventoryDepartmentId}/${product.CategoryId}`}*/}
                    {/*    css={css`*/}
                    {/*            flex:0 0 auto;*/}
                    {/*            cursor:pointer;*/}
                    {/*            color: ${theme.palette.siteTextPrimary};*/}
                    {/*            text-decoration: none;*/}
                    {/*            &:hover {*/}
                    {/*                text-decoration: underline;*/}
                    {/*            }`}*/}
                    {/*    onClick={async e => {*/}
                    {/*        return Promise.resolve().then(() => {*/}
                    {/*            inventoryState.setProductFilterAsync({*/}
                    {/*                warehouse: warehouse,*/}
                    {/*                searchText: '',*/}
                    {/*                showLandingPage: false*/}
                    {/*            })*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*>{product.Category ?? ''}</Link>*/}
                    {product.SubCategoryId && (<>
                        <KeyboardArrowRight css={css`font-size:.9em;`} />
                        <span css={css`flex: 0 0 auto;`}>{product.SubCategory ?? ''}</span>
                        {/*<Link
                            to={`/inventory/${product.WebCatalogId}/${product.InventoryDepartmentId}/${product.CategoryId}/${product.SubCategoryId}`}
                            css={css`
                                    flex:0 0 auto;
                                    cursor:pointer;
                                    color: ${theme.palette.siteTextPrimary};
                                    text-decoration: none;
                                    &:hover {
                                        text-decoration: underline;
                                    }`}
                        >{product.SubCategory ?? ''}</Link>*/}
                    </>)}
                </div>
            </div>
        );
    }
    return null;
}