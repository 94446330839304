/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
/* ShipWeight */
export type ShipWeightFieldProps = {

}
export const ShipWeightField: React.FC<ShipWeightFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const shipWeightLbs = productDetailsState.get().product?.ShipWeightLbs;
    const shipWeightOz = productDetailsState.get().product?.ShipWeightOz;
    const translate = useTranslate();

    return (<>
        <div className="field">
            <div className="caption">{translate("Shipping Weight")}:</div>
            <div className="value">
                {shipWeightLbs && (<>{shipWeightLbs}lbs</>)} {shipWeightOz && (<>{shipWeightOz}oz</>)}
            </div>
        </div>
    </>);
}