/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
//import { Chip, MenuItem } from "@mui/material";
import { PackageItem, Product } from "../../../../hooks/useInventoryState";
import { useShoppingCartItemsState } from "../../../../hooks/useShoppingCartItemsState";
import FwFunc from "../../../../lib/FwReact/FwFunc";
//import FwNumberBox, { useFwNumberBoxState } from "../../../../lib/FwReact/FwNumberBox";
import { FwNumberDropDown } from "../../../../lib/FwReact/FwNumberDropDown";
import { ProductDetailsViewSettings } from "../../ProductDetails";
import { ProductDetailsPackageItemAvailability } from "../PackageItemFields/ProductDetailsPackageItemAvailability";
//import { ProductDetailsPackageItemImage } from "../PackageItemFields/ProductDetailsPackageItemImage";

//------------------------------------------------------------------------------------
export type ProductDetailsOptionalPackageItemDesktopProps = {
    packageProduct: ImmutableObject<Product>;
    packageItem: ImmutableObject<PackageItem>;
    packageQtyInCart: number;
    viewSettings: ProductDetailsViewSettings;
}
export const ProductDetailsOptionalPackageItemDesktop: React.FC<ProductDetailsOptionalPackageItemDesktopProps> = props => {
    const packageProduct = props.packageProduct;
    const isAvailable = (packageProduct.Availability?.TotalIn ?? 0) > 0;
    const canAddToCart = (props.packageQtyInCart >= 0) && (isAvailable || props.viewSettings.allowAddingUnavailableItems);
    const item = props.packageItem;
    //const qtyState = useFwNumberBoxState();
    const shoppingCartItemsState = useShoppingCartItemsState();
    //const showImage = item.Images && (item.Images.length > 0);
    const showAvailability = item.Availability !== undefined;
    const showDailyRate = (props.viewSettings.hasRates && props.viewSettings.hasDailyRate);
    const showWeeklyRate = (props.viewSettings.hasRates && props.viewSettings.hasWeeklyRate);
    const showMonthlyRate = (props.viewSettings.hasRates && props.viewSettings.hasMonthlyRate);
    //const packageItemDefaultQty = props.packageItem.DefaultQuantity;
    const packageItemQtyInCart = shoppingCartItemsState.getPackageItemQty(props.packageProduct, item);

    const handleChangeQtyAsync = async (value: number): Promise<void> => {
        return Promise.resolve().then(() => {
            shoppingCartItemsState.setPackageItemQty(props.packageProduct, props.packageItem, value);
        });
    }

    return (
        <tr className="packageitem" style={{ /*backgroundColor: item.LineColor*/ }}>
            <td className="col-legend"></td>
            {/*<td className="col-image">*/}
            {/*    {showImage && (*/}
            {/*        <ProductDetailsPackageItemImage product={packageProduct} packageItem={item} />*/}
            {/*    )}*/}
            {/*</td>*/}
            {/*<div className="fld ICode">{item.ICode}</div>*/}
            <td className="col-description" colSpan={2}>{item.Description}</td>
            {/*<td className="Tags">*/}
            {/*    {item.IsOption && (*/}
            {/*        <Chip label={'optional'} style={{backgroundColor: 'yellow', color: 'black'}}></Chip>*/}
            {/*    )}*/}
            {/*    <Chip label={`x${item.DefaultQuantity}`} style={{ backgroundColor: 'orange', color: 'black' }}></Chip>*/}
            {/*</td>*/}
            {(canAddToCart || showAvailability) && (<>
                {/*<td className="col-qty">{packageItemDefaultQty}</td>*/}
                <td className="col-qtyincart">
                    {canAddToCart && (
                        <FwNumberDropDown
                            css={css`flex:1 1 0;`}
                            value={packageItemQtyInCart}
                            onChange={(newValue, oldValue) => {
                                handleChangeQtyAsync(newValue);
                            }}
                        />
                        //<div css={css`display:flex;align-items:center;`}>
                        //    {/*<input*/}
                        //    {/*    css={css`width:50px;`}*/}
                        //    {/*    type="text"*/}
                        //    {/*    value={shoppingCartItemsState.getPackageItemQty(props.packageProduct, item)}*/}
                        //    {/*    onChange={e => handleChangeQtyAsync(parseInt(e.currentTarget.value))}*/}
                        //    {/*/>*/}
                        //    {/*<FwNumberBox*/}
                        //    {/*    label={undefined}*/}
                        //    {/*    fwNumberBoxState={qtyState}*/}
                        //    {/*    value={shoppingCartItemsState.getPackageItemQty(props.packageProduct, item)}*/}
                        //    {/*    onChange={handleChangeQtyAsync}*/}
                        //    {/*    backgroundColor={item.IsOption ? 'yellow' : undefined}*/}
                        //    {/*/>*/}
                            

                        //</div>
                    )}
                </td>
            </>)}

            {showDailyRate && (
                <td className="col-dailyrate">
                    <span className="CurrencySymbol">{item.CurrencySymbol}</span>
                    <span className="Price">{FwFunc.numberFormatter(item.Charge ? item.DailyRate : 0, 2, ',', '.')}</span>
                    {/*<span className="CurrencyCode">{item.CurrencyCode}</span>*/}
                </td>
            )}

            {showWeeklyRate && (
                <td className="col-weeklyrate">
                    <span className="CurrencySymbol">{item.CurrencySymbol}</span>
                    <span className="Price">{FwFunc.numberFormatter(item.Charge ? item.WeeklyRate : 0, 2, ',', '.')}</span>
                    {/*<span className="CurrencyCode">{item.CurrencyCode}</span>*/}
                </td>
            )}

            {showMonthlyRate && (
                <td className="col-monthlyrate">
                    <span className="CurrencySymbol">{item.CurrencySymbol}</span>
                    <span className="Price">{FwFunc.numberFormatter(item.Charge ? item.MonthlyRate : 0, 2, ',', '.')}</span>
                    {/*<span className="CurrencyCode">{item.CurrencyCode}</span>*/}
                </td>
            )}

            {false && showAvailability && (
                <td className="col-availability" css={css`/*display:flex;align-items:center;width:100px;*/`}>
                    <ProductDetailsPackageItemAvailability packageItem={item} />
                </td>
            )}
        </tr>
    );
}