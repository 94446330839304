/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useThemeState } from '../../hooks/useThemeState';

interface CustomScrollbarsProps {
    children?: React.ReactNode;
    overridecss?: string;
}
export const CustomScrollbars: React.FC<CustomScrollbarsProps> = props => {
    const themeState = useThemeState();
    const isDarkTheme = themeState.get().themeName === 'dark';

    return (
        <div css={css`flex:1 1 0;overflow:auto;color-scheme:${isDarkTheme ? 'dark' : 'light'};${props.overridecss ?? ''};`}>
            {props.children}
        </div>
    );
}
