import { hookstate, ImmutableArray, ImmutableObject, none, State, useHookstate } from '@hookstate/core'
//import { localstored } from '@hookstate/localstored';
import { LocaleState, useLocaleState } from "./useLocaleState";
import { PackageItem, Product } from './useInventoryState';
import { devtools } from '@hookstate/devtools';
import { CatalogState, useCatalogState } from './useCatalogState';

export class ShoppingCartItemModel {
    product: ImmutableObject<Product>;
    qty: number;
    packageItemQtys: { [key: string]: number } = {};
    showPackageItems: boolean = false;

    constructor(product: ImmutableObject<Product>, qty: number) {
        this.product = product;
        this.qty = qty;
    }
}

//export class ShoppingCartPackageItemModel {
//    inventoryId: string;
//    qty: number;

//    constructor(inventoryId: string, qty: number) {
//        this.inventoryId = inventoryId;
//        this.qty = qty;
//    }
//}

const storefront_shoppingcartitems_key = 'storefront-shoppingcartitems';
const shoppingcartitemsJson = window.localStorage.getItem('storefront-shoppingcartitems');
let initialShoppingCartItems: ShoppingCartItemModel[];
if (shoppingcartitemsJson) {
    initialShoppingCartItems = JSON.parse(shoppingcartitemsJson);
} else {
    initialShoppingCartItems = [];
}
let shoppingCartItemsStateStore = hookstate<ShoppingCartItemModel[]>(initialShoppingCartItems, devtools({ key: 'ShoppingCartItemsState' }));

export class ShoppingCartItemsState {
    private localeState: LocaleState;
    private shoppingCartItemsState: State<ShoppingCartItemModel[]>;
    private catalogState: CatalogState;

    constructor(localeState: LocaleState, shoppingCartItemsState: State<ShoppingCartItemModel[]>, catalogState: CatalogState) {
        this.localeState = localeState;
        this.shoppingCartItemsState = shoppingCartItemsState;
        this.catalogState = catalogState;
    }

    get(): ImmutableArray<ShoppingCartItemModel> {
        let allItemsInCart = this.shoppingCartItemsState.get();
        //let filteredItemsInCart: ShoppingCartItemModel[] = [];
        //// filter the items in cart and don't return any items that are not in an active catalog
        //const catalogs = this.catalogState.get().catalogs;
        //if (catalogs) {
        //    for (let i = 0; i < allItemsInCart.length; i++) {
        //        let item = allItemsInCart[i];
        //        if (item) {
        //            const catalog = this.catalogState.getCatalogById(item.product.WebCatalogId);
        //            if (catalog) {
        //                filteredItemsInCart.push(item);
        //            }
        //        }
        //    }
        //}
        //return filteredItemsInCart as ImmutableArray<ShoppingCartItemModel>;
        return allItemsInCart
    }

    reset(catalogId?: string) {
        /*shoppingCartItemsStateStore = hookstate<ShoppingCartItemModel[]>(initialShoppingCartItems, devtools({ key: 'ShoppingCartItemsState' }));*/
        if (!catalogId) {
            // delete all shopping cart items
            this.shoppingCartItemsState.set([]);
        } else {
            // delete the shopping cart items from the specified catalog
            const items = this.shoppingCartItemsState.get();
            for (let i = items.length - 1; i >= 0; i--) {
                const item = items[i];
                if (item.product.WebCatalogId === catalogId) {
                    this.shoppingCartItemsState[i].set(none);
                }
            }
        }
        this.saveToLocalStorage();
    }

    addFromProduct(product: ImmutableObject<Product>, qty: number | string): ShoppingCartItemModel {
        if (typeof qty === 'string') {
            qty = parseFloat(qty)
        }
        const item = new ShoppingCartItemModel(product, qty);
        this.add(item);
        return item;
    }

    add(item: ShoppingCartItemModel) {
        let alreadyInCart = false;
        for (let i = 0; i < this.shoppingCartItemsState.get().length; i++) {
            const item2 = this.shoppingCartItemsState.get()[i];
            if (item.product.Id === item2.product.Id) {
                alreadyInCart = true;
                this.changeQty(i, item, item2.qty + item.qty);
            }
        }
        if (!alreadyInCart) {
            this.shoppingCartItemsState.merge([item]);
            this.saveToLocalStorage();
        }
    }

    replace(items: ShoppingCartItemModel[]) {
        this.shoppingCartItemsState.set(items);
        this.saveToLocalStorage();
    }

    delete(item: ShoppingCartItemModel) {
        const shoppingCartItems = this.shoppingCartItemsState.get();
        if (shoppingCartItems) {
            const index = shoppingCartItems.findIndex(o => o.product.Id === item.product.Id);
            if (index > -1) {
                this.shoppingCartItemsState.set(p => {
                    p.splice(index, 1);
                    return p;
                });
                this.saveToLocalStorage();
            }
        }
    }

    deleteItems(items: ShoppingCartItemModel[]) {
        const shoppingCartItems = this.shoppingCartItemsState.get();
        const indexesToDelete: number[] = [];
        if (shoppingCartItems && items) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const index = shoppingCartItems.findIndex(o => o.product.Id === item.product.Id);
                if (index > -1) {
                    indexesToDelete.push(index);
                }
            }
            if (indexesToDelete.length > 0) {
                for (let i = indexesToDelete.length - 1; i >= 0; i--) {
                    this.shoppingCartItemsState[i].set(none);
                }
                this.saveToLocalStorage();
            }
        }
    }

    deletePackageItem(shoppingCartItem: ShoppingCartItemModel, packageItem: ImmutableObject<PackageItem>) {
        //const shoppingCartItems = this.shoppingCartItemsState.get();
        //if (shoppingCartItems) {
        //    const shoppingCartItem = shoppingCartItems.find(o => o.product.Id === shoppingCartItem.product.Id);
        //    if (shoppingCartItem) {
        //        shoppingCartItem.packageItemQtys[packageItem.InventoryId] = 0;

        //        const index = shoppingCartItem.packageItemQtys[packageItem.InventoryId];
        //        if (index !== undefined) {

        //        }

        //        shoppingCartItem..set(p => {
        //            p.splice(index, 1);
        //            return p;
        //        });
        //        this.saveToLocalStorage();
        //    }
        //}
        this.setPackageItemQty(shoppingCartItem.product, packageItem, 0);
    }

    changeQty(index: number, item: ImmutableObject<ShoppingCartItemModel>, qty: number) {
        const shoppingCartItems = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
        if (index > -1) {
            if (qty > 0) {
                shoppingCartItems[index].qty = qty;
                this.shoppingCartItemsState.set(shoppingCartItems);
                this.saveToLocalStorage();
            } else {
                this.delete(item);
            }
        }
    }

    getQty(product: ImmutableObject<Product>): number {
        let shoppingCartItems = this.shoppingCartItemsState.get();
        for (let i = 0; i < shoppingCartItems.length; i++) {
            const item = shoppingCartItems[i];
            if (item.product.Id === product.Id) {
                return item.qty;
            }
        }
        return 0; 
    }

    setQty(product: ImmutableObject<Product>, qty: number): void {
        const productId = product.Id;
        const shoppingCartItems = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
        const shoppingCartItemIndex = shoppingCartItems.findIndex(i => i.product.Id === product.Id);
        if ((shoppingCartItemIndex !== -1) && (shoppingCartItemIndex >= 0) && (shoppingCartItemIndex < shoppingCartItems.length)) {
            const shoppingCartItem = shoppingCartItems[shoppingCartItemIndex];
            if (qty > 0) {
                shoppingCartItem.qty = qty;
                this.shoppingCartItemsState.set(shoppingCartItems);
            } else {
                this.shoppingCartItemsState.set(p => {
                    p.splice(shoppingCartItemIndex, 1);
                    return p;
                });
            }
            this.saveToLocalStorage();
        } else {
            this.addFromProduct(product, qty);
        }

        // product becomes undefined by this point (or at least gives an undefined exception)
        const shoppingCartItems2 = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
        let shoppingCartItem2 = shoppingCartItems2.find(i => i.product.Id === productId);
        if (shoppingCartItem2) {
            const packageItems = shoppingCartItem2.product.PackageItems ?? [];
            for (let i = 0; i < packageItems.length; i++) {
                const packageItem = packageItems[i];
                if (packageItem) {
                    if (shoppingCartItem2.packageItemQtys[packageItem.InventoryId] === undefined) {
                        shoppingCartItem2.packageItemQtys[packageItem.InventoryId] = 0;
                    }
                    if (qty > 0) {
                        if (!packageItem.IsOption) {
                            shoppingCartItem2.packageItemQtys[packageItem.InventoryId] = qty * packageItem.DefaultQuantity;
                            this.shoppingCartItemsState.set(shoppingCartItems2);
                        }
                    } else {
                        this.shoppingCartItemsState.set(p => {
                            p.splice(i, 1);
                            return p;
                        });
                    }
                }
            }
            this.saveToLocalStorage();
        }
        
    }

    getPackageItemQty(product: ImmutableObject<Product>, packageiItem: ImmutableObject<PackageItem>): number {
        let shoppingCartItems = this.shoppingCartItemsState.get();
        const shoppingCartItem = shoppingCartItems.find(i => i.product.Id === product.Id);
        if (shoppingCartItem) {
            if (shoppingCartItem.packageItemQtys && shoppingCartItem.packageItemQtys[packageiItem.InventoryId]) {
                return shoppingCartItem.packageItemQtys[packageiItem.InventoryId];
            }
        }
        return 0;
    }

    setPackageItemQty(product: ImmutableObject<Product>, packageItem: ImmutableObject<PackageItem>, qty: number): void {
        let shoppingCartItems = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
        let shoppingCartItemIndex = shoppingCartItems.findIndex(i => i.product.Id === product.Id);
        if (shoppingCartItemIndex === -1) {
            this.setQty(product, qty);
            shoppingCartItems = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
            shoppingCartItemIndex = shoppingCartItems.findIndex(i => i.product.Id === product.Id);
        }
        // if we were able to find the main package in the shopping cart, then update the count of the packageitem
        if ((shoppingCartItemIndex !== -1) && (shoppingCartItemIndex >= 0) && (shoppingCartItemIndex < shoppingCartItems.length)) {
            const shoppingCartItem = shoppingCartItems[shoppingCartItemIndex];
            if (shoppingCartItem) {
                shoppingCartItem.packageItemQtys[packageItem.InventoryId] = qty;
                this.shoppingCartItemsState.set(shoppingCartItems);
                this.saveToLocalStorage();
                return;
            }
        }
    }

    setShowPackageItems(shoppingCartItem: ShoppingCartItemModel, showPackageItems: boolean) {
        let shoppingCartItems = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
        let shoppingCartItemIndex = shoppingCartItems.findIndex(i => i.product.Id === shoppingCartItem.product.Id);
        if (shoppingCartItemIndex !== -1) {
            const shoppingCartItem = shoppingCartItems[shoppingCartItemIndex];
            if (shoppingCartItem) {
                shoppingCartItem.showPackageItems = showPackageItems;
                this.shoppingCartItemsState.set(shoppingCartItems);
                this.saveToLocalStorage();
            }
        }
    }

    getRates(shoppingCartItem: ShoppingCartItemModel) {
        let dailyRate = shoppingCartItem.product.DailyRate;
        let weeklyRate = shoppingCartItem.product.WeeklyRate;
        let monthlyRate = shoppingCartItem.product.MonthlyRate;
        let salesPrice = shoppingCartItem.product.SalesPrice;
        if ((shoppingCartItem.packageItemQtys !== undefined) && (Object.keys(shoppingCartItem.packageItemQtys).length > 0)) {
            dailyRate = 0;
            weeklyRate = 0;
            monthlyRate = 0;
            for (let inventoryId in shoppingCartItem.packageItemQtys) {
                if (shoppingCartItem.product.PackageItems) {
                    const packageItem = shoppingCartItem.product.PackageItems.find(pi => pi.InventoryId === inventoryId);
                    const qty = shoppingCartItem.packageItemQtys[inventoryId];
                    if ((packageItem !== undefined) && (qty !== undefined) /*&& (packageItem.IsOption)*/) {
                        dailyRate += (packageItem.DailyRate * qty);
                        weeklyRate += (packageItem.WeeklyRate * qty);
                        monthlyRate += (packageItem.MonthlyRate * qty);
                        salesPrice += (packageItem.SalesPrice * qty);
                    }
                }
            }
        }
        return {
            dailyRate: dailyRate,
            weeklyRate: weeklyRate,
            monthlyRate: monthlyRate,
            salesPrice: salesPrice
        }
;
    }

    getRatesByProduct(product?: ImmutableObject<Product>) {
        let rates = {
            dailyRate: 0,
            weeklyRate: 0,
            monthlyRate: 0,
            salesPrice: 0
        };
        if (product) {
            rates = {
                dailyRate: product.DailyRate,
                weeklyRate: product.WeeklyRate,
                monthlyRate: product.MonthlyRate,
                salesPrice: product.SalesPrice
            }
            let shoppingCartItems = JSON.parse(JSON.stringify(this.shoppingCartItemsState.get())) as ShoppingCartItemModel[];
            let shoppingCartItemIndex = shoppingCartItems.findIndex(i => i.product.Id === product.Id);
            if (shoppingCartItemIndex !== -1) {
                const shoppingCartItem = shoppingCartItems[shoppingCartItemIndex];
                rates = this.getRates(shoppingCartItem); 
            }
        }
        return rates;
    }

    private saveToLocalStorage() {
        const obj = this.shoppingCartItemsState.get();
        let json = '';
        if (obj) {
            json = JSON.stringify(obj);
        }
        window.localStorage.setItem(storefront_shoppingcartitems_key, json);
    }
}

export function useShoppingCartItemsState(): ShoppingCartItemsState {
    const localeState = useLocaleState();
    const shoppingCartItemsState = useHookstate<ShoppingCartItemModel[]>(shoppingCartItemsStateStore);
    const catalogState = useCatalogState();
    return new ShoppingCartItemsState(localeState, shoppingCartItemsState, catalogState);
}
