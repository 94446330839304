/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------ 
export type TechnicalNotesFieldProps = {

}
export const TechnicalNotesField: React.FC<TechnicalNotesFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();
    if (productDetailsState.get().product?.TechnicalNotes) {
        return (
            <div className="field">
                <div className="caption">
                    {translate('Technical Notes')}
                </div>
                <div className="value" css={css`white-space: pre-wrap;`}>
                    {productDetailsState.get().product?.TechnicalNotes}
                </div>
            </div>
        );
    }
    return null;
}