/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { SiteLayout } from "../components/SiteLayout";
import { useNavigate } from "react-router-dom";
import { useCountdownTimerState } from "../hooks/useCountdownTimer";

export type NotFoundPageProps = {
    
}
export const NotFoundPage: React.FC<NotFoundPageProps> = props => {
    const navigate = useNavigate();
    const countdownTimerState = useCountdownTimerState(() => {
        navigate('/');
    }, 5);
    const timeRemaining = countdownTimerState.getSecondsRemaning();

    return (<>
        <SiteLayout 
            title="Not Found."
            mainChildren={<>
                <div css={css`flex:1 1 0;display:flex;align-items:center;justify-content:center;`}>
                    Not Found.  Redirecting in {timeRemaining} seconds...
                </div>
            </>}
        />
    </>);
}

export default NotFoundPage