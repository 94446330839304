/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

export type CheckoutDateProps = {
    type: 'startdate' | 'stopdate';
    fullwidth?: boolean;
    catalogId?: string;
}
export const CheckoutDate: React.FC<CheckoutDateProps> = props => {
    const checkoutFormState = useCheckoutFormState();
    const isFormDisabled = checkoutFormState.isFormDisabled();
    const translate = useTranslate();
    let label, value;
    if (props.type === 'startdate') {
        label = translate('Start Date');
        const fromDate = checkoutFormState.getFromDate(props.catalogId);
        value = dayjs(fromDate);
    }
    else {
        label = translate('End Date');
        const toDate = checkoutFormState.getToDate(props.catalogId);
        value = dayjs(toDate);
    }

    return (<>
        <FormControl fullWidth={props.fullwidth}>
            <DatePicker
                disabled={isFormDisabled}
                disablePast
                label={label}
                value={value}
                onChange={async (newValue) => {
                    const date = newValue?.format('YYYY-MM-DD') ?? ''
                    return Promise.resolve().then(() => {
                        if (props.type === 'startdate') {
                            checkoutFormState.setFromDate(date, props.catalogId);
                        }
                        else {
                            checkoutFormState.setToDate(date, props.catalogId);
                        }
                    });
                }}
                    css={css`width:170px;`} />
        </FormControl>
    </>)
}
