/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Chip } from "@mui/material";
import { Product } from "../../../hooks/useInventoryState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

export type ProductBrowserGridItemBarcodesProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserGridItemBarcodes: React.FC<ProductBrowserGridItemBarcodesProps> = props => {
    const product = props.product;
    const translate = useTranslate();

    return (<>
        {product.Barcodes && (
            <div className="field">
                <div className="caption">
                    {translate('Barcodes')}:
                </div>
                <div className="value">

                    {product.Barcodes?.split(',').map((tag, index, array) => (<span key={index}>
                        {tag && (
                            <Chip label={tag} variant="outlined" css={css`margin: .25em;`} />
                        )}
                    </span>))}
                </div>
            </div>
        )}
    </>);
}