export const BaseTranslations = {
    "Add to cart": { es: "Añadir", fr: "Ajouter" },
    "Added": { es: "Añadido", fr: "Ajouté" },
    "available": { es: "disponible", fr: "disponible" },
    "Availability": { es: "Disponibilidad", fr: "Disponibilité" },
    "Barcodes": { es: "Códigos de barras", fr: "Codes à barres" },
    "Cancel": { es: "Cancelar", fr: "Annuler" },
    "Cannot request more than then the total available qty.": { es: "No se puede solicitar más de la cantidad total disponible.", fr: "Il n'est pas possible de demander plus que la quantité totale disponible." },
    "CATALOG": { es: "CATÁLOGO", fr: "CATALOGUE" },
    "Cart": { es: "Cesta", fr: "Panier" },
    "Change password failed": { es: "Error al cambiar la contraseña", fr: "Le changement de mot de passe a échoué" },
    "Change password was successful": { es: "El cambio de contraseña se ha realizado correctamente", fr: "Le changement de mot de passe a été effectué avec succès" },
    "Checkout": { es: "Checkout", fr: "Checkout" },
    "Clear Cart?": { es: "¿Despejar el carro?", fr: "Panier vide ?" },
    "Clear Cart": { es: "Despejar el carro", fr: "Panier vide" },
    "Close": { es: "Cerrar", fr: "Fermer" },
    "Collapse": { es: "Colapso", fr: "Effondrement" },
    "Contact Info": { es: "Información de contacto", fr: "Informations de contact" },
    "Copy": { es: "Copiar", fr: "Copie" },
    "day": { es: "día", fr: "jour" },
    "days": { es: "días", fr: "jours" },
    "daily": { es: "diario", fr: "quotidien" },
    "Daily": { es: "Diario", fr: "Quotidien" },
    "Description": { es: "Descripción", fr: "Description" },
    "Delete": { es: "Borrar", fr: "Supprimer" },
    "Email": { es: "Correo electrónico", fr: "Courriel" },
    "Email is required": { es: "Correo electrónico obligatorio", fr: "L'adresse électronique est requise" },
    "End Date": { es: "Fecha final", fr: "Date de fin" },
    "Expand": { es: "Ampliar", fr: "Élargir" },
    "Export Cart": { es: "Cesta de exportación", fr: "Panier d'exportation" },
    "First Name": { es: "Nombre", fr: "Prénom" },
    "First Name is required": { es: "El nombre es obligatorio", fr: "Le prénom est obligatoire" },
    "For rent": { es: "En alquiler", fr: "A louer" },
    "For sale": { es: "Se vende", fr: "A vendre" },
    "Grid View": { es: "Vista en cuadrícula", fr: "Vue en grille" },
    "Image": { es: "Imagen", fr: "Image" },
    "Import Cart": { es: "Cesta de importación", fr: "Panier d'importation" },
    "in cart": { es: "en carro", fr: "dans le panier" },
    "Inquire": { es: "Consulte", fr: "Demander" },
    "Inquire about": { es: "Consulta sobre", fr: "Se renseigner sur" },
    "Inquiry sent": { es: "Consulta enviada", fr: "Demande envoyée" },
    "Inventory": { es: "Inventario", fr: "Inventaire" },
    "Item": { es: "Artículo", fr: "Objet" },
    "items": { es: "artículos", fr: "objets" },
    "Item Name": { es: "Nombre del artículo", fr: "Nom de l'article" },
    "Item No": { es: 'Nº de artículo', fr: "Numéro d'article" },
    "Job": { es: "Trabajo", fr: "Travail" },
    "Keywords": { es: "Palabras clave", fr: "Mots clés" },
    "Last Name": { es: "Apellido", fr: "Nom de famille" },
    "Last Name is required": { es: "Apellido obligatorio", fr: "Le nom de famille est obligatoire" },
    "Link": { es: "Enlace", fr: "Lien" },
    "List View": { es: "Ver lista", fr: "Vue de la liste" },
    "Location": { es: "Ubicación", fr: "Localisation" },
    "Login": { es: "Inicio sesión", fr: "Login" },
    "month": { es: "mes", fr: "mois" },
    "Manufacturer": { es: "Fabricante", fr: "Fabricant" },
    "Manufacturer URL": { es: "URL del fabricante", fr: "URL du fabricant" },
    "Manufacturer Part Number": { es: "Número de pieza del fabricante", fr: "Numéro de pièce du fabricant" },
    "Message": { es: "Mensaje", fr: "Message" },
    "Message is required": { es: "El mensaje es obligatorio", fr: "Le message est obligatoire" },
    "monthly": { es: "mensualmente", fr: "mensuel" },
    "Monthly": { es: "Mensualmente", fr: "Mensuel" },
    "New Job": { es: "Nuevo trabajo", fr: "Nouveau travail" },
    "Notes": { es: "Notas", fr: "Notes" },
    "OK": { es: "OK", fr: "OK" },
    "Optional accessories": { es: "Accesorios opcionales", fr: "Accessoires en option" },
    "Order Description": { es: "Descripción del pedido", fr: "Description de la commande" },
    "package": { es: "paquete", fr: "paquet" },
    "Package includes": { es: "El paquete incluye", fr: "Le paquet comprend" },
    "Page": { es: "Página", fr: "Page" },
    "Phone": { es: "Teléfono", fr: "Téléphone" },
    "Phone is required": { es: "Teléfono obligatorio", fr: "Le téléphone est obligatoire" },
    "Print": { es: "Imprimir", fr: "Imprimer" },
    "Price": { es: "Precio", fr: "Prix" },
    "Qty": { es: "Ctd", fr: "Qté" },
    "Qty in cart": { es: "Ctd en cesta", fr: "Qté en panier" },
    "Quantity": { es: "Cantidad", fr: "Quantité" },
    "Quote Description": { es: "Descripción de el presupuesto", fr: "Description du devis" },
    "Quote Request": { es: "Solicitud de presupuesto", fr: "Demande de devis" },
    "Quote request submitted": { es: "Solicitud de presupuesto enviada", fr: "Demande de devis soumise" },
    "Rate": { es: "Tarifa", fr: "Taux" }, 
    "Register": { es: "Registrarse", fr: "S'inscrire" },
    "Removed": { es: "Eliminado", fr: "Supprimé" },
    "Replacement Cost": { es: "Coste de sustitución", fr: "Coût de remplacement" },
    "Request Quote": { es: "Solicitar presupuesto", fr: "Demande de devis" },
    "Reservations for different date ranges must be submitted as separate Orders": { es: "Las reservas para distintos intervalos de fechas deben presentarse como pedidos separados", fr: "Les réservations pour des plages de dates différentes doivent faire l'objet de commandes distinctes" },
    "Reset password failed": { es: "Error al restablecer la contraseña", fr: "Échec de la réinitialisation du mot de passe" },
    "Reset password was successful": { es: "La contraseña se ha restablecido correctamente", fr: "La réinitialisation du mot de passe a réussi" },
    "Search": { es: "Buscar", fr: "Recherche" },
    "Search Options": { es: "Opciones de búsqueda", fr: "Options de recherche" },
    "Send": { es: "Enviar", fr: "Envoyer" },
    "Share": { es: "Compartir", fr: "Partager" },
    "Shipping Weight": { es: "Peso del envío", fr: "Poids d'expédition" },
    "Shopping Cart": { es: "Cesta de la compra", fr: "Panier d'achat" },
    "Start Date": { es: "Fecha de inicio", fr: "Date de début" },
    "Submit": { es: "Enviar", fr: "Soumettre" },
    "Submit Equipment Request": { es: "Enviar solicitud de presupuesto", fr: "Soumettre une demande de devis" },
    "Submit Order": { es: "Enviar pedido", fr: "Soumettre la commande" },
    "Submit Request for Quote": { es: "Enviar solicitud de presupuesto", fr: "Soumettre une demande de devis" },
    "Submitting Request for Quote": { es: "Envío de la solicitud de presupuesto", fr: "Soumission d'une demande de devis" },
    "Sugggested": { es: "Sugerencia", fr: "Suggéré" },
    "Tags": { es: "Tags", fr: "Tags" },
    "Technical Notes": { es: "Notas técnicas", fr: "Notes techniques" },
    "Thumbnail Size": { es: "Tamaño de la miniatura", fr: "Taille de la vignette" },
    "Total Items": { es: "Total de artículos", fr: "Total des articles" },
    "To": { es: "A", fr: "A" },
    "Unavailable": { es: "No disponible", fr: "Indisponible" },
    "Unable to send inquiry": { es: "No se puede enviar la consulta", fr: "Impossible d'envoyer une demande de renseignements" },
    "Update cart": { es: "Actualizar", fr: "Mise à jour" },
    "Updated": { es: "Actualizado", fr: "Mise à jour" },
    "View accessories": { es: "Ver accesorios", fr: "Voir les accessoires" },
    "View Catalog": { es: "Ver Catálogo", fr: "Voir le Catalogue" },
    "Warehouse": { es: "Almacén", fr: "Entrepôt" },
    "week": { es: "semana", fr: "semaine" },
    "weekly": { es: "semanal", fr: "hebdomadaire" },
    "Weekly": { es: "Semanal", fr: "Hebdomadaire" },
}
