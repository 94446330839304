/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { CircularProgress } from "@mui/material";
import React from "react";
import { Catalog } from "../../../hooks/useCatalogState";
//import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";
import { ProductCategoriesTree, ProductDepartment, /*useInventoryState*/ } from "../../../hooks/useInventoryState";
import { DepartmentControl } from "./DepartmentControl";

//-------------------------------------------------------------------------------------------------------
export type CategoryTreeControlProps = {
    catalog?: Catalog | ImmutableObject<Catalog>;
    rentalCategoriesTree?: ProductCategoriesTree;
}
export const CategoryTreeControl: React.FC<CategoryTreeControlProps> = props => {
    //const inventoryState = useInventoryState();
    //const checkoutFormState = useCheckoutFormState();
   // const warehouse = checkoutFormState.getWarehouse();
    const departments = props.rentalCategoriesTree?.Departments;

    return <>
        {((!props.catalog) || (!props.rentalCategoriesTree)) && (
            <div css={css`
                display: flex;
                align-items: center;
                justify-content: center;
            ` }>
                <CircularProgress color="primary" />
            </div>
        )}
        {props.catalog && departments && (
            <div
                onClick={async () => {
                    //return Promise.resolve().then(() => {
                    //    inventoryState.setProductFilterAsync({
                    //        warehouse: warehouse,
                    //        catalog: props.catalog,
                    //        department: undefined,
                    //        category: undefined,
                    //        subCategory: undefined,
                    //        searchText: undefined,
                    //        showLandingPage: false
                    //    });
                    //});
                }}>
            </div>
        )}
        {props.catalog && departments && departments.map((productDepartment: ProductDepartment, index: number) => (<React.Fragment key={index}>
            <DepartmentControl
                catalog={props.catalog as Catalog}
                department={productDepartment}
                departments={departments}
            />
        </React.Fragment>))}
    </>
}