/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { SiteLayout } from "../components/SiteLayout";
import { ShoppingCartControl } from "../components/ShoppingCart/ShoppingCartControl";
import { useStorefrontSettingsState } from "../hooks/useStorefrontSettingsState";
import { useHookstateEffect } from "@hookstate/core";
//import { CustomScrollbars } from "../components/CustomScrollbars/CustomScrollbars";

export type ShoppingCartPageProps = {

}
export const ShoppingCartPage: React.FC<ShoppingCartPageProps> = props => {

    // reload the storefront settings in case they changed
    const storefrontSettingsState = useStorefrontSettingsState();
    useHookstateEffect(() => {
        storefrontSettingsState.loadAsync();
    }, []);

    return (<div>
        <SiteLayout 
            title="Shopping Cart"
            mainChildren={<>
                {/*<CustomScrollbars>*/}
                    <ShoppingCartControl />
                {/*</CustomScrollbars>*/}
            </>}
        />
    </div>);
}