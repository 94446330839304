/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate, ImmutableObject } from "@hookstate/core";
import { ArrowDropDown } from "@mui/icons-material";
//import { ArrowDropDown } from "@mui/icons-material";
import { ClickAwayListener, /*IconButton,*/ Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Catalog, useCatalogState } from "../hooks/useCatalogState";
import { useInventoryState } from "../hooks/useInventoryState";
//import { useThemeState } from "../hooks/useThemeState";

export type CatalogMenuProps = {
    css?: string;
}
export const CatalogMenu: React.FC<CatalogMenuProps> = props => {
    //const globalStateController = useGlobalState();
    const catalogState = useCatalogState();
    const inventoryState = useInventoryState();
    //const themeState = useThemeState();
    //const theme = themeState.get().theme;
    const navigate = useNavigate();

    const anchorElState = useHookstate<null | HTMLElement>(null);
    const open = Boolean(anchorElState.get());
    const handleClose = async (catalog?: Catalog|ImmutableObject<Catalog>) => {
        anchorElState.set(null);
        if (catalog) {
            //await inventoryState.filterByCatalogAsync(catalog);
            navigate(`/inventory/${catalog.Id}`);
        }
    };

    return (<>
        <ClickAwayListener onClickAway={async e => { await handleClose(); }}>
            <div css={css`
                display: flex;
                align-items: center;`}>
                {/*<div*/}
                {/*    css={css`*/}
                {/*        font-family: 'Ropa Sans';*/}
                {/*        font-size:1em;`}>*/}
                {/*    catalog:*/}
                {/*</div>*/}
                <div
                    css={css`
                        margin: 0 0 0 .5em;
                        font-family: 'Ropa Sans';
                        font-size:1em;
                        display:flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                        ${props.css ?? ''};`}
                    onClick={event => {
                        //navigate(`/inventory/${inventoryState.get().catalog?.Id}`);
                        anchorElState.set(event.currentTarget);
                    }}>
                    {inventoryState.get().catalog?.Name}
                    {<ArrowDropDown />}
                </div>
            </div>
        </ClickAwayListener>
        <Menu
            id="basic-menu"
            anchorEl={anchorElState.get() as HTMLButtonElement}
            open={open}
            onClose={async e => { await handleClose(); }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}>
            {catalogState.get().catalogs?.map((catalog, index, catalogs) => (
                <MenuItem key={index} onClick={async e => await handleClose(catalog)}>{catalog.Name}</MenuItem>
            ))}
        </Menu>
    </>);
}