/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useMediaQuery } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useStorefrontSettingsState } from "../../../hooks/useStorefrontSettingsState";
import { AutoplayOptions, SwiperModule } from "swiper/types";

const mobileImageSize: number = 320;
const desktopImageSize: number = 512;

/* Image */
export type ImageFieldProps = {

}
export const ImageField: React.FC<ImageFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    const images = product?.Images ?? [];
    const isDesktop = useMediaQuery(`@media (min-width: ${desktopImageSize + 32}px)`);
    const itemWidth = isDesktop ? desktopImageSize : mobileImageSize;

    const storefrontSettingsState = useStorefrontSettingsState();
    const swiperModules: SwiperModule[] = [];
    let autoplayOptions: AutoplayOptions | undefined;
    const settings = storefrontSettingsState.get().settings;
    if (settings && settings.AutoplayImagesOnProductDetails) {
        swiperModules.push(Autoplay);
        autoplayOptions = {
            delay: settings.AutoplaySpeed * 1000,
        };
    }
    swiperModules.push(Pagination);
    swiperModules.push(Navigation);

    if (product) {
        return (
            <div>
                <div css={css`
                    display:none;
                    @media print {
                        display: flex;
                        flex-direction: column;
                        justify-content:center;
                    }
                    ` }
                >
                    {images.map((image, index, images) => {
                        if (index === 0) {
                            return (
                                <div
                                    key={0}
                                    css={css`
                                        display:flex;
                                        align-items:center;
                                        justify-content:center;
                                        flex-wrap: wrap;
                                        `}
                                >
                                    <img
                                        src={image.Url}
                                        alt={product.ItemName}
                                        style={{
                                            maxWidth: `${desktopImageSize * .75}px`,
                                        }}
                                    />
                                </div>

                            );
                        }
                        return null;
                    })}
                    {((!images) || (images.length === 0)) && (
                        <div
                            key={0}
                            css={css`
                                display:flex;
                                align-items:center;
                                justify-content:center;
                                background-color: #f5f5f5;
                                width: ${mobileImageSize}px;
                                height: ${mobileImageSize}px;
                                @media (min-width: ${desktopImageSize}px) {
                                    width: ${desktopImageSize}px;
                                    height: ${desktopImageSize}px;
                                }
                                `}
                        >
                            <ImageIcon css={css`flex:1 1 0;width:256px;height:256px;color:#777777;`} />
                        </div>
                    )}
                </div>

                <div
                    className="noprint"
                    css={css`
                        flex: 1 1 0; 
                        display: flex;
                        justify-content:center;
                        `}>
                    <div
                        css={css`
                            flex: 0 0 auto;
                            overflow:hidden;
                            max-width: ${itemWidth}px;
                            max-height: ${itemWidth}px;
                        `}
                    >
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                            }}
                            autoplay={autoplayOptions}
                            spaceBetween={0}
                            navigation={false}
                            grabCursor={true}
                            centeredSlides={true}
                            centerInsufficientSlides={true}
                            slidesPerView={1}
                            modules={swiperModules}
                        >
                            {images.map((image, index, images) => (
                                <SwiperSlide
                                    key={image.Id}
                                    style={{
                                        width: itemWidth,
                                        height: itemWidth,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img
                                        src={image.Url}
                                        alt=""
                                        style={{
                                            maxWidth: itemWidth,
                                            maxHeight: itemWidth,
                                            width: 'auto',
                                            height: 'auto'
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                            {((!images) || (images.length === 0)) && (
                                <SwiperSlide>
                                    <div
                                        key={0}
                                        css={css`
                                        display:flex;
                                        align-items:center;
                                        justify-content:center;
                                        background-color: #f5f5f5;
                                        width: ${itemWidth}px;
                                        height: ${itemWidth}px;
                                        `}
                                    >
                                        <ImageIcon css={css`flex:1 1 0;width:256px;height:256px;color:#777777;`} />
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>

                </div>
            </div>
        );
    }
    else {
        return null;
    }
}
