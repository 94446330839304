/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { useCatalogState } from "../../../hooks/useCatalogState";
import { Product } from "../../../hooks/useInventoryState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import FwFunc from "../../../lib/FwReact/FwFunc";

//-------------------------------------------------------------------------------------------------------
// ListView - Rates
export type ProductBrowserListItemRatesProps = {
    product: ImmutableObject<Product>;
    hasDailyRate: boolean;
    hasWeeklyRate: boolean;
    hasMonthlyRate: boolean;
    hasSalesPrice: boolean;
}
export const ProductBrowserListItemRates: React.FC<ProductBrowserListItemRatesProps> = props => {
    const product = props.product;
    const translate = useTranslate();
    const catalogState = useCatalogState();
    const catalog = catalogState.getProductCatalog(product);
    let isRentalCatalog = false;
    let isSalesCatalog = false;
    if (catalog) {
        isRentalCatalog = catalog.HasRental ?? false;
        isSalesCatalog = catalog.HasSales ?? false;
    }
    const hasRentalRates = props.hasDailyRate || props.hasWeeklyRate || props.hasMonthlyRate;
    const themeState = useThemeState();
    const isDarkTheme = themeState.isDark();

    return (
        <div css={css`flex:0 0 auto;`}>
            <div css={css`
                        padding: 1em 0;
                        .rates {
                            padding: 0 .5em;
                            flex:1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                        .currencysymbol {
                            flex: 1 0 auto;
                            font-size:.8rem;
                            text-align: right;
                            padding: 0 .25em;
                        }
                        .amount {
                            flex: 0 0 auto;
                            text-align: right;
                            padding: 0 .5em;
                        }
                        .amount-wrapper {
                            display: flex;
                            align-items:center;
                        }
                        .ratevalue {
                            flex: 0 0 auto;
                            text-align: left;
                        }
                        .currencycode {
                            font-size:.8rem;
                            text-align: left;
                        }
                        `}>
                <div css={css`display:flex;justify-content:center;`}>
                    <table>
                        <tbody>
                            {isRentalCatalog && hasRentalRates && (<>
                                <tr>
                                    <td colSpan={2}>
                                        <div
                                            css={css`
                                                display:flex;
                                                align-items:center;
                                                justify-content:center;
                                                font-size:.8rem;
                                                border-top:1px solid rgba(255,255,255,.1);
                                                background-color: ${isDarkTheme ? 'rgba(255,255,255,.1);' : 'rgba(0,0,0,.1);'}
                                            `}>
                                            {translate('For rent')}
                                        </div>
                                    </td>
                                </tr>
                            </>)}
                            {isRentalCatalog && (<>
                                {props.hasDailyRate && (
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(product.DailyRate, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{product.CurrencyCode}/{translate('day')}</td>
                                    </tr>
                                )}
                                {props.hasWeeklyRate && (
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(product.WeeklyRate, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{product.CurrencyCode}/{translate('week')}</td>
                                    </tr>
                                )}
                                {props.hasMonthlyRate && (
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(product.MonthlyRate, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{product.CurrencyCode}/{translate('month')}</td>
                                    </tr>
                                )}
                            </>)}
                            {isSalesCatalog && props.hasSalesPrice && (<>
                                <tr>
                                    <td colSpan={2}>
                                        <div
                                            css={css`
                                                display:flex;
                                                align-items:center;
                                                justify-content:center;
                                                font-size:.8rem;
                                                border-top:1px solid rgba(255,255,255,.1);
                                                background-color: ${isDarkTheme ? 'rgba(255,255,255,.1);' : 'rgba(0,0,0,.1);'}
                                            `}>
                                            {translate('For sale')}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="amount">
                                        <div className="amount-wrapper">
                                            <div className="currencysymbol">{product.CurrencySymbol}</div>
                                            <div className="ratevalue">{FwFunc.numberFormatter(product.MonthlyRate, 2, ',', '.')}</div>
                                        </div>
                                    </td>
                                    <td className="currencycode">{product.CurrencyCode}</td>
                                </tr>
                            </>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}