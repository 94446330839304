/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useInventoryState } from "../../hooks/useInventoryState";
import { ToggleButtonGroup, ToggleButton, Tooltip, IconButton } from "@mui/material";
import { useHookstate, useHookstateEffect } from '@hookstate/core';
import { motion } from "framer-motion"
import { useThemeState } from "../../hooks/useThemeState";
import { ArrowDropDownCircle, UnfoldLess, UnfoldMore, ViewList, ViewModule } from "@mui/icons-material";
import { useProductBrowserState } from "../../hooks/useProductBrowserState";
import { BreadCrumbs } from "../../components/ProductBrowser/BreadCrumbs";
import React from "react";
import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { Sidebar } from "./Sidebar/Sidebar";
import { ProductSearchBox } from "./ProductSearchBox";
import { ItemWidthSlider } from "./ItemWidthSlider";
import { ProductBrowserGrid } from "./GridView/ProductBrowserGrid";
import { ProductBrowserList } from "./ListView/ProductBrowserList";
import { useIsDesktopMediaQuery } from "../../hooks/useIsDesktopMediaQuery";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";
import { CustomScrollbars } from "../CustomScrollbars/CustomScrollbars";
import { useSessionState } from "../../hooks/useSessionState";
import { useStorefrontSettingsState } from "../../hooks/useStorefrontSettingsState";
import { LandingPage } from "./LandingPage";
import { useCatalogState } from "../../hooks/useCatalogState";
import { CatalogMenu } from "../CatalogMenu";
import { FacebookCircularProgress } from "../FacebookCircularProgress";

//-------------------------------------------------------------------------------------------------------
// Products Control
export type ProductBrowserProps = {
    catalogid: string | undefined;
}
export const ProductBrowser: React.FC<ProductBrowserProps> = props => {
    const inventoryState = useInventoryState();
    const products = inventoryState.getProductsState().get().data;
    const productBrowserState = useProductBrowserState();
    const view = productBrowserState.get().view;
    const isDesktop = useIsDesktopMediaQuery();
    const sidebarExpandedState = useHookstate<boolean>(isDesktop);
    const isSidebarExpanded = sidebarExpandedState.get();
    const themeState = useThemeState();
    const theme = themeState.get().theme;
    const themeName = themeState.get().themeName;
    const isDarkTheme = themeName === 'dark';
    const translateProductBrowser = useTranslate();
    const checkoutFormState = useCheckoutFormState();
    const showLandingPage = inventoryState.get().showLandingPage;
    const catalogState = useCatalogState();

    const warehouse = checkoutFormState.getWarehouse();
    const warehouseId = warehouse?.WarehouseId;

    const coLocation = checkoutFormState.getLocation();
    const locationId = coLocation?.LocationId;
    const invLocation = inventoryState.get().location;
    let locationChanged = false;
    if (invLocation === undefined) {
        locationChanged = true;
    }
    else if (coLocation !== undefined) {
        if (coLocation.LocationId !== invLocation.LocationId) {
            locationChanged = true;
        }
    }
    const sessionState = useSessionState();
    const loggedIn = sessionState.get().loggedIn;
    const settingsState = useStorefrontSettingsState();
    const settings = settingsState.get().settings;

    useHookstateEffect(() => {
        if (settings && ((settings.IsAnonymousAccessEnabled) || (!settings.IsAnonymousAccessEnabled && loggedIn))) {
            inventoryState.setProductFilterAsync({
                catalog: inventoryState.get().catalog,
                warehouse: warehouse,
                location: locationChanged ? coLocation : undefined,
                showLandingPage: showLandingPage
            });
        }
    }, [loggedIn, settings, warehouseId, locationId, showLandingPage])

    return <>
        <div css={css`
                flex: 1 1 0;
                display:flex;
                flex-direction:column;
                overflow: hidden;
                position: relative;`}>
            {inventoryState.getProductsState().get().isLoading &&
                <div css={css`
                        position:fixed;
                        top:0;
                        right:0;
                        bottom:0;
                        left:0;
                        /*background-color:#FFFFFF55;*/
                        z-index:4;
                        display:flex;
                        align-items:center;
                        justify-content:center;`}>
                    <FacebookCircularProgress />
                </div>
            }
            {true && (
                <div css={css`
                        flex: 1 1 0;
                        display: flex;
                        flex-direction: column;
                        overflow:hidden;`}>
                    <div css={css`flex: 1 1 0;display:flex;`}>
                        {(props.catalogid && isDesktop) && (
                            <motion.div
                                css={css`
                                        flex:0 0 auto;
                                        ${isSidebarExpanded ? 'width: 300px;' : ''}
                                        display:flex;
                                        flex-direction: column;
                                        transition:background-color 1s;
                                        font-size: .8em;
                                        overflow:hidden;
                                    `}
                                style={{
                                    backgroundColor: theme.palette.siteSideBar
                                }}
                                transition={{ type: "spring", stiffness: 200, damping: 22 }}>
                                <Sidebar sidebarExpandedState={sidebarExpandedState} />
                            </motion.div>
                        )}
                        <div
                            css={css`
                                    flex:1 1 0;
                                    display:flex;
                                    flex-wrap: wrap;
                                    flex-direction: column;
                                `}
                        >
                            {!isDesktop && (
                                        <div
                                            css={css`
                                            display:flex;
                                            align-items:center;
                                            justify-content:center;
                                            padding: 0 .5rem 0 0;
                                        `}>
                                            - <CatalogMenu /> -
                                        </div>
                                    
                                    )}
                            {(props.catalogid) && (<>
                                <div css={css`
                                    flex: 0 0 auto;
                                    padding: 2px 0;
                                    overflow: hidden;
                                    display: flex;
                                    flex-wrap: wrap;`}>
                                <div css={css`flex:1 1 0;display: flex;min-width:350px;`}>
                                    <ProductSearchBox />
                                </div>
                                <div css={css`
                                        flex: 1 1 0;
                                        align-self: flex-end;
                                        padding:.25em 0;display:flex;align-items:center;
                                        @media (min-width: 720px) {
                                            flex: 0 0 auto;
                                        }
                                        `}>
                                    {!isDesktop && (<>
                                        <div css={css`flex: 1 1 auto;display:flex;flex:0 0 auto;justify-content:flex-end;`}>
                                            <div css={css`flex:0 0 auto;/*padding:.5em;width:40px*/`}
                                                onClick={async e => {
                                                    return Promise.resolve().then(() => {
                                                        sidebarExpandedState.set(true);
                                                    })
                                                }}>
                                                <Tooltip title={translateProductBrowser('Search Options')}>
                                                    <IconButton aria-label={translateProductBrowser('Search Options')}>
                                                        <ArrowDropDownCircle css={css`font-size:32px;`} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div
                                            css={css`flex: 1 1 0;font-size:.8em;white-space:nowrap;cursor:pointer;`}
                                            aria-label={translateProductBrowser('Search Options')}
                                            onClick={async e => {
                                                return Promise.resolve().then(() => {
                                                    sidebarExpandedState.set(true);
                                                })
                                            }}
                                        >
                                            <table>
                                                <caption css={css`text-decoration: underline;`}>{checkoutFormState.getWarehouse()?.Warehouse ?? ''}</caption>
                                                <tbody>
                                                    <tr>
                                                        <td css={css`text-align:right;`}>{translateProductBrowser('Start Date')}:</td>
                                                        <td css={css`font-weight:bold;`}>{checkoutFormState.get().fromDateValue}</td>
                                                    </tr>
                                                    <tr>
                                                        <td css={css`text-align:right;`}>{translateProductBrowser('End Date')}:</td>
                                                        <td css={css`font-weight:bold;`}>{checkoutFormState.get().toDateValue}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>)}
                                    {isDesktop && (<>
                                        <ItemWidthSlider />
                                        <ToggleButtonGroup
                                            orientation="horizontal"
                                            value={productBrowserState.get().view}
                                            exclusive
                                            onChange={async (e, value) => {
                                                return Promise.resolve().then(() => {
                                                    if (value) {
                                                        productBrowserState.setView(value);
                                                    }
                                                });
                                            }}>
                                            <ToggleButton value="list" aria-label="list">
                                                <Tooltip title={translateProductBrowser('List View')} >
                                                    <ViewList />
                                                </Tooltip>
                                            </ToggleButton>
                                            <ToggleButton value="grid" aria-label="grid">
                                                <Tooltip title={translateProductBrowser('Grid View')} >
                                                    <ViewModule />
                                                </Tooltip>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </>)}
                                    {(productBrowserState.get().view === 'grid' || !isDesktop) && (
                                        <ToggleButtonGroup
                                            css={css`padding:0 0 0 .25rem;`}
                                            orientation="horizontal"
                                            value={productBrowserState.get().view}
                                            exclusive
                                            onChange={async (e, value) => {
                                                return Promise.resolve().then(() => {
                                                    if (value) {
                                                        productBrowserState.setExpandedView(value);
                                                    }
                                                });
                                            }}>
                                            {productBrowserState.get().expandedView && (
                                                <ToggleButton value={false}>
                                                    <Tooltip title={translateProductBrowser('Collapse')}>
                                                        <UnfoldLess onClick={e => productBrowserState.setExpandedView(false)} />
                                                    </Tooltip>
                                                </ToggleButton>
                                            )}
                                            {!productBrowserState.get().expandedView && (
                                                <ToggleButton value={true}>
                                                    <Tooltip title={translateProductBrowser('Expand')} >
                                                        <UnfoldMore onClick={e => productBrowserState.setExpandedView(true)} />
                                                    </Tooltip>
                                                </ToggleButton>
                                            )}
                                        </ToggleButtonGroup>
                                    )}
                                </div>
                            </div>
                            </>)}


                            {(props.catalogid && !isDesktop && isSidebarExpanded) && (
                                <motion.div
                                    css={css`
                                        position:fixed;
                                        top:0;right:0;bottom:0;left:0;
                                        z-index:4;
                                        flex:0 0 auto;
                                        display:flex;
                                        flex-direction: column;
                                        transition:background-color 1s;
                                        font-size: .9em;
                                        overflow:hidden;
                                        box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
                                    `}
                                    style={{
                                        backgroundColor: isDarkTheme ? '#333333EE' : '#ffffffEE'
                                    }}
                                    transition={{ type: "spring", stiffness: 200, damping: 22 }}
                                >
                                    <Sidebar sidebarExpandedState={sidebarExpandedState} />
                                </motion.div>
                            )}

                            <div css={css`flex:0 0 auto;padding:.25em 0;`}>
                                <BreadCrumbs></BreadCrumbs>
                            </div>

                            {showLandingPage && (
                                <div css={css`flex:1 1 0;overflow:auto;`}>
                                    <div css={css`margin: 0 auto;`}>
                                        {catalogState.get().catalogs && catalogState.get().catalogs?.map((catalog, index, catalogs) => {
                                            if (!props.catalogid || props.catalogid === catalog.Id) {
                                                return (
                                                    <LandingPage key={index} catalog={catalog} showCatalogName={props.catalogid !== catalog.Id} />
                                                )
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            )}

                            {!showLandingPage && products && (
                                <div
                                    css={css`
                                            flex: 1 1 0;
                                            display: flex;
                                            flex-direction: column;
                                            position: relative;
                                        `}
                                >
                                    <CustomScrollbars overridecss={`flex:1 1 0;display:flex;flex-direction:column;`}>
                                        {((!isDesktop) || (view === 'grid')) && (
                                            <ProductBrowserGrid products={products} />
                                        )}
                                        {((isDesktop) && (view === 'list')) && (
                                            <ProductBrowserList products={products} />
                                        )}
                                    </CustomScrollbars>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>
}
