/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Alert, Box, Button, TextField } from "@mui/material";
import { CheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useSessionState } from "../../hooks/useSessionState";
import { motion } from "framer-motion";
import { AppRegistration, Login } from "@mui/icons-material";
import { useSiteLayoutState } from "../../hooks/useSiteLayoutState";
import { SelectDeal } from "./SelectDeal";
import { SelectLocation } from "./SelectLocation";
import { useHookstate, useHookstateCallback, useHookstateEffect } from "@hookstate/core";
import { ProgressMeter, StorefrontProgress } from "../ProgressMeter";
import { useStorefrontSettingsState } from "../../hooks/useStorefrontSettingsState";
import { CheckoutDate } from "./CheckoutDate";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

export type CheckoutFormProps = {
    checkoutFormState: CheckoutFormState;
}
export const CheckoutForm: React.FC<CheckoutFormProps> = props => {
    const translate = useTranslate();
    const sessionState = useSessionState();
    const fieldSpacing: string = '1em';
    const isFormDisabled = props.checkoutFormState.isFormDisabled();
    const siteLayoutState = useSiteLayoutState();

    const deals = props.checkoutFormState.get().deals;
    const isLoadingDeals = props.checkoutFormState.get().isLoadingDeals;
    const isLoadingDealsFailed = props.checkoutFormState.get().isLoadingDealsFailed;
    const loggedIn = sessionState.get().loggedIn;
    const quoteDescription = props.checkoutFormState.get().quoteDescription;
    const jobName = props.checkoutFormState.get().jobName;
    const selectedDealId = props.checkoutFormState.get().dealIdValue ?? '';
    const txtQuoteDescriptionIsFocused = useHookstate<boolean>(false);
    const txtJobNameIsFocused = useHookstate<boolean>(false);

    const QUOTEDESCRIPTION_LENGTH = 50;
    const JOBNAME_LENGTH = 255;

    const progressMeterSessionId = props.checkoutFormState.get().progressMeterSessionId;
    const progressMeterOpen = props.checkoutFormState.get().progressMeterOpen;
    //const showProgressMeter = progressMeterSessionId ? true : false;

    const settingsState = useStorefrontSettingsState();
    const isUniversity = settingsState.get().settings?.IsUniversity ?? false;
    const separateQuoteRequestsByCatalog = settingsState.get().settings?.SeparateQuoteRequestsByCatalog ?? false;
    const makeQuoteRequestIntoOrderOnSubmission = settingsState.get().settings?.MakeQuoteRequestIntoOrderOnSubmission ?? false;
    let { catalogid } = useParams();
    if (!separateQuoteRequestsByCatalog) {
        catalogid = undefined;
    }
    let pageHeadingText = '';
    if (isUniversity) {
        pageHeadingText = translate('Submit Equipment Request');
    }
    else {
        if (!makeQuoteRequestIntoOrderOnSubmission) {
            pageHeadingText = translate('Submit Request for Quote');
        }
        else {
            pageHeadingText = translate('Submit Order');
        }
    }
    let captionDescription = '';
    if (isUniversity) {
        captionDescription = translate('Order Description')
    }
    else {
        if (!makeQuoteRequestIntoOrderOnSubmission) {
            captionDescription = translate('Quote Description')
        }
        else {
            captionDescription = translate('Order Description')
        }
    }

    const onInit = useHookstateCallback(() => {
        (async () => {
            if (!props.checkoutFormState.get().deals && !props.checkoutFormState.get().isLoadingDeals && !props.checkoutFormState.get().isLoadingDealsFailed) {
                props.checkoutFormState.loadDealsAsync();
            }
        })();
    }, [deals, isLoadingDeals, isLoadingDealsFailed, loggedIn]);

    useHookstateEffect(() => {
        onInit();
    }, [onInit]);

    return (
        <div css={css`
            padding: 0 1em;
            min-width: 350px;
            max-width: 350px;
            margin: 0 auto;
            .field {
                flex: 0 1 150px;
            }
            .field .caption {
                text-align: center;
            }
            .react-datepicker__input-container > input {
                width: 100%;
                box-sizing: border-box;
            }
            `}>

            <div css={css`
                text-align: center;
                padding: 1em;
                font-family: 'Ropa Sans';
                font-size: 2em;`}>
                {pageHeadingText}
            </div>

            <div>
                {props.checkoutFormState.get().hasSubmitError && (
                    <Alert severity="error">{props.checkoutFormState.get().submitError ?? ''}</Alert>
                )}
                {!isUniversity && (<>
                    <Box css={css`margin:1em 0 0 0;`}>
                        <TextField
                            id="txtQuoteDescription"
                            label={captionDescription + ((txtQuoteDescriptionIsFocused.get()) ? ` (${quoteDescription.length} of ${QUOTEDESCRIPTION_LENGTH} chars)` : '')}
                            type="text"
                            variant="outlined"
                            autoComplete="quotedescription"
                            fullWidth
                            multiline
                            disabled={isFormDisabled}
                            defaultValue={quoteDescription}
                            inputProps={{
                                maxLength: QUOTEDESCRIPTION_LENGTH
                            }}
                            onChange={async e => {
                                const value = e.currentTarget.value;
                                return Promise.resolve().then(() => {
                                    props.checkoutFormState.setQuoteDescription(value);
                                });
                            }}
                            onFocus={async e => {
                                return Promise.resolve().then(() => {
                                    txtQuoteDescriptionIsFocused.set(true);
                                });
                            }}
                            onBlur={async e => {
                                return Promise.resolve().then(() => {
                                    txtQuoteDescriptionIsFocused.set(false);
                                    props.checkoutFormState.validateForm();
                                });
                            }}
                        />
                    </Box>
                    {props.checkoutFormState.get().deals && ((props.checkoutFormState.get().deals?.length ?? 0) > 0) && (
                        <Box css={css`margin:1em 0 0 0;`}>
                            <SelectDeal disabled={isFormDisabled} />
                        </Box>
                    )}
                    {(!props.checkoutFormState.get().deals || ((props.checkoutFormState.get().deals?.length ?? 0) === 0) || (selectedDealId === '-')) && (
                        <Box css={css`margin:1em 0 0 0;`}>
                            <Box css={css`margin:1em 0 0 0;`}>
                                <TextField
                                    id="txtJobName"
                                    label={translate('Job') + ((txtJobNameIsFocused.get()) ? ` (${jobName.length} of ${JOBNAME_LENGTH} chars)` : '')}
                                    type="text"
                                    variant="outlined"
                                    autoComplete="jobname"
                                    fullWidth
                                    multiline
                                    disabled={isFormDisabled}
                                    defaultValue={jobName}
                                    inputProps={{
                                        maxLength: JOBNAME_LENGTH
                                    }}
                                    onChange={async e => {
                                        const value = e.currentTarget.value;
                                        return Promise.resolve().then(() => {
                                            props.checkoutFormState.setJobName(value);
                                        });
                                    }}
                                    onFocus={async e => {
                                        return Promise.resolve().then(() => {
                                            txtJobNameIsFocused.set(true);
                                        });
                                    }}
                                    onBlur={async e => {
                                        return Promise.resolve().then(() => {
                                            txtJobNameIsFocused.set(false);
                                            props.checkoutFormState.validateForm();
                                        });
                                    }} />
                            </Box>
                        </Box>
                    )}
                    {/*<Box css={css`margin:1em 0 0 0;`}>*/}
                    {/*    <SelectWarehouse disabled={isFormDisabled} />*/}
                    {/*</Box>*/}
                    {/*{((warehouseLocations.length > 1) || (!props.checkoutFormState.get().warehouseIdValue)) && <>*/}
                    {/*    <Box css={css`margin:1em 0 0 0;`}>*/}
                    {/*        <SelectLocation disabled={isFormDisabled} />*/}
                    {/*    </Box>*/}
                    {/*</>}*/}
                    <Box css={css`margin:1em 0 0 0;`}>
                        <SelectLocation disabled={isFormDisabled} />
                    </Box>
                    {/*<Box css={css`padding:1em 0 .3em 0;text-align:center;`}>*/}
                    {/*    {props.quoteRequestFormController.translate('Availability')}*/}
                    {/*</Box>*/}
                </>)}
                <div css={css`display:flex;align-items:center;justify-content:center;margin:1em 0 0 0;`}>
                    <div className="field" style={{ margin: '0 1em 0 0' }}>
                        <div className="value">
                            <CheckoutDate type="startdate" fullwidth={true} catalogId={catalogid} />
                        </div>
                    </div>
                    <div className="field">
                        <div className="value">
                            <CheckoutDate type="stopdate" fullwidth={true} catalogId={catalogid} />
                        </div>
                    </div>
                </div>
            </div>

            
            <div css={css`
                margin:${fieldSpacing} 0;`}>
                <TextField
                    label={translate('Notes')}
                    disabled={isFormDisabled}
                    variant="outlined"
                    fullWidth
                    multiline
                    inputProps={{
                        style: {
                            minHeight: '150px'
                        }
                    }}
                    defaultValue={props.checkoutFormState.get().notesValue}
                    onChange={async e => {
                        const value = e.currentTarget.value;
                        return Promise.resolve().then(() => {
                            props.checkoutFormState.setNotes(value);
                        });
                    }}
                />
            </div>
            {!sessionState.get().loggedIn && (<div css={css`flex:1 1 0;position:relative;display:flex;align-items:center;justify-content:center;`}>
                <div css={css`
                    display:flex;
                    margin-top:16px;
                    margin-bottom:${fieldSpacing};
                    align-items:center;
                    justify-content:center;`}>
                    <Box>
                        <Button
                            disabled={isFormDisabled}
                            css={css`flex: 1 1 0;display:flex;align-items:center;`}
                            variant="outlined"
                            onClick={async e => {
                                return Promise.resolve().then(() => {
                                    siteLayoutState.setLoginFormPopupVisible(true);
                                });
                            }}>
                            <motion.div
                                layoutId="loginform"
                                transition={{ type: "spring", stiffness: 300, damping: 22 }}
                                css={css`
                                display:flex;
                                align-items:center;`}>
                                {translate('Login')}
                                <Login css={css`margin-left: 4px;`} />
                            </motion.div>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            disabled={isFormDisabled}
                            css={css`
                            flex: 1 1 0;
                            margin-left: 1em;` }
                            variant="outlined"
                            onClick={async e => {
                                return Promise.resolve().then(() => {
                                    siteLayoutState.setRegisterFormPopupVisible(true);
                                });
                            }}>
                            <motion.div
                                layoutId="registerform"
                                transition={{ type: "spring", stiffness: 300, damping: 22 }}
                                css={css`
                                display:flex;
                                align-items:center;`}>
                                {translate('Register')}
                                <AppRegistration css={css`margin-left: 4px;`} />
                            </motion.div>
                        </Button>
                    </Box>
                    
                </div>
            </div>)}
            <div css={css`
                    display:flex;
                    margin-top:16px;
                    margin-bottom:${fieldSpacing};
                    align-items:center;
                    justify-content:center;`}>
                <Button
                    disabled={isFormDisabled || !sessionState.get().loggedIn}
                    variant="outlined"
                    onClick={async e => { 
                        return Promise.resolve().then(() => {
                            props.checkoutFormState.submitQuoteRequestAsync(catalogid);
                        });
                    }}>
                    {translate('Submit')}
                    {/*<Done css={css`margin: 0 0 0 .5em;color:#00bcd4;`} />*/}
                </Button>
            </div>
            {progressMeterSessionId && (
                <ProgressMeter
                    open={progressMeterOpen}
                    sessionId={progressMeterSessionId}
                    dialogTitle={translate('Submitting Request for Quote') + '...'}
                    initialCaption=""
                    onComplete={async (e: StorefrontProgress) => {
                        return Promise.resolve().then(() => {
                            props.checkoutFormState.setSubmitComplete(catalogid);
                        });
                    }}
                    onFailed={async (e: StorefrontProgress) => {
                        return Promise.resolve().then(() => {
                            //props.checkoutFormState.setProgressMeterSessionId(undefined);
                        });
                    }}
                    onClose={async () => {
                        return Promise.resolve().then(() => {
                            props.checkoutFormState.setProgressMeterOpen(false);
                            props.checkoutFormState.setProgressMeterSessionId(undefined);
                        });
                    }}
                />
            )}
        </div>
    );
}
