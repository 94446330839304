/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
/* Manufacturer */
export type ManufacturerFieldProps = {

}
export const ManufacturerField: React.FC<ManufacturerFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();
    return (<>
        <div className="field">
            <div className="caption">{translate("Manufacturer")}:</div>
            <div className="value">
                {productDetailsState.get().product?.Manufacturer}
            </div>
        </div>
    </>);
}