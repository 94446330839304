/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import React from "react";
import { Product } from "../../../hooks/useInventoryState";
import { AddOrUpdateCart } from "../../ShoppingCart/AddOrUpdateCart";

export type ProductBrowserGridItemQtyProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserGridItemQty: React.FC<ProductBrowserGridItemQtyProps> = props => {

    return (<>
        <AddOrUpdateCart product={props.product} />
    </>)
}
