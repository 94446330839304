import { ImmutableObject, State, useHookstate } from "@hookstate/core"
import axios, { AxiosRequestConfig } from "axios"
import { SessionState, useSessionState } from "../../hooks/useSessionState"

export type FwQueryResponse<TResponse> = {
    Items: TResponse[]
    PageNo: number
    PageSize: number
    TotalItems: number
    Sort: string
}

export type FwQueryFilter = {
    Field: string
    Op: '=' | "<>" | "contains" | "does not contain" | "in" | "not in" | "starts with" | "ends with" | ">" | ">=" | "<" | "<"
    Value: string
}

export const FWQUERYBROWSER_DEFAULT_PAGESIZE = 20;

export class FwQueryBrowserState<T> {
    data: FwQueryResponse<T> | undefined;
    pageNo: number = 1;
    pageSize: number = FWQUERYBROWSER_DEFAULT_PAGESIZE;
    sort: string | undefined;
    filters: FwQueryFilter[] = [];
    isLoading: boolean = false;
    allowFirst: boolean = false;
    allowPrev: boolean = false;
    allowNext: boolean = false;
    allowLast: boolean = false;
    allowSetPageNo: boolean = false;
    allowSetPageSize: boolean = false;
}

export class FwQueryBrowserController<T> {
    private state: State<FwQueryBrowserState<T>>;
    private sessionState: SessionState;

    get(): ImmutableObject<FwQueryBrowserState<T>> {
        return this.state.get();
    }

    getUrlAsync: () => Promise<string | undefined>;

    constructor(store: State<FwQueryBrowserState<T>>, getUrlAsync: () => Promise<string | undefined>, sessionState: SessionState) {
        this.state = store;
        this.getUrlAsync = getUrlAsync;
        this.sessionState = sessionState;
    }

    getMaxPageNo(): number {
        if (this.state.data) {
            return Math.ceil((this.state.data?.get()?.TotalItems ?? 0) / (this.state.data?.get()?.PageSize ?? FWQUERYBROWSER_DEFAULT_PAGESIZE));
        }
        return 1; // not sure if this should be 1 or 0
    }

    toggleLoading(isLoading: boolean): void {
        let newState: Partial<FwQueryBrowserState<T>> = {};
        newState.allowFirst = false;
        newState.allowPrev = false;
        newState.allowNext = false;
        newState.allowLast = false;
        newState.allowSetPageNo = false;
        newState.allowSetPageSize = false;

        // if it finished loading and there's data, then
        if (!isLoading && this.state.data) {
            const pageNoMax = this.getMaxPageNo();
            if ((this.state.data?.get()?.PageNo ?? 1) > 1) {
                newState.allowFirst = true;
                newState.allowPrev = true;
            }
            if ((this.state.data?.get()?.PageNo ?? 0) < pageNoMax) {
                newState.allowNext = true;
                newState.allowLast = true;
                newState.allowSetPageNo = true;
            }
            newState.allowSetPageSize = true;
        }

        newState.isLoading = isLoading;
        this.state.merge(newState);
    }

    async clearAsync(): Promise<void> {
        let newState: Partial<FwQueryBrowserState<T>> = {};
        newState.allowFirst = false;
        newState.allowPrev = false;
        newState.allowNext = false;
        newState.allowLast = false;
        newState.allowSetPageNo = false;
        newState.allowSetPageSize = false;
        newState.data = undefined;
        newState.filters = undefined;
        newState.isLoading = false;
        newState.pageNo = 1;
        newState.pageSize = FWQUERYBROWSER_DEFAULT_PAGESIZE;
        this.state.merge(newState);
    }

    async loadAsync(): Promise<void> {
        if (!this.state.get().isLoading) {
            try {
                this.toggleLoading(true);
                // let newState1: Partial<FwQueryBrowserState<T>> = {};
                // newState1.data = undefined;
                // state.merge(newState1);
                const unfilteredUrl = await this.getUrlAsync();
                if (unfilteredUrl) {
                    const url = new URL(unfilteredUrl);
                    url.searchParams.append('pageno', this.state.get().pageNo.toString());
                    url.searchParams.append('pagesize', this.state.get().pageSize.toString());
                    if (this.state.get()?.sort) {
                        url.searchParams.append('sort', this.state.get().sort ?? '');
                    }
                    if (this.state.get().filters && this.state.get().filters.length > 0) {
                        url.searchParams.append('filter', JSON.stringify(this.state.get().filters));
                    }
                    let newState: Partial<FwQueryBrowserState<T>> = {};
                    const axiosRequestConfig: AxiosRequestConfig = {};
                    if (this.sessionState.get().loggedIn) {
                        axiosRequestConfig.headers = {
                            Authorization: 'Bearer ' + this.sessionState.get().authToken
                        }
                    }
                    newState.data = (await axios.get<FwQueryResponse<T>>(url.toString(), axiosRequestConfig)).data;
                    if (newState.data) {
                        newState.pageNo = newState.data.PageNo;
                        newState.pageSize = newState.data.PageSize;
                    }
                    this.state.merge(newState);
                }
            }
            finally {
                this.toggleLoading(false);
            }
        }
    }

    async firstPageAsync(): Promise<void> {
        if (this.state.get().allowFirst) {
            if (this.state.get().pageNo >= 1) {
                let newState: Partial<FwQueryBrowserState<T>> = {};
                newState.pageNo = 1;
                this.state.merge(newState);
            }
            await this.loadAsync();
        }
    }

    async prevPageAsync(): Promise<void> {
        if (this.state.get().allowPrev) {
            if (this.state.get().data) {
                if (this.state.get().pageNo > 1) {
                    let newState: Partial<FwQueryBrowserState<T>> = {};
                    newState.pageNo = this.state.get().pageNo - 1;
                    this.state.merge(newState);
                }
                await this.loadAsync();
            }
        }
    }

    async nextPageAsync(): Promise<void> {
        if (this.state.get().allowNext) {
            let newState: Partial<FwQueryBrowserState<T>> = {};
            if (this.state.get().data) {
                const maxPageNo = this.getMaxPageNo();
    
                if (this.state.get().pageNo > maxPageNo) {
                    newState.pageNo = maxPageNo;
                    this.state.merge(newState);
                    await this.loadAsync();
                }
                else if (this.state.get().pageNo >= 1 && this.state.get().pageNo < maxPageNo) {
                    newState.pageNo = this.state.get().pageNo + 1;
                    this.state.merge(newState);
                    await this.loadAsync();
                }
                else if (this.state.get().pageNo < 1) {
                    newState.pageNo = 1;
                    this.state.merge(newState);
                    await this.loadAsync();
                }
            }
        }
    };

    async lastPageAsync():  Promise<void> {
        if (this.state.get().allowLast) {
            if (this.state.get().data) {
                const maxPageNo = this.getMaxPageNo();
                if (this.state.get().pageNo <= maxPageNo) {
                    let newState: Partial<FwQueryBrowserState<T>> = {};
                    newState.pageNo = maxPageNo;
                    this.state.merge(newState);
                    await this.loadAsync();
                }
            }
        }
    }

    async setPageNoAsync(pageNo: number, load: boolean, overrideAllowSetPageNo: boolean): Promise<void> {
        if (this.state.get().allowSetPageNo || overrideAllowSetPageNo) {
            if (pageNo === 1) {
                this.state.pageNo.set(pageNo);
                if (load) {
                    await this.loadAsync();
                }
            }
            else if (pageNo > 1 && this.state.get().data) {
                const maxPageNo = this.getMaxPageNo();
                if (this.state.get().pageNo >= 1 && this.state.get().pageNo <= maxPageNo) {
                    this.state.pageNo.set(pageNo);
                    if (load) {
                        await this.loadAsync();
                    }
                }
            }
        }
    }

    async setPageSizeAsync(pageSize: number): Promise<void> {
        if (this.state.get().allowSetPageSize) {
            this.state.pageSize.set(pageSize);
        }
    };

    async setFiltersAsync(filters: FwQueryFilter[]): Promise<void> {
        this.state.filters.set(filters);
    }
}

export function useFwQueryBrowserState<T>(store: State<FwQueryBrowserState<T>>, getUrlAsync: () => Promise<string | undefined>): FwQueryBrowserController<T> {
    //const state = useHookstate(new FwQueryBrowserState<T>());
    const state = useHookstate<FwQueryBrowserState<T>>(store);
    const sessionState = useSessionState();
    return new FwQueryBrowserController<T>(state, getUrlAsync, sessionState);
}
