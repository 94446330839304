/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import DOMPurify from 'dompurify'

type SafeHtmlContentProps = {
    htmlContent?: string;
}
export const SafeHtmlContent: React.FC<SafeHtmlContentProps> = props => {

    return (<>
        {props.htmlContent && (<>
            <div className="content" css={css`all:initial;color:unset;`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.htmlContent) }}></div>
        </>)}
    </>);
}