import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import axios from "axios";
import { devtools } from '@hookstate/devtools';

export type AppConfig = {
    BaseUrl: string;
    StorefrontAppUrl: string;
    CompanyName: string
    CompanyLogoUrl: string
    ClientCode: string;
}

export class AppConfigModel {
    appConfig?: AppConfig;
    isLoading: boolean = false;
}

const appConfigStore = hookstate<AppConfigModel>(new AppConfigModel(), devtools({ key: 'AppConfigState' }));

export class AppConfigState {
    private appConfigState: State<AppConfigModel>;

    constructor(siteConfigState: State<AppConfigModel>) {
        this.appConfigState = siteConfigState;
    }

    get(): ImmutableObject<AppConfigModel> {
        return this.appConfigState.get();
    }

    async loadAsync() {
        try {
            if (!this.appConfigState.get().isLoading) {
                this.appConfigState.isLoading.set(true);

                //const currUrl = window.location.href.substring(0, window.location.href.length - window.location.hash.length);
                //let siteRootUrl;
                //if (currUrl.charAt(currUrl.length - 1) === '/') {
                //    siteRootUrl = currUrl.slice(0, currUrl.length - 1);
                //} else {
                //    siteRootUrl = currUrl;
                //}
                //let basename = new URL(siteRootUrl).pathname;
                //if (!basename.endsWith('/')) {
                //    basename += '/';
                //}
                //console.log({
                //    siteRootUrl: siteRootUrl,
                //    basename: basename,
                //    url: `${basename}appconfig.json`
                //});

                //const appConfigData = (await axios.get<AppConfig>(`${basename}appconfig.json`)).data;
                const appConfigData = (await axios.get<AppConfig>(`/storefront/appconfig.json`)).data;
                this.appConfigState.appConfig.set(appConfigData);

                // theme is bound to ClientCode, so it should reload
                //this.themeState.set(this.themeState.get().themeName);
            }
        }
        finally {
            this.appConfigState.isLoading.set(false);
        }
    }

    getCheckoutUrl(): string {
        let checkoutUrl = 'checkout';
        const clientCode = this.appConfigState.get().appConfig?.ClientCode;
        if (clientCode === 'TELEMUNDO') {
            checkoutUrl = 'quoterequest';
        }
        return checkoutUrl;
    }
}

export function useAppConfigState(): AppConfigState {
    const appConfigState = useHookstate(appConfigStore);
    return new AppConfigState(appConfigState);
}
