/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { motion } from "framer-motion";
import { Product } from "../../../hooks/useInventoryState";
import { useIsDesktopMediaQuery } from "../../../hooks/useIsDesktopMediaQuery";
import { useProductBrowserState } from "../../../hooks/useProductBrowserState";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { FwQueryResponse } from "../../../lib/FwReact/FwQuery";
import { ProductDetailsPopup } from "../../ProductDetails/ProductDetails";
import { ProductBrowserGridItem } from "./ProductBrowserGridItem";

export type ProductBrowserGridProps = {
    products: ImmutableObject<FwQueryResponse<Product>>;
}
export const ProductBrowserGrid: React.FC<ProductBrowserGridProps> = props => {
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    const productDetailsState = useProductDetailsState();
    const products = props.products;
    const productBrowserState = useProductBrowserState();
    const isDesktop = useIsDesktopMediaQuery();
    let itemWidth = productBrowserState.get().itemWidth;
    if (!isDesktop) {
        itemWidth = 185;
    }

    return (<>
        <div
            css={css`
                display: grid;
                justify-items: center;
                gap: 1px 1px;
                @media (min-width: 720px) {
                    gap: 1em 1em;
                }`}
            style={{
                backgroundColor: themeName === 'dark' ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.05)',
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fill, minmax(${itemWidth}px, 1fr))`,
                padding: isDesktop ? '1em' : '0'
            }}
        >
            {products.Items.map(product => (
                <motion.div
                    css={css`
                        flex: 1 1 0;
                        width:100%;
                        box-sizing: border-box;
                        display:flex;
                        flex-direction:column;
                    `}
                    key={product.Id}
                    layoutId={product.Id}
                    transition={{ type: "spring", stiffness: 180, damping: 26 }}
                >
                    <ProductBrowserGridItem product={product} />
                </motion.div>
            ))}
        </div>

        <ProductDetailsPopup layoutId={productDetailsState.get().product?.Id ?? "0"} />
    </>);
}