import { hookstate, ImmutableObject, State, useHookstate } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";

export class LocaleModel {
    language: string = '';
   // country: string = '';
}

export type TranslationCaptions = {
    en: string,
    es: string,
    fr: string
}

const storefront_locale_key = 'storefront-locale';
let initialState = new LocaleModel();
const localstorageJson = window.localStorage.getItem(storefront_locale_key);
if (localstorageJson) {
    try {
        initialState = JSON.parse(localstorageJson);
    }
    catch (ex) {
        console.error(ex);
    }
}
else {
    const [language/*, country*/] = window.navigator.language.split('-');
    initialState = {
        language: language,
        //country: country
    };
    window.localStorage.setItem(storefront_locale_key, JSON.stringify(initialState));
}
const localeGlobalStore = hookstate<LocaleModel>(initialState, devtools({ key: 'LocaleState' }));

export class LocaleState {
    private localeState: State<LocaleModel>;

    constructor(localeState: State<LocaleModel>) {
        this.localeState = localeState;
    }

    get(): ImmutableObject<LocaleModel> {
        return this.localeState.get();
    }

    private saveToLocalStorage() {
        const obj = this.localeState.get();
        let json = '';
        if (obj) {
            json = JSON.stringify(obj);
        }
        window.localStorage.setItem(storefront_locale_key, json);
    }

    setLanguage(language: string) {
        this.localeState.language.set(language);
        this.saveToLocalStorage()
    }

    getLanguageFriendlyName() {
        const language = this.localeState.get().language;
        switch (language) {
            case 'en': return 'English';
            case 'es': return 'Español';
            case 'fr': return 'Français';
            default: return '?';
        }
    }

    translate(labels: TranslationCaptions): string {
        const language = this.localeState.get().language;
        switch (language) {
            case 'en':
            case 'es':
            case 'fr':
                return (labels as any)[language];
            default:
                if (labels.en) return labels.en;
                return '';
        }
    }
}

export function useLocaleState(): LocaleState {
    const localeState = useHookstate<LocaleModel>(localeGlobalStore);
    return new LocaleState(localeState);
}