/* eslint-disable react/jsx-no-undef */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Product } from "../../hooks/useInventoryState";
import { useProductBrowserState } from "../../hooks/useProductBrowserState";
import { useProductDetailsState } from "../../hooks/useProductDetailsState";
import ImageIcon from '@mui/icons-material/Image';
// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation,/*, Autoplay*/ 
Autoplay} from 'swiper';
// End Swiper
import clone from "clone";
import { useIsDesktopMediaQuery } from "../../hooks/useIsDesktopMediaQuery";
import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useStorefrontSettingsState } from "../../hooks/useStorefrontSettingsState";
import { AutoplayOptions, SwiperModule } from "swiper/types";
//-------------------------------------------------------------------------------------------------------
export type ProductImageControlProps = {
    product: ImmutableObject<Product>;
    //imageSize: number
    //imageSizeUnits?: string;
}
export const ProductImageControl: React.FC<ProductImageControlProps> = props => {
    //const mobileImageSize: number = 256;
    //const desktopImageSize: number = 512;
    const productDetailsState = useProductDetailsState();
    const images = props.product.Images ?? [];
    const productBrowserState = useProductBrowserState();
    const isDesktop = useIsDesktopMediaQuery();
    let itemWidth = productBrowserState.get().itemWidth;
    if (!isDesktop) {
        //itemWidth = Math.round(itemWidth / 2);
        itemWidth = 185;
    }
    const checkoutFormState = useCheckoutFormState();

    const warehouseId = checkoutFormState.get().warehouseIdValue;
    const locationId = checkoutFormState.get().locationIdValue;
    const fromDate = checkoutFormState.get().fromDateValue;
    const toDate = checkoutFormState.get().toDateValue;

    const view = productBrowserState.get().view;
    //console.log(images.length);

    const storefrontSettingsState = useStorefrontSettingsState();
    const swiperModules: SwiperModule[] = [];
    let autoplayOptions: AutoplayOptions | undefined;
    const settings = storefrontSettingsState.get().settings;
    if (settings && settings.AutoplayImagesOnProductBrowser) {
        swiperModules.push(Autoplay);
        autoplayOptions = {
            delay: settings.AutoplaySpeed * 1000,
        };
    }
    swiperModules.push(Pagination);
    swiperModules.push(Navigation);
    
    return (
        <div
            css={css`
            cursor:pointer;
            flex: 0 0 auto;
            overflow:hidden;
            /*max-width: ${itemWidth}px;
            max-height: ${itemWidth}px;*/
            `}
            style={{
                width: (view === 'list') ? `${itemWidth}px` : '100%'
            }}
        >
            <Swiper
                pagination={{
                    dynamicBullets: true,
                }}
                autoplay={autoplayOptions}
                spaceBetween={0}
                navigation={false}
                grabCursor={true}
                centeredSlides={true}
                centerInsufficientSlides={true}
                slidesPerView={1}
                modules={swiperModules}
                onClick={async () => {
                    return Promise.resolve().then(async () => {
                        window.history.pushState(null, '', `#/product/${props.product.Id}/warehouseid/${warehouseId}/locationid/${locationId}/fromdate/${fromDate}/todate/${toDate}`);
                        await productDetailsState.setProductAsync(clone(props.product) as Product);
                        productDetailsState.setShowPopup(true);
                    });
                }}
            >
                {/* When the item has images */}
                {(images.length > 0) && images.map((image, index, images) => (
                    <SwiperSlide key={image.Id}
                        style={{
                            /*width: (itemWidth),*/
                            height: (itemWidth),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'

                        }}>
                        <img
                            src={image.Url}
                            alt={props.product.ItemName}
                            title={props.product.ItemName}
                            style={{
                                maxWidth: (itemWidth),
                                maxHeight: (itemWidth),
                                width: 'auto',
                                height: 'auto'
                            }}
                        />
                    </SwiperSlide>
                ))}

                {/* When the item has no images */}
                {/*background-color: ${productBrowserState.get().expandedView ? '#f5f5f5' : 'transparent;'};*/}
                {(images.length === 0) && (
                    <SwiperSlide
                        style={{
                            /*width: (itemWidth),*/
                            height: (itemWidth),
                        }}
                    >
                        <div
                            key={0}
                            css={css`
                                display:flex;
                                align-items:center;
                                justify-content:center;
                                
                                `}
                            style={{
                                width: '100%',
                                height: itemWidth
                            }}>
                            <ImageIcon css={css`flex:1 1 0;color:#777777;`}
                                style={{
                                    maxWidth: (Math.round(itemWidth / 2)),
                                    maxHeight: (Math.round(itemWidth / 2)),
                                    width: 'auto',
                                    height: 'auto'
                                }} />
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    );
}