/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";

//------------------------------------------------------------------------------------
/* ItemName */
export type ItemNameFieldProps = {

}
export const ItemNameField: React.FC<ItemNameFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    return (
        <div
            css={css`
                font-weight:bold;
                padding: .25rem 0 .5rem 0;
                min-width: 350px;
                `}>
            {productDetailsState.get().product?.ItemName}
        </div>
    );
}