/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import clone from "clone";
import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";
import { Product } from "../../../hooks/useInventoryState";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";

export type ProductBrowserGridItemDescriptionProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserGridItemDescription: React.FC<ProductBrowserGridItemDescriptionProps> = props => {
    const product = props.product;
    const productDetailsState = useProductDetailsState();
    const checkoutFormState = useCheckoutFormState();
    const warehouseId = checkoutFormState.get().warehouseIdValue;
    const locationId = checkoutFormState.get().locationIdValue;
    const fromDate = checkoutFormState.get().fromDateValue;
    const toDate = checkoutFormState.get().toDateValue;

    return (<>
        <div
            className="field itemdescription"
            onClick={async () => {
                return Promise.resolve().then(async () => {
                    window.history.pushState(null, '', `#/product/${props.product.Id}/warehouseid/${warehouseId}/locationid/${locationId}/fromdate/${fromDate}/todate/${toDate}`);
                    await productDetailsState.setProductAsync(clone(product) as Product);
                    productDetailsState.setShowPopup(true);
                });
            }}
        >
            {product.ItemName}
        </div>
    </>);
}