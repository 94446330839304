/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Inventory2Outlined } from "@mui/icons-material";
import { useCatalogState } from "../../../hooks/useCatalogState";
import { Product } from "../../../hooks/useInventoryState";
import { useIsDesktopMediaQuery } from "../../../hooks/useIsDesktopMediaQuery";
import { useProductBrowserState } from "../../../hooks/useProductBrowserState";
import { useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import { ProductImageControl } from "../ProductImageControl";
import { ProductBrowserListItemAvailability } from "./ProductBrowserListItemAvailability";
import { ProductBrowserListItemBarcodes } from "./ProductBrowserListItemBarcodes";
import { ProductBrowserListItemDepartmentCategorySubcategoryLinks } from "./ProductBrowserListItemDepartmentCategorySubcategoryLinks";
import { ProductBrowserListItemDescription } from "./ProductBrowserListItemDescription";
import { ProductBrowserListItemItemNo } from "./ProductBrowserListItemItemNo";
import { ProductBrowserListItemQty } from "./ProductBrowserListItemQty";
import { ProductBrowserListItemRates } from "./ProductBrowserListItemRates";
import { ProductBrowserListItemTags } from "./ProductBrowserListItemTags";

// ListView - ListItem
export type ProductBrowserListItemProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserListItem: React.FC<ProductBrowserListItemProps> = props => {
    const product = props.product;
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    const productBrowserState = useProductBrowserState();
    const isDesktop = useIsDesktopMediaQuery();
    const expandedView = productBrowserState.get().expandedView;
    const shoppingCartItemsState = useShoppingCartItemsState();
    const qty = shoppingCartItemsState.getQty(product!);
    const translate = useTranslate();

    const catalogState = useCatalogState();
    let hasImage = true;
    let hasDepartmentCategorySubcategoryLinks = false;
    let hasICode = false;
    let hasDescription = true;
    let hasBarcodes = false;
    let hasTags = false;
    let hasDailyRate = false;
    let hasWeeklyRate = false;
    let hasMonthlyRate = false;
    let hasSalesPrice = false;
    let hasQty = false;
    let hasRates = false;
    let hasAvailability = false;
    const catalog = catalogState.getProductCatalog(product);
    if (catalog) {
        const listFieldsByName = catalog.listFieldsByName;
        if (listFieldsByName) {
            hasDepartmentCategorySubcategoryLinks = (listFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'] !== undefined) && (listFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'].Selected);
            hasICode = (listFieldsByName['ICODE'] !== undefined) && (listFieldsByName['ICODE'].Selected);
            hasBarcodes = (listFieldsByName['BARCODENUMBER'] !== undefined) && (listFieldsByName['BARCODENUMBER'].Selected);
            hasTags = (listFieldsByName['TAGS'] !== undefined) && (listFieldsByName['TAGS'].Selected);
            hasQty = (listFieldsByName['ITEMQUANTITYINPUT'] !== undefined) && (listFieldsByName['ITEMQUANTITYINPUT'].Selected);
            hasDailyRate = (listFieldsByName['DAILYRATE'] !== undefined) && (listFieldsByName['DAILYRATE'].Selected);
            hasWeeklyRate = (listFieldsByName['WEEKLYRATE'] !== undefined) && (listFieldsByName['WEEKLYRATE'].Selected);
            hasMonthlyRate = (listFieldsByName['MONTHLYRATE'] !== undefined) && (listFieldsByName['MONTHLYRATE'].Selected);
            hasSalesPrice = (listFieldsByName['SALESPRICE'] !== undefined) && (listFieldsByName['SALESPRICE'].Selected);
            hasRates = hasDailyRate || hasWeeklyRate || hasMonthlyRate || hasSalesPrice;
            hasAvailability = (listFieldsByName['AVAILABILITY'] !== undefined) && (listFieldsByName['AVAILABILITY'].Selected);
        }
    }

    return (
        <>
            <div
                css={css`
                    position:relative;
                    padding: 1em;
                    border-radius: 5px;
                    transition: background-color 1s;
                    background-color: ${themeName === 'dark' ? 'rgb(33,33,33);' : 'rgb(255,255,255);'};
                    flex:1 0 320px;
                    z-index:1;
                    display:flex;
                    flex-wrap:wrap;
                    justify-content: flex-start;
                    @media (max-width: 768px) {
                        justify-content: center;
                    }
                    `}
            >
                <div css={css`flex:0;cursor:pointer;`} >
                    {hasImage && (<ProductImageControl product={product} />)}
                </div>
                {(expandedView || isDesktop) && (<>
                    <div css={css`flex:1;min-width:300px;`}>
                        {(product.PackageItems && (product.PackageItems.length > 0)) && (
                            <div css={css`background-color:#0d47a1;color:#ffffff;padding:2px 0;font-size:.7rem;z-index:2;display:flex;align-items:center;justify-content:center;`}>
                                <span>-</span><Inventory2Outlined css={css`font-size:.7rem;margin:0 4px;`} />{translate('package')}<span>&nbsp;-</span>
                            </div>
                        )}
                        {hasDepartmentCategorySubcategoryLinks && (<ProductBrowserListItemDepartmentCategorySubcategoryLinks product={product} />)}
                        {hasDescription && (<ProductBrowserListItemDescription product={product} />)}
                        {hasICode && (<ProductBrowserListItemItemNo product={product} />)}
                        {hasBarcodes && (<ProductBrowserListItemBarcodes product={product} />)}
                        {hasTags && (<ProductBrowserListItemTags product={product} />)}
                    </div>
                    <div css={css`flex:0 0 250px;`}>
                        {hasQty && (<ProductBrowserListItemQty product={product} />)}
                        {hasAvailability && (<ProductBrowserListItemAvailability product={product} />)}
                        {hasRates && (<ProductBrowserListItemRates product={product} hasDailyRate={hasDailyRate} hasWeeklyRate={hasWeeklyRate} hasMonthlyRate={hasMonthlyRate} hasSalesPrice={hasSalesPrice} />)}
                    </div>
                </>)}
                {(qty > 0) && (
                    <div
                        css={css`
                        position:absolute;
                        top:.7rem;
                        right:.7rem;
                        background-color:#4caf50;
                        color:#ffffff;
                        z-index:2;
                        padding:.2rem .5rem;
                        border-radius: .8rem;
                        font-size:.8rem;
                    `}
                        title={`${qty} ${translate('in cart')}`}
                    >
                        {qty}
                    </div>
                )}
            </div>
        </>
    );
}