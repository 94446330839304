import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core';
import { devtools } from '@hookstate/devtools';
import { LocaleState, useLocaleState } from './useLocaleState';

class LoginFormModel {
    isPopup: boolean = false;
    //isPopupVisible: boolean = false;
    isWaitingForLogin: boolean = false;

    loginErrorMessage: string = '';
    //loginError: unknown;
    isLoginSuccessful: boolean = false;

    isValid: boolean = false;
    showValidationError = false;
    emailValue: string = '';
    emailError: string = '';
    emailRequiredError: string = '';
    passwordValue: string = '';
    passwordError: string = '';
    passwordRequiredError: string = '';
}

let initialState = new LoginFormModel();
const loginFormGlobalStore = hookstate<LoginFormModel>(initialState, devtools({ key: 'LoginFormState' }));

export class LoginFormState {
    private loginFormState: State<LoginFormModel>;
    private localeState: LocaleState;

    get(): ImmutableObject<LoginFormModel> {
        return this.loginFormState.get();
    }

    constructor(loginFormState: State<LoginFormModel>, localeState: LocaleState) {
        this.loginFormState = loginFormState;
        this.localeState = localeState;
    }

    reset() {
        this.loginFormState.set(new LoginFormModel());
    }

    validateForm(): void {
        // validate the login form, triggering a mutation to the newLoginState only if it needs to change
        const newLoginState: Partial<LoginFormModel> = {};
        
        // validate email
        let emailHasError = !this.loginFormState.get().emailValue;
        if (emailHasError !== (this.loginFormState.get().emailError ? true : false)) {
            newLoginState.emailError = this.loginFormState.get().emailRequiredError;
        };

        // password required error
        let passwordHasError = !this.loginFormState.get().passwordValue;
        if (passwordHasError !== (this.loginFormState.get().passwordError ? true : false)) {
            newLoginState.passwordError = this.loginFormState.get().passwordRequiredError; 
        };

        // update isValid and showValidationError states
        const isValid = !emailHasError && !passwordHasError;
        if (isValid !== this.loginFormState.get().isValid) {
            newLoginState.isValid = isValid;
            newLoginState.showValidationError = newLoginState.isValid;
        }

        // update the login form state
        if (Object.keys(newLoginState).length > 0) {
            this.loginFormState.merge(newLoginState);
        }
    }

    //async loginAsync(): Promise<boolean> {
    //    try {
    //        this.loginFormState.merge({
    //            isWaitingForLogin: true,
    //            loginErrorMessage: '',
    //            loginError: undefined
    //        });
    //        this.validateForm();
    //        if (this.loginFormState.get().isValid) {
    //            const loggedIn = await this.siteLayout.loginAsync(this.loginFormState.get().emailValue, this.loginFormState.get().passwordValue);
    //            //if (loggedIn) {
    //            //    //if (this.loginFormState.get().isPopup) {
    //            //    //    setTimeout(() => {
    //            //    //        this.setIsPopupVisible(false);
    //            //    //    }, 1500);
    //            //    //} else {
    //            //    //    this.navigate('/inventory/DEFAULT')
    //            //    //}
    //            //    this.loginFormState.merge({
    //            //        loginErrorMessage: undefined,
    //            //        loginError: undefined,
    //            //        isLoginSuccessful: true,
    //            //        isWaitingForLogin: false
    //            //    });
    //            //    return true;
    //            //} else {
    //            //    this.loginFormState.merge({
    //            //        loginErrorMessage: "Login failed",
    //            //        loginError: this.sessionState.get().loginError,
    //            //        isLoginSuccessful: false,
    //            //    });
    //            //    if (this.sessionState.get().loginError) {
    //            //        console.error(this.sessionState.get().loginError);
    //            //    }
    //            //}
    //        } else {
    //            this.loginFormState.merge({
    //                loginErrorMessage: "Login failed",
    //                isLoginSuccessful: false
    //            });
    //        }
    //    }
    //    catch (error) {
    //        console.error(error);
    //        this.loginFormState.merge({
    //            //loginError: error,
    //            loginErrorMessage: 'An error occured during login.'
    //        })
    //    }
    //    this.loginFormState.isWaitingForLogin.set(false);
    //    return false;
    //}

    // translated with https://www.deepl.com/translator
    translate(text: string): string {
        const language = this.localeState.get().language;
        switch (text) {
            case 'Login': switch (language) {
                case 'es': return 'Inicio de sesión';
                case 'fr': return "Connexion";
                default: return text;
            }
            case 'E-mail': switch (language) {
                case 'es': return 'Correo electrónico';
                case 'fr': return "E-mail";
                default: return text;
            }
            case 'E-mail is required': switch (language) {
                case 'es': return 'Correo electrónico obligatorio';
                case 'fr': return "L'e-mail est requis";
                default: return text;
            }
            case 'Password': switch (language) {
                case 'es': return 'Contraseña';
                case 'fr': return "Mot de passe";
                default: return text;
            }
            case 'Password is required': switch (language) {
                case 'es': return 'Password is required';
                case 'fr': return "Le mot de passe est requis";
                default: return text;
            }
            case 'Login failed': switch (language) {
                case 'es': return 'Error de inicio de sesión';
                case 'fr': return "Échec de la connexion";
                default: return text;
            }
            default: return text;
        }
    }

    setEmail(value: string): void {
        this.loginFormState.emailValue.set(value);
    }

    setPassword(value: string): void {
        this.loginFormState.passwordValue.set(value);
    }

    setIsPopup(value: boolean): void {
        this.loginFormState.isPopup.set(value);
    }

    setError(error: unknown) {
        if (typeof error === 'string') {
            this.loginFormState.loginErrorMessage.set(error);
        }
        else if (typeof error === 'object') {
            this.loginFormState.loginErrorMessage.set(this.translate('Login failed'));
            console.error(error);
        }
    }

    setIsWaitingForLogin(value: boolean) {
        this.loginFormState.isWaitingForLogin.set(value);
    }
}

export type LoginResponse = {
    AuthToken?: string;
}

export function useLoginFormState(): LoginFormState {
    const loginFormState = useHookstate<LoginFormModel>(loginFormGlobalStore);
    const localeState = useLocaleState();
    return new LoginFormState(loginFormState, localeState);
}