/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { SiteLayout } from "../components/SiteLayout";

export type LoadingPageProps = {
    
}
export const LoadingPage: React.FC<LoadingPageProps> = props => {
    
    return (<>
        <SiteLayout 
            title="Loading"
            mainChildren={<>
                <div css={css``}>Loading</div>
            </>}
        />
    </>);
}

export default LoadingPage