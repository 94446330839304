/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import clone from "clone";
import { motion } from "framer-motion";
import { Product } from "../../../hooks/useInventoryState";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { ShoppingCartItemModel } from "../../../hooks/useShoppingCartItemsState";
import ImageIcon from '@mui/icons-material/Image';
import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";

export type ShoppingCartItemImageProps = {
    item: ShoppingCartItemModel;
}
export const ShoppingCartItemImage: React.FC<ShoppingCartItemImageProps> = props => {
    const item = props.item;
    const productDetailsState = useProductDetailsState();
    const imageSize = 64;
    const checkoutFormState = useCheckoutFormState();
    const warehouseId = checkoutFormState.get().warehouseIdValue;
    const locationId = checkoutFormState.get().locationIdValue;
    const fromDate = checkoutFormState.get().fromDateValue;
    const toDate = checkoutFormState.get().toDateValue;

    return (<>
        {((!productDetailsState.get().showPopup) || (item.product.Id !== (productDetailsState.get().product?.Id ?? ''))) && (
            <div
                className="image"
                css={css`
                        flex: 0;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        @media (min-width: 450px) {
                            flex: 0 0 ${imageSize}px;
                        }`}
            >
                {(!item.product.Images || !item.product.Images.length) && (
                    <motion.div
                        key={0}
                        css={css`
                                display:inline-block;
                                width:${imageSize}px;
                                height:${imageSize}px;
                                display:flex;
                                align-items:center;
                                justify-content:center;
                                cursor: pointer;`}
                        layoutId={item.product.Id}
                        transition={{ type: "spring", stiffness: 180, damping: 26 }}
                        onClick={async e => {
                            return Promise.resolve().then(async () => {
                                window.history.pushState(null, '', `#/product/${item.product.Id}/warehouseid/${warehouseId}/locationid/${locationId}/fromdate/${fromDate}/todate/${toDate}`);
                                await productDetailsState.setProductAsync(clone(item.product) as Product);
                                productDetailsState.setShowPopup(true);
                            });
                        }}
                    >
                        <ImageIcon css={css`width:100%;height:100%;color:#777777;`} />
                    </motion.div>
                )}
                {item.product.Images?.length && (
                    <motion.div
                        css={css`
                                display:inline-block;
                                width:${imageSize}px;
                                height:${imageSize}px;
                                background-image:url(${item.product.Images[0].Url});
                                background-position:center;
                                background-repeat:no-repeat;
                                background-size:contain;
                                cursor: pointer;`}
                        layoutId={item.product.Id}
                        transition={{ type: "spring", stiffness: 180, damping: 26 }}
                        onClick={async e => {
                            return Promise.resolve().then(async () => {
                                window.history.pushState(null, '', `#/product/${item.product.Id}/warehouseid/${warehouseId}/locationid/${locationId}/fromdate/${fromDate}/todate/${toDate}`);
                                await productDetailsState.setProductAsync(clone(item.product) as Product);
                                productDetailsState.setShowPopup(true);
                            });
                        }}
                    >
                    </motion.div>
                )}
            </div>
        )}
    </>);
}