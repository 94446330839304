import { hookstate, ImmutableObject, State, useHookstate } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";
import { LocaleState, useLocaleState } from "./useLocaleState";

export type ProductBrowserViewType = 'grid' | 'list';

export class ProductBrowserModel {
    view: ProductBrowserViewType = 'grid';
    itemWidth: number = 200;
    expandedView: boolean = true;
    //isDesktopSidebarExpanded: boolean = true;
    //isMobileSidebarExpanded: boolean = false;
}

const storefront_productbrowser_key = 'storefront-productbrowser';
let initialState = new ProductBrowserModel();
const localstorageJson = window.localStorage.getItem(storefront_productbrowser_key);
if (localstorageJson) {
    try {
        initialState = JSON.parse(localstorageJson);
    }
    catch (ex) {
        console.error(ex);
    }
}
const productBrowserGlobalStore = hookstate<ProductBrowserModel>(initialState, devtools({ key: 'ProductBrowserState' }));

export class ProductBrowserState {
    private productBrowserState: State<ProductBrowserModel>;
    private localeState: LocaleState

    constructor(productBrowserState: State<ProductBrowserModel>, localeState: LocaleState) {
        this.productBrowserState = productBrowserState;
        this.localeState = localeState;
    }

    get(): ImmutableObject<ProductBrowserModel> {
        return this.productBrowserState.get();
    }

    private saveToLocalStorage() {
        const obj = this.productBrowserState.get();
        let json = '';
        if (obj) {
            json = JSON.stringify(obj);
        }
        window.localStorage.setItem(storefront_productbrowser_key, json);
    }

    setView(value: ProductBrowserViewType) {
        this.productBrowserState.view.set(value);
        this.saveToLocalStorage()
    }

    setExpandedView(value: boolean) {
        this.productBrowserState.expandedView.set(value);
        this.saveToLocalStorage()
    }

    setItemWidth(value: number) {
        this.productBrowserState.itemWidth.set(value);
        this.saveToLocalStorage()
    }

    //setDesktopSidebarExpanded(value: boolean) {
    //    this.productBrowserState.isDesktopSidebarExpanded.set(value);
    //}

    //setMobileSidebarExpanded(value: boolean) {
    //    this.productBrowserState.isMobileSidebarExpanded.set(value);
    //}

    //translate(text: string): string {
    //    const language = this.localeState.get().language;
    //    switch (text) {
    //        case 'Image': switch (language) {
    //            case 'es': return 'Image';
    //            case 'fr': return "Image";
    //            default: return text;
    //        }
    //        case "Item No": switch (language) {
    //            case 'es': return 'Nº de artículo';
    //            case 'fr': return "Numéro d'article";
    //            default: return text;
    //        }
    //        case "Item Name": switch (language) {
    //            case 'es': return "Nombre del artículo";
    //            case 'fr': return "Nom de l'article";
    //            default: return text;
    //        }
    //        case "Tags": switch (language) {
    //            case 'es': return 'Tags';
    //            case 'fr': return 'Tags';
    //            default: return text;
    //        }
    //        case 'Keywords': switch (language) {
    //            case 'es': return 'Palabras clave';
    //            case 'fr': return 'Mots clés';
    //            default: return text;
    //        }
    //        case 'Description': switch (language) {
    //            case 'es': return 'Descripción';
    //            case 'fr': return 'Description';
    //            default: return text;
    //        }
    //        case 'Technical Notes': switch (language) {
    //            case 'es': return 'Notas técnicas';
    //            case 'fr': return 'Notes techniques';
    //            default: return text;
    //        }
    //        case "Qty": switch (language) {
    //            case 'es': return 'Ctd';
    //            case 'fr': return 'Qté';
    //            default: return text;
    //        }
    //        case "Thumbnail Size": switch (language) {
    //            case 'es': return 'Tamaño de la miniatura';
    //            case 'fr': return 'Taille de la vignette';
    //            default: return text;
    //        }
    //        case "List View": switch (language) {
    //            case 'es': return 'Ver lista';
    //            case 'fr': return 'Vue de la liste';
    //            default: return text;
    //        }
    //        case "Grid View": switch (language) {
    //            case 'es': return 'Vista en cuadrícula';
    //            case 'fr': return 'Vue en grille';
    //            default: return text;
    //        }
    //        case "Search Options": switch (language) {
    //            case 'es': return 'Opciones de búsqueda';
    //            case 'fr': return 'Options de recherche';
    //            default: return text;
    //        }
    //        case "Availability": switch (language) {
    //            case 'es': return 'Disponibilidad';
    //            case 'fr': return 'Disponibilité';
    //            default: return text;
    //        }
    //        case "From": switch (language) {
    //            case 'es': return 'De';
    //            case 'fr': return 'De';
    //            default: return text;
    //        }
    //        case "To": switch (language) {
    //            case 'es': return 'A';
    //            case 'fr': return 'A';
    //            default: return text;
    //        }
    //        default: return text;
    //    }
    //}
}

export function useProductBrowserState(): ProductBrowserState {
    const productBrowserState = useHookstate<ProductBrowserModel>(productBrowserGlobalStore);
    const localeState = useLocaleState();
    return new ProductBrowserState(productBrowserState, localeState);
}