/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { motion } from "framer-motion";
import { Product } from "../../../hooks/useInventoryState";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { FwQueryResponse } from "../../../lib/FwReact/FwQuery";
import { ProductDetailsPopup } from "../../ProductDetails/ProductDetails";
import { ProductBrowserListItem } from "./ProductBrowserListItem";

//-------------------------------------------------------------------------------------------------------
// ListView
export type ProductBrowserListProps = {
    products: ImmutableObject<FwQueryResponse<Product>>;
}
export const ProductBrowserList: React.FC<ProductBrowserListProps> = props => {
    const themeName = useThemeState().get().themeName;
    const productDetailsState = useProductDetailsState();
    const products = props.products;

    return (<>
        <div
            css={css`
                background-color: ${themeName === 'dark' ? 'rgba(0,0,0,.3);' : 'rgba(0,0,0,.05);'};
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
                justify-items: stretch;
                gap: 1px 1px;
                @media (min-width: 720px) {
                    padding: 1em;
                    gap: 1em .25em;
                }
                .field {
                    padding:.3em .5em;
                }
                .caption {
                    flex: 0 0 6em;
                    font-size:.75rem;
                    color: ${themeName === 'dark' ? '#00bcd4' : '#006064'};
                    transition: color 1s;
                    white-space:wrap;
                    font-weight:bold;
                }
                .value {
                    padding:0 0 0 .25em;
                    white-space:wrap;
                }
                .field {text-align:left;}
                .field.icode {}
                .field.availability {}
                .field.itemdescription > .caption {bold;}
                .field.itemdescription > .value {
                    font-weight:bold;
                    cursor:pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            `}>
            {products.Items.map((product, index) => (
                <motion.div
                    css={css`
                        width:100%;
                        display:flex;
                        flex-wrap:wrap;
                        padding:4px 0;
                        overflow: hidden;`}
                    key={product.Id}
                    layoutId={product.Id}
                    transition={{ type: "spring", stiffness: 180, damping: 26 }}>
                    <ProductBrowserListItem product={product} />
                </motion.div>
            ))}
        </div>

        <ProductDetailsPopup layoutId={productDetailsState.get().product?.Id ?? "0"} />
    </>);
}