/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ShoppingCartItemModel, useShoppingCartItemsState } from "../../hooks/useShoppingCartItemsState";
import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useThemeState } from "../../hooks/useThemeState";
import { ProductDetailsPopup } from "../ProductDetails/ProductDetails";
import { useProductDetailsState } from "../../hooks/useProductDetailsState";
import React from "react";
import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { ImmutableArray, ImmutableObject, useHookstate, useHookstateEffect } from "@hookstate/core";
import { ShoppingCartItemRow } from "./Desktop/ShoppingCartItemDesktop";
import { Catalog, useCatalogState } from "../../hooks/useCatalogState";
import { Product } from "../../hooks/useInventoryState";
import { enqueueSnackbar } from "notistack";
import { useStorefrontSettingsState } from "../../hooks/useStorefrontSettingsState";
import { CheckoutDate } from "../CheckoutForm/CheckoutDate";
import { AppConstants } from "../../data/AppConstants";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";
import { useAppConfigState } from "../../hooks/useAppConfigState";
import { CustomScrollbars } from "../CustomScrollbars/CustomScrollbars";

//------------------------------------------------------------------------------------
export class ShoppingCartViewSettings {
    //hasImage: boolean = false;
    hasDepartmentCategorySubcategoryLinks: boolean = false;
    hasICode: boolean = false;
    //hasDescription: boolean = false;
    hasBarcodes: boolean = false;
    hasTags: boolean = false;
    hasDailyRate: boolean = false;
    hasWeeklyRate: boolean = false;
    hasMonthlyRate: boolean = false;
    hasSalesPrice: boolean = false;
    hasQty: boolean = false;
    hasRates: boolean = false;
    hasAvailability: boolean = false;
    hasDetailedDescription: boolean = false;
    hasTechnicalNotes: boolean = false;
    allowAddingUnavailableItems: boolean = false;
}

export const getShoppingCartCatalogViewSettings = (catalogs?: ImmutableArray<Catalog>, catalogId?: string): ShoppingCartViewSettings => {
    const viewSettings = new ShoppingCartViewSettings();
    if (catalogs && catalogId) {
        const catalog = catalogs.find(c => c.Id === catalogId);
        if (catalog) {
            const shoppingCartFieldsByName = catalog.shoppingCartFieldsByName;
            if (shoppingCartFieldsByName) {
                //viewSttings.hasImage = (shoppingCartFieldsByName['IMAGE'] !== undefined) && (shoppingCartFieldsByName['IMAGE'].Selected);
                viewSettings.hasDepartmentCategorySubcategoryLinks = (shoppingCartFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'] !== undefined) && (shoppingCartFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'].Selected);
                viewSettings.hasICode = (shoppingCartFieldsByName['ICODE'] !== undefined) && (shoppingCartFieldsByName['ICODE'].Selected);
                //viewSettings.hasDescription = (shoppingCartFieldsByName['DESCRIPTION'] !== undefined) && (shoppingCartFieldsByName['DESCRIPTION'].Selected);
                viewSettings.hasBarcodes = (shoppingCartFieldsByName['BARCODENUMBER'] !== undefined) && (shoppingCartFieldsByName['BARCODENUMBER'].Selected);
                viewSettings.hasTags = (shoppingCartFieldsByName['TAGS'] !== undefined) && (shoppingCartFieldsByName['TAGS'].Selected);
                viewSettings.hasQty = (shoppingCartFieldsByName['ITEMQUANTITYINPUT'] !== undefined) && (shoppingCartFieldsByName['ITEMQUANTITYINPUT'].Selected);
                viewSettings.hasDailyRate = (shoppingCartFieldsByName['DAILYRATE'] !== undefined) && (shoppingCartFieldsByName['DAILYRATE'].Selected);
                viewSettings.hasWeeklyRate = (shoppingCartFieldsByName['WEEKLYRATE'] !== undefined) && (shoppingCartFieldsByName['WEEKLYRATE'].Selected);
                viewSettings.hasMonthlyRate = (shoppingCartFieldsByName['MONTHLYRATE'] !== undefined) && (shoppingCartFieldsByName['MONTHLYRATE'].Selected);
                viewSettings.hasSalesPrice = (shoppingCartFieldsByName['SALESPRICE'] !== undefined) && (shoppingCartFieldsByName['SALESPRICE'].Selected);
                viewSettings.hasRates = viewSettings.hasDailyRate || viewSettings.hasWeeklyRate || viewSettings.hasMonthlyRate;
                viewSettings.hasAvailability = (shoppingCartFieldsByName['AVAILABILITY'] !== undefined) && (shoppingCartFieldsByName['AVAILABILITY'].Selected);
                viewSettings.hasDetailedDescription = (shoppingCartFieldsByName['DETAILEDDESCRIPTION'] !== undefined) && (shoppingCartFieldsByName['DETAILEDDESCRIPTION'].Selected);
                viewSettings.hasTechnicalNotes = (shoppingCartFieldsByName['TECHNICALNOTES'] !== undefined) && (shoppingCartFieldsByName['TECHNICALNOTES'].Selected);
            }
            viewSettings.allowAddingUnavailableItems = catalog.AllowAddingUnavailableItems;
        }
    }
    return viewSettings;
}

export const getShoppingCartProductViewSettings = (catalogs?: ImmutableArray<Catalog>, product?: ImmutableObject<Product>): ShoppingCartViewSettings => {
    const catalogId = product?.WebCatalogId;
    const viewSettings = getShoppingCartCatalogViewSettings(catalogs, catalogId);
    if (product) {
        viewSettings.hasAvailability = viewSettings.hasAvailability && (product.Availability !== undefined);
    }
    return viewSettings;
}

export type ImportCartButtonProps = {
    
}
const ImportCartButton = (props: ImportCartButtonProps) => {
    // Create a state variable to store the file content
    //const [fileContent, setFileContent] = useState('');
    const shoppingCartItemsState = useShoppingCartItemsState();
    const translate = useTranslate();

    // Define a function to handle the file input change event
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Get the selected file from the input element
        if (e && e.target && e.target.files) {
            enqueueSnackbar(`Importing shopping cart...`, {
                variant: 'info',
                autoHideDuration: 5000,

            });
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                if (e && e.target && e.target.result && typeof e.target.result === 'string') {
                    const shoppingCartJson = e.target.result;
                    //setFileContent(shoppingCartJson);
                    const shoppingCartItems = JSON.parse(shoppingCartJson) as ShoppingCartItemModel[];
                    shoppingCartItemsState.replace(shoppingCartItems);
                    //for (let i = 0; i < shoppingCartItems.length; i++) {
                    //    const shoppingCartItem = shoppingCartItems[i];
                    //    shoppingCartItemsState.add(shoppingCartItem);
                    //}
                    //localStorage.setItem('storefront-shoppingcartitems', shoppingCartJson);
                    //shoppingCartState.
                    //console.log(e.target.result);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <div>
            <Button
                css={css`margin:0 .5rem;position:relative;`}
                variant="outlined"
                onClick={async e => {
                    
                }}>
                <input
                    css={css`position:absolute;top:0;right:0;bottom:0;left:0;opacity:0;`}
                    type="file"
                    value=""
                    onChange={handleFileChange} />
                {translate('Import Cart')}
            </Button>
            
            {/*<pre>{fileContent}</pre>*/}
        </div>
    );
};

type ShoppingCartControlWebCatalog = {
    WebCatalogId: string;
    WebCatalog: string;
    TotalItems: number;
}
export type ShoppingCartControlProps = {

}
export const ShoppingCartControl: React.FC<ShoppingCartControlProps> = props => {
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    const isDarkTheme = themeName === 'dark';
    const borderColor = isDarkTheme ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)';
    const shoppingCartItemsState = useShoppingCartItemsState();
    //const imageSize = 64;
    const navigate = useNavigate();
    const productDetailsState = useProductDetailsState();
    const checkoutFormState = useCheckoutFormState();
    let durationDays = checkoutFormState.getDurationInDays();
    const isDesktop = useMediaQuery('(min-width:720px)');
    const catalogState = useCatalogState();
    const catalogs = catalogState.get().catalogs;
    const translate = useTranslate();
    const appConfigState = useAppConfigState();
    const checkoutUrl = appConfigState.getCheckoutUrl();

    const getIsRowNumberColumnVisible = (catalogId?: string) => {
        const isVisible = true;
        return isVisible;
    };

    const getIsImageColumnVisible = (catalogId?: string) => {
        const isVisible = true;
        return isVisible;
    };

    const getIsDescriptionColumnVisible = (catalogId?: string) => {
        const isVisible = true;
        return isVisible;
    };

    const getIsPriceColumnVisible = (catalogId?: string) => {
        let isVisible = true;
        if (catalogId !== undefined) {
            const viewSettings = getShoppingCartCatalogViewSettings(catalogs, catalogId);
            isVisible = (viewSettings.hasDailyRate || viewSettings.hasWeeklyRate || viewSettings.hasMonthlyRate);
        }
        return isVisible;
    };

    const getIsQuantityColumnVisible = (catalogId?: string) => {
        const isVisible = true;
        return isVisible;
    };

    const getTotalColumns = (catalogId?: string) => {
        const isRowNumberColumnVisible = getIsRowNumberColumnVisible(catalogId);
        const isImageColumnVisible = getIsImageColumnVisible(catalogId);
        const isDescriptionColumnVisible = getIsDescriptionColumnVisible(catalogId);
        let isPriceColumnVisible = getIsPriceColumnVisible(catalogId);
        const isQuantityColumnVisible = getIsQuantityColumnVisible(catalogId);
        let totalColumns = 0;
        if (isRowNumberColumnVisible) {
            totalColumns++;
        }
        if (isImageColumnVisible) {
            totalColumns++;
        }
        if (isDescriptionColumnVisible) {
            totalColumns++;
        }
        if (isPriceColumnVisible) {
            totalColumns++;
        }
        if (isQuantityColumnVisible) {
            totalColumns++;
        }
        return totalColumns;
    }
    
    const items = shoppingCartItemsState.get();
    let totalItemCount = 0;
    const webCatalogs: ShoppingCartControlWebCatalog[] = [];
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.qty > 0) {
            totalItemCount += item.qty;
            const webCatalog = webCatalogs.find(c => c.WebCatalogId === item.product.WebCatalogId);
            if (item.product.WebCatalogId && item.product.WebCatalog && !webCatalog) {
                webCatalogs.push({
                    WebCatalogId: item.product.WebCatalogId,
                    WebCatalog: item.product.WebCatalog,
                    TotalItems: item.qty
                })
            }
            else if (webCatalog) {
                webCatalog.TotalItems += item.qty;
            }
        }
    }

    const storefrontSettingsState = useStorefrontSettingsState();
    const separateQuoteRequestsByCatalog = storefrontSettingsState.get().settings?.SeparateQuoteRequestsByCatalog ?? false;

    

    const isDeveloper = localStorage.getItem('developer') === 'true';
    //let grandTotal = 0;

    //const inventoryState = useInventoryState();
    //const shoppingCartFieldsByName = inventoryState.get().shoppingCartFieldsByName;

    //console.log({
    //    shoppingCartFieldsByName: shoppingCartFieldsByName
    //})

    const closeClearCartDialog = () => {
        showClearCartState.set(false)
    };

    function download(filename: string, text: string) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const exportCartAsync = async () => {
        let shoppingCartJson = localStorage.getItem('storefront-shoppingcartitems');
        if (typeof shoppingCartJson === 'string') {
            //const shoppingCart = JSON.parse(shoppingCartJson);
            //shoppingCartJson = JSON.stringify(shoppingCart, null, 2);
            download('shoppingcart.json', shoppingCartJson);
        }
    };

    const showClearCartState = useHookstate<boolean>(false);

    const shoppingCartTableCss = css`
        transition: all .5s ease;
        margin:${(separateQuoteRequestsByCatalog && webCatalogs.length > 1) ? '2rem 0 0 0' : '0'};
        width: 100%;
        border-collapse: collapse;
        & > thead {
            top: -1px;
            z-index: 2;
            position: sticky;
        }
        & > thead th {
            font-weight: normal;
            transition: all .5s ease;
            background-color: ${themeState.getTheme().palette.siteBackground};
        }
        & > thead tr.columnheaders {
            /*display: none;*/
        }
        & > thead tr.columnheaders th {
            background-color: #000000;
            color: #ffffff;
            font-size: .9rem;
        }
        & > tbody > tr.packageitem > td {
            padding: 0;
        }
        & > tfoot {
            transition: all .5s ease;
            border-top: 1px solid ${isDarkTheme ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'};
            /*border-bottom: 1px solid ${isDarkTheme ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)'};*/
            /*background-color: ${isDarkTheme ? 'rgba(255,255,255,.03)' : 'rgba(0,0,0,.03)'};*/
        }
    `;

    const shoppingCartTableThead = (catalogId?: string) => (<>
        <tr className="columnheaders">
            <th>
                {getIsRowNumberColumnVisible(catalogId) && (<></>)}
            </th>
            <th>
                {getIsImageColumnVisible(catalogId) && (<></>)}
            </th>
            <th css={css`text-align:left;padding:0 0 0 .25rem;`}>
                {getIsDescriptionColumnVisible(catalogId) && (<>
                    {translate('Description')}
                </>)}
            </th>
            {isDesktop && (<>
                <th css={css`text-align:center;`}>
                    {getIsPriceColumnVisible(catalogId) && (<>
                        {translate('Price')}
                    </>)}
                </th>
            </>)}
            <th>
                {getIsQuantityColumnVisible(catalogId) && (<>
                    {translate('Quantity')}
                </>)}
            </th>
        </tr>
    </>);

    const footer = (catalog?: ShoppingCartControlWebCatalog) => (<>
        <div css={css`text-align:center;padding:4px 32px 0 0;`}>
            {!catalog && (<>
                {translate('Total Items')}: {totalItemCount}
            </>)}
            {catalog && (<>
                {translate('Total Items')}: {catalog.TotalItems}
            </>)}
        </div>
        <div css={css`flex:0 0 auto;padding:16px 0 16px 0;display:flex;align-items:center;justify-content:center;`}>
            <Dialog open={showClearCartState.get()} fullWidth={true} maxWidth={"xs"}>
                <DialogTitle>
                    {translate('Clear Cart?')}
                    <IconButton
                        aria-label="close"
                        onClick={closeClearCartDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="outlined"
                            onClick={async e => {
                                return Promise.resolve().then(() => {
                                    shoppingCartItemsState.reset();
                                    closeClearCartDialog();
                                });
                            }}>
                            {translate('OK')}
                        </Button>
                        <Button
                            css={css`margin-left:16px;`}
                            variant="outlined"
                            onClick={async e => {
                                return Promise.resolve().then(() => {
                                    closeClearCartDialog();
                                });
                            }}>
                            {translate('Cancel')}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {isDeveloper && (<>
                <Button
                    css={css`margin:0 .5rem;`}
                    variant="outlined"
                    disabled={shoppingCartItemsState.get().length === 0}
                    onClick={async e => {
                        return Promise.resolve().then(async () => {
                            await exportCartAsync();
                        });
                    }}>
                    {translate('Export Cart')}
                </Button>
                <ImportCartButton />
            </>)}
            <Button
                css={css`margin:0 .5rem;`}
                variant="outlined"
                
                disabled={shoppingCartItemsState.get().length === 0}
                onClick={async e => {
                    return Promise.resolve().then(() => {
                        showClearCartState.set(true);
                        //if (window.confirm('Clear cart?')) {
                        //    shoppingCartItemsState.reset();
                        //}
                        //endIcon={<Delete />}
                        //endIcon = {< ShoppingCartCheckout />}
                    });
                }}>
                {translate('Clear Cart')}
            </Button>
            <Button
                css={css`margin:0 .5rem;`}
                variant="outlined"
                disabled={shoppingCartItemsState.get().length === 0}
                onClick={async e => {
                    return Promise.resolve().then(() => {
                        if (!separateQuoteRequestsByCatalog) {
                            navigate(`/${checkoutUrl}`);
                        }
                        else {
                            navigate(`/${checkoutUrl}/catalogid/${catalog?.WebCatalogId}`);
                        }
                    });
                }}>
                {translate('Checkout')}...
            </Button>
        </div>
    </>);


    useHookstateEffect(() => {
        if (productDetailsState.get().showPopup) {
            productDetailsState.setShowPopup(false);
        }
    }, []);

    // clear out any shopping cart items for catalogs that are no longer accessible
    useHookstateEffect(() => {
        let allItemsInCart = shoppingCartItemsState.get();
        let removeList: ShoppingCartItemModel[] = [];
        let itemNames: string[] = [];
        if (catalogs) {
            for (let i = 0; i < allItemsInCart.length; i++) {
                let item = allItemsInCart[i];
                if (item && item.product && item.product.WebCatalogId) {
                    const catalog = catalogs.find(c => c.Id === item.product.WebCatalogId);
                    if (!catalog) {
                        itemNames.push(item.product.ItemName ?? '');
                        removeList.push(item);
                    }
                }
            }
        }
        shoppingCartItemsState.deleteItems(removeList);
        if (itemNames.length > 0) {
            enqueueSnackbar(`Some shopping cart items were removed because the catalog(s) are no longer available.`, { variant: 'info', persist: true });
        }
    }, [catalogs]);

    return (
        <div css={css`
            flex:1 1 0;
            display:flex;
            justify-content:center;
            `}>
            <div css={css`
                flex:1 1 0;
                max-width:${AppConstants.siteWidthCss};
                display:flex;
                flex-direction:column;
                overflow:hidden;
                min-width: 320px;
                contain: content; /* this is the container where you want fixed position popups to be relative to */
                @media (min-width: 720px) {
                    min-width: 512px;
                }`}>
                <div
                    css={css`
                    flex: 0 0 auto;
                    text-align: center;
                    font-family: 'Ropa Sans';
                    font-size: 2em;
                    padding: .25em;
                `}>
                    {translate('Shopping Cart')}
                </div>
                {!separateQuoteRequestsByCatalog && (<>
                    <div css={css`border-bottom: 1px solid ${borderColor};padding:0 0 16px 0;display:flex;align-items:center;justify-content:center;flex-wrap:wrap;`}>
                        <CheckoutDate type="startdate" /><span css={css`padding:0 .5rem;`}>-</span><CheckoutDate type="stopdate" /> <span css={css`padding:0 .5rem;`}>({durationDays} {translate('days')})</span>
                    </div>
                </>)}
                <CustomScrollbars 
                    css={css`
                        flex: 1 1 0;
                    `}
                >
                    
                    {separateQuoteRequestsByCatalog && webCatalogs.map((catalog, catalogIndex) => (<React.Fragment key={catalogIndex}>
                        <table css={shoppingCartTableCss}>
                            <thead>
                                <tr>
                                    <th colSpan={getTotalColumns(catalog.WebCatalogId)}>
                                        <div css={css`display:flex;justify-content:center;align-items:center;padding:.25rem 0;`}>
                                            {/*<div css={css`padding:0 4px 0 0;font-size:.8rem;`}>{shoppingCartItemsState.translate('Catalog')}:</div>*/}
                                            <div css={css`padding:0 16px 0 0;font-weight:bold;font-size:1.4rem;`}>- {catalog.WebCatalog} -</div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan={getTotalColumns(catalog.WebCatalogId)}>
                                        <div css={css`border-bottom: 1px solid ${borderColor};padding:8px;display:flex;align-items:center;justify-content:center;flex-wrap:wrap;`}>
                                            <div css={css`display:flex;align-items:center;justify-content:center;flex-wrap:wrap;`}>
                                                <CheckoutDate type="startdate" catalogId={catalog.WebCatalogId} />
                                                <span css={css`padding:0 .5rem;`}>-</span>
                                                <CheckoutDate type="stopdate" catalogId={catalog.WebCatalogId} />
                                                <span css={css`padding:0 .5rem;`}>({durationDays} {translate('days')})</span>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                {shoppingCartTableThead(catalog.WebCatalogId)}
                            </thead>
                            <tbody>
                                {shoppingCartItemsState.get().filter(i => i.product.WebCatalogId === catalog.WebCatalogId).map((shoppingCartItem, itemIndex) => (<React.Fragment key={itemIndex}>
                                    <ShoppingCartItemRow
                                        shoppingCartItem={shoppingCartItem}
                                        index={itemIndex}
                                        isRowNumberColumnVisible={getIsRowNumberColumnVisible(catalog.WebCatalogId)}
                                        isImageColumnVisible={getIsImageColumnVisible(catalog.WebCatalogId)}
                                        isDescriptionColumnVisible={getIsDescriptionColumnVisible(catalog.WebCatalogId)}
                                        isPriceColumnVisible={getIsPriceColumnVisible(catalog.WebCatalogId)}
                                        isQuantityColumnVisible={getIsQuantityColumnVisible(catalog.WebCatalogId)} />
                                </React.Fragment>))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={getTotalColumns(catalog.WebCatalogId)}>
                                        {footer(catalog)}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </React.Fragment>))}

                    {!separateQuoteRequestsByCatalog && (<>
                        <table css={shoppingCartTableCss}>
                            <thead>
                                {shoppingCartTableThead()}
                            </thead>
                            <tbody>
                                {shoppingCartItemsState.get().map((shoppingCartItem, index) => (<React.Fragment key={index}>
                                    <ShoppingCartItemRow
                                        shoppingCartItem={shoppingCartItem}
                                        index={index}
                                        isRowNumberColumnVisible={getIsRowNumberColumnVisible(shoppingCartItem.product.WebCatalogId)}
                                        isImageColumnVisible={getIsImageColumnVisible(shoppingCartItem.product.WebCatalogId)}
                                        isDescriptionColumnVisible={getIsDescriptionColumnVisible(shoppingCartItem.product.WebCatalogId)}
                                        isPriceColumnVisible={getIsPriceColumnVisible(shoppingCartItem.product.WebCatalogId)}
                                        isQuantityColumnVisible={getIsQuantityColumnVisible(shoppingCartItem.product.WebCatalogId)} />
                                </React.Fragment>))}
                            </tbody>
                        </table>
                    </>)}

                </CustomScrollbars>
                {!separateQuoteRequestsByCatalog && (<>
                    {footer()}
                </>)}
                <ProductDetailsPopup layoutId={productDetailsState.get().product?.Id ?? "0"} />
            </div>
        </div>
     );
}
