/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Product } from "../../../hooks/useInventoryState";
import { AddOrUpdateCart } from "../../ShoppingCart/AddOrUpdateCart";

// ListView - Qty
export type ProductBrowserListItemQtyProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserListItemQty: React.FC<ProductBrowserListItemQtyProps> = props => {

    return (<>
        <AddOrUpdateCart product={props.product} />
    </>)
}
