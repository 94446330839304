/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
/* Availability */
export type AvailabilityFieldProps = {

}
export const AvailabilityField: React.FC<AvailabilityFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    const translate = useTranslate();

    if (product && product.Availability) {
        const totalIn = productDetailsState.get().product?.Availability?.TotalIn ?? 0;
        return (
            <div className="field availability">
                {totalIn > 0 && (
                    <span css={css`${themeName === 'dark' ? 'color:hsl(180 100% 40% / 1);' : 'color:#777777;'}`}>({(product.Availability?.TotalIn ?? 0).toString() + ' of ' + (product.Availability?.Total ?? 0).toString() + ' ' + translate('available')})</span>
                )}
                {totalIn === 0 && (
                    <span css={css`color:hsl(0 0% 40% / 1);`}>({translate('Unavailable')})</span>
                )}
            </div>);
    }
    else {
        return null;
    }
}