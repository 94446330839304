import debounce from 'debounce'
import { useRef } from 'react'

interface DebouncedArgs<T> {
    delay?: number
    immediate?: boolean;
    callback?: (value: T) => void
}

export const useDebounce = <T = unknown>({ callback, delay = 100, immediate = false }: DebouncedArgs<T>) => {
    const dispatchValue = (value: T) => callback?.(value)
    const setValueDebounced = useRef(debounce(dispatchValue, delay, immediate))
    return (value: T) => setValueDebounced.current(value)
}