/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject, useHookstateEffect } from "@hookstate/core";
import { ChevronLeft, ChevronRight, FirstPage, LastPage, Refresh } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SiteLayout } from "../components/SiteLayout";
import { Catalog, useCatalogState } from "../hooks/useCatalogState";
import { useThemeState } from "../hooks/useThemeState";
import { ProductBrowser } from "../components/ProductBrowser/ProductBrowser";
import { useProductDetailsState } from "../hooks/useProductDetailsState";
import { ProductCategory, ProductDepartment, ProductSubCategory, useInventoryState } from "../hooks/useInventoryState";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useCheckoutFormState } from "../hooks/useCheckoutFormState";
import { AppConstants } from "../data/AppConstants";
import { useTranslate } from "../hooks/useTranslate/useTranslate";


// Inventory Page
export type InventoryPageProps = {
    showlandingpage?: boolean;
}
export const InventoryPage: React.FC<InventoryPageProps> = props => {
    const translate = useTranslate();
    const theme = useThemeState().get().theme;
    const inventoryState = useInventoryState();
    const catalogState = useCatalogState();
    const { catalogid, departmentid, categoryid, subcategoryid } = useParams();
    const catalogs = catalogState.get().catalogs;
    //const showlandingpage = props.showlandingpage ?? true;
    let [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get('keywords') ?? '';
    const showlandingpage = props.showlandingpage || (searchText.length === 0);
    let pageNo = parseInt(searchParams.get('pageno') ?? '1');
    const productDetailsState = useProductDetailsState();
    const totalProducts = inventoryState.getProductsState().get().data?.TotalItems ?? 0;
    const pageSize = inventoryState.getProductsState().get().data?.PageSize ?? 100;
    const totalPages = Math.ceil(totalProducts / pageSize);
    const navigate = useNavigate();
    const checkoutFormState = useCheckoutFormState();
    const warehouse = checkoutFormState.getWarehouse();
    const location = checkoutFormState.getLocation();

    useHookstateEffect(() => {
        inventoryState.getProductsState().setPageNoAsync(pageNo, true, true);
    }, [pageNo]);

    useHookstateEffect(() => {
        (async () => {
            try {
                productDetailsState.setShowPopup(false);

                if (location && warehouse) {
                    if (!catalogid && !departmentid && !categoryid && !subcategoryid) {
                        //await inventoryState.clearProductsAsync();
                        await inventoryState.clearCatalogFilterAsync();
                        inventoryState.selectDefaultCatalog();
                    }
                    else if (!catalogid && !inventoryState.get().catalog && catalogs && catalogs.length > 0) {
                        await inventoryState.filterByCatalogIdAsync(warehouse, location, catalogs[0].Id, true);
                        if (showlandingpage === undefined) {
                            //await inventoryState.filterByCatalogIdAsync(warehouse, location, catalogs[0].Id, true);
                            inventoryState.selectDefaultCatalog();
                            const catalog = inventoryState.get().catalog;
                            if (catalog) {
                                await inventoryState.filterByCatalogIdAsync(warehouse, location, catalog.Id, true);
                            }
                        } else {
                            await inventoryState.loadProductCategoryTreeAsync(catalogs[0].Id);
                        }
                    }
                    else if (catalogid && !departmentid && !categoryid && !subcategoryid) {
                        if (showlandingpage === undefined) {
                            await inventoryState.clearCatalogFilterAsync();
                            if (catalogs && catalogs.find(c => c.Id !== catalogid) !== undefined) {
                                await inventoryState.filterByCatalogIdAsync(warehouse, location, catalogid, true);
                            }
                            else {
                                inventoryState.selectDefaultCatalog();
                            }
                        } else {
                            if (showlandingpage) {
                                //await inventoryState.clearProductsAsync();
                                await inventoryState.loadProductCategoryTreeAsync(catalogid);
                                await inventoryState.clearCatalogFilterAsync(catalogid);
                            } else {
                                if (catalogid !== inventoryState.get().catalog?.Id) {
                                    await inventoryState.loadProductCategoryTreeAsync(catalogid);
                                }
                            }
                            if (searchText.length === 0) {
                                try {
                                    await inventoryState.filterByCatalogIdAsync(warehouse, location, catalogid, showlandingpage);
                                }
                                catch {
                                    inventoryState.selectDefaultCatalog();
                                    const catalog = inventoryState.get().catalog;
                                    if (catalog) {
                                        await inventoryState.filterByCatalogIdAsync(warehouse, location, catalog.Id, true);
                                    }
                                }
                            }
                        }
                    }
                    else {
                        let catalog: Catalog | ImmutableObject<Catalog> | undefined;
                        let department: ProductDepartment | undefined;
                        let category: ProductCategory | undefined;
                        let subCategory: ProductSubCategory | undefined;

                        if (catalogid) {
                            catalog = await inventoryState.getCatalogByIdAsync(catalogid);
                            if (departmentid) {
                                department = await inventoryState.getDepartmentByIdAsync(catalogid, departmentid);
                                if (categoryid) {
                                    category = await inventoryState.getCategoryByIdAsync(catalogid, departmentid, categoryid);
                                    if (subcategoryid) {
                                        subCategory = await inventoryState.getSubCategoryByIdAsync(catalogid, departmentid, categoryid, subcategoryid);
                                    }
                                }
                            }
                            if (searchText.length === 0) {
                                await inventoryState.setProductFilterAsync({
                                    warehouse: warehouse,
                                    catalog: catalog,
                                    department: department,
                                    category: category,
                                    subCategory: subCategory,
                                    searchText: '',
                                    showLandingPage: false
                                });
                            }
                        }
                        else {
                            await inventoryState.clearCatalogFilterAsync();
                        }
                    }
                    // if doing a keyword search
                    if (catalogid && searchText.length > 0) {
                        await inventoryState.searchAsync(catalogid, searchText, pageNo);
                    }
                }
            } catch (ex) {
                if (ex && (ex instanceof (AxiosError)) && (ex.response) && (ex.response.status === 404)) {
                    enqueueSnackbar<'error'>('Not found, redirecting to home page.');
                    navigate('/');
                }
            }
        })();
    }, [/*loggedIn,*/ props, catalogid, departmentid, categoryid, subcategoryid, searchText/*, location, warehouse*/]);

    return <>
        <SiteLayout
            title={translate('Inventory')}
            afterLogo={<>
                <div css={css`flex:0 0 auto;display:flex;flex-wrap:wrap;align-items:center;padding:1em;`}>
                    {/*{isDesktop && (*/}
                    {/*    <BreadCrumbs></BreadCrumbs>*/}
                    {/*)}*/}
                </div>
            </>}
            fullLeftGutter={<>
                
            </>}
            mainChildren={<>
                <div
                    css={css`
                            flex:1 1 0;
                            display:flex;
                            justify-content: center;
                            overflow:hidden;
                        `}>

                    <motion.div
                        css={css`
                                flex:1 1 0;
                                max-width:${AppConstants.siteWidthCss};
                                display:flex;
                                overflow:hidden;
                                contain: content; /* this is the container where you want fixed position popups to be relative to */
                                `}
                        layout>

                        { /* Page Main Section */}
                        <div css={css`flex:1 1 0;display:flex;flex-direction:column;overflow:hidden;`}>
                            <div
                                css={css`
                                        flex:1 1 0;
                                        overflow:hidden;
                                        display:flex;
                                        flex-direction:column;
                                    `}>
                                <ProductBrowser catalogid={catalogid} />

                            </div>

                            {!inventoryState.get().showLandingPage && !productDetailsState.get().showPopup && (
                                <div css={css`flex:0 0 auto;text-align:center;padding:.25em 0;color:${theme.palette.siteTextPrimary};font-size:.8rem;`}>
                                    <IconButton color="primary" disabled={!inventoryState.getProductsState().get().allowFirst} aria-label="first page" onClick={async e => {
                                        searchParams.set("pageno", (1).toString());
                                        setSearchParams(searchParams);
                                        //await inventoryState.getProductsState().firstPageAsync();
                                    }}><FirstPage css={css`width:32px;height:32px;`} /></IconButton>
                                    <IconButton color="primary" disabled={!inventoryState.getProductsState().get().allowPrev} aria-label="previous page" onClick={async e => {
                                        let prevPage = inventoryState.getProductsState().get().pageNo - 1;
                                        if (prevPage < 1) {
                                            prevPage = 1;
                                        }
                                        searchParams.set("pageno", (prevPage).toString());
                                        setSearchParams(searchParams);
                                        //await inventoryState.getProductsState().prevPageAsync();
                                    }}><ChevronLeft css={css`width:32px;height:32px;`} /></IconButton>
                                    {translate('Page')}: <input
                                        type="text"
                                        css={css`width:35px;text-align:center;`}
                                        disabled={!inventoryState.getProductsState().get().allowSetPageNo}
                                        value={inventoryState.getProductsState().get().pageNo}
                                        onChange={async e => {
                                            const value = e.currentTarget.value;
                                            return Promise.resolve().then(() => {
                                                inventoryState.getProductsState().setPageNoAsync(+value, false, false);
                                            });
                                        }}
                                        onKeyDown={async e => {
                                            const value = (e.currentTarget as HTMLInputElement).value;
                                            const key = e.key;
                                            return Promise.resolve().then(() => {
                                                if (key === 'Enter') {
                                                    inventoryState.getProductsState().setPageNoAsync(+value, true, false);
                                                }
                                            });
                                        }}
                                    /> of {totalPages}

                                    <IconButton color="primary" disabled={!inventoryState.getProductsState().get().allowNext} aria-label="next page" onClick={async e => {
                                        let nextPageNo = inventoryState.getProductsState().get().pageNo + 1;
                                        if (nextPageNo > totalPages) {
                                            nextPageNo = totalPages;
                                        }
                                        searchParams.set("pageno", (nextPageNo).toString());
                                        setSearchParams(searchParams);
                                        //await inventoryState.getProductsState().nextPageAsync();
                                    }}><ChevronRight css={css`width:32px;height:32px;`} /></IconButton>
                                    <IconButton color="primary" disabled={!inventoryState.getProductsState().get().allowLast} aria-label="last page" onClick={async e => {
                                        searchParams.set("pageno", (totalPages).toString());
                                        setSearchParams(searchParams);
                                        //await inventoryState.getProductsState().lastPageAsync();
                                    }}><LastPage css={css`width:32px;height:32px;`} /></IconButton>
                                    <span css={css`white-space:nowrap;font-size:.7rem;`}>{(inventoryState.getProductsState().get().data?.TotalItems ?? 0).toLocaleString()} {translate('items')}</span>
                                    {/*Page Size: {pageSize}*/}
                                    <IconButton color="primary" onClick={async e => {
                                        inventoryState.getProductsState().setPageNoAsync(inventoryState.getProductsState().get().pageNo, true, false);
                                    }}><Refresh css={css`width:32px;height:32px;`} /></IconButton>
                                </div>
                            )}
                        </div>
                    </motion.div>
                </div>
            </>}
        />
    </>
}