/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SiteLayout } from "../components/SiteLayout";

export type CheckoutCompletePageProps = {

}
export const CheckoutCompletePage: React.FC<CheckoutCompletePageProps> = props => {
    return (<SiteLayout
        title="Checkout Complete"
        mainChildren={
            <div css={css``}>
                Checkout Complete
            </div>
        }
    />);
}
