/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
//import { Button } from "@mui/material";
//import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
//import { useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
//import { useSiteLayoutState } from "../../../hooks/useSiteLayoutState";
//import /*FwNumberBox,*/ { useFwNumberBoxState } from "../../../lib/FwReact/FwNumberBox";
//import { FwNumberDropDown } from "../../../lib/FwReact/FwNumberDropDown";
import { AddOrUpdateCart } from "../../ShoppingCart/AddOrUpdateCart";

//------------------------------------------------------------------------------------
/* Qty */
export type QtyFieldProps = {
    allowAddingUnavailableItems: boolean;
}
export const QtyField: React.FC<QtyFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    //const shoppingCartItemsState = useShoppingCartItemsState();
    const product = productDetailsState.get().product;
    //const fwNumberBoxState = useFwNumberBoxState();
    //const siteLayoutState = useSiteLayoutState();
    const isAvailable = product && ((product.Availability?.TotalIn ?? 0) > 0);
    const canAddToCart = isAvailable || props.allowAddingUnavailableItems;

    if (product) {

        //const onChangeNumberDropDownQty = async (value: number, oldValue: number) => {
        //    return Promise.resolve().then(() => {
        //        const totalIn = product.Availability?.TotalIn ?? 0;
        //        if (value > totalIn) {
        //            if (!props.allowAddingUnavailableItems) {
        //                siteLayoutState.showTooltip({ id: tooltipId, variant: 'warning', place: 'top', hideDelay: 6000, message: shoppingCartItemsState.translate('Cannot request more than then the total available qty.') })
        //                value = totalIn;
        //            }
        //        }
        //        else if (value < 0) {
        //            value = 0;
        //        }
        //        shoppingCartItemsState.setQty(product!, value);
        //        if (value > shoppingCartQty) {
        //            siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', message: `${shoppingCartItemsState.translate('Added')}: ${value - shoppingCartQty}` })
        //        }
        //        else if (value < shoppingCartQty) {
        //            siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', message: `${shoppingCartItemsState.translate('Removed')}: ${shoppingCartQty - value}` })
        //        }
        //    });
        //};

        //const tooltipId = 'ProductDetailsQtyField-' + product.Id;
        //const shoppingCartQty = shoppingCartItemsState.getQty(product!);
        if (canAddToCart) {
            return (<>
                <AddOrUpdateCart product={product} showQtyInCart={true} />
            </>);
        }
    }
    return null;
}