/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Product } from "../../../hooks/useInventoryState";

export type ProductBrowserGridItemItemNoProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserGridItemItemNo: React.FC<ProductBrowserGridItemItemNoProps> = props => {
    const product = props.product;

    return (<>
        <div className="field icode">
            # {product.InventoryNo}
        </div>
    </>);
}