/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
//import { Chip, MenuItem } from "@mui/material";
import { PackageItem, Product } from "../../../hooks/useInventoryState";
import { useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import FwFunc from "../../../lib/FwReact/FwFunc";
import { ShoppingCartPackageItemAvailability } from "../PackageItemFields/ShoppingCartPackageItemAvailability";
//import { ShoppingCartPackageItemImage } from "../PackageItemFields/ShoppingCartPackageItemImage";
import { ShoppingCartViewSettings } from "../ShoppingCartControl";
//import FwNumberBox, { useFwNumberBoxState } from "../../../../lib/FwReact/FwNumberBox";
//import { FwNumberDropDown } from "../../../../lib/FwReact/FwNumberDropDown";
//import { ShoppingCartViewSettings } from "../../ShoppingCart";
//import { ShoppingCartPackageItemAvailability } from "../PackageItemFields/ShoppingCartPackageItemAvailability";
//import { ShoppingCartPackageItemImage } from "../PackageItemFields/ShoppingCartPackageItemImage";

//------------------------------------------------------------------------------------
export type ShoppingCartPackageItemIncludedDesktopProps = {
    packageProduct: ImmutableObject<Product>;
    packageItem: ImmutableObject<PackageItem>;
    enableQty: boolean;
    viewSettings: ShoppingCartViewSettings;
}
export const ShoppingCartPackageItemIncludedDesktop: React.FC<ShoppingCartPackageItemIncludedDesktopProps> = props => {
    const packageProduct = props.packageProduct;
    const isAvailable = (packageProduct.Availability?.TotalIn ?? 0) > 0;
    const canAddToCart = props.enableQty && (isAvailable || props.viewSettings.allowAddingUnavailableItems);
    const item = props.packageItem;
    //const qtyState = useFwNumberBoxState();
    const shoppingCartItemsState = useShoppingCartItemsState();
   // const showImage = item.Images && (item.Images.length > 0);
    const showAvailability = item.Availability !== undefined;
    const showDailyRate = (props.viewSettings.hasRates && props.viewSettings.hasDailyRate);
    const showWeeklyRate = (props.viewSettings.hasRates && props.viewSettings.hasWeeklyRate);
    const showMonthlyRate = (props.viewSettings.hasRates && props.viewSettings.hasMonthlyRate);

    //const handleChangeQtyAsync = async (value: number): Promise<void> => {
    //    return Promise.resolve().then(() => {
    //        shoppingCartItemsState.setPackageItemQty(props.packageProduct, props.packageItem, value);
    //    });
    //}

    return (
        <tr className="packageitemrow" style={{ /*backgroundColor: item.LineColor*/ }}>
            <td className="col-legend"></td>
            {/*<td className="col-image">*/}
            {/*    {showImage && (*/}
            {/*        <ShoppingCartPackageItemImage product={packageProduct} packageItem={item} />*/}
            {/*    )}*/}
            {/*</td>*/}
            {/*<div className="fld ICode">{item.ICode}</div>*/}
            <td className="col-description">{item.Description}</td>
            {/*<td className="Tags">*/}
            {/*    {item.IsOption && (*/}
            {/*        <Chip label={'optional'} style={{backgroundColor: 'yellow', color: 'black'}}></Chip>*/}
            {/*    )}*/}
            {/*    <Chip label={`x${item.DefaultQuantity}`} style={{ backgroundColor: 'orange', color: 'black' }}></Chip>*/}
            {/*</td>*/}
            {(canAddToCart || showAvailability) && (<>
                <td className="col-qty">
                    {canAddToCart && (
                        <div>{shoppingCartItemsState.getPackageItemQty(props.packageProduct, item)}</div>
                    )}
                </td>
            </>)}
            {showDailyRate && (
                <td className="col-dailyrate">
                    <span className="CurrencySymbol">{item.CurrencySymbol}</span>
                    <span className="Price">{FwFunc.numberFormatter((item.Charge ? item.DailyRate : 0), 2, ',', '.')}</span>
                    {/*<span className="Price">{FwFunc.numberFormatter(item.DailyRate, 2, ',', '.')}</span>*/}
                    {/*<span className="CurrencyCode">{item.CurrencyCode}</span>*/}
                </td>
            )}

            {showWeeklyRate && (
                <td className="col-weeklyrate">
                    <span className="CurrencySymbol">{item.CurrencySymbol}</span>
                    <span className="Price">{FwFunc.numberFormatter((item.Charge ? item.WeeklyRate : 0), 2, ',', '.')}</span>
                    {/*<span className="CurrencyCode">{item.CurrencyCode}</span>*/}
                </td>
            )}

            {showMonthlyRate && (
                <td className="col-monthlyrate">
                    <span className="CurrencySymbol">{item.CurrencySymbol}</span>
                    <span className="Price">{FwFunc.numberFormatter((item.Charge ? item.MonthlyRate : 0), 2, ',', '.')}</span>
                    {/*<span className="CurrencyCode">{item.CurrencyCode}</span>*/}
                </td>
            )}
            {false && showAvailability && (
                <td className="col-availability" css={css`/*display:flex;align-items:center;width:100px;*/`}>
                    <ShoppingCartPackageItemAvailability packageItem={item} />
                </td>
            )}
        </tr>
    );
}