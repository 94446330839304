import { ImmutableArray } from "@hookstate/core";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { WarehouseLocation, useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

export type SelectLocationProps = {
    disabled: boolean;
}
export const SelectLocation: React.FC<SelectLocationProps> = props => {
    const checkoutFormState = useCheckoutFormState();
    const translate = useTranslate();
    //const warehouse = checkoutFormState.getWarehouse();
    //const warehouseId = warehouse?.WarehouseId ?? '';

    //const warehouseLocations: ImmutableArray<WarehouseLocation> = (checkoutFormState.get().lookuplocation ?? []).filter(l => l.WarehouseId === warehouseId);
    const warehouseLocations: ImmutableArray<WarehouseLocation> = (checkoutFormState.get().lookuplocation ?? []);
    const locationCaption = translate('Location');
    let locationid = '';
    //console.log({
    //    warehouseLocations: checkoutFormState.get().lookuplocation
    //})
    if (checkoutFormState.get().locationIdValue && (checkoutFormState.get().lookuplocation?.length ?? 0)) {
        locationid = checkoutFormState.get().locationIdValue ?? '';
    }
    let value = '';
    if (warehouseLocations.length > 0) {
        const currentWarehouse = warehouseLocations.find(o => o.LocationId === locationid);
        if (currentWarehouse) {
            value = locationid;
        }
    }

    return (<>
        <FormControl fullWidth>
            <InputLabel id="location-select-label">{locationCaption}</InputLabel>
            <Select
                //labelId="warehouse-label"
                id="location-select"
                //value={age}
                label={locationCaption}
                //onChange={handleChange}
                disabled={props.disabled}
                fullWidth
                required
                onChange={async e => {
                    const warehouselocationid = e.target.value as string;
                    return Promise.resolve().then(() => {
                        const warehouselocationparts = warehouselocationid.split('~');
                        if (warehouselocationparts.length > 0) {
                            const locationid = warehouselocationparts[0];
                            checkoutFormState.setLocationId(locationid);
                        }
                        if (warehouselocationparts.length > 1) {
                            const warehouseid = warehouselocationparts[1];
                            checkoutFormState.setWarehouseId(warehouseid);
                        }
                    });
                }}
                value={value}
            >
                {warehouseLocations.map((location, index, array) => {
                    let locationName = location.Location;
                    if (location.Warehouse !== location.Location) {
                        locationName += ` (${location.Warehouse})`;
                    }
                    return (<MenuItem key={location.LocationId} value={location.LocationId}>{locationName}</MenuItem>)
                })}
            </Select>
        </FormControl>
    </>)
}