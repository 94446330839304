/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useThemeState } from "../../hooks/useThemeState";
import { useProductDetailsState } from "../../hooks/useProductDetailsState";
import { AnimatePresence, motion } from "framer-motion";
import { ImmutableArray, ImmutableObject, useHookstateEffect } from "@hookstate/core";
import React from "react";
import { Catalog, useCatalogState } from "../../hooks/useCatalogState";
import { ProductDetailsButtonBar } from "./ProductDetailsButtonBar";
import { ImageField } from "./ItemDetailFields/ImageField";
import { DepartmentCategorySubcategoryLinks } from "./DepartmentCategorySubcategoryLinks";
import { ItemNameField } from "./ItemDetailFields/ItemNameField";
import { ItemNoField } from "./ItemDetailFields/ItemNoField";
import { QtyField } from "./ItemDetailFields/QtyField";
import { AvailabilityField } from "./ItemDetailFields/AvailabilityField";
import { TechnicalNotesField } from "./ItemDetailFields/TechnicalNotesField";
import { RatesFields } from "./ItemDetailFields/RatesFields";
import { DetailedDescriptionField } from "./ItemDetailFields/DetailedDescriptionField";
import { BarcodesField } from "./ItemDetailFields/BarcodesField";
import { TagsField } from "./ItemDetailFields/TagsField";
import { ProductDetailsPackageItemsDesktop } from "./PackageItem/Desktop/ProductDetailsPackageItemsDesktop";
import { CustomScrollbars } from "../CustomScrollbars/CustomScrollbars";
import { Product } from "../../hooks/useInventoryState";
import { Inventory2Outlined } from "@mui/icons-material";
import { ManufacturerField } from "./ItemDetailFields/ManufacturerField";
import { ManufacturerPartNumberField } from "./ItemDetailFields/ManufacturerPartNumberField";
import { ManufacturerUrlField } from "./ItemDetailFields/ManufacturerUrlField";
import { ReplacementCostField } from "./ItemDetailFields/ReplacementCostField";
import { ShipWeightField } from "./ItemDetailFields/ShipWeightField";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
export class ProductDetailsViewSettings {
    //hasImage: boolean = false;
    hasDepartmentCategorySubcategoryLinks: boolean = false;
    hasICode: boolean = false;
    //hasDescription: boolean = false;
    hasBarcodes: boolean = false;
    hasTags: boolean = false;
    hasDailyRate: boolean = false;
    hasWeeklyRate: boolean = false;
    hasMonthlyRate: boolean = false;
    hasQty: boolean = false;
    hasRates: boolean = false;
    hasAvailability: boolean = false;
    hasDetailedDescription: boolean = false;
    hasTechnicalNotes: boolean = false;
    allowAddingUnavailableItems: boolean = false;
}
export const getProductDetailsViewSettings = (catalogs?: ImmutableArray<Catalog>, product?: ImmutableObject<Product>): ProductDetailsViewSettings => {
    const viewSettings = new ProductDetailsViewSettings();
    if (catalogs && product) {
        const catalog = catalogs.find(c => c.Id === product.WebCatalogId);
        if (catalog) {
            const itemDetailFieldsByName = catalog.itemDetailFieldsByName;
            if (itemDetailFieldsByName) {
                //viewSttings.hasImage = (shoppingCartFieldsByName['IMAGE'] !== undefined) && (shoppingCartFieldsByName['IMAGE'].Selected);
                viewSettings.hasDepartmentCategorySubcategoryLinks = (itemDetailFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'] !== undefined) && (itemDetailFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'].Selected);
                viewSettings.hasICode = (itemDetailFieldsByName['ICODE'] !== undefined) && (itemDetailFieldsByName['ICODE'].Selected);
                //viewSettings.hasDescription = (shoppingCartFieldsByName['DESCRIPTION'] !== undefined) && (shoppingCartFieldsByName['DESCRIPTION'].Selected);
                viewSettings.hasBarcodes = (itemDetailFieldsByName['BARCODENUMBER'] !== undefined) && (itemDetailFieldsByName['BARCODENUMBER'].Selected);
                viewSettings.hasTags = (itemDetailFieldsByName['TAGS'] !== undefined) && (itemDetailFieldsByName['TAGS'].Selected);
                viewSettings.hasQty = (itemDetailFieldsByName['ITEMQUANTITYINPUT'] !== undefined) && (itemDetailFieldsByName['ITEMQUANTITYINPUT'].Selected);
                viewSettings.hasDailyRate = (itemDetailFieldsByName['DAILYRATE'] !== undefined) && (itemDetailFieldsByName['DAILYRATE'].Selected);
                viewSettings.hasWeeklyRate = (itemDetailFieldsByName['WEEKLYRATE'] !== undefined) && (itemDetailFieldsByName['WEEKLYRATE'].Selected);
                viewSettings.hasMonthlyRate = (itemDetailFieldsByName['MONTHLYRATE'] !== undefined) && (itemDetailFieldsByName['MONTHLYRATE'].Selected);
                viewSettings.hasRates = viewSettings.hasDailyRate || viewSettings.hasWeeklyRate || viewSettings.hasMonthlyRate;
                viewSettings.hasAvailability = (itemDetailFieldsByName['AVAILABILITY'] !== undefined) && (itemDetailFieldsByName['AVAILABILITY'].Selected) && (product.Availability !== undefined);
                viewSettings.hasDetailedDescription = (itemDetailFieldsByName['DETAILEDDESCRIPTION'] !== undefined) && (itemDetailFieldsByName['DETAILEDDESCRIPTION'].Selected);
                viewSettings.hasTechnicalNotes = (itemDetailFieldsByName['TECHNICALNOTES'] !== undefined) && (itemDetailFieldsByName['TECHNICALNOTES'].Selected);
            }
            viewSettings.allowAddingUnavailableItems = catalog.AllowAddingUnavailableItems;
        }
    }
    return viewSettings;
}

type ProductDetailsLoadedProps = {
    printMode: boolean;
}
const ProductDetailsLoaded: React.FC<ProductDetailsLoadedProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();
    const product = productDetailsState.get().product;
    const images = product?.Images ?? [];

    const catalogState = useCatalogState();
    const catalogs = catalogState.get().catalogs;
    const viewSettings = getProductDetailsViewSettings(catalogs, product);
    let hasImage = true;
    let hasDepartmentCategorySubcategoryLinks = false;
    let hasICode = false;
    let hasDescription = true;
    let hasPackageItems = false;
    let hasBarcodes = false;
    let hasTags = false;
    let hasDailyRate = false;
    let hasWeeklyRate = false;
    let hasMonthlyRate = false;
    let hasQty = false;
    let hasRates = false;
    let hasAvailability = false;
    let hasDetailedDescription = false;
    let hasTechnicalNotes = false;
    let allowAddingUnavailableItems = false;
    let hasManufacturer = false;
    let hasManufacturerPartNumber = false;
    let hasManufacturerUrl = false;
    let hasReplacementCost = false;
    let hasShipWeight = false;

    if (catalogs && product) {
        const catalog = catalogs.find(c => c.Id === product.WebCatalogId);
        if (catalog) {
            const itemDetailFieldsByName = catalog.itemDetailFieldsByName;
            if (itemDetailFieldsByName) {
                //hasImageField = (shoppingCartFieldsByName['IMAGE'] !== undefined) && (shoppingCartFieldsByName['IMAGE'].Selected);
                hasDepartmentCategorySubcategoryLinks = (itemDetailFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'] !== undefined) && (itemDetailFieldsByName['DEPARTMENTCATEGORYSUBCATEGORYLINKS'].Selected);
                hasICode = (itemDetailFieldsByName['ICODE'] !== undefined) && (itemDetailFieldsByName['ICODE'].Selected);
                //hasDescription = (shoppingCartFieldsByName['DESCRIPTION'] !== undefined) && (shoppingCartFieldsByName['DESCRIPTION'].Selected);
                hasPackageItems = (product.PackageItems !== undefined) && (product.PackageItems.length > 0);
                hasBarcodes = (itemDetailFieldsByName['BARCODENUMBER'] !== undefined) && (itemDetailFieldsByName['BARCODENUMBER'].Selected);
                hasTags = (itemDetailFieldsByName['TAGS'] !== undefined) && (itemDetailFieldsByName['TAGS'].Selected);
                hasQty = (itemDetailFieldsByName['ITEMQUANTITYINPUT'] !== undefined) && (itemDetailFieldsByName['ITEMQUANTITYINPUT'].Selected);
                hasDailyRate = (itemDetailFieldsByName['DAILYRATE'] !== undefined) && (itemDetailFieldsByName['DAILYRATE'].Selected);
                hasWeeklyRate = (itemDetailFieldsByName['WEEKLYRATE'] !== undefined) && (itemDetailFieldsByName['WEEKLYRATE'].Selected);
                hasMonthlyRate = (itemDetailFieldsByName['MONTHLYRATE'] !== undefined) && (itemDetailFieldsByName['MONTHLYRATE'].Selected);
                hasRates = hasDailyRate || hasWeeklyRate || hasMonthlyRate;
                hasAvailability = (itemDetailFieldsByName['AVAILABILITY'] !== undefined) && (itemDetailFieldsByName['AVAILABILITY'].Selected) && (product.Availability !== undefined);
                hasDetailedDescription = (itemDetailFieldsByName['DETAILEDDESCRIPTION'] !== undefined) && (itemDetailFieldsByName['DETAILEDDESCRIPTION'].Selected);
                hasTechnicalNotes = (itemDetailFieldsByName['TECHNICALNOTES'] !== undefined) && (itemDetailFieldsByName['TECHNICALNOTES'].Selected);

                hasManufacturer = (itemDetailFieldsByName['MANUFACTURER'] !== undefined && itemDetailFieldsByName['MANUFACTURER'].Selected) &&
                    (product.Manufacturer !== undefined && product.Manufacturer.length > 0);
                hasManufacturerPartNumber = (itemDetailFieldsByName['MANUFACTURERPARTNUMBER'] !== undefined && itemDetailFieldsByName['MANUFACTURERPARTNUMBER'].Selected) &&
                    (product.ManufacturerPartNumber !== undefined && product.ManufacturerPartNumber.length > 0);
                hasManufacturerUrl = (itemDetailFieldsByName['MANUFACTURERURL'] !== undefined && itemDetailFieldsByName['MANUFACTURERURL'].Selected) &&
                    (product.ManufacturerUrl !== undefined && product.ManufacturerUrl.length > 0);
                hasReplacementCost = (itemDetailFieldsByName['REPLACEMENTCOST'] !== undefined && itemDetailFieldsByName['REPLACEMENTCOST'].Selected) &&
                    (product.ReplacementCost !== undefined && product.ReplacementCost > 0);
                const isShipWeightAvailable = product.ShipWeightLbs || product.ShipWeightOz;
                if (isShipWeightAvailable) {
                    hasShipWeight = (itemDetailFieldsByName['SHIPWEIGHT'] !== undefined) && (itemDetailFieldsByName['SHIPWEIGHT'].Selected);
                }
            }
            allowAddingUnavailableItems = catalog.AllowAddingUnavailableItems;
        }
    }
    const isDesktop = useMediaQuery('(min-width: 929px');

    return (<>
        <div css={css`
            flex: 1 1 0;
            display:flex;
            justify-content:center;
            @media print {
                .noprint {display:none}
            }
            .field {
                /*display: flex;
                border-bottom: 1px dotted #aaaaaa;*/
                padding: .5em 0em;
            }
            .caption {
                flex: 0 0 6em;
                font-size:1rem;
                font-weight:bold;
                transition: color 1s;
                padding: .5rem 0;
                text-decoration: underline;
            }
            .value {
                padding:0 0 0 .25em;
                font-size:1em;
                flex: 1 1 0;
            }
            .field.availability {
                font-size:.8rem;
                text-align: center;
            }
        ` }>

            {!product && (<>
                <div css={css`
                    flex: 1 1 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: auto;` }>
                    <CircularProgress color="primary" />
                </div>
            </>)}
            {(product) && (
                <div css={css`flex:1 1 0;display:flex;flex-direction:column;`}>
                    <div
                        css={css`
                            flex: 0 0 auto;
                            display:flex;
                        ` }>
                        {(!props.printMode && !isDesktop) && (
                            <ProductDetailsButtonBar printMode={props.printMode} />
                        )}
                    </div>
                    <div css={css`
                        @media screen {
                            flex: 1 1 0;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                        }
                        `}>
                        <div css={css`flex: 0 0 auto;`}>
                            {hasDepartmentCategorySubcategoryLinks && (<DepartmentCategorySubcategoryLinks />)}
                            {hasImage && (<ImageField />) }
                        </div>
                        <div css={css`flex: 1 1 0;`}>
                            {(!props.printMode && isDesktop) && (
                                <div
                                    css={css`
                                        /*background-color: rgba(200,200,200,.4);*/
                                        border-radius: 13px;
                                        margin: 0 0 1em 0;
                                        display:flex;
                                    ` }>
                                    <ProductDetailsButtonBar printMode={props.printMode} />

                                </div>
                            )}
                            <div css={css`display:flex;flex-wrap:wrap;`}>
                                {isDesktop && (<>
                                    <div css={css`flex:1 1 0;padding: 0 1rem;`}>
                                        {(product.PackageItems && (product.PackageItems.length > 0)) && (
                                            <div css={css`background-color:#0d47a1;color:#ffffff;padding:2px 0;font-size:.7rem;z-index:2;display:flex;align-items:center;justify-content:center;`}>
                                                <Inventory2Outlined css={css`font-size:.7rem;margin:0 4px;`} />{translate('package')}
                                            </div>
                                        )}
                                        {hasDescription && (<ItemNameField />)}
                                        {hasICode && (<ItemNoField />)}
                                        {hasPackageItems && <ProductDetailsPackageItemsDesktop viewSettings={viewSettings} />}
                                        {hasDetailedDescription && (<DetailedDescriptionField />)}
                                        {hasTechnicalNotes && (<TechnicalNotesField />)}
                                        {hasBarcodes && (<BarcodesField />)}
                                        {hasTags && (<TagsField />)}
                                        {hasManufacturer && (<ManufacturerField />)}
                                        {hasManufacturerPartNumber && (<ManufacturerPartNumberField />)}
                                        {hasManufacturerUrl && (<ManufacturerUrlField />)}
                                        {hasReplacementCost && (<ReplacementCostField />)}
                                        {hasShipWeight && (<ShipWeightField />)}
                                    </div>
                                    <div
                                        css={css`
                                            flex: ${isDesktop ? '0 0 200px;' : '1 1 0'};
                                            border: 1px solid rgba(200,200,200,.4);
                                            border-radius: 13px;
                                            justify-content: center;
                                            display:none;
                                            @media screen {
                                                display: block;
                                            }
                                        `}>
                                        <div css={css`margin:0 auto;max-width:175px;padding:1rem .25rem .25rem .25rem;`}>
                                            {hasQty && (<QtyField allowAddingUnavailableItems={allowAddingUnavailableItems} />)}
                                        </div>
                                        {hasAvailability && (<AvailabilityField />)}
                                        {hasRates && (<RatesFields />)}
                                    </div>
                                </>)}
                                {(!isDesktop) && (<div css={css`padding: 0 .25rem;`}>
                                    <div css={css`flex:1 1 0;`}>
                                        {(product.PackageItems && (product.PackageItems.length > 0)) && (
                                            <div css={css`background-color:rgb(136, 136, 255);color:#ffffff;padding:2px 0;font-size:.7rem;z-index:2;display:flex;align-items:center;justify-content:center;border-radius:5px;`}>
                                                <span>-</span><Inventory2Outlined css={css`font-size:.7rem;margin:0 4px;`} />{translate('package')}<span>&nbsp;-</span>
                                            </div>
                                        )}
                                        {hasDescription && (<ItemNameField />)}
                                        {hasICode && (<ItemNoField />)}
                                    </div>
                                    <div
                                        css={css`
                                            flex: 0 0 200px;
                                            border: 1px solid rgba(200,200,200,.4);
                                            border-radius: 13px;
                                            justify-content: center;
                                            display:none;
                                            @media screen {
                                                display: block;
                                            }
                                        `}>
                                        <div css={css`margin:0 auto;width:175px;padding:1rem .25rem .25rem .25rem;`}>
                                            {hasQty && (<QtyField allowAddingUnavailableItems={allowAddingUnavailableItems} />)}
                                        </div>
                                        {hasAvailability && (<AvailabilityField />)}
                                        {hasRates && (<RatesFields />)}
                                    </div>
                                    {hasPackageItems && <ProductDetailsPackageItemsDesktop viewSettings={viewSettings} />}
                                    {hasDetailedDescription && (<DetailedDescriptionField />)}
                                    {hasTechnicalNotes && (<TechnicalNotesField />)}
                                    {hasBarcodes && (<BarcodesField />)}
                                    {hasTags && (<TagsField />)}

                                    {hasManufacturer && (<ManufacturerField />)}
                                    {hasManufacturerPartNumber && (<ManufacturerPartNumberField />)}
                                    {hasManufacturerUrl && (<ManufacturerUrlField />)}
                                    {hasReplacementCost && (<ReplacementCostField />)}
                                    {hasShipWeight && (<ShipWeightField />)}
                                </div>)}
                                
                            </div>
                        </div>
                    </div>

                    <div
                        css={css`
                            display:none;
                            @media print {
                                display: flex;
                                flex-direction: column;
                                justify-content:center;
                            }
                            ` }
                    >
                        {images.map((image, index, images) => {
                            if (index > 0) {
                                return (
                                    <div
                                        key={image.Id}
                                        css={css`
                                            display:flex;
                                            align-items:center;
                                            justify-content:center;
                                            flex-wrap: wrap;
                                            `}
                                    >
                                        <img
                                            src={image.Url}
                                            alt=""
                                            style={{
                                                maxWidth: `128px`
                                            }}
                                        />
                                    </div>

                                );
                            }
                            return null;
                        })}
                    </div>

                </div>
            )}
        </div>
    </>)
}
//------------------------------------------------------------------------------------ 
export type ProductDetailsProps = {
    printMode: boolean;
}
export const ProductDetails: React.FC<ProductDetailsProps> = props => {
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;

    useHookstateEffect(() => {
        document.title = product?.ItemName ?? 'Product Details';
    }, []);

    return (<>
        <div
            css={css`
                display:flex;
                flex-align:flex-start;
                justify-content:center;
                flex: 1 1 0;
                padding:1em;`}>
            {(!product) && (<>
                <div
                    css={css`
                        flex:1 1 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;` }>
                    <CircularProgress color="primary" />
                </div>
            </>)}
            {(product) && (<>
                <ProductDetailsLoaded printMode={props.printMode} />
            </>)}
            
        </div>
    </>);
}

export type ProductDetailsPopupProps = {
    layoutId: string;
}
export const ProductDetailsPopup: React.FC<ProductDetailsPopupProps> = props => {
    const productDetailsState = useProductDetailsState();
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    const product = productDetailsState.get().product;

    return (
        <AnimatePresence>
            {(productDetailsState.get().showPopup && product) && (
                <div
                    css={css`
                        position:fixed;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        z-index:101;
                        flex:1;
                        display:flex;
                        `}
                    onClick={async e => {
                        return Promise.resolve().then(() => {
                            if (productDetailsState.get().showPopup) {
                                productDetailsState.setShowPopup(false)
                            }
                        });
                    }}>
                    
                    <motion.div
                        css={css`
                            flex:1;
                            z-index: 2;
                            background-color: ${themeName === 'dark' ? 'rgb(33,33,33)' : '#ffffff'};
                            display:flex;
                        `}
                        layoutId={props.layoutId}
                        transition={{ duration: .3, type: "tween" }}
                        //transition={{ type: "spring", stiffness: 500, damping: 70 }}
                    >
                        <CustomScrollbars overridecss={`flex:1 1 0;display:flex;`}>
                            <div
                                css={css`
                                    flex:1 1 0;
                                    display:flex;
                                    flex-direction:column;`}
                                onClick={async e => {
                                    e.stopPropagation()
                                }}>
                                <ProductDetails key={productDetailsState.get().product?.Id ?? "0"} printMode={false} />
                            </div>
                        </CustomScrollbars>
                    </motion.div>

                </div>
            )}
        </AnimatePresence>
    )
}
//------------------------------------------------------------------------------------