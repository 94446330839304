import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import axios, { AxiosRequestConfig } from "axios";
import { devtools } from '@hookstate/devtools';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { SiteLayoutState, useSiteLayoutState } from './useSiteLayoutState';
import { Product } from './useInventoryState';
import { SessionState, useSessionState } from './useSessionState';

export type CatalogField = {
    Value: string;
    Text: string;
    Selected: boolean;
}

export type Catalog = {
    Id: string;
    Name: string;
    UrlName: string;
    DealId?: string;
    DepartmentId: string;
    LocationId: string;
    WarehouseId: string;
    Images: string;
    LandingPageHtml?: string;
    AllowAddingUnavailableItems: boolean;
    GridFields?: CatalogField[];
    ListFields?: CatalogField[];
    DetailFields?: CatalogField[];
    ShoppingCartFields?: CatalogField[];
    HasRental?: boolean;
    HasSales?: boolean;

    gridFieldsByName?: { [fieldName: string]: ImmutableObject<CatalogField> | CatalogField };
    listFieldsByName?: { [fieldName: string]: ImmutableObject<CatalogField> | CatalogField }
    itemDetailFieldsByName?: { [fieldName: string]: ImmutableObject<CatalogField> | CatalogField }
    shoppingCartFieldsByName?: { [fieldName: string]: ImmutableObject<CatalogField> | CatalogField }
}

export class CatalogModel {
    catalogs?: Catalog[];
    stopLoadingCatalogs: boolean = false;
    isLoadingCatalogs: boolean = false;
    selectedCatalog?: Catalog;
    expires: Date = new Date();
}

const catalogStateStore = hookstate<CatalogModel>(new CatalogModel(), devtools({ key: 'CatalogState' }));

async function waitUntilAsync(condition: () => boolean, time: number = 100) {
    while (!condition()) {
        await new Promise((resolve) => setTimeout(resolve, time));
    }
}

export class CatalogState {
    private catalogState: State<CatalogModel>;
    private appConfigState: AppConfigState;
    private siteLayoutState: SiteLayoutState;
    private sessionState: SessionState;

    constructor(catalogState: State<CatalogModel>, appConfigState: AppConfigState, siteLayoutState: SiteLayoutState, sessionState: SessionState) {
        this.catalogState = catalogState;
        this.appConfigState = appConfigState;
        this.siteLayoutState = siteLayoutState;
        this.sessionState = sessionState;
    }

    get(): ImmutableObject<CatalogModel> {
        return this.catalogState.get();
    }

    getExpirationSeconds(): number {
        //return 5;
        return 10 * 60; //10 minutes
    }

    isExpired(): boolean {
        return this.catalogState.get().expires <= new Date();
    }

    async loadAsync() {
        try {
            if (this.appConfigState.get().appConfig &&
                /*!this.catalogState.get().catalogs*/
                this.isExpired() &&
                !this.catalogState.get().isLoadingCatalogs &&
                !this.catalogState.get().stopLoadingCatalogs) {
                this.catalogState.isLoadingCatalogs.set(true);
                const axiosRequestConfig: AxiosRequestConfig = {};
                if (this.sessionState.get().loggedIn) {
                    axiosRequestConfig.headers = {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                }
                const getCatalogsResponse = await axios.get<Catalog[]>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/catalog`, axiosRequestConfig);
                const catalogs = getCatalogsResponse.data;
                for (let i = 0; i < catalogs.length; i++) {
                    const catalog = catalogs[i];
                    CatalogState.addFieldIndexesToCatalog(catalog);
                }

                this.catalogState.catalogs.set(catalogs);
                if (getCatalogsResponse.data) {
                    this.catalogState.merge({
                        selectedCatalog: getCatalogsResponse.data[0],
                        expires: new Date(Date.now() + this.getExpirationSeconds() * 1000)
                    });
                }
            }
            else if (this.catalogState.get().isLoadingCatalogs) {
                await waitUntilAsync(() => !this.catalogState.get().isLoadingCatalogs);
            }

        }
        catch (error) {
            this.catalogState.stopLoadingCatalogs.set(true);
            this.siteLayoutState.showError(error);
        }
        finally {
            if (this.catalogState.get().isLoadingCatalogs) {
                this.catalogState.isLoadingCatalogs.set(false);
            }
        }
    }

    static async addFieldIndexesToCatalog(catalog: Catalog) {
        // index the dynamic fields so there's a quick way to lookup which fields are enabled
        const gridFieldsByName: { [key: string]: ImmutableObject<CatalogField> | CatalogField } = {};
        const listFieldsByName: { [key: string]: ImmutableObject<CatalogField> | CatalogField } = {};
        const itemDetailFieldsByName: { [key: string]: ImmutableObject<CatalogField> | CatalogField } = {};
        const shoppingCartFieldsByName: { [key: string]: ImmutableObject<CatalogField> | CatalogField } = {};
        if (catalog) {
            // index the grid fields
            const gridFields = catalog.GridFields;
            if (gridFields !== undefined) {
                for (let i = 0; i < gridFields.length; i++) {
                    const gridField = gridFields[i];
                    if (gridField !== undefined) {
                        gridFieldsByName[gridField.Value] = gridField;
                    }
                }
            }
            catalog.gridFieldsByName = gridFieldsByName;

            // index the list fields
            const listFields = catalog.ListFields;
            if (listFields !== undefined) {
                for (let i = 0; i < listFields.length; i++) {
                    const listField = listFields[i];
                    if (listField !== undefined) {
                        listFieldsByName[listField.Value] = listField;
                    }
                }
            }
            catalog.listFieldsByName = listFieldsByName;

            // index the item detail fields
            const itemDetailsFields = catalog.DetailFields;
            if (itemDetailsFields !== undefined) {
                for (let i = 0; i < itemDetailsFields.length; i++) {
                    const itemDetailField = itemDetailsFields[i];
                    if (itemDetailField !== undefined) {
                        itemDetailFieldsByName[itemDetailField.Value] = itemDetailField;
                    }
                }
            }
            catalog.itemDetailFieldsByName = itemDetailFieldsByName;

            // index the shopping cart fields
            const shoppingCartFields = catalog.ShoppingCartFields;
            if (shoppingCartFields !== undefined) {
                for (let i = 0; i < shoppingCartFields.length; i++) {
                    const shoppingCartField = shoppingCartFields[i];
                    if (shoppingCartField !== undefined) {
                        shoppingCartFieldsByName[shoppingCartField.Value] = shoppingCartField;
                    }
                }
            }
            catalog.shoppingCartFieldsByName = shoppingCartFieldsByName;
        }
    }

    getProductCatalog(product: ImmutableObject<Product>): ImmutableObject<Catalog> | undefined {
        const catalogs = this.catalogState.get().catalogs;
        if (catalogs) {
            const catalog = catalogs.find(c => c.Id === product.WebCatalogId);
            if (catalog) {
                return catalog;
            }
        }
        return undefined;
    }

    getCatalogById(catalogid: string | undefined): ImmutableObject<Catalog> | undefined {
        if (catalogid === undefined) return undefined;
        const catalogs = this.catalogState.get().catalogs;
        if (catalogs) {
            const catalog = catalogs.find(c => c.Id === catalogid);
            if (catalog) {
                return catalog;
            }
        }
        return undefined;
    }
}

export function useCatalogState(): CatalogState {
    const catalogState = useHookstate(catalogStateStore);
    const appConfigState = useAppConfigState();
    const siteLayoutState = useSiteLayoutState();
    const sessionState = useSessionState();
    return new CatalogState(catalogState, appConfigState, siteLayoutState, sessionState);
}
