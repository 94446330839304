/** @jsxImportSource @emotion/react */
import { css, /*Interpolation, Theme*/ } from "@emotion/react"
import { IMaskInput } from 'react-imask';
import { OutlinedInput } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import React from "react";


type PhoneNumberTextMaskProps = {
    onChange: (event: { currentTarget: { name: string; value: string } }) => void;
    name: string;
};

const PhoneNumberTextMask = React.forwardRef<HTMLInputElement, PhoneNumberTextMaskProps>((props, ref) => {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ currentTarget: { name: props.name, value } })}
                overwrite
            />
        );
    },
);


type FwPhoneNumberBoxProps = {
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    label: string;
    required?: boolean;
}

const FwPhoneNumberBox: React.FC<FwPhoneNumberBoxProps> = props => {
    return (
        <div className="MuiTextField-root"
            css={css`
                margin: .5rem 0 0 0;
                .MuiOutlinedInput-notchedOutline legend {
                    font-family: "Arial";
                    font-size: 14px;
                }`}>
            <FormControl fullWidth>
                <InputLabel id="phone-label">{props.label} {props.required ? ' *' : ''}</InputLabel>
                <OutlinedInput
                    label={props.label}
                    margin="dense"
                    type="phone"
                    required={props.required ?? false }
                    value={props.value}
                    onChange={props.onChange}
                    name="textmask"
                    inputComponent={PhoneNumberTextMask as any}
                />
            </FormControl>
        </div>
    );
}

export default FwPhoneNumberBox;