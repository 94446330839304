import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import { FwQueryBrowserController, FwQueryBrowserState, FwQueryFilter, useFwQueryBrowserState } from "../lib/FwReact/FwQuery";
import axios, { AxiosRequestConfig } from "axios";
import { LocaleState, useLocaleState } from "./useLocaleState";
import { Catalog, CatalogState, useCatalogState } from "./useCatalogState";
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { devtools } from '@hookstate/devtools';
import { WarehouseLocation, useCheckoutFormState, Warehouse } from './useCheckoutFormState';
import { SiteLayoutState, useSiteLayoutState } from './useSiteLayoutState';
import { SessionState, useSessionState } from './useSessionState';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export type ProductCategoriesTree = {
    Departments?: ProductDepartment[];
}

export type ProductDepartment = {
    Id: string;
    Name: string;
    UrlName?: string;
    //Count?: number;
    Categories?: ProductCategory[];
    hasExpander?: boolean;
}

export type ProductCategory = {
    Id: string;
    Name: string;
    UrlName: string;
    LinkUrl: string;
    //Count: number;
    SubCategories?: ProductSubCategory[];
}

export type ProductSubCategory = {
    Id: string;
    Name: string;
    UrlName: string;
    LinkUrl: string;
    //Count: number;
}

export class ProductAvailability {
    TotalIn: number = 0;
    Total: number = 0;
}

export type Product = {
    Id: string;
    WebCatalogId?: string;
    WebCatalog?: string;
    InventoryDepartmentId?: string;
    InventoryDepartment?: string;
    CategoryId?: string;
    Category?: string;
    SubCategoryId?: string;
    SubCategory?: string;
    InventoryId: string;
    InventoryNo: string;
    ItemName?: string;
    ItemUrlName?: string;
    Images?: ProductImage[];
    DetailedDescription?: string;
    TechnicalNotes?: string;
    Keywords?: string;
    Tags?: string;
    Barcodes?: string;

    // details
    Availability?: ProductAvailability;
    DailyRate: number;
    WeeklyRate: number;
    MonthlyRate: number;
    SalesPrice: number;
    CurrencySymbol: string;
    CurrencyCode: string;
    Manufacturer: string;
    ManufacturerPartNumber: string;
    ManufacturerUrl: string;
    UnitValue: number;
    ReplacementCost: number;
    ShipWeightLbs: number;
    ShipWeightOz: number;

    PackageItems?: PackageItem[] 
}

export type PackageItem = {
    Images?: ProductImage[];
    Charge: boolean;
    DefaultQuantity: number;
    DefaultColor: string;
    Description: string;
    ICode: string;
    //ICodeColor: string;
    InventoryId: string;
    InventoryPackageInventoryId: string;
    IsOption: boolean;
    IsPrimary: boolean;
    IsRequired: boolean;
    //LineColor: string;
    NoChargePrint: false;
    ProductId: number;
    OptionColor: string;
    ItemColor: string;
    DailyRate: number;
    WeeklyRate: number;
    MonthlyRate: number;
    SalesPrice: number;
    CurrencyId: string;
    CurrencyCode: string;
    CurrencySymbol: string;
    Availability?: ProductAvailability;
}

export type ProductImage = {
    Id: string
    Url: string
    //Thumbnail: string
    //Image: string
}

export type ProductsFilter = {
    catalog?: Catalog | ImmutableObject<Catalog>
    department?: ProductDepartment
    category?: ProductCategory
    subCategory?: ProductSubCategory
    searchText?: string;
    warehouse?: Warehouse;
    location?: WarehouseLocation;
    showLandingPage: boolean;
}

export class InventoryModel {
    productCategoriesTree?: ProductCategoriesTree;

    catalog?: Catalog | ImmutableObject<Catalog>;
    department?: ProductDepartment;
    category?: ProductCategory;
    subCategory?: ProductSubCategory;
    searchText?: string;
    location?: WarehouseLocation | ImmutableObject<WarehouseLocation>;
    warehouse?: Warehouse | ImmutableObject<Warehouse>;

    stopLoadingCategoryTree: boolean = false;
    loadingCategoryTree: boolean = false;
    loadingCategoryTreeAbortController?: AbortController;

    stopLoadingCatalog: boolean = false;
    loadingCatalog: boolean = false;
    loadingCatalogId?: string;
    loadingCatalogAbortController?: AbortController;

    showLandingPage: boolean = true;
}

// global hookstates
const inventoryPageStateGlobalStore = hookstate<InventoryModel>(new InventoryModel(), devtools({ key: 'InventoryPageState' }));
const productBrowserStateGlobalStore = hookstate<FwQueryBrowserState<Product>>(new FwQueryBrowserState<Product>(), devtools({ key: 'ProductBrowserState' }));

export class InventoryState {
    private appConfigState: AppConfigState;
    private localeState: LocaleState
    private catalogState: CatalogState
    private inventoryState: State<InventoryModel>;
    private productsQueryState: FwQueryBrowserController<Product>;
    private siteLayoutState: SiteLayoutState;
    private sessionState: SessionState;
    private navigate: NavigateFunction;

    constructor(appConfigState: AppConfigState, localeState: LocaleState, catalogState: CatalogState, inventoryState: State<InventoryModel>, productsQueryState: FwQueryBrowserController<Product>, siteLayoutState: SiteLayoutState, sessionState: SessionState, navigate: NavigateFunction) {
        this.appConfigState = appConfigState;
        this.localeState = localeState;
        this.catalogState = catalogState;
        this.inventoryState = inventoryState;
        this.productsQueryState = productsQueryState;
        this.siteLayoutState = siteLayoutState;
        this.sessionState = sessionState;
        this.navigate = navigate;
    }

    get(): ImmutableObject<InventoryModel> {
        return this.inventoryState.get();
    }

    getProductsState(): FwQueryBrowserController<Product> {
        return this.productsQueryState;
    }

    async clearProductsAsync() {
        const newProductCategoriesTreeState: Partial<ProductCategoriesTree> = {};
        newProductCategoriesTreeState.Departments = undefined;
        this.inventoryState.productCategoriesTree.merge(newProductCategoriesTreeState);
        await this.productsQueryState.clearAsync();
    }

    async loadProductCategoryTreeAsync(catalogid: string): Promise<void> {
        if (this.appConfigState.get().appConfig && !this.inventoryState.get().loadingCategoryTree && !this.inventoryState.get().stopLoadingCategoryTree) {
            try {
                this.inventoryState.loadingCategoryTree.set(true);
                const axiosRequestConfig: AxiosRequestConfig = {};
                if (this.sessionState.get().loggedIn) {
                    axiosRequestConfig.headers = {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                }
                //this.inventoryState.loadingCategoryTreeAbortController.set(abortController);
                //const abortController = new AbortController();
                //axiosRequestConfig.signal = abortController.signal;
                const axiosCategoryTreeResponse = await axios.get<ProductCategoriesTree>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/catalog/${catalogid}/categorytree`, axiosRequestConfig);
                this.inventoryState.productCategoriesTree.set(axiosCategoryTreeResponse.data);
            }
            catch (error) {
                this.inventoryState.stopLoadingCategoryTree.set(true);
                this.siteLayoutState.handleErrorAsync(error);
            }
            finally {
                this.inventoryState.loadingCategoryTree.set(false);
            }
        }
    }

    

    async getCatalogAsync(catalogid: string): Promise<Catalog | undefined> {
        if (this.appConfigState.get().appConfig && !this.inventoryState.get().loadingCatalog && !this.inventoryState.get().stopLoadingCatalog) {
            try {
                this.inventoryState.loadingCatalog.set(true);
                const axiosRequestConfig: AxiosRequestConfig = {};
                if (this.sessionState.get().loggedIn) {
                    axiosRequestConfig.headers = {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                }
                const axiosCatalogResponse = await axios.get<Catalog>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/catalog/${catalogid}`, axiosRequestConfig);
                const catalog = axiosCatalogResponse.data;
                CatalogState.addFieldIndexesToCatalog(catalog);
                return catalog;
            }
            catch (error) {
                this.inventoryState.stopLoadingCatalog.set(true);
                this.siteLayoutState.handleErrorAsync(error);
            }
            finally {
                this.inventoryState.loadingCatalog.set(false);
            }
            
        }
        return;
    }

    async getCatalogByIdAsync(catalogId: string): Promise<Catalog | ImmutableObject<Catalog> | undefined> {
        //if (!this.catalogState.get().catalogs) {
        //    await this.catalogState.loadAsync();
        //}
        const catalogs = this.catalogState.get().catalogs ?? [];
        if (catalogs) {
            for (let i = 0; i < (catalogs.length ?? 0); i++) {
                const catalog = catalogs[i];
                if (catalog.Id === catalogId) {
                    return catalog;
                }
            }       
        }
        return;
    }

    async getDepartmentByIdAsync(catalogId: string, departmentId: string): Promise<ProductDepartment | undefined> {
        if (!this.inventoryState.get().productCategoriesTree) {
            await this.loadProductCategoryTreeAsync(catalogId);
        }
        if (this.inventoryState.get().productCategoriesTree) {
            const departments = (this.inventoryState.get().productCategoriesTree?.Departments ?? []);
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.Id === departmentId) {
                    return department as ProductDepartment;
                }
            }       
        }
        return;
    }

    async getCategoryByIdAsync(catalogId: string, departmentId: string, categoryId: string): Promise<ProductCategory | undefined> {
        if (!this.inventoryState.get().productCategoriesTree) {
            await this.loadProductCategoryTreeAsync(catalogId);
        }
        if (this.inventoryState.get().productCategoriesTree) {
            const departments = (this.inventoryState.get().productCategoriesTree?.Departments ?? []);
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.Categories) {
                    for (let j = 0; j < department.Categories.length; j++) {
                        const category = department.Categories[j];
                        if (category.Id === categoryId) {
                            return category as ProductCategory;
                        }
                    }
                }
            }       
        }
        return;
    }

    async getSubCategoryByIdAsync(catalogId: string, departmentId: string, categoryId: string, subCategoryId: string): Promise<ProductSubCategory | undefined> {
        if (!this.inventoryState.get().productCategoriesTree) {
            await this.loadProductCategoryTreeAsync(catalogId);
        }
        if (this.inventoryState.get().productCategoriesTree) {
            const departments = (this.inventoryState.get().productCategoriesTree?.Departments ?? []);
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.Categories) {
                    for (let j = 0; j < department.Categories.length; j++) {
                        const category = department.Categories[j];
                        if (category.SubCategories) {
                            for (let k = 0; k < category.SubCategories.length; k++) {
                                const subCategory = category.SubCategories[k];
                                if (subCategory.Id === subCategoryId) {
                                    return subCategory as ProductSubCategory;
                                }
                            }
                        }
                    }
                }
            }       
        }
        return;
    }

    // function: filters the catalog by catalogid
    async filterByCatalogIdAsync(warehouse: Warehouse | undefined, location: WarehouseLocation | undefined, catalogId: string, showLandingPage: boolean): Promise<void> {
        //try {
            let catalog: Catalog | ImmutableObject<Catalog> | undefined;
            catalog = {
                Id: catalogId,
                Name: 'Loading...',
                UrlName: '',
                Images: '',
                DepartmentId: '',
                LocationId: '',
                WarehouseId: '',
                AllowAddingUnavailableItems: false
            };
            if (catalogId) {
                if (!this.inventoryState.get().catalog || ((this.inventoryState.get().catalog?.Id ?? '') === '') || ((this.inventoryState.get().catalog?.Id ?? '') !== catalogId)) {
                    catalog = await this.getCatalogAsync(catalogId);
                    //this.inventoryState.catalog.set(catalog);
                } else {
                    catalog = this.inventoryState.get().catalog;
                }
            }
            await this.setProductFilterAsync({
                warehouse: warehouse,
                location: location,
                catalog: catalog,
                department: undefined,
                category: undefined,
                subCategory: undefined,
                searchText: '', // clear the searchText
                showLandingPage: showLandingPage
            });
        //} catch (error) {
        //   this.siteLayoutState.showError(error);
        //}
    }

    // function: filters the catalog by catalogid
    //async filterByCatalogAsync(warehouse: Warehouse, location: WarehouseLocation, catalog: Catalog, showLandingPage: boolean): Promise<void> {
    //    try {
    //        //let catalog: Catalog | undefined;
    //        //if (catalogId) {
    //        //    let result = await this.getCatalogAsync(catalogId);
    //        //    //console.log(result);
    //        //    catalog = result
    //        //}
    //        this.inventoryState.catalog.set(catalog);
    //        await this.setProductFilterAsync({
    //            warehouse: warehouse,
    //            location: location,
    //            catalog: catalog,
    //            department: undefined,
    //            category: undefined,
    //            subCategory: undefined,
    //            searchText: '', // clear the searchText
    //            showLandingPage: showLandingPage
    //        });
    //    } catch (error) {
    //        this.siteLayoutState.showError(error);
    //    }
    //}

    async clearCatalogFilterAsync(catalogid?: string) {
        let newInventoryState: Partial<InventoryModel> = {};
        newInventoryState.catalog = undefined;
        if (catalogid !== undefined) {
            const catalogs = this.catalogState.get().catalogs;
            if (catalogs !== undefined && catalogs.length > 0) {
                const catalog = catalogs.find(c => c.Id === catalogid);
                if (catalog !== undefined) {
                    newInventoryState.catalog = catalog;
                }
            }
        }
        newInventoryState.showLandingPage = true;
        this.inventoryState.merge(newInventoryState);

        await this.productsQueryState.clearAsync();
    }

    selectDefaultCatalog() {
        const catalogs = this.catalogState.get().catalogs;
        if (catalogs !== undefined && catalogs.length > 0) {
            this.navigate(`/inventory/${catalogs[0].Id}`);
        }
    }

    async searchAsync(catalogId: string, searchText: string, pageNo: number) {
        //try {
            const filters: FwQueryFilter[] = []
            if (searchText && searchText.length > 0) {
                filters.push({ Field: 'Keywords', Op: "contains", Value: searchText });
            }
            await this.productsQueryState.setFiltersAsync(filters);
            await this.productsQueryState.setPageNoAsync(pageNo, false, true);
            let catalog = this.inventoryState.get().catalog;
            if (!catalog) {
                await this.filterByCatalogIdAsync(undefined, undefined, catalogId, false);
            }
            if (catalog) {
                this.inventoryState.merge({
                    searchText: searchText,
                    department: undefined,
                    category: undefined,
                    subCategory: undefined,
                    showLandingPage: false
                });
                await this.productsQueryState.loadAsync();
            }
            //else {
            //    enqueueSnackbar(this.translate('Catalog is required.'), { variant: 'error' });
            //}
       // }
        //catch (error) {
        //    this.siteLayoutState.handleErrorAsync(error);
        //}
    }

    //async filterByDepartmentAsync(catalog: Catalog, department: ProductDepartment) {

    //}

    //async filterByCategoryAsync(catalog: Catalog, department: ProductDepartment, category: ProductCategory) {

    //}

    //async filterBySubCategoryAsync(catalog: Catalog, department: ProductDepartment, category: ProductCategory, subCategory: ProductSubCategory) {

    //}

    // setProductFilterAsync
    async setProductFilterAsync(options: ProductsFilter): Promise<void> {
        let newInventoryState: Partial<InventoryModel> = {};
        // filter by catalog
        //console.log('filter', options);
        const catalogidChanged = (this.inventoryState.get().catalog?.Id) !== (options.catalog?.Id);
        const departmentidChanged = (this.inventoryState.get().department?.Id) !== (options.department?.Id);
        const categoryidChanged = (this.inventoryState.get().category?.Id) !== (options.category?.Id);
        const subcategoryidChanged = (this.inventoryState.get().subCategory?.Id) !== (options.subCategory?.Id);
        const searchtextChanged = (this.inventoryState.get()?.searchText) !== (options.searchText);
        const warehouseidChanged = (this.inventoryState.get().warehouse?.WarehouseId) !== (options.warehouse?.WarehouseId);
        const locationidChanged = (this.inventoryState.get().location?.LocationId) !== (options.location?.LocationId);
        const showLandingPageChanged = (this.inventoryState.get()?.showLandingPage) !== options.showLandingPage;
        const filterChanged = catalogidChanged || departmentidChanged || categoryidChanged || subcategoryidChanged || searchtextChanged || warehouseidChanged || locationidChanged || showLandingPageChanged;
        const hasCategory = options.category !== undefined;
        const hasSubCategories = hasCategory &&
            (options.category?.SubCategories !== undefined) &&
            ((options.category?.SubCategories.length ?? 0) > 0);
        const isLoadingCatalog = (options.catalog?.Id !== undefined) && (options.department?.Id === undefined);
        const isLoadingDepartment = (options.department?.Id !== undefined) && (options.category?.Id === undefined);
        const isLoadingCategoryWithSubCategories = hasSubCategories && options.subCategory === undefined;

        //console.log({
        //    warehouseidChanged: warehouseidChanged,
        //    locationidChanged: locationidChanged,
        //    filterChanged: filterChanged,
        //    showLandingPage: options.showLandingPage
        //})
        if (showLandingPageChanged) {
            newInventoryState.showLandingPage = options.showLandingPage;
        }

        if (options.catalog && filterChanged) {
            document.title = options.catalog.Name;

            // if catalog changed then reload the category tree
            if (!this.inventoryState.get().catalog || (this.inventoryState.get().catalog?.Id !== options.catalog.Id)) {
                //newInventoryState.productCategoriesTree = await RentalWorksRepo.getProductCategoryTreeAsync(options.catalog.Id);
                //await this.loadProductCategoryTreeAsync(options.catalog.Id);
            }

            // if the catalog record is not loaded, then load it from the id
            if (!options.catalog.Name) {
                // ajax for the catalog name
                newInventoryState.catalog = await this.getCatalogAsync(options.catalog.Id);
            } else {
                newInventoryState.catalog = options.catalog;
            }

            newInventoryState.department = undefined;
            newInventoryState.category = undefined;
            newInventoryState.subCategory = undefined;
            //newInventoryState.searchText = undefined;
            //this.productBrowserState.filters.push({ Field: 'CatalogId', Op: "=", Value: this.catalog.Id });
            await this.productsQueryState.setPageNoAsync(1, false, true);


            const filters: FwQueryFilter[] = []

            // filter by department
            if (options.department) {
                newInventoryState.department = options.department;
                newInventoryState.category = undefined;
                newInventoryState.subCategory = undefined;
                //newInventoryState.searchText = undefined;
                //filters.push({ Field: 'CatalogId', Op: "=", Value: inventoryPageState2.get().catalog?.Id ?? '' });

                if (options.category) {
                    newInventoryState.category = options.category;
                    newInventoryState.subCategory = undefined;
                    //newInventoryState.searchText = undefined;
                    //filters.push({ Field: 'Category', Op: "=", Value: inventoryPageState2.get().catalog?.Id ?? '' });
                    if (options.subCategory) {
                        newInventoryState.subCategory = options.subCategory;
                        //newInventoryState.searchText = undefined;
                        //filters.push({ Field: 'SubCategory', Op: "=", Value: inventoryPageState2.get().catalog?.Id ?? '' });
                    }
                }
            }
            if (typeof options.searchText === 'string') {
                //console.log('SearchText:', options.searchText)
                newInventoryState.searchText = options.searchText;
                if (options.searchText.length > 0) {
                    filters.push({ Field: 'Keywords', Op: "contains", Value: options.searchText });
                }
            }
            else if (this.inventoryState.get()?.searchText) {
                filters.push({ Field: 'Keywords', Op: "contains", Value: (this.inventoryState.get()?.searchText ?? '') });
            }
            if (options.warehouse && warehouseidChanged) {
                newInventoryState.warehouse = options.warehouse;
            }
            if (options.location && locationidChanged) {
                newInventoryState.location = options.location;
            }
            if (Object.keys(newInventoryState)) {
                this.inventoryState.merge(newInventoryState);
            }
            await this.productsQueryState.setFiltersAsync(filters);
            if (!options.showLandingPage) {
                //console.log('load products');
                if ((!isLoadingCatalog) && (!isLoadingDepartment) && (!isLoadingCategoryWithSubCategories)) {
                    await this.productsQueryState.loadAsync();
                }
            }
        }
    }

    // setProductFilterAsync
    async setShowLandingPageAsync(value: boolean): Promise<void> {
        this.inventoryState.showLandingPage.set(value);
    }
}

export function useInventoryState(): InventoryState {
    const appConfigState = useAppConfigState();
    const localeState = useLocaleState();
    const catalogState = useCatalogState();
    let inventoryState: State<InventoryModel>;
    inventoryState = useHookstate<InventoryModel>(inventoryPageStateGlobalStore);
    const checkoutFormState = useCheckoutFormState();
    const siteLayoutState = useSiteLayoutState();
    const sessionState = useSessionState();
    const navigate = useNavigate();

    const productBrowserState = useFwQueryBrowserState<Product>(
        productBrowserStateGlobalStore,
        // getUrlAsync
        async () => {
            const catalogid = inventoryState.get().catalog?.Id;
            if (catalogid) {
                //let baseUrl = window.location.href.substring(0, window.location.href.indexOf('/storefront'));
                if (appConfigState.get().appConfig) {
                    const locationid = checkoutFormState.get().locationIdValue;
                    const warehouseid = checkoutFormState.get().warehouseIdValue;
                    const fromdate = checkoutFormState.get().fromDateValue;
                    const todate = checkoutFormState.get().toDateValue;
                    let url1 = `${appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/catalog/${catalogid}/products/warehouseid/${warehouseid}`;
                    if (locationid && warehouseid && fromdate && fromdate !== "Invalid Date" && todate && todate !== "Invalid Date") {
                        url1 = `${appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/catalog/${catalogid}/products/locationid/${locationid}/warehouseid/${warehouseid}/fromdate/${fromdate}/todate/${todate}`;
                    }
                    const url = new URL(url1);
                    if (inventoryState.get().department) {
                        const departmentid = inventoryState.get().department?.Id;
                        const filter: string[] = []
                        if (departmentid) {
                            filter.push(`{Field:"InventoryDepartmentId",Op:"=",Value:"${departmentid}"}`);
                            if (inventoryState.get().category) {
                                const categoryid = inventoryState.get().category?.Id;
                                if (categoryid) {
                                    filter.push(`{Field:"CategoryId",Op:"=",Value:"${categoryid}"}`);
                                    if (inventoryState.get().subCategory) {
                                        const subcategoryid = inventoryState.get().subCategory?.Id;
                                        if (subcategoryid) {
                                            filter.push(`{Field:"SubCategoryId",Op:"=",Value:"${subcategoryid}"}`);
                                        }
                                    }
                                }
                            }
                        }
                        if (filter.length) {
                            url.searchParams.set('filter', `[${filter.join(',')}]`);
                        }
                    }
                    //url.searchParams.set('sort', 'OrderBy');
                    return url.href;
                }
                return;
            } else {
                return;
            }
        }
    );

    return new InventoryState(appConfigState, localeState, catalogState, inventoryState, productBrowserState,
        siteLayoutState, sessionState, navigate);
}
