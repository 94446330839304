/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Link } from "react-router-dom";
import { Catalog } from "../../../hooks/useCatalogState";
//import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";
import { ProductCategory, ProductDepartment, ProductSubCategory, useInventoryState } from "../../../hooks/useInventoryState";
import { useThemeState } from "../../../hooks/useThemeState";
import { FilterAlt } from "@mui/icons-material";

//-------------------------------------------------------------------------------------------------------
export type SubCategoryControlProps = {
    catalog: Catalog;
    department: ProductDepartment;
    category: ProductCategory;
    subCategory: ProductSubCategory;
    isParentFilterActive: boolean;
}
export const SubCategoryControl: React.FC<SubCategoryControlProps> = props => {
    const theme = useThemeState().get().theme;
    const inventoryState = useInventoryState();
    //const subcategory = inventoryState.get().subCategory;
    //const isFilterActive = (subcategory !== undefined) && (props.subCategory.Id === subcategory.Id);
    let url = `/inventory/${props.catalog.Id}/${props.department.Id}/${props.category.Id}/${props.subCategory.Id}`;
    //if (isFilterActive) {
    //    url = `/inventory/${props.catalog.Id}/${props.department.Id}/${props.category.Id}`;
    //}
    //const checkoutFormState = useCheckoutFormState();
    //const warehouse = checkoutFormState.getWarehouse();

    return (
        <div key={props.subCategory.Id} css={css`display:flex;flex-wrap:wrap;align-items:center;margin:.25em 0 0 14px;padding: 0 0 0 8px;border-left: 1px dotted #aaaaaa;`}>
            <div css={css`
                flex:1 1 0;
                font-size:.8rem;
                cursor:pointer;
                color: ${(inventoryState.get().subCategory?.Id === props.subCategory.Id) ? theme.palette.siteSideBarContrastText : theme.palette.siteSideBarText};
                &:hover {
                    text-decoration: underline;
                }`}
                //onClick={async () => {
                    //return Promise.resolve().then(() => {
                    //    inventoryState.setProductFilterAsync({
                    //        warehouse: warehouse,
                    //        catalog: props.catalog,
                    //        department: props.department,
                    //        category: props.category,
                    //        subCategory: props.subCategory,
                    //        searchText: '',
                    //        showLandingPage: false
                    //    });
                    //});
                //}}
            >
                <Link
                    to={url}
                    css={css`
                        flex:1 1 0;
                        cursor:pointer;
                        color: ${(inventoryState.get().subCategory?.Id === props.subCategory.Id) ? theme.palette.siteSideBarContrastText : theme.palette.siteSideBarText};
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        &:hover {
                            text-decoration: underline;
                        }`}>
                    <FilterAlt sx={{ fontSize: 12 }} />
                    <span css={css`padding: 0 0 0 4px;` }>{props.subCategory.Name}</span>
                </Link>
            </div>
        </div>
    );
}