/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useCatalogState } from "../../../hooks/useCatalogState";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import FwFunc from "../../../lib/FwReact/FwFunc";

//------------------------------------------------------------------------------------
/* Rates */
export type RatesFieldsProps = {

}
export const RatesFields: React.FC<RatesFieldsProps> = props => {
    const translate = useTranslate();
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    const hasDetailFields = productDetailsState.get().catalog && productDetailsState.get().catalog?.DetailFields;
    const shoppingCartItemsState = useShoppingCartItemsState();
    const themeState = useThemeState();
    const isDarkTheme = themeState.isDark();
    const catalogState = useCatalogState();
    let isRentalCatalog = false;
    let isSalesCatalog = false;
    if (product) {
        const catalog = catalogState.getProductCatalog(product);
        if (catalog) {
            isRentalCatalog = catalog.HasRental ?? false;
            isSalesCatalog = catalog.HasSales ?? false;
        }
    }
    const rates = shoppingCartItemsState.getRatesByProduct(product);
    let showDailyRate: boolean = false;
    let showWeeklyRate: boolean = false;
    let showMonthlyRate: boolean = false;
    let showSalesPrice: boolean = false;
    let hasAnyRates: boolean = false;
    if (hasDetailFields) {
        const detailFields = productDetailsState.get().catalog?.DetailFields ?? [];
        const dailyRateEnabled = (detailFields.findIndex(f => f.Value === 'DAILYRATE' && f.Selected) >= 0);
        const weeklyRateEnabled = (detailFields.findIndex(f => f.Value === 'WEEKLYRATE' && f.Selected) >= 0);
        const monthlyRateEnabled = (detailFields.findIndex(f => f.Value === 'MONTHLYRATE' && f.Selected) >= 0);
        const salesPriceEnabled = (detailFields.findIndex(f => f.Value === 'SALESPRICE' && f.Selected) >= 0);
        const hasDailyRateData = (productDetailsState.get().product?.DailyRate !== undefined);
        const hasWeeklkyRateData = (productDetailsState.get().product?.WeeklyRate !== undefined);
        const hasMonthlyRateData = (productDetailsState.get().product?.MonthlyRate !== undefined);
        const hasSalesPriceData = (productDetailsState.get().product?.SalesPrice !== undefined);
        showDailyRate = hasDailyRateData && dailyRateEnabled;
        showWeeklyRate = hasWeeklkyRateData && weeklyRateEnabled;
        showMonthlyRate = hasMonthlyRateData && monthlyRateEnabled;
        showSalesPrice = hasSalesPriceData && salesPriceEnabled;
        const hasRentalRates = showDailyRate || showWeeklyRate || showMonthlyRate
        hasAnyRates = hasRentalRates || showSalesPrice;
    }

    if (product && hasAnyRates) {
        return (<>
            <div css={css`flex:0 0 auto;`}>
                <div css={css`
                        padding: 1em 0;
                        .rates {
                            padding: 0 .5em;
                            flex:1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                        .currencysymbol {
                            flex: 1 0 auto;
                            font-size:.8rem;
                            text-align: right;
                            padding: 0 .25em;
                        }
                        .amount {
                            flex: 0 0 auto;
                            text-align: right;
                            padding: 0 .5em;
                            color: #B12704;
                        }
                        .amount-wrapper {
                            display: flex;
                            align-items:center;
                        }
                        .ratevalue {
                            flex: 0 0 auto;
                            text-align: left;
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
                        .currencycode {
                            font-size:.6rem;
                            text-align: left;
                            color: #B12704;
                        }
                        `}>
                    <div css={css`display:flex;justify-content:center;`}>
                        <table>
                            <tbody>
                                {isRentalCatalog && (<>
                                    <tr>
                                        <td colSpan={2}>
                                            <div
                                                css={css`
                                                display:flex;
                                                align-items:center;
                                                justify-content:center;
                                                font-size:.8rem;
                                                border-top:1px solid rgba(255,255,255,.1);
                                                background-color: ${isDarkTheme ? 'rgba(255,255,255,.1);' : 'rgba(0,0,0,.1);'}
                                            `}>
                                                {translate('For rent')}
                                            </div>
                                        </td>
                                    </tr>
                                    {showDailyRate && (
                                        <tr>
                                            <td className="amount">
                                                <div className="amount-wrapper">
                                                    <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                    <div className="ratevalue">{FwFunc.numberFormatter(rates.dailyRate, 2, ',', '.')}</div>
                                                </div>
                                            </td>
                                            <td className="currencycode">{product.CurrencyCode}/{translate('day')}</td>
                                        </tr>
                                    )}
                                    {showWeeklyRate && (
                                        <tr>
                                            <td className="amount">
                                                <div className="amount-wrapper">
                                                    <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                    <div className="ratevalue">{FwFunc.numberFormatter(rates.weeklyRate, 2, ',', '.')}</div>
                                                </div>
                                            </td>
                                            <td className="currencycode">{product.CurrencyCode}/{translate('week')}</td>
                                        </tr>
                                    )}
                                    {showMonthlyRate && (
                                        <tr>
                                            <td className="amount">
                                                <div className="amount-wrapper">
                                                    <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                    <div className="ratevalue">{FwFunc.numberFormatter(rates.monthlyRate, 2, ',', '.')}</div>
                                                </div>
                                            </td>
                                            <td className="currencycode">{product.CurrencyCode}/{translate('month')}</td>
                                        </tr>
                                    )}
                                </>)}
                                {isSalesCatalog && showSalesPrice && (<>
                                    <tr>
                                        <td colSpan={2}>
                                            <div
                                                css={css`
                                                display:flex;
                                                align-items:center;
                                                justify-content:center;
                                                font-size:.8rem;
                                                border-top:1px solid rgba(255,255,255,.1);
                                                background-color: ${isDarkTheme ? 'rgba(255,255,255,.1);' : 'rgba(0,0,0,.1);'}
                                            `}>
                                                {translate('For sale')}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(rates.salesPrice, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{product.CurrencyCode}</td>
                                    </tr>
                                </>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>);
    }
    else {
        return null;
    }
};