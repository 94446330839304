/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { RegisterForm } from "../components/RegisterForm";
import { useRegisterFormState } from "../hooks/useRegisterFormState";
import { SiteLayout } from "../components/SiteLayout";

export type RegisterPageProps = {

}
export const RegisterPage: React.FC<RegisterPageProps> = props => {
    const registerFormState = useRegisterFormState();

    return (<SiteLayout
        title={registerFormState.translate('Register')}
        mainChildren={
            <div css={css`
                flex:1 1 0;`}>
                <div css={css`
                    display:flex;
                    justify-content:center;
                    align-items:center;`}>
                    <RegisterForm registerFormState={registerFormState} />
                </div>
            </div>
        }
    />);
}
