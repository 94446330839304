import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core';
import { devtools } from '@hookstate/devtools';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { LocaleState, useLocaleState } from './useLocaleState';

class ResetPasswordFormModel {
    isPopup: boolean = false;
    isWaitingForSendResetPasswordEmail: boolean = false;

    sendResetPasswordEmailErrorMessage: string = '';
    isSendResetPasswordEmailSuccessful: boolean = false;

    isValid: boolean = false;
    showValidationError = false;

    emailValue: string = '';
    emailError: string = '';
    emailRequiredError: string = '';
    emailIsDisabled: boolean = false;

    temporaryPasswordValue: string = '';
    temporaryPasswordError: string = '';
    temporaryPasswordRequiredError: string = '';

    newPasswordValue: string = '';
    newPasswordError: string = '';
    newPasswordRequiredError: string = '';
}

let initialState = new ResetPasswordFormModel();
const resetPasswordFormGlobalStore = hookstate<ResetPasswordFormModel>(initialState, devtools({ key: 'ResetPasswordFormState' }));

export class ResetPasswordFormState {
    private resetPasswordFormState: State<ResetPasswordFormModel>;
    private localeState: LocaleState;
    private appConfigState: AppConfigState;

    get(): ImmutableObject<ResetPasswordFormModel> {
        return this.resetPasswordFormState.get();
    }

    constructor(loginFormState: State<ResetPasswordFormModel>, localeState: LocaleState, appConfigState: AppConfigState) {
        this.resetPasswordFormState = loginFormState;
        this.localeState = localeState;
        this.appConfigState = appConfigState;
    }

    reset() {
        this.resetPasswordFormState.set(new ResetPasswordFormModel());
    }

    validateForm(): void {
        // validate the login form, triggering a mutation to the newLoginState only if it needs to change
        const newResetPasswordFormState: Partial<ResetPasswordFormModel> = {};
        
        // validate email
        let emailHasError = !this.resetPasswordFormState.get().emailValue;
        if (emailHasError !== (this.resetPasswordFormState.get().emailError ? true : false)) {
            newResetPasswordFormState.emailError = this.resetPasswordFormState.get().emailRequiredError;
        };

        // update isValid and showValidationError states
        const isValid = !emailHasError;
        if (isValid !== this.resetPasswordFormState.get().isValid) {
            newResetPasswordFormState.isValid = isValid;
            newResetPasswordFormState.showValidationError = newResetPasswordFormState.isValid;
        }

        // update the login form state
        if (Object.keys(newResetPasswordFormState).length > 0) {
            this.resetPasswordFormState.merge(newResetPasswordFormState);
        }
    }

    // translated with https://www.deepl.com/translator
    translate(text: string): string {
        const language = this.localeState.get().language;
        switch (text) {
            case 'Reset Password': switch (language) {
                case 'es': return 'Restablecer contraseña';
                case 'fr': return "Réinitialiser le mot de passe";
                default: return text;
            }
            case 'Complete Registration': switch (language) {
                case 'es': return 'Completar la inscripción';
                case 'fr': return "Compléter l'inscription";
                default: return text;
            }
            case 'Change Password': switch (language) {
                case 'es': return 'Cambiar contraseña';
                case 'fr': return "Modifier le mot de passe";
                default: return text;
            }
            case 'Enter a password to complete your registration.': switch (language) {
                case 'es': return "Introduzca una contraseña para completar su registro.";
                case 'fr': return 'Saisissez un mot de passe pour terminer votre inscription.';
                default: return text;
            }
            case 'Enter your new password.': switch (language) {
                case 'es': return "Introduzca su nueva contraseña.";
                case 'fr': return 'Entrez votre nouveau mot de passe.';
                default: return text;
            }
            case 'E-mail': switch (language) {
                case 'es': return 'Correo electrónico';
                case 'fr': return "E-mail";
                default: return text;
            }
            case 'E-mail is required': switch (language) {
                case 'es': return 'Correo electrónico obligatorio';
                case 'fr': return "L'e-mail est requis";
                default: return text;
            }
            case "New Password": switch (language) {
                case 'es': return 'Nueva contraseña';
                case 'fr': return "Nouveau mot de passe";
                default: return text;
            }
            case "Reset password email was sent to": switch (language) {
                case 'es': return "Se ha enviado un correo electrónico de restablecimiento de contraseña a";
                case 'fr': return "Le courriel de réinitialisation du mot de passe a été envoyé à";
                default: return text;
            }
            case "Change password email was sent to": switch (language) {
                case 'es': return "Se ha enviado un correo electrónico de cambio de contraseña a";
                case 'fr': return "L'email de changement de mot de passe a été envoyé à";
                default: return text;
            }
            case 'Sending reset password email failed': switch (language) {
                case 'es': return 'Error al enviar el correo electrónico de restablecimiento de contraseña';
                case 'fr': return "L'envoi de l'e-mail de réinitialisation du mot de passe a échoué";
                default: return text;
            }
            case "Enter your email address and click the 'Reset Password' button to receive an email with an active link to reset your password.": switch (language) {
                case 'es': return "Introduzca su dirección de correo electrónico y haga clic en el botón 'Restablecer contraseña' para recibir un correo electrónico con un enlace activo para restablecer su contraseña.";
                case 'fr': return "Saisissez votre adresse électronique et cliquez sur le bouton 'Réinitialiser le mot de passe' pour recevoir un courriel contenant un lien actif vous permettant de réinitialiser votre mot de passe.";
                default: return text;
            }
            case 'Reset password was sucessful.': switch (language) {
                case 'es': return 'Se ha podido restablecer la contraseña.';
                case 'fr': return "La réinitialisation du mot de passe a réussi.";
                default: return text;
            }
            default: return text;
        }
    }

    setEmail(value: string): void {
        this.resetPasswordFormState.emailValue.set(value);
    }

    setTemporaryPassword(value: string): void {
        this.resetPasswordFormState.temporaryPasswordValue.set(value);
    }

    setNewPassword(value: string): void {
        this.resetPasswordFormState.newPasswordValue.set(value);
    }

    setIsPopup(value: boolean): void {
        this.resetPasswordFormState.isPopup.set(value);
    }

    setError(error: unknown) {
        if (typeof error === 'string') {
            this.resetPasswordFormState.sendResetPasswordEmailErrorMessage.set(error);
        }
        else if (typeof error === 'object') {
            this.resetPasswordFormState.sendResetPasswordEmailErrorMessage.set(this.translate('Sending reset password email failed'));
            console.error(error);
        }
    }

    setIsWaitingForSendResetPasswordEmail(value: boolean) {
        this.resetPasswordFormState.isWaitingForSendResetPasswordEmail.set(value);
    }
}

export type ResetPasswordResponse = {
    //AuthToken?: string;
}

export function useResetPasswordFormState(): ResetPasswordFormState {
    const resetPasswordFormState = useHookstate<ResetPasswordFormModel>(resetPasswordFormGlobalStore);
    const localeState = useLocaleState();
    const appConfigState = useAppConfigState();
    return new ResetPasswordFormState(resetPasswordFormState, localeState, appConfigState);
}