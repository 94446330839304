/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Product } from "../../../hooks/useInventoryState";

// ListView - ItemNo
export type ProductBrowserListItemItemNoProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserListItemItemNo: React.FC<ProductBrowserListItemItemNoProps> = props => {
    const product = props.product;

    return (<>
        <div className="field icode">
            <div className="caption">I-Code</div>
            <div className="value">
                # {product.InventoryNo}
            </div>
        </div>
    </>);
}