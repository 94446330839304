/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Chip } from "@mui/material";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
/* Tags */
export type TagsFieldProps = {

}
export const TagsField: React.FC<TagsFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();

    if (productDetailsState.get().product?.Tags) {
        return (<>
            <div className="field">
                <div className="caption">
                    {translate('Tags')}:
                </div>
                <div className="value">
                    {productDetailsState.get().product?.Tags && productDetailsState.get().product?.Tags?.split(',').map((tag, index, array) => (<span key={index}>
                        {tag && (
                            <Chip label={tag} variant="outlined" css={css`margin: .25em;`} />
                        )}
                    </span>))}
                </div>
            </div>
        </>);
    }
    else {
        return null;
    }
}