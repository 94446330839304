/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ShoppingCartItemModel } from "../../../hooks/useShoppingCartItemsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

export type ShoppingCartItemAvailabilityProps = {
    item: ShoppingCartItemModel;
}
export const ShoppingCartItemAvailability: React.FC<ShoppingCartItemAvailabilityProps> = props => {
    const translate = useTranslate();
    const item = props.item;
    const product = item.product;
    const totalIn = item.product.Availability?.TotalIn ?? 0;
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;
    
    return (<>
        {product.Availability && (
            <div className="field availability" css={css`display:flex;justify-content:flex-start;font-size:.8em;padding:.25rem 0;white-space:nowrap;`}>
                {totalIn > 0 && (
                    <span css={css`${themeName === 'dark' ? 'color:hsl(180 100% 40% / 1);' : 'color:#777777;'}`}>({(product.Availability?.TotalIn ?? 0).toString() + ' of ' + (product.Availability?.Total ?? 0).toString() + ' ' + translate('available')})</span>
                )}
                {totalIn === 0 && (
                    <span css={css`color:hsl(0 0% 40% / 1);`}>({translate('Unavailable')})</span>
                )}
            </div>
        )}
    </>);
}