import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Deal, useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

export type SelectDealProps = {
    disabled: boolean;
}
export const SelectDeal: React.FC<SelectDealProps> = props => {
    const componentName = 'deal';
    const checkoutFormState = useCheckoutFormState();
    const translate = useTranslate();

    const items: Deal[] = [];
    items.push({
        DealId: '-', Deal: '(' + translate('New Job') + ')'
    });
    items.push(...checkoutFormState.get().deals ?? []);

    const caption = translate('Job');
    let selectedValue = checkoutFormState.get().dealIdValue ?? '';
    //if (checkoutFormState.get().warehouseIdValue && (checkoutFormState.get().lookupwarehouse?.length ?? 0)) {
    //    selectedValue = checkoutFormState.get().dealIdValue ?? '';
    //}

    return (<>
        <FormControl fullWidth>
            <InputLabel id={componentName + "select-label"}>{caption}</InputLabel>
            <Select
                id={componentName + "-select"}
                label={caption}
                disabled={props.disabled}
                fullWidth
                onChange={async e => {
                    const dealid = e.target.value as string;
                    return Promise.resolve().then(() => {
                        checkoutFormState.setSelectedDealId(dealid);
                        checkoutFormState.setJobName('');
                    });
                }}
                value={selectedValue}
            >
                {items.map((deal, index, array) => {
                    return (<MenuItem key={deal.DealId} value={deal.DealId}>{deal.Deal}</MenuItem>)
                })}
            </Select>

        </FormControl>
    </>)
}