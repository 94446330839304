/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------ 
export type DetailedDescriptionFieldProps = {

}
export const DetailedDescriptionField: React.FC<DetailedDescriptionFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();
    if (productDetailsState.get().product?.DetailedDescription) {
        return (
            <div className="field">
                <div className="caption">
                    {translate('Description')}
                </div>
                <div className="value" css={css`white-space: pre-wrap;`}>
                    {productDetailsState.get().product?.DetailedDescription}
                </div>
            </div>
        );
    }
    return null;
}