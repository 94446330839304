/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react";
import { useCatalogState } from "../../../hooks/useCatalogState";
import { ShoppingCartItemModel, useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import { ShoppingCartItemAvailability } from "../ItemFields/ShoppingCartItemAvailability";
import { ShoppingCartItemImage } from "../ItemFields/ShoppingCartItemImage";
import { ShoppingCartItemQty } from "../ItemFields/ShoppingCartItemQty";
import { ShoppingCartItemRates } from "../ItemFields/ShoppingCartItemRates";
import { useMediaQuery } from "@mui/material";
import { ShoppingCartPackageItemsDesktop } from "./ShoppingCartPackageItemsDesktop";
import { getShoppingCartProductViewSettings } from "../ShoppingCartControl";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

export type ShoppingCartItemProps = {
    shoppingCartItem: ShoppingCartItemModel;
    index: number;
    isRowNumberColumnVisible: boolean;
    isImageColumnVisible: boolean;
    isDescriptionColumnVisible: boolean;
    isPriceColumnVisible: boolean;
    isQuantityColumnVisible: boolean;
}
export const ShoppingCartItemRow: React.FC<ShoppingCartItemProps> = props => {
    const translate = useTranslate();
    const shoppingCartItemsState = useShoppingCartItemsState();
    const shoppingCartItem = props.shoppingCartItem;
    const isDesktop = useMediaQuery('(min-width:720px)');
    const catalogState = useCatalogState();
    const catalogs = catalogState.get().catalogs;

    if (catalogs && props.shoppingCartItem.product) {
        const product = props.shoppingCartItem.product;
        const viewSettings = getShoppingCartProductViewSettings(catalogs, product);
        return (<>
            <tr className="item">
                <td css={css`width:32px;text-align:center;font-size:.8rem;`}>
                    {props.isRowNumberColumnVisible && (<>
                        {props.index + 1}.
                    </>)}
                </td>
                <td css={css`width: 64px;`}>
                    {props.isImageColumnVisible && (<>
                         <ShoppingCartItemImage item={shoppingCartItem} />
                    </>)}
                </td>
                <td className="description">
                    {props.isDescriptionColumnVisible && (<>
                        <div css={css`font-size:.8rem;font-weight:bold;padding:0 .25rem;`}>
                            {props.shoppingCartItem.product.ItemName}
                        </div>
                        {viewSettings.hasICode && (
                            <div css={css`font-size:.7rem;padding:.25rem .25rem 0 .25rem;`}>
                                #{props.shoppingCartItem.product.InventoryNo}
                            </div>
                        )}
                        {(!isDesktop) && (<>
                            {viewSettings.hasRates && (
                                <ShoppingCartItemRates item={shoppingCartItem} hasDailyRate={viewSettings.hasDailyRate} hasWeeklyRate={viewSettings.hasWeeklyRate} hasMonthlyRate={viewSettings.hasMonthlyRate} hasSalesPrice={viewSettings.hasSalesPrice} justifyContent="flex-start" />
                            )}
                        </>)}
                        <div css={css`display:flex;flex-wrap:wrap;`}>
                            {shoppingCartItem.product.PackageItems && (
                                <button
                                    css={css`
                                    white-space: nowrap;
                                    cursor: pointer;
                                    font-weight: 600;
                                    border: 0;
                                    background-color: transparent;
                                    color: #2196f3;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                `}
                                    onClick={() => {
                                        shoppingCartItemsState.setShowPackageItems(shoppingCartItem, !shoppingCartItemsState.get()[props.index].showPackageItems);
                                    }}
                                >
                                    {translate('View accessories')}
                                </button>
                            )}
                        </div>
                    </>)}
                </td>
                {isDesktop && (
                    <td css={css`width: 200px;`}>
                        {props.isPriceColumnVisible && isDesktop && viewSettings.hasRates && (<>
                            <ShoppingCartItemRates item={shoppingCartItem} hasDailyRate={viewSettings.hasDailyRate} hasWeeklyRate={viewSettings.hasWeeklyRate} hasMonthlyRate={viewSettings.hasMonthlyRate} hasSalesPrice={viewSettings.hasSalesPrice} />
                        </>)}
                    </td>
                )}
                <td css={css`width: 100px;`}>
                    {props.isQuantityColumnVisible && (<>
                        <ShoppingCartItemQty item={shoppingCartItem} index={props.index} />
                        <div css={css`padding:0 .25rem;`}>
                            {viewSettings.hasAvailability && (<ShoppingCartItemAvailability item={shoppingCartItem} />)}
                        </div>
                    </>)}
                </td>
                
                {/*<td>*/}
                {/*    <ShoppingCartItemDeleteButton item={shoppingCartItem} />*/}
                {/*</td>*/}
            </tr>
            {(shoppingCartItem.showPackageItems && shoppingCartItem.product.PackageItems) && (<>
                <tr className="packageitem">
                    <td></td>
                    <td colSpan={4}>
                        <ShoppingCartPackageItemsDesktop
                            shoppingCartItem={shoppingCartItem}
                            index={props.index}
                            viewSettings={viewSettings} />
                    </td>
                </tr>
            </>)}
            
        </>);
    }
    return null;
};