import { ImmutableObject, State, useHookstate } from '@hookstate/core'
import axios from "axios";
import { enqueueSnackbar } from 'notistack';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { LocaleState, useLocaleState } from './useLocaleState';
import { /*SessionLocalStorageModel,*/ SessionState, useSessionState } from './useSessionState';
import { SiteLayoutState, useSiteLayoutState } from './useSiteLayoutState';

export class RegisterFormModel {
    isWaitingToRegister?: boolean;
    FirstName: string = '';
    LastName: string = '';
    Phone: string = '';
    Email: string = '';
}

type RegisterRequest = {
    Language?: 'en' | 'es' | 'fr' | string;
    FirstName?: string;
    LastName?: string;
    Phone?: string;
    Email?: string;
}

type RegisterResponse = {
    Success: boolean;
    //LoginResponse: LoginResponse;
}

//type LoginResponse = {
//    AuthToken: string;
//    FullName: string;
//}

export class RegisterFormState {
    private appConfigState: AppConfigState;
    private localeState: LocaleState;
    private siteLayoutState: SiteLayoutState;
    private sessionState: SessionState;
    private registerFormState: State<RegisterFormModel>;

    constructor(appConfigState: AppConfigState, localeState: LocaleState, siteLayoutState: SiteLayoutState, sessionState: SessionState, registerFormState: State<RegisterFormModel>) {
        this.appConfigState = appConfigState;
        this.localeState = localeState;
        this.siteLayoutState = siteLayoutState;
        this.sessionState = sessionState;
        this.registerFormState = registerFormState;
    }

    get(): ImmutableObject<RegisterFormModel> {
        return this.registerFormState.get();
    }

    async registerAsync(): Promise<void> {
        try {
            this.setIsWaitingToRegister(true);
            const registerRequest: RegisterRequest = {
                Language: this.localeState.get().language,
                FirstName: this.registerFormState.get().FirstName,
                LastName: this.registerFormState.get().LastName,
                Phone: this.registerFormState.get().Phone,
                Email: this.registerFormState.get().Email
            };
            if (!registerRequest.FirstName) throw new Error(this.localeState.translate({ en: 'First Name is required', es: 'El nombre es obligatorio', fr: 'Le prénom est obligatoire' }));
            if (!registerRequest.LastName) throw new Error(this.localeState.translate({ en: 'Last Name is required', es: 'Apellido obligatorio', fr: 'Le nom de famille est obligatoire' }));
            if (!registerRequest.Email) throw new Error(this.localeState.translate({ en: 'Email is required', es: 'Correo electrónico obligatorio', fr: "L'adresse électronique est requise" }));
            const registerResponse = await axios.post<RegisterResponse>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/register`, registerRequest);
            if (registerResponse.status === 200) {
                if (registerResponse.data.Success) {
                    //const session = new SessionLocalStorageModel();
                    //session.loggedIn = true;
                    //session.authToken = signUpContactResponse.data.LoginResponse.AuthToken;
                    //session.fullName = signUpContactResponse.data.LoginResponse.FullName;
                    //session.email = contactRegistrationRequest.Email;
                    //this.sessionState.set(session);
                    this.siteLayoutState.setRegisterFormPopupVisible(false);
                    enqueueSnackbar(this.translate('Please check your email and click the link to verify your email address.'), {
                        variant: 'info',
                        autoHideDuration: null
                    });
                    //this.siteLayoutState.showMessage(this.translate('Please check your email and click the link to verify your email address.'));
                } else {
                    //this.siteLayoutState.showError(this.translate('Unable to register.'));
                    this.siteLayoutState.showError(this.localeState.translate({ en: 'Unable to register', es: 'No se puede registrar', fr: "Impossible de s'inscrire" }));
                }
            }
        } catch (error) {
            this.siteLayoutState.showError(error);
        }
        finally {
            this.setIsWaitingToRegister(false);
        }
    }

    translate(text: string): string {
        const language = this.localeState.get().language;
        switch (text) {
            case 'Register': switch (language) {
                case 'es': return 'Registrar';
                case 'fr': return 'Enregistrer';
                default: return text;
            }
            case 'First Name': switch (language) {
                case 'es': return 'Nombre';
                case 'fr': return 'Prénom';
                default: return text;
            }
            case 'Last Name': switch (language) {
                case 'es': return 'Apellido';
                case 'fr': return 'Nom de famille';
                default: return text;
            }
            case 'Phone': switch (language) {
                case 'es': return 'Teléfono';
                case 'fr': return 'Téléphone';
                default: return text;
            }
            case 'Email': switch (language) {
                case 'es': return 'Correo electrónico';
                case 'fr': return 'Courriel';
                default: return text;
            }
            case ' is required': switch (language) {
                case 'es': return ' es obligatorio';
                case 'fr': return ' est obligatoire';
                default: return text;
            }
            case 'Unable to register.': switch (language) {
                case 'es': return 'No se puede registrar.';
                case 'fr': return "Impossible de s'inscrire.";
                default: return text;
            }
            case 'Please check your email and click the link to verify your email address.': switch (language) {
                case 'es': return 'Compruebe su correo electrónico y haga clic en el enlace para verificar su dirección de correo electrónico.';
                case 'fr': return "Veuillez vérifier votre courrier électronique et cliquer sur le lien pour vérifier votre adresse électronique.";
                default: return text;
            }
            
            default: return text;
        }
    }

    setFirstName(value: string) {
        this.registerFormState.FirstName.set(value);
    }

    setLastName(value: string) {
        this.registerFormState.LastName.set(value);
    }

    setPhone(value: string) {
        this.registerFormState.Phone.set(value);
    }

    setEmail(value: string) {
        this.registerFormState.Email.set(value);
    }

    setIsWaitingToRegister(value: boolean) {
        this.registerFormState.isWaitingToRegister.set(value);
    }
}

export function useRegisterFormState(): RegisterFormState {
    const appConfigState = useAppConfigState();
    const localeState = useLocaleState();
    const siteLayoutState = useSiteLayoutState();
    const sessionState = useSessionState();
    const contactRegistrationState = useHookstate<RegisterFormModel>(new RegisterFormModel());
    return new RegisterFormState(appConfigState, localeState, siteLayoutState, sessionState, contactRegistrationState);
}