/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate, useHookstateEffect } from "@hookstate/core";
import { IconButton } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useInventoryState } from "../../hooks/useInventoryState";
import { useThemeState } from "../../hooks/useThemeState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

//-------------------------------------------------------------------------------------------------------
export type ProductSearchBoxProps = {

}
export const ProductSearchBox: React.FC<ProductSearchBoxProps> = props => {
    const translate = useTranslate();
    const theme = useThemeState().get().theme;
    const inventoryState = useInventoryState();
    const navigate = useNavigate();
    const keywords = inventoryState.get().searchText ?? '';
    const searchTextState = useHookstate<string>(keywords);

    let inputPlaceholderText = translate('Search');
    if (inventoryState.get().catalog) {
        inputPlaceholderText += ' ' + (inventoryState.get().catalog?.Name ?? '');
    }

    const doSearch = (searchText: string) => {
        navigate({
            pathname: `/inventory/${inventoryState.get().catalog?.Id}`,
            search: createSearchParams({
                keywords: searchText,
                pageno: '1'
            }).toString()
        });
    }

    useHookstateEffect(() => {
        searchTextState.set(keywords);
    }, [keywords])

    return (
        <OutlinedInput
            type="text"
            placeholder={inputPlaceholderText}
            endAdornment={
                <InputAdornment position="end">
                    {(searchTextState.get().length > 0) && (
                        <IconButton onClick={async e => Promise.resolve().then(() => doSearch(''))}>
                            <Clear css={css`color:${theme.palette.siteTextPrimary};`} />
                        </IconButton>

                    )}
                    <IconButton>
                        <Search css={css`color:${theme.palette.siteTextPrimary};`} />
                    </IconButton>
                </InputAdornment>
            }
            size="small"
            margin="none"
            css={css`
                flex: 1 1 0;
                min-width: 300px;
                box-sizing:border-box;
                transition: all 1s;
                border-color:${theme.palette.siteTextPrimary};`}
            value={searchTextState.get() ?? ''}
            onChange={async e => {
                const searchText = (e.target as HTMLInputElement).value;
                searchTextState.set(searchText);
            }}
            onKeyUp={async e => {
                const searchText = (e.target as HTMLInputElement).value;
                if (e.key === 'Enter') {
                    doSearch(searchText);
                }
            }}
            onBlur={
                async e => {
                    const searchText = (e.target as HTMLInputElement).value;
                    if (searchText !== inventoryState.get().searchText) {
                        doSearch(searchText);
                    }
                }
            }
        />
    );
}