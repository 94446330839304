/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Chip } from "@mui/material";
import { Product } from "../../../hooks/useInventoryState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

export type ProductBrowserGridItemTagsProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserGridItemTags: React.FC<ProductBrowserGridItemTagsProps> = props => {
    const product = props.product;
    const translate = useTranslate();

    return (<>
        {product.Tags && (
            <div className="field">
                <div className="caption">
                    {translate('Tags')}:
                </div>
                <div className="value">
                    {product.Tags && product.Tags?.split(',').map((tag, index, array) => (<span key={index}>
                        {tag && (
                            <Chip label={tag} variant="outlined" css={css`margin: .25em;`} />
                        )}
                    </span>))}
                </div>
            </div>
        )}
    </>);
}