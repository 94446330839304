/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../../hooks/useProductDetailsState";
import { useShoppingCartItemsState } from "../../../../hooks/useShoppingCartItemsState";
import { ProductDetailsIncludedPackageItemDesktop } from "./ProductDetailsIncludedPackageItemDesktop";
import { ProductDetailsViewSettings } from "../../ProductDetails";
import { ProductDetailsOptionalPackageItemDesktop } from "./ProductDetailsOptionalPackageItemDesktop";
import { useTranslate } from "../../../../hooks/useTranslate/useTranslate";

//------------------------------------------------------------------------------------
export type ProductDetailsPackageItemsDesktopProps = {
    viewSettings: ProductDetailsViewSettings;
}
export const ProductDetailsPackageItemsDesktop: React.FC<ProductDetailsPackageItemsDesktopProps> = props => {
    const translate = useTranslate();
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    const packageItems = product?.PackageItems ?? [];
    const shoppingCartItemsState = useShoppingCartItemsState();
    let qtyInCart = 0;
    if (product) {
        qtyInCart = shoppingCartItemsState.getQty(product)
    }
    const showDailyRate = (props.viewSettings.hasRates && props.viewSettings.hasDailyRate);
    const showWeeklyRate = (props.viewSettings.hasRates && props.viewSettings.hasWeeklyRate);
    const showMonthlyRate = (props.viewSettings.hasRates && props.viewSettings.hasMonthlyRate);
    let qtyOptionalAccessories = 0;
    if (packageItems) {
        qtyOptionalAccessories = packageItems.filter(i => i.IsOption).length;
    }

    const styles = css`
        table.packageitems {
            font-size: .8rem;
            border-collapse: collapse;
        }
        tr.headerrow td {
            padding: 1rem .25rem .1rem .25rem;
            white-space: no-wrap;
            font-weight: bold;
        }
        tbody.included tr.headerrow {
            border-bottom: 1px solid #ffc107;
        }
        tbody.optional tr.headerrow {
            border-bottom: 1px solid #8bc34a;
        }
        tbody.included tr.headerrow .col-legend {
            background-color: #ffc107;
            width: 6px;
        }
        tr.packageitemrow {
            font-size:.7rem;
        }
        tbody.included .col-legend {
            background-color: #ffc107;
            width: 6px;
        }
        tbody.optional .col-legend {
            background-color: #8bc34a;
            width: 6px;
        }
        .col-image {
            padding: 0 .25rem;
        }
        .col-description {
            width: auto;
            text-align: left;
        }
        .col-qty {
            text-align: center;
        }
        td.col-qty .MuiInputBase-root {
            text-align: right;
        }
        .col-qtyincart {
            text-align: center;
        }
        .col-dailyrate
        {
            text-align: right;
            padding: 0 .25em;
        }
        .col-weeklyrate {;
            text-align: right;
        }
        .col-monthlyrate {
            text-align: right;
        }
    `;

    return (<>
        {product && (
            <div css={styles}>
                {/*{(packageItems && (packageItems.length > 0)) && (*/}
                {/*    <div className="accessories-caption">Package Includes:</div>*/}
                {/*)}*/}

                {/* Package Includes */}
                <table className="packageitems">
                    <tbody className="included">
                        <tr className="headerrow">
                            {/*<td className="col-legend" colSpan={1}></td*/}
                            <td className="col-description" colSpan={2}>{translate('Package includes')}</td>
                            {/*<th className="col-tags">Tags</th>*/}
                            <td className="col-qty">{translate('Qty')}</td>
                            <td className="col-qtyincart">{translate('Cart')}</td>
                            {showDailyRate && (
                                <td className="col-dailyrate">{translate('Daily')}</td>
                            )}
                            {showWeeklyRate && (
                                <td className="col-weeklyrate">{translate('Weekly')}</td>
                            )}
                            {showMonthlyRate && (
                                <td className="col-monthlyrate">{translate('Monthly')}</td>
                            )}
                            {/*<td className="col-availability">Availability</td>*/}
                        </tr>
                        {packageItems && packageItems.map((item) => {
                            if (item.IsOption) return null;
                            return (
                                <ProductDetailsIncludedPackageItemDesktop
                                    key={item.InventoryId}
                                    packageProduct={product}
                                    packageItem={item}
                                    packageQtyInCart={qtyInCart}
                                    viewSettings={props.viewSettings} />
                            )
                        })}
                    </tbody>
                    {qtyOptionalAccessories && (<>
                        <tbody className="optional">
                            <tr className="headerrow">
                                <td className="col-description" colSpan={3}>{translate('Optional accessories')}</td>
                                {/*<td className="col-tags">Tags</td>*/}
                                {/*<td className="col-qty">{translate('Sugggested')}</td>*/}
                                <td className="col-qtyincart">{translate('Cart')}</td>
                                {showDailyRate && (
                                    <td className="col-dailyrate">{translate('Daily')}</td>
                                )}
                                {showWeeklyRate && (
                                    <td className="col-weeklyrate">{translate('Weekly')}</td>
                                )}
                                {showMonthlyRate && (
                                    <td className="col-monthlyrate">{translate('Monthly')}</td>
                                )}
                                {/*<td className="col-availability">Availability</td>*/}
                            </tr>
                            {packageItems && packageItems.map((item) => {
                                if (item.IsOption) {
                                    return (
                                        <ProductDetailsOptionalPackageItemDesktop
                                            key={item.InventoryId}
                                            packageProduct={product}
                                            packageItem={item}
                                            packageQtyInCart={qtyInCart}
                                            viewSettings={props.viewSettings} />
                                    )
                                }
                                return null;
                            })}
                        </tbody>
                    </>)}
                </table>


                {/*{packageItems && packageItems.map((item) => (*/}
                {/*    <ProductDetailsPackageItem key={item.InventoryId} packageProduct={product} packageItem={item} allowAddingUnavailableItems={props.allowAddingUnavailableItems} enableQty={enableQty} viewSettings={props.viewSettings} />*/}
                {/*))}*/}
            </div>
        )}
    </>);
}

