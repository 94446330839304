import { hookstate, ImmutableObject, State, useHookstate } from '@hookstate/core'
import { devtools } from '@hookstate/devtools';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ModelStateDictionary } from '../data/ModelStateDictionary';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { LocaleState, useLocaleState } from './useLocaleState';
import { LoginFormState, useLoginFormState } from './useLoginFormState';
import { ResetPasswordFormState, useResetPasswordFormState } from './useResetPasswordFormState';
import { SessionState, useSessionState } from './useSessionState';

export type LoginRequest = {
    Email: string;
    Password: string;
}

export type LoginResponse = {
    AuthToken?: string;
    FullName?: string;
}

export class SendResetPasswordEmailRequest {
    EmailTo: string = '';
    Language: string = 'en';
}

export type ResetPasswordFromEmailRequest = {
    Email: string;
    TemporaryPassword: string;
    NewPassword: string;
}

export class SiteLayoutModel {
    loginFormPopupVisible: boolean = false;
    removeloginFormPopupCloseButton: boolean = false;
    registerFormPopupVisible: boolean = false;
    resetPasswordFormPopupVisible: boolean = false;
    errorMessage?: string; // shows error dialog when defined
    snackbarMessages: string[] = []; // shows snackbar message when not empty
    message?: string; // shows popup message dialog when defined

    tooltipId?: string;
    tooltipMessage?: string;
    tooltipVariant?: TooltipVariant;
    tooltipPlace?: TooltipPlace;
    tooltipHideDelay?: number;
    tooltipTimeoutHandle?: number;
}

let initialState = new SiteLayoutModel();
const siteLayoutGlobalStore = hookstate<SiteLayoutModel>(initialState, devtools({ key: 'SiteLayoutState' }));

export class SiteLayoutState {
    private siteLayoutState: State<SiteLayoutModel>;
    private appConfigState: AppConfigState;
    private sessionState: SessionState;
    private loginFormState: LoginFormState;
    private resetPasswordFormState: ResetPasswordFormState;
    private localeState: LocaleState;
    private navigate: NavigateFunction;

    constructor(siteLayoutState: State<SiteLayoutModel>, appConfigState: AppConfigState, sessionState: SessionState, loginFormState: LoginFormState, resetPasswordFormState: ResetPasswordFormState, localeState: LocaleState, navigate: NavigateFunction) {
        this.siteLayoutState = siteLayoutState;
        this.appConfigState = appConfigState;
        this.sessionState = sessionState;
        this.loginFormState = loginFormState;
        this.resetPasswordFormState = resetPasswordFormState;
        this.localeState = localeState;
        this.navigate = navigate;
    }

    get(): ImmutableObject<SiteLayoutModel> {
        return this.siteLayoutState.get();
    }

    setLoginFormPopupVisible(isVisible: boolean, removeCloseButton?: boolean) {
        this.loginFormState.reset();
        this.siteLayoutState.loginFormPopupVisible.set(isVisible);
        this.siteLayoutState.removeloginFormPopupCloseButton.set(removeCloseButton ?? false);
    }

    setRegisterFormPopupVisible(isVisible: boolean) {
        this.siteLayoutState.registerFormPopupVisible.set(isVisible);
    }

    setResetPasswordFormPopupVisible(isVisible: boolean) {
        if (isVisible) {
            this.setLoginFormPopupVisible(false);
        }
        this.resetPasswordFormState.reset();
        this.siteLayoutState.resetPasswordFormPopupVisible.set(isVisible);
    }

    async loginAsync() {
        const loginFailedTxt = this.loginFormState.translate('Login failed') + '.';
        this.sessionState.reset();
        var request: LoginRequest = {
            Email: this.loginFormState.get().emailValue,
            Password: this.loginFormState.get().passwordValue
        };
        try {
            this.loginFormState.setIsWaitingForLogin(true);
            const loginResponse = await axios.post<LoginResponse>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/login`, request);
            if (loginResponse.status === 200 && loginResponse.data.AuthToken) {
                this.sessionState.set({
                    authToken: loginResponse.data.AuthToken,
                    fullName: loginResponse.data.FullName ?? 'Unknown User',
                    email: request.Email,
                    loggedIn: true
                })
                this.setLoginFormPopupVisible(false);
                return true;
            }
            else {
                //this.loginFormState.setError(`${loginFailedTxt}`);
                this.showError(`${loginFailedTxt}`)
            }
        }
        catch (error) {
            this.showError(error);
        }
        finally {
            this.loginFormState.setIsWaitingForLogin(false);
        }
        return false;
    }

    async logoutAsync(withReload: boolean = true): Promise<void> {
        this.sessionState.reset();
        if (withReload) {
            window.location.reload();
        }
    }

    async sendResetPasswordEmailAsync(type: 'reset' | 'change') {
        const sendResetPasswordEmailFailedTxt = this.loginFormState.translate(`Send ${type} password email failed`) + '.';
        if (this.sessionState.get().loggedIn) {
            this.resetPasswordFormState.setEmail(this.sessionState.get().email);
        } else {
            // reset session
            this.sessionState.reset();
        }
        var request = new SendResetPasswordEmailRequest();
        request.EmailTo = this.resetPasswordFormState.get().emailValue;
        request.Language = this.localeState.get().language;
        try {
            this.resetPasswordFormState.setIsWaitingForSendResetPasswordEmail(true);
            const resetPasswordResponse = await axios.post<any>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/send${type}passwordemail`, request);
            if (resetPasswordResponse.status === 200) {
                if (resetPasswordResponse.data && resetPasswordResponse.data.Success === false && resetPasswordResponse.data.StatusMessage) {
                    this.showError(resetPasswordResponse.data.StatusMessage);
                }
                else {
                    this.setResetPasswordFormPopupVisible(false);
                    let msgTxt = this.sessionState.get().loggedIn ? this.resetPasswordFormState.translate(`A ${type} password email was sent to`) : this.resetPasswordFormState.translate(`Reset password email was sent to`);
                    enqueueSnackbar(msgTxt + `: ${request.EmailTo}`, {
                        variant: 'success',
                        autoHideDuration: null
                    });
                    return true;
                }
            }
            else {
                this.showError(`${sendResetPasswordEmailFailedTxt}`)
            }
        }
        catch (error) {
            this.showError(error);
        }
        finally {
            this.resetPasswordFormState.setIsWaitingForSendResetPasswordEmail(false);
        }
        return false;
    }

    async resetPasswordFromEmailAsync(type: 'reset' | 'change'): Promise<boolean> {
        let upperCaseType = 'Reset';
        if (type === 'change') {
            upperCaseType = 'Change';
        }
        const resetPasswordFailed = this.loginFormState.translate(`${upperCaseType} password failed`);
        try {
            const loginResponse = await axios.post<ResetPasswordFromEmailRequest, AxiosResponse<LoginResponse>>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/resetpasswordfromemail`, {
                Email: this.resetPasswordFormState.get().emailValue,
                TemporaryPassword: this.resetPasswordFormState.get().temporaryPasswordValue,
                NewPassword: this.resetPasswordFormState.get().newPasswordValue
            });
            if (loginResponse.status === 200 && loginResponse.data.AuthToken) {
                this.sessionState.set({
                    authToken: loginResponse.data.AuthToken,
                    fullName: loginResponse.data.FullName ?? 'Unknown User',
                    email: this.resetPasswordFormState.get().emailValue,
                    loggedIn: true
                })
                enqueueSnackbar(this.resetPasswordFormState.translate(`${upperCaseType} password was sucessful`), {
                    variant: 'success',
                    autoHideDuration: null
                });
                this.navigate('/')
                return true;
            }
            else {
                this.showError(`${resetPasswordFailed}`)
            }
        }
        catch (error) {
            this.showError(error);
        }
        return false;
    }

    showMessage(message: string) {
        if (message) {
            this.siteLayoutState.message.set(message);
            return;
        }
    }

    showError(error: unknown) {
        const anErrorOccuredTxt = this.resetPasswordFormState.translate('An error occured') + '.';
        if (!error) {
            this.siteLayoutState.errorMessage.set(undefined);
            return;
        }
        if (typeof error === 'string') {
            this.loginFormState.setError(`${error}`);
            this.siteLayoutState.errorMessage.set(error);
            console.error(error);
            return;
        }
        else if (error instanceof AxiosError) {
            if (error.response) {
                if (error.response?.data) {
                    switch (error.response.status) {
                        case 401:
                            this.setLoginFormPopupVisible(true);
                            break;
                        case 400:
                            if (error.response.data instanceof Object) {
                                let msg = '';
                                for (let field in (error.response.data as ModelStateDictionary)) {
                                    if (msg) {
                                        msg += ',';
                                    }
                                    msg += '[' + field + ']';
                                    msg += ' ';
                                    for (let i = 0; i < (error.response.data as ModelStateDictionary)[field].length; i++) {
                                        msg += ': ';
                                        msg += (error.response.data as ModelStateDictionary)[field][i];
                                        msg += ' ';
                                    }
                                }
                                //this.loginFormState.setError(`${error.response.statusText}\n${msg}`);
                                this.siteLayoutState.errorMessage.set(`${error.response.statusText}\n${msg}`);
                                console.error(error.response.data);
                                return;
                            }
                            break;
                        case 500:
                            if (error.message && error.response.data.Message) {
                                //this.loginFormState.setError(`${anErrorOccuredTxt}. ${error.response.data.Message}`);
                                this.siteLayoutState.errorMessage.set(`${error.message}. ${error.response.data.Message}`);
                                console.error(error.response.data.Message, error.response.data.StackTrace);
                                return;
                            }
                            else {
                                this.siteLayoutState.errorMessage.set(error.message);
                                console.error(error.response.data);
                                return;
                            }
                        case 503:
                            if (typeof error.response.data === 'string' && error.response.data.length > 0) {
                                this.siteLayoutState.errorMessage.set(error.response.data)
                                console.error(error.response.data);
                                return;
                            }
                            else {
                                const errorText = 'Service Unavailable';
                                this.siteLayoutState.errorMessage.set(errorText)
                                console.error(errorText);
                                return;
                            }
                        default:
                            this.siteLayoutState.errorMessage.set(error.message);
                            console.error(error);
                            return;
                    }
                }
                else {
                    switch (error.response.status) {
                        case 401:
                            this.setLoginFormPopupVisible(true);
                            break;
                        default:
                            this.siteLayoutState.errorMessage.set(error.message);
                            console.error(error);
                            break;
                    }
                    return;
                }
            }
            this.siteLayoutState.errorMessage.set(error.message);
            console.log(error);
            return;
        }
        else if (error instanceof Error) {
            this.siteLayoutState.errorMessage.set(error.message);
            console.error(error);
            return;
        }
        if (!this.siteLayoutState.get().errorMessage) {
            this.siteLayoutState.errorMessage.set(anErrorOccuredTxt);
            console.error(error);
            return;
        }
    }

    hideError() {
        this.siteLayoutState.errorMessage.set(undefined);
    }

    async handleErrorAsync(error: AxiosError | any) {
        if (error && error.response && error.response.status) {
            const axiosError = error as AxiosError;
            switch (error.response.status) {
                case 401:
                    enqueueSnackbar(`401: Please try logging in again.`, { variant: 'warning', autoHideDuration: 15000 });
                    await this.logoutAsync(false);
                    this.setLoginFormPopupVisible(true);
                    break;
                case 403:
                    await this.logoutAsync(false);
                    this.setLoginFormPopupVisible(true);
                    enqueueSnackbar(`403: Please try logging in again.`, { variant: 'warning', autoHideDuration: 15000 });
                    break;
                case 400:
                    let message = '';
                    if (axiosError.response) {
                        const badRequestResponse = axiosError.response.data as BadRequestResponse;
                        for (let key in badRequestResponse) {
                            const property = badRequestResponse[key];
                            for (let i = 0; i < property.length; i++) {
                                message += ` ${property[i]}`
                            }
                        }
                    }
                    if (!message) {
                        message = '400: Bad Request.';
                    }
                    enqueueSnackbar(message, { variant: 'error', autoHideDuration: 15000 });
                    break;
                default:
                    if (error.message) {
                        enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 15000 });
                    }
                    else {
                        enqueueSnackbar(JSON.stringify(error), { variant: 'error', autoHideDuration: 15000 });
                    }
                    break;
            }

            return;
        } else {
            if (error.message) {
                enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 15000 });
            }
            else {
                enqueueSnackbar(JSON.stringify(error), { variant: 'error', autoHideDuration: 15000 });
            }
        }
        //this.showError(error);
    }

    // translated with https://www.deepl.com/translator
    translate(text: string): string {
        const language = this.localeState.get().language;
        switch (text) {
            case 'OK': switch (language) {
                default: return text;
            }
            case 'Reset Password': switch (language) {
                case 'es': return 'Restablecer contraseña';
                case 'fr': return "Réinitialiser le mot de passe";
                default: return text;
            }
            case 'Change Password': switch (language) {
                case 'es': return 'Cambiar contraseña';
                case 'fr': return "Modifier le mot de passe";
                default: return text;
            }
            case 'Register': switch (language) {
                case 'es': return 'Registrar';
                case 'fr': return "Enregistrer";
                default: return text;
            }
            case 'Theme': switch (language) {
                case 'es': return 'Tema';
                case 'fr': return "Thème";
                default: return text;
            }
            case 'Light': switch (language) {
                case 'es': return 'Luminoso';
                case 'fr': return "Lumineux";
                default: return text;
            }
            case 'Dark': switch (language) {
                case 'es': return 'Oscuro';
                case 'fr': return "Sombre";
                default: return text;
            }
            case 'Shopping Cart': switch (language) {
                case 'es': return 'Cesta de la compra';
                case 'fr': return "Panier d'achat";
                default: return text;
            }
            case 'User Account Menu': switch (language) {
                case 'es': return 'Menú de cuenta de usuario';
                case 'fr': return "Menu du compte d'utilisateur";
                default: return text;
            }
            default: return text;
        }
    }

    showTooltip(options: TooltipOptions) {
        enqueueSnackbar(options.message, {
            variant: options.variant ?? 'default',
            autoHideDuration: options.hideDelay ?? 3000,
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom'
            }
        });
    }

    hideTooltip() {
        const tooltipTimeoutHandle = this.siteLayoutState.get().tooltipTimeoutHandle;
        if (tooltipTimeoutHandle !== undefined) {
            clearTimeout(tooltipTimeoutHandle);
        }
        this.siteLayoutState.merge({
            tooltipId: undefined,
            tooltipMessage: undefined,
            tooltipVariant: undefined,
            tooltipPlace: undefined,
            tooltipHideDelay: undefined,
            tooltipTimeoutHandle: undefined
        });
    }
}

export type TooltipVariant = 'success' | 'warning' | 'error' | 'info' | 'default';
export type TooltipPlace = 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end';

export type TooltipOptions = {
    id?: string;
    message?: string;
    variant?: TooltipVariant;
    place?: TooltipPlace;
    hideDelay?: number;
}

export function useSiteLayoutState(): SiteLayoutState {
    const siteLayoutState = useHookstate(siteLayoutGlobalStore);
    const appConfigState = useAppConfigState();
    const sessionState = useSessionState();
    const loginFormState = useLoginFormState();
    const resetPasswordFormState = useResetPasswordFormState();
    const localeState = useLocaleState();
    const navigate = useNavigate();
    return new SiteLayoutState(siteLayoutState, appConfigState, sessionState, loginFormState, resetPasswordFormState, localeState, navigate);
}

type BadRequestResponse = {
    [key: string]: string[];
}