/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import clone from "clone";
import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";
import { Product } from "../../../hooks/useInventoryState";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

// ListView - Description
export type ProductBrowserListItemDescriptionProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserListItemDescription: React.FC<ProductBrowserListItemDescriptionProps> = props => {
    const product = props.product;
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();
    const checkoutFormState = useCheckoutFormState();
    const warehouseId = checkoutFormState.get().warehouseIdValue;
    const locationId = checkoutFormState.get().locationIdValue;
    const fromDate = checkoutFormState.get().fromDateValue;
    const toDate = checkoutFormState.get().toDateValue;

    return (<>
        <div className="field itemdescription">
            <div className="caption">{translate('Description')}</div>
            <div
                className="value"
                onClick={async () => {
                    return Promise.resolve().then(async () => {
                        window.history.pushState(null, '', `#/product/${props.product.Id}/warehouseid/${warehouseId}/locationid/${locationId}/fromdate/${fromDate}/todate/${toDate}`);
                        await productDetailsState.setProductAsync(clone(product) as Product);
                        productDetailsState.setShowPopup(true);
                    });
                }}
            >
                {product.ItemName}
            </div>
        </div>
    </>);
}