/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHookstate, useHookstateEffect } from "@hookstate/core";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import axios from "axios";
import React from "react";
import { useAppConfigState } from "../hooks/useAppConfigState";
import { useTranslate } from "../hooks/useTranslate/useTranslate";
//import { useThemeState } from "../hooks/useThemeState";

//----------------------------------------------------------------------------------------------
export type StorefrontProgress = {
    Failed: boolean;
    FailureMessage: string;
    FailureStackTrace: string;
    SessionId: string;
    Caption: string;
    CurrentStep?: number;
    TotalSteps?: number;
    PercentComplete?: number;
}
//----------------------------------------------------------------------------------------------
export type FwProgressMeterProps = {
    sessionId: string;
    open: boolean;
    initialCaption: string;
    dialogTitle: string;
    onComplete?: (e: StorefrontProgress) => void;
    onFailed?: (e: StorefrontProgress) => void;
    onClose?: () => void;
}
export const ProgressMeter: React.FC<FwProgressMeterProps> = props => {
    const appConfigState = useAppConfigState();
    const appConfig = appConfigState.get().appConfig;
    const visible = props.sessionId ? true : false;
    const display = visible ? 'block' : 'none';
    const translate = useTranslate();

    const progressMeterLogicState = useHookstate<StorefrontProgress>({
        Failed: false,
        FailureMessage: '',
        FailureStackTrace: '',
        SessionId: props.sessionId,
        Caption: '',
        CurrentStep: 0,
        TotalSteps: 0,
        PercentComplete: 0
    });
    //const sessionId = progressMeterLogicState.get().SessionId;
    const progress = progressMeterLogicState.get();
    let failed = progress.Failed;
    let failureMessage = progress.FailureMessage;
    let failureStackTrace = progress.FailureStackTrace;
    //let currentStep = progress.CurrentStep ?? 0;
    //let totalSteps = progress.TotalSteps ?? 0;
    let caption = progress.Caption ?? '';
    let percentage = progress.PercentComplete ?? 0;
    //let completed = percentage === 100;
    const percentText = `${percentage}%`;
    let progressMeterUrl = '';
    if (appConfig) {
        progressMeterUrl = `${appConfig.BaseUrl}/api/v1/storefront/progressmeter/${props.sessionId}`;
    }

    // dialog
    //const [open, setOpen] = React.useState(visible);
    //const themeState = useThemeState();
    //const theme = themeState.getTheme();
    //const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const reset = () => {
        progressMeterLogicState.set({
            Failed: false,
            FailureMessage: '',
            FailureStackTrace: '',
            SessionId: '',
            Caption: '',
            CurrentStep: 0,
            TotalSteps: 0,
            PercentComplete: 0
        });
    }

    const handleClose = () => {
        //setOpen(false);
        reset();
        if (props.onClose) {
            props.onClose();
        }
    };

    // effect to call the webapi to update the progress meter every 3 seconds
    useHookstateEffect(() => {
        const interval = setInterval(async () => {
            //console.log('progress interval');
            // don't use progress data from outer scope, since this isn't running on rerender, it's in a timer
            let percentComplete = progressMeterLogicState.get().PercentComplete ?? 0;
            let isCompleted = percentComplete === 100;
            let isFailed = progressMeterLogicState.get().Failed;
            if (isCompleted || isFailed) {
                try {
                    if (isCompleted && props.onComplete) {
                        props.onComplete(progress);
                    }
                    if (isFailed && props.onFailed) {
                        props.onFailed(progress);
                    }
                }
                finally {
                    clearInterval(interval);
                    return;
                }
            }
            if ((!progressMeterUrl) || (!props.sessionId)) {
                clearInterval(interval);
                return;
            }
            const responseProgressMeter = await axios.get<StorefrontProgress>(progressMeterUrl);
            if (responseProgressMeter.status !== 200) {
                //console.error(`Unable to load: ${progressMeterUrl}`, responseProgressMeter.data)
                return;
            }
            progressMeterLogicState.set(responseProgressMeter.data);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [props.sessionId]);

    return (
        <div className="progressmeter">
            <Dialog
                open={props.open}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {props.dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <div css={css`width:350px;`} style={{ display: display }}>
                        <div css={css`padding:.5rem 0;font-size:.8rem;`} style={{ textDecoration: failed ? 'line-through' : 'auto'}}>{caption}</div>
                        <LinearProgress variant="determinate" value={progress.PercentComplete ?? 0} />
                        <div css={css`padding: 0 0 1rem 0;text-align:right;`} style={{ textDecoration: failed ? 'line-through' : 'auto' }}>{percentText}</div>
                        {failed && (<>
                            <div css={css`color:red;font-size:.8rem;padding: .5rem;`}>Error:<br />{failureMessage}</div>
                            <div css={css`margin:1rem 0 0 0;font-size:.7rem;display:none;`}>Stack Trace:<br />{failureStackTrace}</div>
                        </>)}
                    </div>
                </DialogContent>
                <DialogActions>
                    {(failed || (progress.PercentComplete === 100)) && (
                        <Button onClick={handleClose} autoFocus>
                            {translate('Close')}
                        </Button>
                    )}
                </DialogActions>
              </Dialog>
        </div>
    );
}
//----------------------------------------------------------------------------------------------
