/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate, useHookstateEffect } from "@hookstate/core";
import { Slider, Tooltip } from "@mui/material";
import { useDebounce } from "../../hooks/useDebounce";
import { useProductBrowserState } from "../../hooks/useProductBrowserState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";

export type ItemWidthSliderProps = {
    //catalogid: string | undefined;
}
export const ItemWidthSlider: React.FC<ItemWidthSliderProps> = props => {
    const SLIDER_MIN = 185;
    const SLIDER_MAX = Math.min((window.innerWidth - (6 * 16)), 512);
    const productBrowserState = useProductBrowserState();
    const debounceItemWidth = useDebounce<number>({
        callback: (value: number) => {
            productBrowserState.setItemWidth(sliderState.get());
        },
        delay: 250,
        immediate: false
    });
    const itemWidth = productBrowserState.get().itemWidth;
    const sliderState = useHookstate<number>(itemWidth);
    //console.log(productBrowserState.get().itemWidth, sliderState.get());
    const translateProductBrowser = useTranslate();

    useHookstateEffect(() => {
        sliderState.set(itemWidth);
    }, [itemWidth])

    return (
        <Tooltip title={translateProductBrowser('Thumbnail Size') + ': ' + sliderState.get()} >
            <Slider
                css={css`width:75px;margin:0 16px;`}
                value={sliderState.get()}
                onChange={async (event, value, activeThumb) => {
                    return Promise.resolve().then(() => {
                        sliderState.set(value as number);
                        debounceItemWidth(value as number);
                    });
                }}
                min={SLIDER_MIN}
                max={SLIDER_MAX}
            />
        </Tooltip>
    )
}