/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Chip } from "@mui/material";
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";


//------------------------------------------------------------------------------------
/* Barcodes */
export type BarcodesFieldProps = {

}
export const BarcodesField: React.FC<BarcodesFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();

    if (productDetailsState.get().product?.Barcodes) {
        return (
            <div className="field">
                <div className="caption">
                    {translate('Barcodes')}:
                </div>
                <div className="value">
                    {productDetailsState.get().product?.Barcodes?.split(',').map((tag, index, array) => (<span key={index}>
                        {tag && (
                            <Chip label={tag} variant="outlined" css={css`margin: .25em;`} />
                        )}
                    </span>))}
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}