/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate } from "@hookstate/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
//import { Login } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useResetPasswordFormState } from "../hooks/useResetPasswordFormState";
import { useSiteLayoutState } from "../hooks/useSiteLayoutState";

type ResetPasswordFormProps = {
    type: 'resetpassword' | 'completeregistration' | 'changepassword';
}
export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = props => {
    //const theme = useGlobalTheme();
    const fieldSpacing: string = '0em';
    const resetPasswordFormState = useResetPasswordFormState();
    const isFormDisabled = resetPasswordFormState.get().isWaitingForSendResetPasswordEmail || resetPasswordFormState.get().isSendResetPasswordEmailSuccessful;
    const siteLayoutState = useSiteLayoutState();
    const hasTemporaryPassword = !!resetPasswordFormState.get().temporaryPasswordValue;
    const disableEmail = (isFormDisabled || hasTemporaryPassword);
    let pageTitle = '';
    const isResetPasswordPage = (props.type === 'resetpassword');
    const isCompleteRegistrationPage = (props.type === 'completeregistration');
    const isChangePasswordPage = (props.type === 'changepassword');
    if (isResetPasswordPage) {
        pageTitle = resetPasswordFormState.translate('Reset Password');
    } else if (isCompleteRegistrationPage) {
        pageTitle = resetPasswordFormState.translate('Complete Registration');
    } else if (isChangePasswordPage) {
        pageTitle = resetPasswordFormState.translate('Change Password');
    }
    const showPasswordState = useHookstate<boolean>(false);
    const showPassword = showPasswordState.get();
    let resetPasswordType: 'change' | 'reset' = 'reset';
    if (isChangePasswordPage) {
        resetPasswordType = 'change';
    }

    return (
        <div css={css`max-width: 600px;`}>
            <div css={css`
                padding:1em;
                text-align:center;
                font-family:'Ropa Sans';
                font-size:2em;
                ` }>
                {pageTitle}
            </div>
            {isCompleteRegistrationPage && (<>
                <div css={css`
                    text-align:center;
                    font-size:1em;
                ` }>
                    {resetPasswordFormState.translate('Enter a password to complete your registration.')}
                </div>
            </>)}
            {(isChangePasswordPage) && (<>
                <div css={css`
                    text-align:center;
                    font-size:1em;
                ` }>
                    {resetPasswordFormState.translate('Enter your new password.')}
                </div>
            </>)}
            <div>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                    {resetPasswordFormState.get().sendResetPasswordEmailErrorMessage && (<>
                        <div css={css`
                            padding:0 .5em;
                            .MuiAlert-message {
                                white-space: pre;
                            }` }>
                            <Alert severity="error">{resetPasswordFormState.get().sendResetPasswordEmailErrorMessage ?? ''}</Alert>
                        </div>
                    </>)}
                    {resetPasswordFormState.get().isSendResetPasswordEmailSuccessful && (<>
                        <Alert severity="success">{resetPasswordFormState.translate('Login successful')}</Alert>
                    </>)}

                    {(!resetPasswordFormState.get().temporaryPasswordValue) && (
                        <Box>
                            <Alert severity="info">{resetPasswordFormState.translate("Enter your email address and click the 'Reset Password' button to receive an email with an active link to reset your password.")}</Alert>
                        </Box>
                    )}

                    <Box css={css`display:flex;justify-content:center;align-items:center;margin:16px 0 0 0`}>
                        <TextField
                            css={css`flex:1;`}
                            id="txtEmail"
                            label={resetPasswordFormState.translate('E-mail')}
                            type="text"
                            variant={disableEmail ? "filled" : "outlined"}
                            autoComplete="username"
                            fullWidth
                            required={!disableEmail}
                            margin="dense"
                            disabled={disableEmail}
                            value={resetPasswordFormState.get().emailValue || ''}
                            inputProps={{ maxLength: 255 }}
                            onChange={async e => {
                                const value = e.currentTarget.value;
                                return Promise.resolve().then(() => {
                                    resetPasswordFormState.setEmail(value);
                                });
                            }}
                            onBlur={async e => {
                                return Promise.resolve().then(() => {
                                    resetPasswordFormState.validateForm();
                                });
                            }}
                        />
                    </Box>

                    {hasTemporaryPassword && (<>
                        <Box css={css`display:flex;justify-content:center;align-items:center;margin-bottom:${fieldSpacing};`}>
                            <TextField
                                css={css`flex:1;`}
                                id="txtNewPassword"
                                label={resetPasswordFormState.translate('New Password')}
                                type={showPassword ? "text" : "password"}
                                variant="outlined"
                                autoComplete="new-password"
                                fullWidth
                                required
                                margin="dense"
                                disabled={isFormDisabled}
                                value={resetPasswordFormState.get().newPasswordValue || ''}
                                inputProps={{ maxLength: 20 }}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => showPasswordState.set(!showPassword)}
                                                onMouseDown={() => showPasswordState.set(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={async e => {
                                    const value = e.currentTarget.value;
                                    return Promise.resolve().then(() => {
                                        resetPasswordFormState.setNewPassword(value);
                                    });
                                }}
                                onBlur={async e => {
                                    return Promise.resolve().then(() => {
                                        resetPasswordFormState.validateForm();
                                    });
                                }}
                            />
                        </Box>
                    </>)}

                    <div css={css`
                        display:flex;
                        margin-bottom:${fieldSpacing};
                        align-items:center;
                        justify-content:center;
                        margin:1em 0 0 0;`}>
                        <Button
                            variant="outlined"
                            onClick={async e => {
                                return Promise.resolve().then(async () => {
                                    if (resetPasswordFormState.get().temporaryPasswordValue) {
                                        await siteLayoutState.resetPasswordFromEmailAsync(resetPasswordType);
                                    }
                                    else {
                                        await siteLayoutState.sendResetPasswordEmailAsync(resetPasswordType);
                                    }
                                });
                            }}
                            disabled={isFormDisabled}>
                            {resetPasswordFormState.translate('Submit')}
                            {/*<Login css={css`margin-left: 4px;`} />*/}
                        </Button>
                    </div>
                </Box>
            </div>
        </div>
    );
}

