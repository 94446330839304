/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { State, useHookstate } from "@hookstate/core";
import { ArrowDropDown } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { nanoid } from "nanoid";
import { FocusEventHandler, KeyboardEventHandler } from "react";
import { useThemeState } from "../../hooks/useThemeState";

export type FwNumberDropDownProps = {
    value: number;
    onChange?: (newValue: number, oldvalue: number) => void;
    label?: string;
    required?: boolean;
    children?: React.ReactNode[];
    maxQty?: number;
    maxDropDown?: number;
    labelPrefix?: string;
    inputHeight?: string;
}

export const FwNumberDropDown: React.FC<FwNumberDropDownProps> = props => {
    let maxDropDown = props.maxDropDown ?? 9;
    if ((props.maxQty !== undefined) && (maxDropDown > props.maxQty)) {
        maxDropDown = props.maxQty;
    }
    const value = props.value.toString();
    const showTextboxState: State<boolean> = useHookstate<boolean>(props.value > maxDropDown);
    const showTextbox = showTextboxState.get();
    const textboxValueState: State<string> = useHookstate<string>(value);
    const textboxValue = textboxValueState.get();
    let children: React.ReactNode[] = props.children ?? [];
    const showTextboxValue = 'SHOWTEXTBOX';
    const themeState = useThemeState();
    const isDarkTheme = themeState.isDark();
    const selectId = nanoid();
    const labelId = props.label ? nanoid() : undefined;
    const labelPrefix = props.labelPrefix ? props.labelPrefix + ': ' : '';
    const inputHeight = props.inputHeight ? props.inputHeight : '3.4375em';

    if (children.length === 0) {
        for (let i = 0; i <= maxDropDown; i++) {
            const val = i.toString();
            let description = val;
            //if (i === 0) {
            //    description += ' (Delete)'
            //}
            children.push(<MenuItem key={val} value={val}>{description}</MenuItem>)
        }
        if ((props.maxQty === undefined) || (maxDropDown < props.maxQty)) {
            children.push(<MenuItem key={showTextboxValue} value={showTextboxValue}>{maxDropDown + 1}+</MenuItem>)
        }
    }

    const onChangeSelect: SelectInputProps<string>['onChange'] = async e => {
        const newValue = e.target.value;
        return Promise.resolve().then(() => {
            const newShowTextbox = newValue === showTextboxValue;
            if (showTextboxState.get() !== newShowTextbox) {
                showTextboxState.set(newShowTextbox);
            }
            if (newValue !== showTextboxValue) {
                if (props.onChange) {
                    props.onChange(+newValue, props.value);
                }
            }
        });
    }

    const onChangeTextbox: React.ChangeEventHandler<HTMLInputElement> = async e => {
        const value = e.target.value;
        return Promise.resolve().then(() => {
            textboxValueState.set(value);
        });
    }

    const onKeyUpTextbox: KeyboardEventHandler<HTMLInputElement> = async e => {
        const key = e.key;
        return Promise.resolve().then(() => {
            if (key === 'Enter') {
                if (textboxValue === '') {
                    showTextboxState.set(false);
                }
                if(props.onChange) {
                    props.onChange(+textboxValue, props.value);
                }
            }
        });
    }

    const onBlurTextbox: FocusEventHandler<HTMLInputElement> = async e => {
        const value = e.target.value;
        return Promise.resolve().then(() => {
            if (value === '') {
                showTextboxState.set(false);
            }
            if (props.onChange) {
                props.onChange(+textboxValue, props.value);
            }
        });
    }

    if (!showTextbox) {
        return (<>
            <div css={css`flex: 1 1 0;position:relative;min-width: 50px;height: ${inputHeight};` }>
                <div
                    css={css`
                        border: 1px solid;
                        border-color: ${isDarkTheme ? 'rgb(133, 133, 133)' : 'rgb(118, 118, 118)'};
                        padding: 16.5px 14px;
                        border-radius: 4px;
                    ` }
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <div css={css`flex: 1 1 0;`}>{`${labelPrefix}${props.value}`}</div>
                    <ArrowDropDown />
                </div>
                <div 
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        textAlign: 'center',
                        opacity: 0,
                        display: 'flex',
                        height: '3.4375em'
                    }}
                >
                    <FormControl
                        css={css`flex: 1 1 0;`}
                        fullWidth>
                        {props.label && (
                            <InputLabel id={labelId}>{props.label}</InputLabel>
                        )}
                        <Select
                            label={props.label}
                            placeholder={textboxValue}
                            css={css`
                                flex:1 1 0;
                                width: 100%;
                                text-align: center;
                                padding: .1rem !important;
                                .MuiSelect-select {
                                    font-size: .6rem !important;
                                }
                            `}
                            sx={{
                                px: 0,
                                py: 0,
                                fontSize: '.5rem'
                            }}
                            labelId={labelId}
                            id={selectId}
                            value={showTextbox ? showTextboxValue : value}
                            onChange={onChangeSelect}
                        >
                            {children}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </>)
    }
    else {
        return (
            <FormControl
                fullWidth>
                {props.label && (
                    <InputLabel id={labelId}>{props.label}</InputLabel>
                )}
                <OutlinedInput
                    label={props.label}
                    css={css`
                        flex:1 1 0;
                        .MuiOutlinedInput-input {
                            text-align: center;
                            font-size: .7rem;
                            padding:.7rem;
                        }
                    `}
                    value={(textboxValue === '0') ? '' : textboxValue}
                    placeholder={textboxValue}
                    onKeyUp={onKeyUpTextbox}
                    onBlur={onBlurTextbox}
                    onChange={onChangeTextbox}
                    autoFocus={true}
                />
            </FormControl>
        )
    }
}