/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";

//------------------------------------------------------------------------------------
/* ItemNo */
export type ItemNoFieldProps = {

}
export const ItemNoField: React.FC<ItemNoFieldProps> = props => {
    const productDetailsState = useProductDetailsState();

    return (
        <div
            css={css`
                padding: .1rem;
            `}>
            # {productDetailsState.get().product?.InventoryNo}
        </div>
    );
}