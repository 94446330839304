/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react"
import { CustomScrollbars } from "../components/CustomScrollbars/CustomScrollbars"
import { LandingPage } from "../components/ProductBrowser/LandingPage"
//-------------------------------------------------------------------------------------------------------

import { SiteLayout } from "../components/SiteLayout"
import { useCatalogState } from "../hooks/useCatalogState"

// Home Page
export type HomePageProps = {
    
}
export const HomePage: React.FC<HomePageProps> = props => {
    const catalogState = useCatalogState();
    const catalogs = (catalogState.get().catalogs && catalogState.get().catalogs) ?? [];

    return <>
        <SiteLayout 
            title="Home"
            mainChildren={<>
                <CustomScrollbars>
                    <div css={css`margin: 0 auto;`}>
                        {catalogs.map((catalog, index, catalogs) => {
                            return <React.Fragment key={index}>
                                <LandingPage catalog={catalog} showCatalogName={true} />
                            </React.Fragment>
                        })}
                    </div>
                </CustomScrollbars>
                
            </>}
        />
    </>    
}
//-------------------------------------------------------------------------------------------------------