import React from 'react';
import ReactDOM from 'react-dom/client';
import { /*BrowserRouter,*/ HashRouter } from "react-router-dom";
import './styles/main.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/ropa-sans/400.css';
import 'react-tooltip/dist/react-tooltip.css'

var WebFont = require('webfontloader');

//window.addEventListener("error", (event) => {
//    //store.dispatch<any>(setErrorAction({ message: event.message }));
//    console.error(event);
//});

//window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
//    //store.dispatch<any>(setErrorAction({ message: event.reason.message }));
//    console.error(event);
//});

// load web fonts directly from google instead of npm installing them like the @fontsource imports above.
WebFont.load({
    google: {
        families: ['Noto Sans'/*'Archivo Black'*/, /*'Roboto Condensed'*/]
    }
});

//const currUrl = document.URL;
//let basename = '';
//let foundSchema = false;
//let foundFirstSlashAfterSchema = false;
//let currentNode = '';
//console.log(currUrl)
//for (let i = 0; i < currUrl.length; i++) {
//    if (!foundSchema && (currUrl[i] === '/') && (i < currUrl.length - 2) && (currUrl[i + 1] === '/')) {
//        foundSchema = true;
//        i++;
//    }
//    else if (foundSchema && !foundFirstSlashAfterSchema && currUrl[i] === '/') {
//        foundFirstSlashAfterSchema = true;
//        basename += currUrl[i];
//        currentNode = '/';
//    }
//    else if (foundSchema && foundFirstSlashAfterSchema) {
//        basename += currUrl[i];
//        if (currUrl[i] === '/') {
//            currentNode = '/';
//        } else {
//            currentNode += currUrl[i];
//        }
//        if (currentNode === '/storefront') {
//            break;
//        }
//        //console.log(currentNode);
//    }
//}
////basename += '/';
//console.log(basename);
const rootEl = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootEl);
root.render(
     //<React.StrictMode>
     //    <BrowserRouter basename={basename} >
     //        <App />
     //    </BrowserRouter>
     //</React.StrictMode>
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();