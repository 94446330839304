/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate } from "@hookstate/core";
import { Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, InputAdornment, Link, TextField } from "@mui/material";
import { useLoginFormState } from "../hooks/useLoginFormState";
import { useSiteLayoutState } from "../hooks/useSiteLayoutState";

type LoginFormProps = {
    
}
export const LoginForm: React.FC<LoginFormProps> = props => {
    //const theme = useGlobalTheme();
    const fieldSpacing: string = '0em';
    const loginFormState = useLoginFormState();
    const isFormDisabled = loginFormState.get().isWaitingForLogin || loginFormState.get().isLoginSuccessful;
    const siteLayoutState = useSiteLayoutState();
    const showPasswordState = useHookstate<boolean>(false);
    const showPassword = showPasswordState.get();

    return (
        <div>
            <div css={css`
                padding:1em;
                text-align:center;
                font-family:'Ropa Sans';
                font-size:2em;` }>
                {loginFormState.translate('Login')}
            </div>
            <div>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '50ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    {loginFormState.get().loginErrorMessage && (<>
                        <div css={css`
                            padding:0 .5em;
                            .MuiAlert-message {
                                white-space: pre;
                            }` }>
                            <Alert severity="error">{loginFormState.get().loginErrorMessage ?? ''}</Alert>
                        </div>
                    </>)}
                    {loginFormState.get().isLoginSuccessful && (<>
                        <Alert severity="success">{loginFormState.translate('Login successful')}</Alert>
                    </>)}

                    <div css={css`display:flex;margin-bottom:${fieldSpacing};`}>
                        <TextField
                            id="txtEmail"
                            label={loginFormState.translate('Email')}
                            placeholder={loginFormState.translate('Email')}
                            type="text"
                            variant="outlined"
                            autoComplete="username"
                            fullWidth
                            required
                            disabled={isFormDisabled}
                            value={loginFormState.get().emailValue}
                            inputProps={{ maxLength: 255 }}
                            autoFocus
                            onChange={async e => {
                                const value = e.currentTarget.value;
                                return Promise.resolve().then(() => {
                                    loginFormState.setEmail(value);
                                });
                            }}
                            onBlur={async e => {
                                return Promise.resolve().then(() => {
                                    loginFormState.validateForm();
                                });
                            }}
                        />
                    </div>
                    <div css={css`display:flex;margin-bottom:${fieldSpacing};`}>
                        <TextField
                            id="txtPassword"
                            label={loginFormState.translate('Password')}
                            placeholder={loginFormState.translate('Password')}
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            autoComplete="current-password"
                            fullWidth
                            required
                            disabled={isFormDisabled}
                            value={loginFormState.get().passwordValue}
                            inputProps={{ maxLength: 20 }}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => showPasswordState.set(!showPassword)}
                                            onMouseDown={() => showPasswordState.set(!showPassword)}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={async e => {
                                const value = e.currentTarget.value;
                                return Promise.resolve().then(() => {
                                    loginFormState.setPassword(value);
                                });
                            }}
                            onBlur={async e => {
                                return Promise.resolve().then(() => {
                                    loginFormState.validateForm();
                                });
                            }}
                            onKeyDown={async e => {
                                const key = e.key;
                                return Promise.resolve().then(async () => {
                                    if (key === 'Enter') {
                                        await siteLayoutState.loginAsync();
                                    }
                                });
                            } }
                        />
                    </div>

                    {(!loginFormState.get().isWaitingForLogin && !loginFormState.get().isLoginSuccessful) && (<>
                    
                    </>)}
                    <div>
                        <div css={css`
                            display:flex;
                            margin-bottom:${fieldSpacing};
                            align-items:center;
                            justify-content:center;
                            margin:1em 0 0 0;`}>
                            <Button
                                variant="outlined"
                                onClick={async e => {
                                    return Promise.resolve().then(async () => {
                                        await siteLayoutState.loginAsync();
                                    });
                                }}
                                disabled={isFormDisabled}>
                                {loginFormState.translate('Login')}
                                <Login css={css`margin-left: 4px;`} />
                            </Button>
                        </div>
                        <div css={css`
                            display:flex;
                            margin-bottom:${fieldSpacing};
                            align-items:center;
                            justify-content:center;
                            margin:1em 0 0 0;`}>
                            <Link
                                css={css`
                                    cursor:pointer;
                                `}
                                component="span"
                                variant="body2"
                                onClick={async => {
                                    return Promise.resolve().then(async () => {
                                        await siteLayoutState.setLoginFormPopupVisible(false)
                                        await siteLayoutState.setResetPasswordFormPopupVisible(true)
                                    });
                                }}>
                                {siteLayoutState.translate('Reset Password')}
                            </Link>
                            <div css={css`flex: 0 0 1em;`}></div>
                            <Link
                                css={css`
                                    cursor:pointer;
                                `}
                                component="span"
                                variant="body2"
                                onClick={async => {
                                    return Promise.resolve().then(async () => {
                                        await siteLayoutState.setLoginFormPopupVisible(false)
                                        await siteLayoutState.setRegisterFormPopupVisible(true)
                                    });
                                }}>
                                {siteLayoutState.translate('Register')}
                            </Link>
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
}

