/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Box, Button, TextField } from "@mui/material";
//import { SiteLayout } from "./SiteLayout";
import { RegisterFormState,/* useRegisterFormState*/ } from "../hooks/useRegisterFormState";
import { AppRegistration } from "@mui/icons-material";
import FwPhoneNumberBox from "../lib/FwReact/FwPhoneNumberBox";

type RegisterFormProps = {
    registerFormState: RegisterFormState;
}
export const RegisterForm: React.FC<RegisterFormProps> = props => {
    //const theme = useGlobalTheme();
    const fieldSpacing: string = '0em';
    const isFormDisabled = props.registerFormState.get().isWaitingToRegister;

    return (<div>
        <div css={css`padding:1em;text-align:center;font-family:'Ropa Sans';font-size:2em;`}>
            {props.registerFormState.translate('Register')}
        </div>
        <div>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off">
                <div css={css`display:flex;margin-bottom:${fieldSpacing};`}>
                    <TextField
                        label={props.registerFormState.translate('First Name')}
                        //placeholder={props.contactRegistrationFormState.translate('First Name')}
                        variant="outlined"
                        fullWidth
                        required
                        //error={!props.contactRegistrationFormState.get().FirstName}
                        value={props.registerFormState.get().FirstName}
                        inputProps={{ maxLength: 50 }}
                        autoFocus
                        onChange={async e => {
                            const value = e.currentTarget.value;
                            return Promise.resolve().then(() => {
                                props.registerFormState.setFirstName(value)
                            });
                        }} />
                </div>
                <div css={css`display:flex;margin-bottom:${fieldSpacing};`}>
                    <TextField
                        label={props.registerFormState.translate('Last Name')}
                        //placeholder={props.contactRegistrationFormState.translate('Last Name')}
                        variant="outlined"
                        fullWidth
                        required
                        //error={!props.contactRegistrationFormState.get().LastName}
                        value={props.registerFormState.get().LastName}
                        inputProps={{ maxLength: 50 }}
                        onChange={async e => {
                            const value = e.currentTarget.value;
                            return Promise.resolve().then(() => {
                                props.registerFormState.setLastName(value);
                            });
                        }} />
                </div>
                <div css={css`display:flex;margin-bottom:${fieldSpacing};`}>
                    <FwPhoneNumberBox
                        label={props.registerFormState.translate('Phone')}
                        value={props.registerFormState.get().Phone ?? ''}
                        onChange={async e => {
                            const value = e.currentTarget.value;
                            return Promise.resolve().then(() => {
                                props.registerFormState.setPhone(value);
                            });
                        }}
                    />
                </div>
                <div css={css`display:flex;margin-bottom:${fieldSpacing};`}>
                    <TextField
                        label={props.registerFormState.translate('Email')}
                        //placeholder={props.contactRegistrationFormState.translate('Email')}
                        type="email"
                        variant="outlined"
                        fullWidth
                        required
                        //error={!props.contactRegistrationFormState.get().Email}
                        value={props.registerFormState.get().Email}
                        inputProps={{ maxLength: 255 }}
                        onChange={async e => {
                            const value = e.currentTarget.value;
                            return Promise.resolve().then(() => {
                                props.registerFormState.setEmail(value);
                            });
                        }}
                    />
                </div>
                <div css={css`
                    display:flex;
                    margin-bottom:${fieldSpacing};
                    align-items:center;
                    justify-content:center;`}>
                    <Button 
                        variant="outlined"
                        disabled={isFormDisabled}
                        onClick={async e => {
                            return Promise.resolve().then(async () => {
                                await props.registerFormState.registerAsync()
                            });
                        }}>
                        {props.registerFormState.translate('Register')}
                        <AppRegistration css={css`margin-left: 4px;`} />
                    </Button>
                </div>
            </Box>
        </div>
    </div>);
}

//type RegisterPageProps = {

//}
//export const ContactRegistrationPage: React.FC<RegisterPageProps> = props => {
//    //const theme = useGlobalTheme();
//    const contactRegistrationController = useRegisterFormState();

//    return (<SiteLayout
//        title={contactRegistrationController.translate('User Registration')}
//        mainChildren={
//            <div css={css`
//                min-width:350px;
//                max-width:960px;
//                margin:0 auto;`}>
//                <RegisterForm registerFormState={contactRegistrationController} />
//            </div>
//        }
//    />);
//}

//export default SignUpPage