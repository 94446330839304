/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { State } from "@hookstate/core";
import { ChevronLeft, ChevronRight, Close, Help, ImportContacts } from "@mui/icons-material";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { ProductCategoriesTree, useInventoryState } from "../../../hooks/useInventoryState";
import { useThemeState } from "../../../hooks/useThemeState";
import { CatalogMenu } from "../../CatalogMenu";
import { CategoryTreeControl } from "./CategoryTree";
import { CustomScrollbars } from "../../CustomScrollbars/CustomScrollbars";
import { SelectLocation } from "../../CheckoutForm/SelectLocation";
import { useStorefrontSettingsState } from "../../../hooks/useStorefrontSettingsState";
import { CheckoutDate } from "../../CheckoutForm/CheckoutDate";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";

//-------------------------------------------------------------------------------------------------------
export type SidebarProps = {
    sidebarExpandedState: State<boolean>;
}

export const Sidebar: React.FC<SidebarProps> = props => {
    const translate = useTranslate();
    const isSidebarExpanded = props.sidebarExpandedState.get();
    const themeState = useThemeState()
    const theme = themeState.get().theme;
    const inventoryState = useInventoryState();
    const sidebarCollapsedWidth = 40;
    const settingsState = useStorefrontSettingsState();
    const isUniversity = settingsState.get().settings?.IsUniversity ?? false;
    const isDesktop = useMediaQuery('(min-width:720px)');

    return (
        <div css={css`flex:1 1 0;display:flex;`}>
            <div
                css={css`
                    flex: 1 1 0;
                    display:  ${isSidebarExpanded ? 'flex' : 'none'};
                    flex-direction:column;
                    overflow:hidden;
                `}
                style={{
                    marginLeft: isSidebarExpanded ? '0' : `${sidebarCollapsedWidth}px`
                }}
            >
                {!isUniversity && (<>
                    <div
                        css={css`
                            flex:0;
                            padding:.5em .5em 0 .5em;
                            display:flex;
                        `}>
                        <SelectLocation disabled={false} />
                        {/*<SelectWarehouse disabled={false} />*/}
                        <div css={css`width:24px;`}></div>
                    </div>
                </>)}
                <div
                    css={css`
                        flex:0 0 auto;
                        padding:.5em;
                        .field {
                            margin:1em 0 2px 0;
                        }
                        .caption {
                            text-align:center;
                            color: ${theme.palette.siteSideBarText};
                        }
                        .flex {
                            display:flex;
                        }
                        `}>
                    <div className="flex">
                        <div>
                            <div className="field">
                                <div
                                    className="value"
                                    css={css``}>
                                    <CheckoutDate type="startdate" fullwidth={true} catalogId={inventoryState.get().catalog?.Id} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="value">
                                    <CheckoutDate type="stopdate" fullwidth={true} catalogId={inventoryState.get().catalog?.Id} />
                                </div>
                            </div>
                        </div>
                        <div css={css`display:flex;justify-content:flex-end;align-items:center;`}>
                            <Tooltip title={translate('Reservations for different date ranges must be submitted as separate Orders')} arrow>
                                <Help />
                            </Tooltip>
                        </div>
                    </div>

                </div>
                <div
                    css={css`
                        flex:0;
                        font-size:1rem;
                        line-height:2rem;
                        font-weight:700;
                        color: ${theme.palette.siteSideBarText};
                        padding:.5em;
                        display:flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-family: 'Ropa Sans';
                        font-size:1.1em;`}>
                    <ImportContacts css={css`padding:0 .25em;`} /><CatalogMenu />
                </div>
                <div
                    css={css`
                        flex:1 1 0;
                        padding: 0 1em;
                        display:flex;
                        flex-direction:column;
                    `}
                >
                    <CustomScrollbars
                        overridecss={`
                            flex: 1 1 0;
                            overflow-x:hidden;
                        `}>
                        {inventoryState.get().catalog &&
                            <CategoryTreeControl
                                catalog={inventoryState.get().catalog}
                                rentalCategoriesTree={inventoryState.get().productCategoriesTree as ProductCategoriesTree} />
                        }
                    </CustomScrollbars>

                </div>
            </div>

            {/* Expand/Collapse button */}
            {isDesktop && (
                <div css={css`flex:0 0 auto;display:flex;flex:0 0 auto;justify-content:flex-end;`}>
                    <div css={css`flex:0 0 auto;`}
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                //productBrowserState.setDesktopSidebarExpanded(!isSidebarExpanded);
                                props.sidebarExpandedState.set(!isSidebarExpanded);
                            })
                        }}>
                        <IconButton
                            aria-label="Close">
                            {isSidebarExpanded && (
                                <ChevronLeft />
                            )}
                            {!isSidebarExpanded && (
                                <ChevronRight />
                            )}
                        </IconButton>
                    </div>
                </div>
            )}
            {(!isDesktop && isSidebarExpanded) && (
                <div css={css`flex:0 0 auto;display:flex;flex:0 0 auto;justify-content:flex-end;`}>
                    <div css={css`flex:0 0 auto;/*padding:.5em;width:40px*/`}
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                //productBrowserState.setMobileSidebarExpanded(!isSidebarExpanded);
                                props.sidebarExpandedState.set(!isSidebarExpanded);
                            })
                        }}>
                        <IconButton
                            aria-label="Close">
                            <Close />
                        </IconButton>
                    </div>
                </div>
            )}
        </div>
    );
}