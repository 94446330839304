/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate, useHookstateEffect } from "@hookstate/core";
import { Close, ContactSupport, ContentCopy, Print, Share } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useMemo, useRef } from "react";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TwitterShareButton, VKIcon, VKShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { useAppConfigState } from "../../hooks/useAppConfigState";
import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useProductDetailsState } from "../../hooks/useProductDetailsState";
import { useSessionState } from "../../hooks/useSessionState";
import { useSiteLayoutState } from "../../hooks/useSiteLayoutState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";
import FwPhoneNumberBox from "../../lib/FwReact/FwPhoneNumberBox";

//------------------------------------------------------------------------------------
type SendInquiryEmailResponse = {
    Success: boolean;
    FailureMessage: string;
}
type IframeComponentProps = {
    sourceUri: string;
    somePayload: string;
}
//------------------------------------------------------------------------------------
const IframeComponent: React.FC<IframeComponentProps> = (props) => {
    // create a ref to store the iframe element
    const iframeRef = useRef<HTMLIFrameElement>(null);

    // define a function to post messages to the iframe
    //const postMessage = (message: string) => {
    //    if (iframeRef.current?.contentWindow?.postMessage) {
    //        iframeRef.current.contentWindow.postMessage(message, window.location.origin);
    //    }
    //};

    // use useEffect to post a message when somePayload changes
    //useEffect(() => {
    //    postMessage({ type: "TYPEA", payload: props.somePayload });
    //}, [props.somePayload]);

    // use useMemo to create the iframe element and memoize it
    const memoizedIframe = useMemo(
        () => (
             <iframe
                ref={iframeRef}
                id="printpage"
                src={props.sourceUri}
                style={{
                    display: 'none',
                    position: 'absolute',
                    inset: 0,
                    zIndex: 101,
                    width: '100vw',
                    height: '100vh'
                }}
                title="Print"
            />
        ),
        [props.sourceUri]
    );

    // return the memoized iframe element
    return memoizedIframe;
}
//------------------------------------------------------------------------------------
export type PrintPageButtonProps = {
    pageUrl: string;
    printMode: boolean;
}
export const PrintPageButton: React.FC<PrintPageButtonProps> = props => {
    const loadPrintPage = useHookstate<boolean>(false);
    const isPrintPageLoaded = useHookstate<boolean>(false);
    const isLoading = (loadPrintPage.get() && !isPrintPageLoaded.get());
    const autoPrinted = useHookstate<boolean>(false);
    const translate = useTranslate();

    const onPrintIframe = (): Promise<void> => {
        return Promise.resolve().then(() => {
            if (!loadPrintPage.get()) {
                loadPrintPage.set(true);
            }
            else if (isPrintPageLoaded.get()) {
                printIframe('printpage');
            }
        });
    }

    const printIframe = (id: string) => {
        const iframe = (document as any).frames
            ? (document as any).frames[id] as any
            : document.getElementById(id);
        if (iframe) {
            const iframeWindow = iframe.contentWindow || iframe;
            iframe.focus();
            iframeWindow.print();
        }

        return false;
    };

    useHookstateEffect(() => {
        if (props.printMode) {
            const handleMessage = (event: MessageEvent) => {
                // if you are troubleshooting why this is not working, then make sure your browser url matches the url in appsettings.json for PublicBaseUrl.  Window message passing will not work cross-origin.
                //console.log(event);
                if (event.data.action === 'page-loaded') {
                    if (!autoPrinted.get()) {
                        isPrintPageLoaded.set(true);
                        autoPrinted.set(true);
                        printIframe('printpage');
                    }
                }
            };

            window.addEventListener('message', handleMessage);

            return () => {
                window.removeEventListener('message', handleMessage);
            };
        }
    }, []);


    //const PrintIframe = useHookstateMemo<JsxElement>(() => (
    //    <iframe
    //        id="printpage"
    //        src={props.pageUrl}
    //        style={{ display: 'none' }}
    //        title="Print"
    //    />
    //), [iframeUrl]);

    return (
        <>
            {loadPrintPage.get() && (
                <IframeComponent sourceUri={props.pageUrl} somePayload={''} />
            )}
            <Button
                size="small"
                variant="outlined"
                disabled={isLoading}
                onClick={onPrintIframe}
            >
                <Print />{isLoading ? 'Loading...' : translate('Print')}
            </Button>
        </>
    );
}
//------------------------------------------------------------------------------------
export type ProductDetailsButtonBarProps = {
    printMode: boolean;
}
export const ProductDetailsButtonBar: React.FC<ProductDetailsButtonBarProps> = props => {
    const appConfigState = useAppConfigState();
    const sessionState = useSessionState();
    const siteLayoutState = useSiteLayoutState();
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    const shareIconSize = 48;
    const shareIconRound: boolean = true;
    const checkoutFormState = useCheckoutFormState();
    const warehouseid = checkoutFormState.get().warehouseIdValue;
    const locationid = checkoutFormState.get().locationIdValue;
    const fromdate = checkoutFormState.get().fromDateValue;
    const todate = checkoutFormState.get().toDateValue;
    const translate = useTranslate();

    // Share Dialog
    let productUrl = (product !== undefined) ? `${appConfigState.get().appConfig?.StorefrontAppUrl}/#/product/${product.Id}/warehouseid/${warehouseid}/locationid/${locationid}/fromdate/${fromdate}/todate/${todate}` : '';
    const shareDialogOpenState = useHookstate<boolean>(false);
    const closeShareDialog = () => {
        shareDialogOpenState.set(false);
    }
    const copyLinkToClipboard = () => {
        if (window.isSecureContext && navigator && navigator.clipboard) {
            navigator.clipboard.writeText(productUrl).then(
                () => {
                    enqueueSnackbar('Copied link to the clipboard', { variant: 'info' });
                    closeShareDialog();
                },
                () => {
                    enqueueSnackbar('Unable to copy link to the clipboard.  Please copy the link manually.', { variant: 'error' });
                },
            );
        }
        else {
            enqueueSnackbar('Copying to clipboard is not supported because the web page is not running in a secure context.  Please copy the link manually.', { variant: 'error' });
        }
    }

    // Inquire Dialog
    const inquireDialogOpenState = useHookstate<boolean>(false);
    const inquireDialogFirstNameState = useHookstate<string>('');
    const inquireDialogLastNameState = useHookstate<string>('');
    const inquireDialogPhoneState = useHookstate<string>('');
    const inquireDialogEmailState = useHookstate<string>(sessionState.get().email ?? '');
    const inquireDialogMessageState = useHookstate<string>('');
    const inquireDialogDisabledState = useHookstate<boolean>(false);
    const closeInquireDialog = () => {
        inquireDialogOpenState.set(false);
        inquireDialogFirstNameState.set('');
        inquireDialogLastNameState.set('');
        inquireDialogPhoneState.set('');
        inquireDialogEmailState.set('');
        inquireDialogMessageState.set('');
        inquireDialogDisabledState.set(false);
    }
    const sendInquireDialog = async () => {
        return Promise.resolve().then(async () => {
            try {
                if (product) {
                    //console.log(product);

                    const firstName = inquireDialogFirstNameState.get();
                    if (!firstName && firstName.length === 0) {
                        enqueueSnackbar(translate('First Name is required'), { variant: 'error' });
                        return;
                    }

                    const lastName = inquireDialogLastNameState.get();
                    if (!lastName && lastName.length === 0) {
                        enqueueSnackbar(translate('Last Name is required'), { variant: 'error' });
                        return;
                    }

                    const phone = inquireDialogPhoneState.get();
                    if (!phone && phone.length === 0) {
                        enqueueSnackbar(translate('Phone is required'), { variant: 'error' });
                        return;
                    }

                    const email = inquireDialogEmailState.get();
                    if (!email && email.length === 0) {
                        enqueueSnackbar(translate('Email is required'), { variant: 'error' });
                        return;
                    }

                    const message = inquireDialogMessageState.get();
                    if (!message && message.length === 0) {
                        enqueueSnackbar(translate('Message is required'), { variant: 'error' });
                        return;
                    }
                    const request = {
                        CatalogProductId: product.Id,
                        WarehouseId: warehouseid,
                        LocationId: locationid,
                        FirstName: inquireDialogFirstNameState.get(),
                        LastName: inquireDialogLastNameState.get(),
                        Phone: inquireDialogPhoneState.get(),
                        Email: inquireDialogEmailState.get(),
                        Message: inquireDialogMessageState.get()
                    }
                    const response = (await axios.post<SendInquiryEmailResponse>(`${appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/sendinquiryemail`, request, {
                        headers: {
                            Authorization: 'Bearer ' + sessionState.get().authToken
                        }
                    }));
                    if (response.data.Success) {
                        enqueueSnackbar(translate('Inquiry sent'), { variant: 'success' });
                    }
                    else {
                        enqueueSnackbar(translate('Unable to send inquiry'), { variant: 'success' });
                    }
                } else {
                    enqueueSnackbar(translate('Unable to send inquiry'), { variant: 'error' });
                }
                closeInquireDialog();
            }
            catch (error) {
                await siteLayoutState.handleErrorAsync(error);
            }
        });
    }

    const inquireDialogRowPadding = ".1em 0 .1em 1em"

    // Print Product
    let printProductUrl = (product !== undefined) ? `${appConfigState.get().appConfig?.StorefrontAppUrl}/#/printproduct/${product.Id}/warehouseid/${warehouseid}/locationid/${locationid}/fromdate/${fromdate}/todate/${todate}` : '';


    if (product) {
        return (
            <div
                css={css`
                flex: 1 1 0;
                display: flex;
                & > div {
                    flex: 1 1 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background-color 1s;
                    padding: .5em 0;
                    cursor: pointer;
                }
                @media print {
                    display: none;
                }
            `}>
                <div>
                    <Dialog open={inquireDialogOpenState.get()} onClose={closeInquireDialog} fullWidth={true} maxWidth={"sm"}>
                        <DialogTitle>
                            {`${translate('Inquire about')}: ${product.ItemName}`}
                            <IconButton
                                aria-label={translate('Close')}
                                onClick={closeInquireDialog}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box padding=".1rem 0" textAlign="left" fontSize=".9rem">
                                {translate('Contact Info')}
                            </Box>
                            <Box padding={inquireDialogRowPadding} >
                                <TextField
                                    id="firstname"
                                    label={translate("First Name")}
                                    fullWidth
                                    margin="dense"
                                    type="text"
                                    variant="outlined"
                                    required={true}
                                    value={inquireDialogFirstNameState.get()}
                                    onChange={async e => {
                                        const value = e.currentTarget.value;
                                        return Promise.resolve().then(() => {
                                            return Promise.resolve().then(() => {
                                                inquireDialogFirstNameState.set(value);
                                            });
                                        })
                                    }}
                                />
                            </Box>
                            <Box padding={inquireDialogRowPadding}>
                                <TextField
                                    id="lastname"
                                    label={translate("Last Name")}
                                    fullWidth
                                    margin="dense"
                                    type="text"
                                    variant="outlined"
                                    required={true}
                                    value={inquireDialogLastNameState.get()}
                                    onChange={async e => {
                                        const value = e.currentTarget.value;
                                        return Promise.resolve().then(() => {
                                            return Promise.resolve().then(() => {
                                                inquireDialogLastNameState.set(value);
                                            });
                                        })
                                    }}
                                />
                            </Box>
                            <Box padding={inquireDialogRowPadding}>
                                <FwPhoneNumberBox
                                    label={translate('Phone')}
                                    value={inquireDialogPhoneState.get() ?? ''}
                                    required={true}
                                    onChange={async e => {
                                        const value = e.currentTarget.value;
                                        return Promise.resolve().then(() => {
                                            inquireDialogPhoneState.set(value);
                                        });
                                    }}
                                />
                            </Box>
                            <Box padding={inquireDialogRowPadding}>
                                <TextField
                                    id="email"
                                    label={translate("Email")}
                                    fullWidth
                                    margin="dense"
                                    type="text"
                                    variant="outlined"
                                    required={true}
                                    value={inquireDialogEmailState.get()}
                                    onChange={async e => {
                                        const value = e.currentTarget.value;
                                        return Promise.resolve().then(() => {
                                            return Promise.resolve().then(() => {
                                                inquireDialogEmailState.set(value);
                                            });
                                        })
                                    }}
                                />
                            </Box>
                            <Box padding="2em 0 0 0">
                                <TextField
                                    id="message"
                                    label={translate("Message")}
                                    fullWidth
                                    multiline
                                    margin="dense"
                                    type="text"
                                    variant="outlined"
                                    required={true}
                                    inputProps={{
                                        style: {
                                            minHeight: '150px'
                                        }
                                    }}
                                    value={inquireDialogMessageState.get()}
                                    onChange={async e => {
                                        const value = e.currentTarget.value;
                                        return Promise.resolve().then(() => {
                                            return Promise.resolve().then(() => {
                                                inquireDialogMessageState.set(value);
                                            });
                                        })
                                    }}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeInquireDialog}>{translate("Cancel")}</Button>
                            <Button onClick={sendInquireDialog}>{translate("Send")}</Button>
                        </DialogActions>
                    </Dialog>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                inquireDialogOpenState.set(true);
                            });
                        }}
                    >
                        <ContactSupport />{translate('Inquire')}
                    </Button>
                </div>
                <div>
                    <Dialog open={shareDialogOpenState.get()} onClose={closeShareDialog} fullWidth={true} maxWidth={"sm"}>
                        <DialogTitle>
                            {translate('Share')}
                            <IconButton
                                aria-label="close"
                                onClick={closeShareDialog}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexWrap="wrap">
                                <Tooltip title="E-mail">
                                    <EmailShareButton subject={`Inquiry: #${product?.InventoryNo} ${product.ItemName}`} url={productUrl}>
                                        <EmailIcon size={shareIconSize} round={shareIconRound} />
                                    </EmailShareButton>
                                </Tooltip>
                                <Tooltip title="Facebook">
                                    <FacebookShareButton url={productUrl}>
                                        <FacebookIcon size={shareIconSize} round={shareIconRound} />
                                    </FacebookShareButton>
                                </Tooltip>
                                <Tooltip title="X">
                                    <TwitterShareButton url={productUrl}>
                                        <div css={css`background-color:#000000;width:${shareIconSize}px;height:${shareIconSize}px;display:flex;justify-content:center;align-items:center;border-radius:50%;overflow:hidden;`}>
                                            <svg width={shareIconSize / 2.5} height={shareIconSize / 2.5} viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white" />
                                            </svg>
                                        </div>
                                    </TwitterShareButton>
                                </Tooltip>
                                <Tooltip title="Pinterest">
                                    <PinterestShareButton url={productUrl} media={productUrl}>
                                        <PinterestIcon size={shareIconSize} round={shareIconRound} />
                                    </PinterestShareButton>
                                </Tooltip>
                                <Tooltip title="Reddit">
                                    <RedditShareButton url={productUrl}>
                                        <RedditIcon size={shareIconSize} round={shareIconRound} />
                                    </RedditShareButton>
                                </Tooltip>
                                <Tooltip title="Telegram">
                                    <TelegramShareButton url={productUrl}>
                                        <TelegramIcon size={shareIconSize} round={shareIconRound} />
                                    </TelegramShareButton>
                                </Tooltip>
                                <Tooltip title="VK">
                                    <VKShareButton url={productUrl}>
                                        <VKIcon size={shareIconSize} round={shareIconRound} />
                                    </VKShareButton>
                                </Tooltip>
                                <Tooltip title="WhatsApp">
                                    <WhatsappShareButton url={productUrl}>
                                        <WhatsappIcon size={shareIconSize} round={shareIconRound} />
                                    </WhatsappShareButton>
                                </Tooltip>
                            </Box>
                            <Box display="flex">
                                <Box flex="1 0 200px">
                                    <TextField
                                        css={css`flex:1;`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="dense"
                                        id="name"
                                        label={translate('Link')}
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={productUrl}
                                        onFocus={e => { e.target.select(); }}
                                    />
                                </Box>
                                <Box flex="0" display="flex" alignItems="center" justifyContent="center">
                                    <Tooltip title={translate('Copy')} >
                                        <IconButton aria-label="copy" onClick={copyLinkToClipboard}>
                                            <ContentCopy />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                shareDialogOpenState.set(true);
                            });
                        }}
                    >
                        <Share />{translate('Share')}
                    </Button>
                </div>
                <div>
                    <PrintPageButton pageUrl={`${printProductUrl}`} printMode={true} />
                </div>
                {productDetailsState.get().showPopup && (
                    <div
                        css={css`
                            .MuiButtonBase-root {
                                background-color: #D32F2F;
                                color: #ffffff;
                                min-width: 32px;
                            }
                        `}
                    >
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={async e => {
                                return Promise.resolve().then(() => {
                                    productDetailsState.setShowPopup(false)
                                });
                            }}
                        >
                            <Close />{/*productDetailsState.translate('Close')*/}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
    return null;
}