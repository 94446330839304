/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstateEffect } from "@hookstate/core";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { ProductDetails } from "../components/ProductDetails/ProductDetails";
import { SiteLayout } from "../components/SiteLayout";
import { AppConstants } from "../data/AppConstants";
import { useCheckoutFormState } from "../hooks/useCheckoutFormState";
import { useProductDetailsState } from "../hooks/useProductDetailsState";

//------------------------------------------------------------------------------------ 
type ProductPageProps = {

}
const ProductPage: React.FC<ProductPageProps> = props => {
    const { productid, warehouseid, locationid, fromdate, todate } = useParams();
    const productDetailsState = useProductDetailsState();
    const product = productDetailsState.get().product;
    let productDetailsProductId: string | undefined;
    if (product !== undefined) {
        productDetailsProductId = product.Id;
    }
    const checkoutFormState = useCheckoutFormState();

    useHookstateEffect(() => {
        if (product !== undefined) {
            const today = dayjs().startOf('day');
            let newFromDate = fromdate;
            let newToDate = todate;
            let needsToNavigate = false;
            if (dayjs(fromdate).isBefore(today)) {
                newFromDate = today.format('YYYY-MM-DD');
                newToDate = dayjs(fromdate).add(6, 'day').format('YYYY-MM-DD');
                needsToNavigate = true;
            }
            if (dayjs(todate).isBefore(fromdate)) {
                newToDate = dayjs(fromdate).add(6, 'day').format('YYYY-MM-DD');
                needsToNavigate = true;
            }
            if (needsToNavigate) {
                window.history.pushState(null, '', `#/product/${product.Id}/warehouseid/${warehouseid}/locationid/${locationid}/fromdate/${newFromDate}/todate/${newToDate}`);
            }
        }
    }, [fromdate, todate]);

    useHookstateEffect(() => {
        (async () => {
            //console.log(`productid: ${productid}, productDetailsProductId: ${productDetailsProductId}`)
            if ((warehouseid && !productDetailsState.get().isLoadingProductDetails) && productid && ((!productDetailsProductId) || (productDetailsProductId && (productid !== productDetailsProductId)))) {
                checkoutFormState.setWarehouseId(warehouseid);
                await productDetailsState.setProductIdAsync(productid);
            }
        })();
    }, [productid, productDetailsProductId, warehouseid]);

    return (<>
        <SiteLayout
            title="Product"
            mainChildren={<>
                <div css={css`
                    display:flex;
                    justify-content:center;
                    overflow:hidden;
                    `}>
                    <div css={css`
                        flex:1 1 0;
                        max-width:${AppConstants.siteWidthCss};
                        display:flex;
                        justify-content:center;
                        @media screen {
                            overflow: auto;
                        }
                    `}>
                        <ProductDetails printMode={false} />
                    </div>
                </div>
            </>}
        />
    </>);
}

export default ProductPage