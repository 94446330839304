/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useHookstate } from "@hookstate/core";
import { FilterAlt, Folder, FolderOpen, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import { Catalog } from "../../../hooks/useCatalogState";
//import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";
import { ProductCategory, ProductDepartment, ProductSubCategory, useInventoryState } from "../../../hooks/useInventoryState";
import { useThemeState } from "../../../hooks/useThemeState";
import { SubCategoryControl } from "./SubCategoryControl";

//-------------------------------------------------------------------------------------------------------
export type CategoryControlProps = {
    catalog: Catalog;
    department: ProductDepartment;
    category: ProductCategory;
    isParentFilterActive: boolean;
}
export const CategoryControl: React.FC<CategoryControlProps> = props => {
    const theme = useThemeState().get().theme;
    const inventoryState = useInventoryState();
    let isFilterActiveState = useHookstate<boolean>(false);
    let isFilterActive = isFilterActiveState.get();
    //let isFilterActive = (category !== undefined) && (props.category.Id === category.Id)
    let url = `/inventory/${props.catalog.Id}/${props.department.Id}/${props.category.Id}`;
    //if (isFilterActive) {
    //    url = `/inventory/${props.catalog.Id}/${props.department.Id}`;
    //}
    //const checkoutFormState = useCheckoutFormState();
    //const warehouse = checkoutFormState.getWarehouse();
    const hasSubCategories = (props.category.SubCategories && props.category.SubCategories.length > 0) ?? false;
    const isSelected = (inventoryState.get().category?.Id === props.category.Id);

    return (
        <React.Fragment key={props.category.Id}>
            <div
                css={css`
                    display:flex;
                    align-items:center;
                    margin:0 0 0 8px;
                    padding:.1rem 0;
                `}
                onClick={async () => {
                    return Promise.resolve().then(() => {
                        isFilterActive = (!isFilterActive);
                        isFilterActiveState.set(isFilterActive);
                        //if (!hasSubCategories) {
                        //    inventoryState.setProductFilterAsync({
                        //        warehouse: warehouse,
                        //        catalog: props.catalog,
                        //        department: props.department,
                        //        category: isFilterActive ? props.category : undefined,
                        //        subCategory: undefined,
                        //        searchText: '',
                        //        showLandingPage: false
                        //    });
                        //}
                    });
                }}
            >
                {!hasSubCategories && (<>
                    <Link
                        to={url}
                        css={css`
                            flex:1 1 0;
                            cursor:pointer;
                            color: ${isSelected ? theme.palette.siteSideBarContrastText : theme.palette.siteSideBarText};
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }`}>
                        <FilterAlt sx={{ fontSize: 12 }} />
                        <span css={css`padding: 0 0 0 4px;`}>{props.category.Name}</span>
                    </Link>
                </>)}
                {hasSubCategories && (<>
                    <div
                        css={css`
                            flex:1 1 0;
                            display:flex;
                        `}>
                        <div
                            css={css`
                                flex:1 1 0;
                                cursor:pointer;
                                font-size:.8rem;
                                padding-left:.5em;
                                text-indent: -.5em;
                                display:flex;
                                align-items: center;
                            `}>
                            <div css={css`flex: 0 0 auto;`}>
                                {!isFilterActive && (<Folder sx={{ fontSize: 12 }} />)}
                                {isFilterActive && (<FolderOpen sx={{ fontSize: 12 }} />)}
                            </div>
                            <div css={css`flex: 1 1 0;padding:0 10px;`}>
                                {props.category.Name}
                            </div>
                        </div>
                            
                        {props.category.SubCategories && props.category.SubCategories.length > 0 &&
                            <div
                                css={css`
                                    flex:0 0 auto;
                                    color:${theme.palette.siteSideBarText};
                                `}>
                                {!isFilterActive &&
                                    <KeyboardArrowRight css={css`font-size:.8rem;`} />
                                }
                                {isFilterActive &&
                                    <KeyboardArrowDown css={css`font-size:.8rem;`} />
                                }
                            </div>
                        }
                    </div>
                </>)}
                


            </div>
            {isFilterActive && props.category.SubCategories && props.category.SubCategories.map((productSubCategory: ProductSubCategory) => (
                <SubCategoryControl
                    key={productSubCategory.Id}
                    catalog={props.catalog}
                    department={props.department}
                    category={props.category}
                    subCategory={productSubCategory}
                    isParentFilterActive={props.isParentFilterActive && isFilterActive} />
            ))}
        </React.Fragment>
    );
}