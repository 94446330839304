/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import { Catalog } from "../../hooks/useCatalogState";
import { useInventoryState } from "../../hooks/useInventoryState";

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Button } from "@mui/material";
import { useCheckoutFormState } from "../../hooks/useCheckoutFormState";
import { useStorefrontSettingsState } from "../../hooks/useStorefrontSettingsState";
import { AutoplayOptions, SwiperModule } from "swiper/types";
import { SafeHtmlContent } from "../SafeHtmlContent";
import { useThemeState } from "../../hooks/useThemeState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";
//-------------------------------------------------------------------------------------------------------
export type LandingPageProps = {
    catalog: Catalog | ImmutableObject<Catalog>;
    showCatalogName: boolean;
}
export const LandingPage: React.FC<LandingPageProps> = props => {
    const translate = useTranslate();
    const themeState = useThemeState();
    const isDarkTheme = themeState.isDark();
    const inventoryState = useInventoryState();
    const navigate = useNavigate();
    const checkoutFormState = useCheckoutFormState();
    const warehouse = checkoutFormState.getWarehouse();
    const location = checkoutFormState.getLocation();

    const storefrontSettingsState = useStorefrontSettingsState();
    const swiperModules: SwiperModule[] = [];
    let autoplayOptions: AutoplayOptions | undefined;
    const settings = storefrontSettingsState.get().settings;
    if (settings && settings.AutoplayImagesOnLandingPage) {
        swiperModules.push(Autoplay);
        autoplayOptions = {
            delay: settings.AutoplaySpeed * 1000,
        };
    }
    swiperModules.push(Pagination);
    swiperModules.push(Navigation);

    return (
        <div
            css={css`
                ${props.showCatalogName && `
                    border-bottom: ${isDarkTheme ? '1px solid rgba(255,255,255,.1);' : '1px solid rgba(0,0,0,.1);'}
                    padding: 2rem 0 2rem 0;
                    .swiper-slide img {
                        cursor: pointer;
                    }
                `}
                ${!props.showCatalogName && `
                    padding: 2rem 0 2rem 0;
                `}
                .swiper-wrapper {
                    align-items:center;
                }
            `}>
            {(props.showCatalogName) && (<>
                <div
                    css={css`
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    font-family: 'Ropa Sans';
                    font-size: 2em;
                    padding: 0 0 2px 0;
                `}>
                    {props.catalog?.Name ?? ''}
                </div>
            </>)}
            
            {/*autoplay={{*/}
            {/*    delay: 10000,*/}
            {/*    stopOnLastSlide: false*/}
            {/*}}*/}

            {(props.catalog.Images?.length > 0) && (
                <div css={css`
                    margin: .5em 0 0 0;
                    .swiper-wrapper
                    {
                      transition-timing-function: linear;
                    }
                `}>
                    <Swiper
                        //speed={1000}
                        pagination={{
                            dynamicBullets: true,
                            type: "bullets"
                        }}
                        autoplay={autoplayOptions}
                        spaceBetween={0}
                        navigation={false}
                        grabCursor={true}
                        centeredSlides={true}
                        centerInsufficientSlides={true}
                        slidesPerView={'auto'}
                        modules={swiperModules}
                        onClick={async _e => {
                            return Promise.resolve().then(() => {
                                if (props.showCatalogName) {
                                    //inventorystate.setShowLandinPage(false);
                                    navigate(`/inventory/${props.catalog.Id}/index`);
                                    inventoryState.loadProductCategoryTreeAsync(props.catalog.Id);
                                    inventoryState.filterByCatalogIdAsync(warehouse, location, props.catalog.Id, true);
                                }
                            });
                        }}
                    >
                        {(props.catalog.Images?.split(',') ?? []).map((image, index, images) => (
                            <SwiperSlide key={index}>
                                <img
                                    src={image} alt={props.catalog.Name}
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        maxHeight: '40vh'
                                    }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}

            {(props.showCatalogName) && (<>
                <div
                    css={css`
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        margin: 16px 0 0 0;
                    `}>
                    <Button
                        variant="outlined"
                        onClick={async e => {
                            return Promise.resolve().then(() => {
                                //inventoryState.setShowLandingPage(false);
                                navigate(`/inventory/${props.catalog.Id}/index`);
                                inventoryState.loadProductCategoryTreeAsync(props.catalog.Id);
                                inventoryState.filterByCatalogIdAsync(warehouse, location, props.catalog.Id, true);
                            });
                        }}>
                        {translate('View Catalog')}: {props.catalog.Name ?? ''}
                    </Button>
                </div>
            </>)}

            {(props.catalog.LandingPageHtml && props.catalog.LandingPageHtml.length > 0) && (<>
                <div
                    css={css`
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    font-family: 'Ropa Sans';
                    font-size: 2.5em;
                    padding: 0 0 10px 0;
                `}>
                    <SafeHtmlContent htmlContent={props.catalog.LandingPageHtml} />
                </div>
            </>)}
        </div>
    );
}