/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
//import { useCatalogState } from "../../../hooks/useCatalogState";
import { ShoppingCartItemModel } from "../../../hooks/useShoppingCartItemsState";
//import { useSiteLayoutState } from "../../../hooks/useSiteLayoutState";
//import FwNumberBox, { useFwNumberBoxState } from "../../../lib/FwReact/FwNumberBox";
//import { FwNumberDropDown } from "../../../lib/FwReact/FwNumberDropDown";
import { AddOrUpdateCart } from "../AddOrUpdateCart";

export type ShoppingCartItemQtyProps = {
    item: ShoppingCartItemModel;
    index: number;
}
export const ShoppingCartItemQty: React.FC<ShoppingCartItemQtyProps> = props => {

    return (<>
        <AddOrUpdateCart product={props.item.product} shoppingCartMode={true} />
    </>)
}
//export const ShoppingCartItemQty: React.FC<ShoppingCartItemQtyProps> = props => {
//    const item = props.item;
//    const shoppingCartItemsState = useShoppingCartItemsState();
//    const product = item.product;
//    const fwNumberBoxState = useFwNumberBoxState();
//    const tooltipId = 'ShoppingCartItemQtyProps-' + product.Id;
//    const siteLayoutState = useSiteLayoutState();

//    if (product) {
//        const shoppingCartQty = shoppingCartItemsState.getQty(product);

//        const onChangeQty = async (value: number, oldValue: number) => {
//            return Promise.resolve().then(() => {
//                const totalIn = product.Availability?.TotalIn ?? 0;
//                if (value > totalIn) {
//                    siteLayoutState.showTooltip({ id: tooltipId, variant: 'warning', place: 'top', hideDelay: 6000, message: shoppingCartItemsState.translate('Cannot request more than then the total available qty.') })
//                    value = totalIn;
//                }
//                else if (value < 0) {
//                    value = 0;
//                }
//                shoppingCartItemsState.setQty(product!, value);
//                if (value > shoppingCartQty) {
//                    siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', message: `${shoppingCartItemsState.translate('Added')}: ${value - shoppingCartQty}` })
//                }
//                else if (value < shoppingCartQty) {
//                    siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', message: `${shoppingCartItemsState.translate('Removed')}: ${shoppingCartQty - value}` })
//                }
//            });
//        };

//        return (<>
//            <div css={css`padding:.5em;`}>
//                {/*<FwNumberBox*/}
//                {/*    label={shoppingCartItemsState.translate('Qty')}*/}
//                {/*    value={item.qty}*/}
//                {/*    fwNumberBoxState={fwNumberBoxState}*/}
//                {/*    onChange={async value => {*/}
//                {/*        return Promise.resolve().then(() => {*/}
//                {/*            const totalIn = product.Availability?.TotalIn ?? 0;*/}
//                {/*            if (value > totalIn) {*/}
//                {/*                siteLayoutState.showTooltip({ id: tooltipId, variant: 'warning', place: 'top', hideDelay: 6000, message: shoppingCartItemsState.translate('Cannot request more than then the total available qty.') })*/}
//                {/*                value = totalIn;*/}
//                {/*            }*/}
//                {/*            else if (value < 0) {*/}
//                {/*                value = 0;*/}
//                {/*            }*/}
//                {/*            shoppingCartItemsState.setQty(product!, value);*/}
//                {/*            if (value > shoppingCartQty) {*/}
//                {/*                siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', message: `${shoppingCartItemsState.translate('Added')}: ${value - shoppingCartQty}` })*/}
//                {/*            }*/}
//                {/*            else if (value < shoppingCartQty) {*/}
//                {/*                siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', message: `${shoppingCartItemsState.translate('Removed')}: ${shoppingCartQty - value}` })*/}
//                {/*            }*/}
//                {/*        });*/}
//                {/*    }}*/}
//                {/*/>*/}
//                <FwNumberDropDown
//                    label={shoppingCartItemsState.translate('Qty')}
//                    value={item.qty}
//                    onChange={onChangeQty}
//                />
//            </div>
//        </>);
//    }
//    return null;
//}