/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { Product } from "../../../hooks/useInventoryState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";


// ListView - Availability
export type ProductBrowserListItemAvailabilityProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserListItemAvailability: React.FC<ProductBrowserListItemAvailabilityProps> = props => {
    const translate = useTranslate();
    const product = props.product;
    const themeState = useThemeState();
    const themeName = themeState.get().themeName;

    return (<>
        {product.Availability && (
            <div className="availability" css={css`text-align:center;`}>
                {(product.Availability?.TotalIn ?? 0) > 0 && (
                    <span css={css`${themeName === 'dark' ? 'color:hsl(180 100% 40% / 1)' : 'color:#777777'};font-size:.8rem;`}>({(product.Availability?.TotalIn ?? 0).toString() + ' of ' + (product.Availability?.Total ?? 0).toString() + ' ' + translate('available')})</span>
                )}
                {(product.Availability?.TotalIn ?? 0) === 0 && (
                    <span css={css`color:hsl(0 0% 40% / 1);font-size:.8rem;`}>({translate('Unavailable')})</span>
                )}
            </div>
        )}
        {!product.Availability && (
            <div className="availability" css={css`text-align:center;`}>
                (no availability data)
            </div>
        )}
    </>);
}