/** @jsxImportSource @emotion/react */
//import { css } from "@emotion/react"
import { useProductDetailsState } from "../../../hooks/useProductDetailsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import FwFunc from "../../../lib/FwReact/FwFunc";

//------------------------------------------------------------------------------------
/* ReplacementCost */
export type ReplacementCostFieldProps = {

}
export const ReplacementCostField: React.FC<ReplacementCostFieldProps> = props => {
    const productDetailsState = useProductDetailsState();
    const translate = useTranslate();
    const product = productDetailsState.get().product;
    if (!product) {
        return null;
    }
    let replacementCost: number = productDetailsState.get().product?.ReplacementCost ?? 0;
    const unitValue: number = productDetailsState.get().product?.UnitValue ?? 0;
    if (unitValue > replacementCost) {
        replacementCost = unitValue;
    }
    return (<> 
        <div className="field">
            <div className="caption">{translate("Replacement Cost")}:</div>
            <div className="value">
                {product.CurrencySymbol} {FwFunc.numberFormatter(replacementCost, 2, ',', '.')} {product.CurrencyCode}
            </div>
        </div>
    </>);
}