import { useAppConfigState } from "../useAppConfigState";
import { useLocaleState } from "../useLocaleState";
import { BaseTranslations } from "./BaseTranslations";
import { ClientCodeTelemundoTranslationTable } from "./OverrideTranslations";

type TranslationKey = keyof typeof BaseTranslations;
type TranslationEntry = {
    en?: string;
    es: string;
    fr: string;
}
export type TranslationRenames = {
    [key: string]: string;
}
export type TranslationTable = {
    [key: string]: TranslationEntry;
}

export type TranslateFunction = (text: TranslationKey) => string;

export const useTranslate = (overrideTranslation?: TranslationTable) => {
    const localeState = useLocaleState();
    const language = localeState.get().language;
    const appConfigState = useAppConfigState();
    const clientCode = appConfigState.get().appConfig?.ClientCode;
    let mergedTranslations = Object.assign({}, BaseTranslations);
    if (overrideTranslation !== undefined) {
        mergedTranslations = Object.assign(mergedTranslations, overrideTranslation);
    }
    if (clientCode === 'TELEMUNDO') {
        mergedTranslations = Object.assign(mergedTranslations, ClientCodeTelemundoTranslationTable);
    }

    const translate: TranslateFunction = (text: TranslationKey): string => {
        if (language === 'es' || language === 'fr') {
            const translationEntry = mergedTranslations[text]
            if (translationEntry) {
                const translation = translationEntry[language] as string;
                if (translation) {
                    return translation;
                }
            }
        }
        else if (language === 'en') {
            const translationEntry = mergedTranslations[text] as TranslationEntry;
            if (translationEntry && translationEntry.hasOwnProperty('en')) {
                const translation = translationEntry.en;
                if (translation) {
                    return translation;
                }
            }
        }
        return text;
    }
    return translate;
}
