export default class FwFunc {
    static numberFormatter(number: number, fractionDigits: number = 0, thousandSeperator: string = ',', fractionSeperator: string = '.'): string {
        if (typeof number !== 'number') {
            number = 0;
        }
        if (number !== 0 && (!number || !Number.isFinite(number))) return number.toString();
        const frDigits = Number.isFinite(fractionDigits) ? Math.min(Math.max(fractionDigits, 0), 7) : 0
        const num: string = number.toFixed(frDigits).toString()

        const parts = num.split('.')
        let digits = parts[0].split('').reverse()
        let sign: string = ''
        if (+num < 0) {
            sign = digits.pop() ?? ''
        }
        let final = []
        let pos = 0

        while (digits.length > 1) {
            final.push(digits.shift())
            pos++
            if (pos % 3 === 0) { final.push(thousandSeperator) }
        }
        final.push(digits.shift())
        return `${sign}${final.reverse().join('')}${frDigits > 0 ? fractionSeperator : ''}${frDigits > 0 && parts[1] ? parts[1] : ''}`
    }

    static beep(freq = 660, duration = 90, vol = 50) {
        var context = new (window.AudioContext || (window as any).webkitAudioContext)();
        const oscillator = context.createOscillator();
        const gain = context.createGain();
        gain.gain.setValueAtTime(0, context.currentTime);
        gain.gain.linearRampToValueAtTime(1, context.currentTime + 0.002);
        oscillator.connect(gain);
        oscillator.frequency.value = freq;
        oscillator.type = "square";
        gain.connect(context.destination);
        oscillator.start(context.currentTime);
        oscillator.stop(context.currentTime + duration * .001);
        oscillator.onended = () => context.close();
    }
}
