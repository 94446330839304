/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useResetPasswordFormState } from "../hooks/useResetPasswordFormState";
import { ResetPasswordForm } from "../components/ResetPasswordForm";
import { SiteLayout } from "../components/SiteLayout";
//import { useEffect } from "react";
import { useHookstateEffect } from "@hookstate/core";
import { useParams } from "react-router-dom";
//import { useSiteLayoutState } from "../hooks/useSiteLayoutState";

export type ResetPasswordPageProps = {
    type: 'resetpassword' | 'completeregistration' | 'changepassword';
}
export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = props => {
    const { email, resettoken } = useParams();
    const resetPasswordFormState = useResetPasswordFormState();
    const search = window.location.search;
    //const siteLayoutState = useSiteLayoutState();

    useHookstateEffect(() => {
        resetPasswordFormState.setIsPopup(false);
        //const email = new URLSearchParams(search).get('email');
        //const resettoken = new URLSearchParams(search).get('resettoken');
        //console.log('Reset Password:', search, email, resettoken);
        if (email && resettoken) {
            //console.log(2)
            resetPasswordFormState.setEmail(email);
            resetPasswordFormState.setTemporaryPassword(resettoken);
        }
    }, [search]);

    return (<SiteLayout
        title={resetPasswordFormState.translate('Reset Password')}
        mainChildren={
            <div css={css`
                flex:1 1 0;`}>
                <div css={css`
                    display:flex;
                    justify-content:center;
                    align-items:center;`}>
                    {email && resettoken && (
                        <ResetPasswordForm type={props.type} />
                    )}
                </div>
            </div>
        }
    />);
}
