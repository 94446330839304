/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useCheckoutFormState } from "../hooks/useCheckoutFormState";
import { SiteLayout } from "../components/SiteLayout";
import { CheckoutForm } from "../components/CheckoutForm/CheckoutForm";
import { CustomScrollbars } from "../components/CustomScrollbars/CustomScrollbars";
import { AppConstants } from "../data/AppConstants";
import { useTranslate } from "../hooks/useTranslate/useTranslate";

export type CheckoutPageProps = {

}
export const CheckoutPage: React.FC<CheckoutPageProps> = props => {
    const quoteRequestFormState = useCheckoutFormState();
    const translate = useTranslate();

    return (<SiteLayout
        title={translate('Checkout')}
        mainChildren={
            <CustomScrollbars
                css={css`
                    flex:1 1 0;
                `}>
                <div css={css`max-width:${AppConstants.siteWidthCss};margin: 0 auto;`}>
                    <CheckoutForm checkoutFormState={quoteRequestFormState} />
                </div>
            </CustomScrollbars>
        }
    />);
}
