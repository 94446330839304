/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject, useHookstate, useHookstateEffect } from "@hookstate/core";
//import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup /*, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper*/ } from "@mui/material";
import React from "react";
import { useCatalogState } from "../../hooks/useCatalogState";
import { Product } from "../../hooks/useInventoryState";
import { useShoppingCartItemsState } from "../../hooks/useShoppingCartItemsState";
import { useSiteLayoutState } from "../../hooks/useSiteLayoutState";
import { useTranslate } from "../../hooks/useTranslate/useTranslate";
import { FwNumberDropDown } from "../../lib/FwReact/FwNumberDropDown";

export type AddToCartProps = {
    product: ImmutableObject<Product>;
    shoppingCartMode?: boolean;
    showQtyInCart?: boolean;
    minHeight?: string;
}
export const AddOrUpdateCart: React.FC<AddToCartProps> = props => {
    const product = props.product;
    const shoppingCartItemsState = useShoppingCartItemsState();
    const shoppingCartQty = shoppingCartItemsState.getQty(product!);
    //const fwNumberBoxState = useFwNumberBoxState();
    const tooltipId = 'AddToCart-' + product.Id;
    const siteLayoutState = useSiteLayoutState();
    const isAvailable = product && ((product.Availability?.TotalIn ?? 0) > 0);
    const translate = useTranslate();

    const initialQtyToDisplay = props.shoppingCartMode ? shoppingCartQty : ((shoppingCartQty > 0) ? shoppingCartQty : 1);

    //console.log({
    //    product: product.ItemName,
    //    shoppingCartQty: shoppingCartQty,
    //    initialQtyToDisplay: initialQtyToDisplay
    //})

    // split button
    const qtyState = useHookstate<number>(initialQtyToDisplay);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    //const [open, setOpen] = React.useState(false);
    //const [selectedIndex, setSelectedIndex] = React.useState(0);

    const catalogState = useCatalogState();
    const catalog = catalogState.getProductCatalog(product);
    useHookstateEffect(() => {
        if (catalog) {
            //const canAddtoCart = isAvailable || catalog.AllowAddingUnavailableItems;
            qtyState.set(initialQtyToDisplay);
        }
    }, [catalog]);
    if (!catalog) {
        return null;
    }

    const canAddtoCart = isAvailable || catalog.AllowAddingUnavailableItems;
    

    const qty = qtyState.get();
    const qtyIn = (product.Availability?.TotalIn ?? 0);
    const qtyAvailable = qtyIn - shoppingCartQty;
    const minHeight = props.minHeight ?? 'auto';
    let maxQty = undefined;
    if (props.shoppingCartMode) {
        if (!catalog.AllowAddingUnavailableItems) {
            maxQty = qtyIn;
        }
    }
    else {
        if (!catalog.AllowAddingUnavailableItems) {
            maxQty = qtyIn;
        }
    }

    // Split button
    const ADD_TO_CART = translate('Add to cart');
    const UPDATE_CART = translate('Update cart');
    const options: string[] = []
    if (canAddtoCart) {
        options.push(ADD_TO_CART);
    }
    if (shoppingCartQty > 0) {
        options.push(UPDATE_CART);
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        //const selectedValue = options[selectedIndex];
        //if (selectedValue === ADD_TO_CART) {
        //    addToCart();
        //}
        //else if (selectedValue === UPDATE_CART) {
        //    updateCart();
        //}
        if (shoppingCartQty === 0) {
            addToCart();
        }
        else {
            updateCart();
        }

    };
    //const handleMenuItemClick = (
    //    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    //    index: number,
    //) => {
    //    //setSelectedIndex(index);
    //    setOpen(false);
    //};
    //const handleToggle = () => {
    //    setOpen((prevOpen) => !prevOpen);
    //};
    //const handleClose = (event: Event) => {
    //    if (
    //        anchorRef.current &&
    //        anchorRef.current.contains(event.target as HTMLElement)
    //    ) {
    //        return;
    //    }

    //    setOpen(false);
    //};

    const addToCart = async () => {
        return Promise.resolve().then(() => {
            if (qty > 0) {
                const shoppingCartQty = shoppingCartItemsState.getQty(product);
                const newQty = shoppingCartQty + qty;
                if ((!catalog.AllowAddingUnavailableItems) && (newQty > qtyAvailable)) {
                    siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', message: translate('Cannot request more than then the total available qty.') + ': ' + qtyAvailable.toString() });
                    return;
                }
                shoppingCartItemsState.setQty(product, newQty);
                //const canAddToCart = catalog.AllowAddingUnavailableItems || (qtyAvailable > 0);
                qtyState.set(newQty/*canAddToCart ? 1 : 0*/);
                siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', message: translate('Added') + ': ' + qty.toString() })
            }
        });
    }

    const updateCart = async () => {
        return Promise.resolve().then(() => {
            const qty = qtyState.get();
            //const shoppingCartQty = shoppingCartItemsState.getQty(product);
            if ((!catalog.AllowAddingUnavailableItems) && (qty > qtyIn)) {
                siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', message: translate('Cannot request more than then the total available qty.') + ': ' + qtyAvailable.toString() });
                return;
            }
            shoppingCartItemsState.setQty(product, qty);
            //const canAddToCart = catalog.AllowAddingUnavailableItems || (qtyAvailable > 0);
            //qtyState.set(canAddToCart ? 1 : 0);
            //if (qty === 0) {
            //    setSelectedIndex(0);
            //}
            siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', message: translate('Updated') + ': ' + qty.toString() })
        });
    }

    return (<>
        <div
            css={css`
            flex: 0 0 auto;
            padding:0;
            min-height:${minHeight};
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            font-size: .7rem;
            `}>
            {((props.showQtyInCart) && shoppingCartQty > 0) && (
                <div css={css`display:flex;justify-content:center;align-items:center;padding:.5rem;font-size:.8rem;background-color:#f44336;color:#ffffff;border-radius: .5em;`}>
                    {`${shoppingCartItemsState.getQty(product!)} ${translate('in cart')}`}
                </div>
            )}
            {canAddtoCart && (
                <div css={css`margin: .5rem auto;width:6rem;`}>
                        {/*value={props.shoppingCartMode ? shoppingCartQty : qtyState.get()}*/}
                    <FwNumberDropDown
                        label={translate('Qty')}
                        labelPrefix={translate('Qty')}
                        value={props.shoppingCartMode ? shoppingCartQty : qtyState.get()}
                        maxQty={maxQty}
                        onChange={value => {
                            if (props.shoppingCartMode) {
                                shoppingCartItemsState.setQty(product, value);
                            } else {
                                qtyState.set(value)
                            }
                        }}
                    />
                </div>
                //<FwNumberBox
                //    label={shoppingCartItemsState.translate('Qty in cart')}
                //    fwNumberBoxState={fwNumberBoxState}
                //    showPlusMinusButtons={true}
                //    value={shoppingCartItemsState.getQty(product!)}
                //    onChange={async value => {
                //        return Promise.resolve().then(() => {
                //            const totalIn = product.Availability?.TotalIn ?? 0;
                //            if (value > totalIn) {
                //                if (!props.allowAddingUnavailableItems) {
                //                    siteLayoutState.showTooltip({ id: tooltipId, variant: 'warning', place: 'top', hideDelay: 6000, message: shoppingCartItemsState.translate('Cannot request more than then the total available qty.') })
                //                    shoppingCartItemsState.setQty(product, totalIn);
                //                }
                //                else {
                //                    shoppingCartItemsState.setQty(product, value);
                //                    if (value > shoppingCartQty) {
                //                        siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', hideDelay: 1500, message: `${shoppingCartItemsState.translate('Added')} ${value - shoppingCartQty}` })
                //                    }
                //                    else if (value < shoppingCartQty) {
                //                        siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', hideDelay: 1500, message: `${shoppingCartItemsState.translate('Removed')}: ${shoppingCartQty - value}` })
                //                    }
                //                }

                //            }
                //            else if (value < 0) {
                //                shoppingCartItemsState.setQty(product, 0);
                //            }
                //            else {
                //                if (value !== shoppingCartQty) {
                //                    shoppingCartItemsState.setQty(product, value);
                //                    if (value > shoppingCartQty) {
                //                        siteLayoutState.showTooltip({ id: tooltipId, variant: 'success', place: 'top', hideDelay: 1500, message: `${shoppingCartItemsState.translate('Added')} ${value - shoppingCartQty}` })
                //                    }
                //                    else if (value < shoppingCartQty) {
                //                        siteLayoutState.showTooltip({ id: tooltipId, variant: 'error', place: 'top', hideDelay: 1500, message: `${shoppingCartItemsState.translate('Removed')}: ${shoppingCartQty - value}` })
                //                    }
                //                }
                //            }
                //        });
                //    }}
                ///>
            )}
            {canAddtoCart && (options.length > 0) && (!props.shoppingCartMode) && (
                <div css={css`display:flex;justify-content:center;align-items:center;width:10rem;`}>
                    <ButtonGroup
                        variant="outlined"
                        ref={anchorRef}
                        aria-label="Add/update cart"
                        //disabled={(qty === 0) && (shoppingCartQty === 0)}
                    >
                        <Button
                            variant="outlined"
                            sx={{ fontSize: '.6rem' }}
                            fullWidth
                            data-tooltip-id={tooltipId}
                            onClick={handleClick}
                        >
                            {/*{options[selectedIndex]}*/}
                            {options[(shoppingCartQty > 0) ? 1 : 0]}
                        </Button>
                        {/*{(shoppingCartQty > 0) && (options.length > 1) && (*/}
                        {/*    <Button*/}
                        {/*        size="small"*/}
                        {/*        aria-controls={open ? 'split-button-menu' : undefined}*/}
                        {/*        aria-expanded={open ? 'true' : undefined}*/}
                        {/*        aria-label="select merge strategy"*/}
                        {/*        aria-haspopup="menu"*/}
                        {/*        onClick={handleToggle}*/}
                        {/*    >*/}
                        {/*        <ArrowDropDown />*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </ButtonGroup>
                    {/*<Popper*/}
                    {/*    sx={{*/}
                    {/*        zIndex: 1,*/}
                    {/*    }}*/}
                    {/*    open={open}*/}
                    {/*    anchorEl={anchorRef.current}*/}
                    {/*    role={undefined}*/}
                    {/*    transition*/}
                    {/*    disablePortal*/}
                    {/*>*/}
                    {/*    {({ TransitionProps, placement }) => (*/}
                    {/*        <Grow*/}
                    {/*            {...TransitionProps}*/}
                    {/*            style={{*/}
                    {/*                transformOrigin:*/}
                    {/*                    placement === 'bottom' ? 'center top' : 'center bottom',*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Paper>*/}
                    {/*                <ClickAwayListener onClickAway={handleClose}>*/}
                    {/*                    <MenuList id="split-button-menu" autoFocusItem>*/}
                    {/*                        {options.map((option, index) => (*/}
                    {/*                            <MenuItem*/}
                    {/*                                key={option}*/}
                    {/*                                disabled={index === 2}*/}
                    {/*                                selected={index === selectedIndex}*/}
                    {/*                                onClick={(event) => handleMenuItemClick(event, index)}*/}
                    {/*                            >*/}
                    {/*                                {option}*/}
                    {/*                            </MenuItem>*/}
                    {/*                        ))}*/}
                    {/*                    </MenuList>*/}
                    {/*                </ClickAwayListener>*/}
                    {/*            </Paper>*/}
                    {/*        </Grow>*/}
                    {/*    )}*/}
                    {/*</Popper>*/}
                </div>
            )}
        </div>
    </>);
}