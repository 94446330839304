/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useLoginFormState } from "../hooks/useLoginFormState";
import { LoginForm } from "../components/LoginForm";
import { SiteLayout } from "../components/SiteLayout";
import { useEffect } from "react";

export type LoginPageProps = {

}
export const LoginPage: React.FC<LoginPageProps> = props => {
    const loginFormState = useLoginFormState();

    useEffect(() => {
        loginFormState.setIsPopup(false);
    }, [loginFormState]);

    return (<SiteLayout
        title={loginFormState.translate('Login')}
        mainChildren={
            <div css={css`
                flex:1 1 0;`}>
                <div css={css`
                    display:flex;
                    justify-content:center;
                    align-items:center;`}>
                    <LoginForm />
                </div>
            </div>
        }
    />);
}
