/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useCatalogState } from "../../../hooks/useCatalogState";
import { ShoppingCartItemModel, useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import { useThemeState } from "../../../hooks/useThemeState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import FwFunc from "../../../lib/FwReact/FwFunc";

export type ShoppingCartItemRatesProps = {
    item: ShoppingCartItemModel;
    hasDailyRate: boolean;
    hasWeeklyRate: boolean;
    hasMonthlyRate: boolean;
    hasSalesPrice: boolean;
    justifyContent?: string;
}
export const ShoppingCartItemRates: React.FC<ShoppingCartItemRatesProps> = props => {
    const item = props.item;
    const justifyContent = props.justifyContent ?? 'center';
    const product = item.product;
    const shoppingCartItemsState = useShoppingCartItemsState();
    const rates = shoppingCartItemsState.getRates(item);
    const translate = useTranslate();
    const hasRentalRates = props.hasDailyRate || props.hasWeeklyRate || props.hasMonthlyRate;
    const themeState = useThemeState();
    const isDarkTheme = themeState.isDark();
    const catalogState = useCatalogState();
    const catalog = catalogState.getProductCatalog(product);
    let isRentalCatalog = false;
    let isSalesCatalog = false;
    if (catalog) {
        isRentalCatalog = catalog.HasRental ?? false;
        isSalesCatalog = catalog.HasSales ?? false;
    }

    return (<>
        <div css={css`flex:0 0 auto;`}>
            <div
                css={css`
                    /*.rates {
                        padding: 0 .5em;
                        flex:1;
                        display: flex;
                        align-items: center;
                        justify-content: ${justifyContent};
                        text-align: center;
                    }*/
                    .currencysymbol {
                        flex: 1 0 auto;
                        font-size:.8rem;
                        text-align: right;
                        padding: 0 .25em;
                    }
                    .amount {
                        flex: 0 0 auto;
                        text-align: right;
                        padding: 0 .25em;
                    }
                    .amount-wrapper {
                        display: flex;
                        align-items:center;
                    }
                    .ratevalue {
                        flex: 0 0 auto;
                        text-align: left;
                        font-size:.8rem;
                    }
                    .currencycode {
                        font-size:.8rem;
                        text-align: left;
                    }
                    `}>
                <div css={css`display:flex;justify-content:${justifyContent};`}>
                    <table css={css`flex:1 1 0;`}>
                        <tbody>
                            {isRentalCatalog && hasRentalRates && (<>
                                <tr>
                                    <td colSpan={2}>
                                        <div
                                            css={css`
                                                display:flex;
                                                align-items:center;
                                                justify-content:center;
                                                font-size:.8rem;
                                                border-top:1px solid rgba(255,255,255,.1);
                                                background-color: ${isDarkTheme ? 'rgba(255,255,255,.1);' : 'rgba(0,0,0,.1);'}
                                            `}>
                                            {translate('For rent')}
                                        </div>
                                    </td>
                                </tr>
                                {props.hasDailyRate && (
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(rates.dailyRate, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{translate('daily')}</td>
                                        {/*<td className="currencycode">{product.CurrencyCode}/day</td>*/}
                                    </tr>
                                )}
                                {props.hasWeeklyRate && (
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(rates.weeklyRate, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{translate('weekly')}</td>
                                        {/*<td className="currencycode">{product.CurrencyCode}/week</td>*/}
                                    </tr>
                                )}
                                {props.hasMonthlyRate && (
                                    <tr>
                                        <td className="amount">
                                            <div className="amount-wrapper">
                                                <div className="currencysymbol">{product.CurrencySymbol}</div>
                                                <div className="ratevalue">{FwFunc.numberFormatter(rates.monthlyRate, 2, ',', '.')}</div>
                                            </div>
                                        </td>
                                        <td className="currencycode">{translate('monthly')}</td>
                                        {/*<td className="currencycode">{product.CurrencyCode}/month</td>*/}
                                    </tr>
                                )}
                            </>)}
                            {isSalesCatalog && props.hasSalesPrice && (<>
                                <tr>
                                    <td colSpan={2}>
                                        <div
                                            css={css`
                                                display:flex;
                                                align-items:center;
                                                justify-content:center;
                                                font-size:.8rem;
                                                border-top:1px solid rgba(255,255,255,.1);
                                                background-color: ${isDarkTheme ? 'rgba(255,255,255,.1);' : 'rgba(0,0,0,.1);'}
                                            `}>
                                            {translate('For sale')}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="amount">
                                        <div className="amount-wrapper">
                                            <div className="currencysymbol">{product.CurrencySymbol}</div>
                                            <div className="ratevalue">{FwFunc.numberFormatter(rates.salesPrice, 2, ',', '.')}</div>
                                        </div>
                                    </td>
                                    <td className="currencycode">{product.CurrencyCode}</td>
                                </tr>
                            </>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>);
}