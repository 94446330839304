/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { lightTheme, useThemeState } from "../hooks/useThemeState";
import { Button, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { motion } from "framer-motion";
import { useSiteLayoutState } from "../hooks/useSiteLayoutState";
import { CustomScrollbars } from "./CustomScrollbars/CustomScrollbars";

type ErrorDialogProps = {

}
export const ErrorDialog: React.FC<ErrorDialogProps> = props => {
    const siteLayoutState = useSiteLayoutState();
    const themeState = useThemeState();
    const theme = themeState.get().theme;

    return (<>
        {siteLayoutState.get().errorMessage && (
            <ThemeProvider theme={lightTheme}>
                <div css={css`
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    z-index: 2;`}>
                    <div css={css`
                        background-color:#777777;
                        color:${theme.palette.siteSideBarText};
                        background-color: #ffffff;
                        color: #000000;
                        z-index: 3;
                        /*border:1px solid #777777;*/
                        border-radius:8px;
                        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
                        display:flex;
                        flex-direction:column;
                        min-width: 240px;
                        max-width: 95vw;
                        min-height:100px;
                        max-height:95vh;
                        overflow:hidden;`}>
                        <motion.div
                            layoutId="errordialog"
                            css={css`
                                flex:1 1 auto;
                                display:flex;
                                align-items:center;
                                background-color: #311B92;
                                color: #ffffff;
                                padding:.4px 8px;`}>
                            <div css={css`flex:1 1 0;padding:4px;`}>Error</div>
                            <div css={css`flex:0 0 auto;`}>
                                <IconButton
                                    onClick={e => {
                                        return Promise.resolve().then(() => {
                                            siteLayoutState.hideError();
                                        });
                                    }}>
                                    <Close css={css`color: #ffffff;`} />
                                </IconButton>
                            </div>
                        </motion.div>
                        <div
                            css={css`
                                flex:1 1 auto;
                                padding: 1em;
                                max-width: 95vw;
                                max-height: 95vh;
                                display:flex;
                                flex-direction: column;
                            `}
                        >
                            <CustomScrollbars
                                overridecss="flex:1 0 auto;"
                            >
                                {siteLayoutState.get().errorMessage}
                            </CustomScrollbars>
                        </div>
                        <div css={css`
                            flex:0 0 auto;
                            display:flex;
                            flex-align:center;
                            justify-content:center;
                            padding:.5em;`}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={async e => {
                                    return Promise.resolve().then(() => {
                                        siteLayoutState.hideError();
                                    });
                                }}>
                                {siteLayoutState.translate('OK')}
                                {/*<Done css={css`margin: 0 0 0 .5em;color:#00bcd4;`} />*/}
                            </Button>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        )}
    </>);
}